import React from "react";
import { Container, NavBtnLine } from "./styles";

interface INavBtnProps {
  toggleMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isMenuOpen: boolean;
}

const NavBtn = ({ toggleMenu, isMenuOpen }: INavBtnProps) => {
  return (
    <Container onClick={toggleMenu}>
      <NavBtnLine isMenuOpen={isMenuOpen}/>
      <NavBtnLine isMenuOpen={isMenuOpen}/>
      <NavBtnLine isMenuOpen={isMenuOpen}/>
    </Container>
  )
}

export default NavBtn;
