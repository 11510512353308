import styled from "styled-components";

interface IStep {
  wichStep?: boolean;
  reduceSizeInPercentage?: number;
}

interface IInitialKit {
  initialKit?: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding-top: 0.5rem;

  @media(max-width: 720px){
    height: 100%;
    width: 100%;
    padding: 0 0.8rem;
    background: white;
  }
`;

export const PrincipalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Description = styled.div`
  max-width: 60%;
  margin-bottom: 20px;

  h5 {
    font-size: 15px;
    font-weight: normal;
    color: var(--dark-gray);
    text-align: center;
  }

  @media (max-width: 450px) {
    width: 100%;
    max-width: 100%;

    h5 {
      width: 100%;
    }
  }
`;

export const Attachments = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 700px) {
    width: 100%;
    padding: 0 30px;
  }
`;

export const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  a {
    margin-right: 10px;
  }
`;

export const VideoDescription = styled.div`
  font-size: 15px;
  font-weight: normal;
  color: var(--dark-gray);
  text-align: start;
  margin-bottom: 20px;
`;

export const LinkDescription = styled.div`
  font-size: 15px;
  font-weight: normal;
  color: var(--dark-gray);
  text-align: start;
  margin-bottom: 10px;
`;

export const Header = styled.header`
  width: 64rem;
  font-size: 2rem;
  color: var(--fixit);
  text-align: center;
  margin-top: 60px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-size: 25px;
    margin-bottom: 20px;
    max-width: 60%;
  }

  h4 {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-gray);
  }

  @media (max-width: 720px) {
    width: 100%;
    padding: 0 20px;

    h2 {
      max-width: 100%;
    }
  }
`;

export const Separator = styled.div`
  height: 1px;
  border-top: 2px;
  border-color: var(--dark-gray);
  border-style: solid;
  margin: 0.2rem;
  width: 15rem;
`;

export const ProceedButton = styled.button`
  align-self: start;
  padding: 0px 9px 0px 31px;
  gap: 10px;
  border-radius: 30px;
  height: 3rem;
  font-size: 0.8rem;
  border: 2px solid var(--fixit);
  margin-top: 30px;
  margin-bottom: 20px;
  background-color: inherit;

  color: var(--fixit);
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: 0.6s;
  cursor: pointer;

  :hover {
    background: var(--fixit);
    color: white;
    /* -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1); */
  }
`;

export const InitialKitButton = styled.button<IInitialKit>`
  align-self: start;
  padding: 0px 9px 0px 20px;
  gap: 10px;
  border-radius: 30px;
  height: 3rem;
  font-size: 0.8rem;
  border: 2px solid
    ${(IInitialKit) =>
      IInitialKit.initialKit ? "var(--finished)" : "var(--fixit)"};
  background-color: inherit;
  margin-bottom: 10px;

  color: ${(IInitialKit) =>
    IInitialKit.initialKit ? "var(--finished)" : "var(--fixit)"};
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: 0.6s;
  cursor: pointer;

  :hover {
    background: var(--white);
    border: 2px solid var(--finished);
    transition: 0.6s;

    color: var(--finished);

    svg {
      transform: rotate(360deg);
      transition: 0.2s;
    }
  }
`;

export const StartButton = styled.button`
  padding: 0px 9px 0px 31px;
  gap: 10px;
  border-radius: 30px;
  height: 3rem;
  font-size: 0.8rem;
  border: 2px solid var(--fixit);
  margin-top: 20px;
  background-color: inherit;

  color: var(--fixit);
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: 0.6s;
  cursor: pointer;

  span {
    text-transform: uppercase;
  }

  :hover {
    background: var(--fixit);
    color: white;
  }
`;

export const SendButton = styled.a`
  padding: 0px 9px 0px 31px;
  gap: 10px;
  border-radius: 30px;
  height: 3rem;
  font-size: 0.8rem;
  border: 2px solid var(--fixit);
  margin-top: 20px;
  background-color: inherit;

  color: var(--fixit);
  font-weight: 500;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  transition: 0.6s;
  cursor: pointer;
  text-decoration: none;

  span {
    text-transform: uppercase;
  }

  :hover {
    background: var(--fixit);
    color: white;
  }
`;

export const ProgressBar = styled.div`
  width: 62rem;
  height: 3rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const StepContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

export const StepContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Progress = styled.div`
  width: 8.5rem;
  height: 1px;
  z-index: -1;
  border: 2px solid var(--light-gray);
`;

export const Step = styled.div<IStep>`
  height: 3rem;
  width: 3rem;
  border-radius: 100%;
  border: 3px solid var(--light-gray);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 500;

  background-color: ${(IStep) =>
    IStep.wichStep ? "var(--fixit)" : "var(--white)"};
  color: ${(IStep) => (IStep.wichStep ? "var(--white)" : "var(--gray)")};
`;
export const Label = styled.div`
  width: 45rem;
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
`;

export const StepLabel = styled.div`
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--gray);
  width: 11rem;
`;

export const JumpThisStepButton = styled.div`
width: 100%;
display: flex;
position: relative;
align-items: center;
top: -3rem;
right: -3rem;
justify-content: end;

:hover{
  cursor: pointer;
}

div{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.15s ease-in-out;

  :hover{
    transform: scale(1.2);
  }
}

span{
  font-size: 16px;
}
`
