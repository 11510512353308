import React, { useState } from 'react'
import { Container, Content } from './styles'

// TODO: Make clickable
// TODO: Add offset feature

interface ITooltipProps {
  width?: string
  height?: string
  children: React.ReactNode
  content: any
  position:
    "top" |
    "bottom" |
    "left" |
    "right"
  triangle?: boolean
  clickable?: boolean
  offset?: number
}

const Tooltip: React.FC<ITooltipProps> = ({ children, content, position, triangle, clickable, offset, width, height }) => {
  const [clickCss, setClickCss] = useState(``)

  const handdleClick = () => {
    if (clickable) {
      setClickCss(`
        display: flex;
        visibility: visible;
        opacity: 1;
        margin: initial;
      `)
    }
  }

  const handleMouseOut = () => {
    setClickCss(``)
  }

  return (
    <Container
      onClick={handdleClick}
      width={width}
      height={height}
    >
      {children}
      <Content
        position={position}
        triangle={triangle}
        clickable={clickable}
        clickCss={clickCss}
        onMouseOut={handleMouseOut}
        offset={offset}
      >
        {content}
      </Content>
    </Container>
  )
}

export default Tooltip
