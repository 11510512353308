import styled, { css } from "styled-components";

interface ButtonProps {
  absolute: boolean;
  circle?: boolean;
}

export const Button = styled.div<ButtonProps>`
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 170px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-radius: 8px;
  color: var(--fixit);
  font-size: 16px;
  background: var(--white);
  cursor: pointer;
  transition: background-color 0.2s;
  z-index: 10;

  box-shadow: 4px 3px 11px var(--gray);

  ${({ circle }) =>
    circle &&
    css`
      height: 60px;
      width: 60px;
      border-radius: 50%;
    `}

  &:hover {
    background: ${(props) => props.color};
    color: var(--white);
    svg {
      color: var(--white);
    }
  }

  svg {
    width: 30px;
    height: 30px;
    color: ${(props) => props.color};
  }

  @media (max-width: 720px) {
    width: 50px;
    height: 50px;
    border-radius: 50%;

    svg {
      width: 30px;
      height: 30px;
    }

    .button_text {
      display: none;
    }
  }
`;
