import styled from "styled-components";

interface DragItemProps {
  id: string;
}

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const Title = styled.div`
  text-align: center;
  margin-top: 14px;

  font-family: Roboto;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: var(--dark-gray);
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  @media (max-width: 540px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InputLabel = styled.p`
  color: var(--dark-gray);
  font-size: 18px;
  margin-bottom: 4px;
`;

export const InputContainer = styled.div`
  width: 80%;
  display: flex;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 28px;
`;

export const SolutionsOrderContainer = styled.div`
  width: 80%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: start;
  
  gap: 10px;
  border-radius: 10px;
  
  margin-bottom: 15px;
  margin-top: 5px;
`;

export const Text = styled.h1`
  font-size: 12px;
  font-weight: 400;
  color: var(--fixit);
`

export const SecondText = styled.h1`
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: var(--dark-gray);
  text-align: justify;
`

export const SolutionsOrder = styled.table`
  width: 100%;
  display: flex;
  
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  font-size: 12px;
`;

export const DragItem = styled.div<DragItemProps>`
  width: 100%;
  height: 25px;

  display: flex;
  flex-direction: row;
  padding-left: 5px;

  align-items: center;
  justify-content: space-between;

  border: ${(DragItemProps) => (DragItemProps.id === 'newMeasure') ? '2px solid var(--finished)' : '1px solid var(--light-gray)'};
  border-radius: 5px;
  box-shadow: 3px 3px 3px var(--light-gray);
`;

export const Content = styled.div`
  width: 90%;
  color: var(--fixit);
`

export const Icon = styled.div`
  width: 10%;
  color: var(--fixit);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  }
`

export const NewMeasure = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  gap: 10px;

  button {
    margin-left: 200px;
    font-size: 12px;
  }
  
`;
