import { AssociateContainer, Container, DataContainer, FixitLogo, Separator } from "./style";
import logoImage from '../../../../../../assets/imagem_coringa.png'
import { FaInstagram } from "react-icons/fa";
import { MdOutlineWhatsapp } from "react-icons/md";
import api from "../../../../../../services/api";

interface IAssociatesItemProps {
  id: string;
  name: string;
  phone: string;
  instagram: string;
  profile_picture: string;
  professional_number: string;
  distance: string;
  duration: string;
  rating: number;
}
export default function AssociatesItem(associate: IAssociatesItemProps) {

  async function assignLicenseeToALead(licenseeId: string) {
    try {
      await api.put(`patients-leads/update-patient-lead/${localStorage.getItem('@FixitApp:Lead')}`, {
        licensee_id: licenseeId
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container>
      <Separator />
      <AssociateContainer>
        <FixitLogo src={associate.profile_picture ? `${process.env.REACT_APP_STORAGE_BASE_URL}${associate.profile_picture}` : logoImage} alt='Fix it Logo' />
        <DataContainer>
          <h3>{associate.name}</h3>
          <div>
            <a
              href={`http://wa.me/+55${associate.phone.replace(/\D/g, '')}`}
              target="_blank"
              rel="noreferrer"
              style={{ marginRight: '0.3rem' }}
              onClick={() => {
                assignLicenseeToALead(associate.id)
              }}
            >
              <MdOutlineWhatsapp size={24} color="var(--gray)" />
            </a>
            {
              associate.instagram &&
              <a
                href={`https://www.instagram.com/${associate.instagram.slice(1)}`}
                target="_blank"
                rel="noreferrer">
                <FaInstagram size={24} color="var(--gray)" />
              </a>
            }
            {
              associate.professional_number &&
              <p>Nº de registro: {associate.professional_number}</p>
            }

          </div>
          <p>Contato: {associate.phone}</p>
          <p>Distância: {associate.distance}</p>
          <p>Tempo: {associate.duration}</p>

        </DataContainer>
      </AssociateContainer>
    </Container>

  )
}
