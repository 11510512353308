import { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { ITips } from "../../modules/Admin/pages/Tips/dtos";
import { Modal } from "../Modal";
import { OutlinedCheckbox } from "../OutlinedCheckbox";
import { SolidButton } from "../SolidButton";
import "react-image-gallery/styles/css/image-gallery.css";

import {
  Container,
  Header,
  TitleText,
  Separator,
  ImageContainer,
  Image,
  Description,
  Footer,
  CarouselContainer,
} from "./styles";
import { i18n } from "../../localization/i18n";

interface TipsModalProps {
  tip: ITips;
  open: boolean;
  createTipsRelation: (
    currentTip: ITips,
    dontShowAgain: boolean
  ) => Promise<void>;
  closeModal: () => void;
}

export function TipsModal({
  tip,
  open,
  createTipsRelation,
  closeModal,
}: TipsModalProps) {
  const [images, setImages] = useState<string[]>([]);
  const [description, setDescription] = useState("");
  const [dontShowAgain, setDontShowAgain] = useState(false);

  function getTipDescription() {
    try {
      const activeLanguage = i18n.language;
      const parsedDescription = JSON.parse(tip?.description);

      const tipDescription = parsedDescription[activeLanguage];

      if (tipDescription) setDescription(tipDescription);
      else setDescription(parsedDescription["en"]);
    } catch (error) {
      return;
    }
  }

  useEffect(() => {
    if (tip) {
      const tipImages: string[] = [];
      tip.images_link.forEach((image) => {
        tipImages.push(`${process.env.REACT_APP_STORAGE_BASE_URL}${image}`);
      });

      setImages(tipImages);
      getTipDescription();
    }
  }, [tip]);

  return (
    <Modal
      open={open}
      modalWidth={430}
      onRequestClose={() => {
        createTipsRelation(tip, dontShowAgain);
        closeModal();
      }}
    >
      <Container>
        <Header>
          <TitleText>
            <>
              {i18n.t(`tips.new`)}
            </>
            </TitleText>
        </Header>

        <Separator />
        <ImageContainer>
          {images.length > 1 ? (
            <CarouselContainer>
              <ImageGallery
                items={images.map((item) => ({
                  original: item,
                  originalHeight: 190,
                }))}
                showFullscreenButton={false}
                showPlayButton={false}
                slideInterval={Number(tip?.gif_time) || 5000}
                autoPlay
              />
            </CarouselContainer>
          ) : (
            <Image src={images[0]} />
          )}
        </ImageContainer>

        <Description>{description}</Description>

        <OutlinedCheckbox
          label={`${i18n.t(`tips.dontShowAgain`)}`}
          checked={dontShowAgain}
          handleChange={() => {
            setDontShowAgain((prevState) => !prevState);
          }}
        />

        <Footer>
          <SolidButton
            text={`${i18n.t(`address.address_options.close`)}`}
            onClick={async () => {
              await createTipsRelation(tip, dontShowAgain);
              closeModal();
            }}
          />
        </Footer>
      </Container>
    </Modal>
  );
}
