import { useState } from "react";
import {
  Container,
  DropdownContainer,
  OptionsContainer,
  Option,
  Error,
} from "./styles";

import { RiArrowDownSLine } from "react-icons/ri";

interface IOption {
  optionText: string;
  value: string | number;
}

interface Props {
  options: IOption[];
  handleSelect: (selectedOption: IOption) => any;
  placeholder?: string;
  disabled?: boolean;
  selectedOption?: string | number;
  inputWidth?: number;
  error?: string;
}

export default function Dropdown({
  handleSelect,
  options,
  disabled,
  placeholder,
  selectedOption,
  inputWidth,
  error,
}: Props) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <Container>
      <DropdownContainer
        open={showOptions}
        inputWidth={inputWidth}
        error={error}
        onClick={() => {
          setShowOptions(!showOptions);
        }}
      >
        <input
          type="text"
          value={selectedOption}
          disabled={disabled}
          placeholder={placeholder}
        />

        <RiArrowDownSLine color="var(--dark-gray)" className="arrow_icon" />
      </DropdownContainer>

      {error && <Error>{error}</Error>}

      {showOptions && (
        <OptionsContainer open={showOptions} inputWidth={inputWidth}>
          <div className="dropdown_options">
            {options.map((option) => {
              return (
                <Option
                  key={option.value}
                  onClick={() => {
                    handleSelect(option);
                    setShowOptions(false);
                  }}
                >
                  <div className="option_text">{option.optionText}</div>
                </Option>
              );
            })}
          </div>
        </OptionsContainer>
      )}
    </Container>
  );
}
