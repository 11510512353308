import { useEffect, useState } from "react";
import { i18n } from "../../localization/i18n";
import { showToast } from "../CustomToast";
import { Modal } from "../Modal";
import { OutlinedButton } from "../OutlinedButton";
import { OutlinedInput } from "../OutlinedInput";
import { OutlinedSelect } from "../OutlinedSelect";
import { OperationalCountryCoins } from "./mock";
import { Container, Description, Footer, Title } from "./styles";

interface ModalProps {
    open: boolean;
    onRequestClose: () => void;
    setFilamentConfigured: (arg: boolean) => void;
}

interface DataProps {
    filamentCost: string | null;
    coin: string | number | null;
}

export function FilamentConfig ({open, onRequestClose, setFilamentConfigured}: ModalProps) {
    const [data, setData] = useState<DataProps>({
        filamentCost: '',
        coin: '',
    });
    const [editCost, setEditCost] = useState(false);

    let cost = localStorage.getItem("filamentConfigs");

    useEffect(() => {
        setFilamentConfigured(editCost);
    }, [editCost])

    useEffect(() => {
        if (cost !== null) {
            setEditCost(true);
            setData(JSON.parse(cost));
        }
    } , [cost])

    async function setConfigs () {
        try {
            localStorage.setItem("filamentConfigs", JSON.stringify(data));
            showToast({
                type: 'success',
                message: `${i18n.t('printer.filament.success')}`
            })
        } catch (err) {
            console.log('Não foi possível salvar no local storage');
            showToast({
                type: 'error',
                message: `${i18n.t('printer.filament.error')}`
            })
        } 
    }
    
    return (
        <Modal
            open={open}
            onRequestClose={onRequestClose}
        >   
            <Title>{`${i18n.t('printer.filament.filamentConfigs')}`}</Title>
            <Description>{`${i18n.t('printer.filament.filamentDescription')}`}</Description>
            <Container>
                <OutlinedSelect
                    readOnly={editCost}
                    options={OperationalCountryCoins}
                    label={`${i18n.t('printer.filament.buyingCoin')}`}
                    handleSelect={(event) => {
                        setData((prevState) => ({
                            ...prevState,
                            coin: event.optionText
                        }))
                    }}
                    selectedOption={data.coin || ""}
                />
                
                <OutlinedInput
                    readOnly={editCost}
                    inputName={'filament_cost'} 
                    label={`${i18n.t('printer.filament.buyingCost')}`}
                    handleChange={(event) => {
                        setData((prevState) => ({
                            ...prevState,
                            filamentCost: event.replace(/,/g, '.'),
                        }))
                    }}
                    value={data.filamentCost || ""}
                />
            </Container>
            <Footer>
                {editCost ? 
                <OutlinedButton 
                    text={'Editar Configuração'}
                    onClick={async () => {
                        setEditCost(false);
                    }}
                />
                :
                <OutlinedButton 
                    text={'Configurar'}
                    onClick={async () => {
                        await setConfigs();
                        onRequestClose();
                    }}
                />
            }
            </Footer>
        </Modal>
    )
}