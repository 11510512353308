import styled, { css } from "styled-components";

interface Props {
  open: boolean;
  inputWidth?: number;
  inputHeight?: number;
  error?: string;
  selectedOption?: boolean;
  readOnly?: boolean;
  marginOnError?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const DropdownContainer = styled.div<Props>`
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "100%")};
  height: 46px;
  margin-bottom: ${({ error, marginOnError }) =>
    error && marginOnError ? 10 : 0}px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: margin-bottom 0.4s ease;

  input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-weight: 500;
    text-align: left;
    padding: 0 35px 0 24px;
    cursor: pointer;
    outline: none;
    border: 2px solid #989898;
    background: var(--white);
    font-size: 16px;
    color: var(--dark-gray);
    font-weight: 400;
    font-style: normal;
    line-height: 8px;
  }
  input[disabled]{
    background-color: #EFEFEF;
  }
  .arrow_icon {
    position: absolute;
    right: 12px;
    top: 11px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: 0.2s;
  }
  ${({ open }) =>
    open &&
    css`
      .arrow_icon {
        transform: rotate(-180deg);
      }
      input {
        border: 2px solid var(--fixit);
      }
    `}
  ${({ error }) =>
    error &&
    css`
      input {
        border: 2px solid var(--formError);
      }
    `}
`;

export const Label = styled.span<Props>`
  position: absolute;
  top: 16px;
  left: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #989898;
  margin-left: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  ${({ open, selectedOption, readOnly }) =>
    (open || selectedOption) &&
    css`
      position: absolute;
      top: -7px;
      left: 0px;
      background: var(--white);
      padding: 0 6px;
      color: ${readOnly ? 'var(--dark-gray)' : 'var(--fixit)'};
    `}
  ${({ error }) =>
    error &&
    css`
      color: var(--formError);
    `}
`;

export const OptionsContainer = styled.div<Props>`
  position: absolute;
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "100%")};
  max-width: 100%;
  z-index: 10;
  margin-top: 4px;

  .dropdown_options {
    width: 100%;
    max-width: 100%;
    max-height: calc(5 * 29px);
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

    ::-webkit-scrollbar {
      width: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 8px;
      background: var(--gray);
    }
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  min-height: 16px;
  height: 32px;
  width: 100%;
  padding: 0 16px;
  margin: 6px 0;
  transition: background-color 0.2s;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #e8e9eb;
  }

  &:active:hover {
    background-color: #ccd5e2;
  }

  .option_text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--dark-gray);
    margin-left: 8px;
  }
`;

export const Error = styled.div`
  position: absolute;
  bottom: -17px;
  left: 11px;
  font-size: 12px;
  color: var(--formError);
`;
