import { useState } from "react";
import { i18n } from "../../../localization/i18n";
import { OutlinedInput } from "../../../components/OutlinedInput";
import logoImg from "../../../assets/logo.svg";

import {
  Form,
  FormContent,
  Footer,
  InputContainer,
} from "../styles";
import { NotRobotLine } from "../../../components/CodeComponent/styles";
import ReCAPTCHA from "react-google-recaptcha";
import { SolidButton } from "../../../components/SolidButton";
interface ISendCodeStep {
  email: string;
  setEmail: (event: any) => void;
  validateSendCodeStep: (event: any) => void;
}

export default function SendCode({email, setEmail, validateSendCodeStep, }: ISendCodeStep) {
  const [reCaptcha, setReCaptcha] = useState<any>();
  const [errors, setErrors] = useState({} as any);

  return (
    <Form onSubmit={validateSendCodeStep}>
          <img className="logo" src={logoImg} alt="FixIt logo" />
          <h1>{`${i18n.t('login.recover_password')}`}</h1>
          <FormContent>
            <InputContainer errorMargin={!!errors.email}>
              <OutlinedInput
                label={`${i18n.t('login.email')}`}
                inputName="email"
                type="email"
                value={email}
                handleChange={(event) => {
                  setEmail(event);
                  setErrors((prevState: any) => ({
                    ...prevState,
                    email: "",
                  }));
                }}
                error={errors.email}
                marginOnError={false}
              />
            </InputContainer>
            <InputContainer errorMargin={!!errors.email}>
            <NotRobotLine>
              {
               process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                  onChange={(value) => setReCaptcha(value)}
                />
               )
              }
            </NotRobotLine>
            </InputContainer>
            <Footer>
              <SolidButton type="submit" text={i18n.t('login.send_code')} />
            </Footer>
          </FormContent>
        </Form>
  );
}
