import styled from "styled-components";
import { Avatar, Badge, styled as mui_styled } from "@mui/material";

export const Container = styled.div``;

export const ActionBadge = mui_styled(Badge)(({ theme }) => ({
  cursor: "pointer",
}));

export const SmallAvatar = mui_styled(Avatar)(({ theme }) => ({
  width: 20,
  height: 20,
  border: `2px solid #FFF`,
  backgroundColor: "var(--fixit)",
}));
