import { OutlinedCheckbox } from "../../../../../../../../components/OutlinedCheckbox";
import { OutlinedInput } from "../../../../../../../../components/OutlinedInput";

import {
  DiagnosisData,
  LanguageDiagnosisData,
  LanguageValues,
} from "../../../../dtos";

import { InputContainer, InputLine } from "../../styles";

type DiagnosisLanguageFormProps = {
  diagnosisData: DiagnosisData;
  languageDiagnosisData: LanguageDiagnosisData;
  language: keyof LanguageDiagnosisData;
  errors: any;
  changeDianosisData: (data: Partial<DiagnosisData>) => void;
  changeLanguageDiagnosisData: (
    data: Partial<LanguageValues>,
    language: keyof LanguageDiagnosisData
  ) => void;
};

export function DiagnosisLanguageForm({
  diagnosisData,
  changeDianosisData,
  changeLanguageDiagnosisData,
  language,
  errors,
  languageDiagnosisData,
}: DiagnosisLanguageFormProps) {
  return (
    <>
      <InputLine>
        <InputContainer>
          <OutlinedInput
            label="CID"
            inputName={`cid${language}`}
            handleChange={(event) => changeDianosisData({ cid: event })}
            value={diagnosisData?.cid ?? ""}
            error={errors?.cid}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            label="Nome"
            inputName={`name${language}`}
            handleChange={(event) =>
              changeLanguageDiagnosisData({ name: event }, language)
            }
            value={languageDiagnosisData[language]?.name}
            error={errors[language]?.name}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            label="Descrição"
            inputName={`description${language}`}
            textArea
            handleChange={(event) =>
              changeLanguageDiagnosisData({ description: event }, language)
            }
            value={languageDiagnosisData[language]?.description}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedCheckbox
            label="Verificado"
            checked={diagnosisData.verified}
            handleChange={() =>
              changeDianosisData({ verified: !diagnosisData.verified })
            }
          />
        </InputContainer>
      </InputLine>
    </>
  );
}
