import styled from 'styled-components';
import global from '../../global';

interface INavBtnLineProps {
  readonly isMenuOpen: boolean;
}

export const Container = styled.button`
  z-index: 10;
  width: 22px;
  height: 22px;
  position: fixed;
  top: 16px;
  left: 16px;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  
  @media(min-width: ${global.responsive.small.width.value + 1}${global.responsive.small.width.unit}) {
    display: none;
  }
`

export const NavBtnLine = styled.div<INavBtnLineProps>`
  width: 100%;
  height: 2px;
  transition: all .3s;
  ${props => props.isMenuOpen ?
    `background-color: var(--white);
    :nth-child(1) {
      transform: rotate(45deg) translate(25%, 20%);
    }
    :nth-child(2) {
      display: none;
    }
    :nth-child(3) {
      transform: rotate(-45deg) translate(25%, 20%);
    }`:
    `background-color: var(--dark-gray);`}
`
