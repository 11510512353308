import styled from "styled-components";
import global from "../../../../../../global";

export const Container = styled.div`
  width: 100%;
  border-radius: 14px;
  padding: 16px 36px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
  position: relative;

  transition: all 0.3s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    padding: 16px 20px 16px 22px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  .image {
    min-width: 50px;
    min-height: 50px;
    border-radius: 50%;
    background: var(--fixit);

    display: flex;
    align-items: center;
    justify-content: center;

    span {
      color: var(--white);
    }
  }

  .title {
    min-width: 250px;
    font-size: 14px;
    font-weight: 500;
    color: var(--finished);
  }

  @media (max-width: 560px) {
    .title {
      min-width: auto;
    }
  }
`;

export const Info = styled.div`
  margin-left: 24px;

  display: flex;
  flex-direction: column;

  .info {
    font-size: 14px;
    color: var(--gray);
    font-weight: 500;
  }

  .value {
    font-size: 16px;
    color: var(--gray);
    margin-top: 4px;
    font-weight: bolder;
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .actions {
    position: relative;
  }

  @media (max-width: 560px) {
    width: 100%;
    margin-top: 10px;
    justify-content: flex-end;

    .title {
      min-width: auto;
    }
  }
`;
