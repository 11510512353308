import { format, isValid } from "date-fns";

import { i18n } from "../../../../../../../localization/i18n";

import { CustomSolutionForm } from "../../../../dtos";

interface PlanitOrderInfosProps {
  order: CustomSolutionForm;
}

export function PlanitOrderInfos({ order }: PlanitOrderInfosProps) {
  return (
    <div className="order_resume">
      {order?.partner?.name && (
        <span className="item">
          <strong>{`${i18n.t("orders.confirmation.partner")}`}: </strong>
          {order?.partner?.name}
        </span>
      )}

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.patient")}`}: </strong>
        {order.patient.name} - {order.patient.age}{" "}
        {`${i18n.t("orders.confirmation.years")}`}
      </span>

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.bodyPart")}`}: </strong>
        {order.planit.body_part}
      </span>

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.injuryType")}`}: </strong>
        {order.planit.injury_type}
      </span>

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.target")}`}: </strong>
        {order.planit.target}
      </span>

      {isValid(new Date(order.planit.surgery_date)) && (
        <span className="item">
          <strong>{`${i18n.t("orders.confirmation.surgeryDate")}`}: </strong>
          {format(
            new Date(order.planit.surgery_date.replace("Z", "")),
            "dd/MM/yyy"
          )}
        </span>
      )}
    </div>
  );
}
