import styled from 'styled-components';
import dropdownArrow from '../../assets/dropdown-arrow-down.svg'
import global from '../../global';

const mobileWidth = 720;

interface IDropdownBtnProps {
  width?: string
}

interface IListProps {
  width?: string
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  // margin-left: 11px;
  position: relative;
  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    width: 100%;
    margin-left: 0;
  }
`

export const Overlay = styled.div`
  z-index: 10;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`

export const DropdownBtn = styled.button<IDropdownBtnProps>`

  width: ${props => props.width || "100%" };
  height: 40px;
  border: 1px solid var(--light-gray);
  background: url(${dropdownArrow});
  background-position: 95%;
  background-repeat: no-repeat;
  border-radius: 6px;
  color: var(--dark-gray);
  font-size: 18px;
  text-align: left;
  padding-left: 10px;
  @media(max-width: ${mobileWidth}px) {
    width: 100%;
  }

  :hover{
    cursor: pointer;
  }
`

export const List = styled.ul<IListProps>`
  width: ${props => props.width || "100%" };
  position: absolute;
  right: 0;
  top: 39px;

  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 6px;

  background-color: var(--white);

  list-style-type: none;
  z-index: 11;
  @media(max-width: ${mobileWidth}px) {
    width: 100%;
  }
`

export const ListItem = styled.li`
  color: var(--dark-gray);
  font-size: 18px;
  padding: 4px 0;
  cursor: pointer;
  :hover{
    color: var(--fixit)
  }
`
