import Popover from "../Popover";
import { RiArrowRightLine } from "react-icons/ri";

import {
  ActionItem,
  ActionsContainer,
  Container,
  Header,
  IconButtonContainer,
  LeftContent,
  PrincipalInfos,
  RightContent,
  ValueContainer,
} from "./styles";
import { useEffect, useState } from "react";
import ReactDynamicFont from "react-dynamic-font";
import { BiDollar } from "react-icons/bi";
import { i18n } from "../../localization/i18n";
import { Bill } from "../../hooks/AuthContext";
import { MdSync } from "react-icons/md";
import { currencyValue } from "../UsersProfile/mock";

interface IClosureItem {
  title: string;
  description: string;
  help?: string;
  value: string | number;
  onClick?: () => void;
  period?: string;
  acceptPayment?: boolean;
  onHandlePayBill?: () => void;
  bill?: Bill;
  closurePeriod?: Date;
  currency?: string;
}

export function ClosureItem({
  title,
  description,
  help,
  value,
  onClick,
  period,
  acceptPayment,
  onHandlePayBill,
  bill,
  closurePeriod,
  currency,
}: IClosureItem) {
  const [formatedValue, setFormatedValue] = useState<string[]>([]);

  useEffect(() => {
    const arr = value.toString().split(".");
    setFormatedValue(arr);
  }, [value]);

  return (
    <Container>
      <Header>
        <LeftContent>
          <ValueContainer
            paymentPending={
              acceptPayment && value > 0
                ? "not_paid"
                : bill && !bill.payment_date
                ? "pending"
                : "paid"
            }
          >
            <ReactDynamicFont content={formatedValue[0]} />
            {formatedValue[1] && <div>.{formatedValue[1]}</div>}
          </ValueContainer>
          <PrincipalInfos
            paymentPending={
              acceptPayment && value > 0
                ? "not_paid"
                : bill && !bill.payment_date
                ? "pending"
                : "paid"
            }
          >
            <span className="solution">{title}</span>
            <span className="date">
              {description}
              <br />
              {period}
            </span>
          </PrincipalInfos>
        </LeftContent>
        {help && (
          <RightContent>
            <ActionsContainer>
              {acceptPayment &&
                value > 0 &&
                closurePeriod &&
                new Date(
                  closurePeriod?.getFullYear(),
                  closurePeriod?.getMonth() + 1,
                  0
                ) <= new Date() && (
                  <ActionItem>
                    <Popover position="left" label={`${i18n.t("bills.pay")}`}>
                      <IconButtonContainer>
                        <BiDollar size={18} onClick={onHandlePayBill} />
                      </IconButtonContainer>
                    </Popover>
                  </ActionItem>
                )}
              {currency === currencyValue.BRL &&
              bill?.payment_situation !== "pago" &&
              !bill?.payment_date &&
              (bill?.payment_method === "pix" ||
                bill?.payment_method === "boleto") ? (
                <ActionItem>
                  <Popover
                    position="left"
                    label={`${i18n.t("bills.changePaymentMethod")}`}
                  >
                    <IconButtonContainer>
                      <MdSync size={18} onClick={onHandlePayBill} />
                    </IconButtonContainer>
                  </Popover>
                </ActionItem>
              ) : (
                bill?.payment_method === "fixcoin" &&
                bill.payment_situation !== "pago" && (
                  <ActionItem>
                    <Popover
                      position="left"
                      label={`${i18n.t("bills.completePayment")}`}
                    >
                      <IconButtonContainer>
                        <BiDollar size={18} onClick={onHandlePayBill} />
                      </IconButtonContainer>
                    </Popover>
                  </ActionItem>
                )
              )}
              <ActionItem>
                <Popover position="left" label={help}>
                  <IconButtonContainer>
                    <RiArrowRightLine size={18} onClick={onClick} />
                  </IconButtonContainer>
                </Popover>
              </ActionItem>
            </ActionsContainer>
          </RightContent>
        )}
      </Header>
    </Container>
  );
}
