import { useNavigate } from "react-router-dom";
import { roles } from "../../utils/constants";

interface IUser {
  role: string;
  person: {
    licensee: {
      is_blocked: boolean;
      signature: string;
      completed_onboard: boolean;
      situation: string;
    };
  };
}

class Services {
  async getUserRoleAndRedirect(
    path: string,
    user: IUser,
    user_role: string,
    planHasExpired: boolean
  ) {
    const block_status = user?.person?.licensee?.is_blocked;
    const userSignature = !!user?.person?.licensee?.signature[0];
    const onboard_completed = user?.person?.licensee?.completed_onboard;

    switch (user_role) {
      case roles.licensee:
        if (block_status === true) {
          return "/payment";
        }
        return "/dashboard";
      case roles.admin:
        return "/admin/dashboard";
      case "DISABLED":
        return;
      case roles.fixiter:
        if (!onboard_completed) {
          return "/fixiter/onboard";
        }
        if (!userSignature) {
          return "/fixiter/dashboard";
        }
        return "/fixiter/dashboard";
      case roles.creator:
        return "/creators/dashboard";
      case roles.saf:
        return "/saf/dashboard";
      case roles.employee:
        if (block_status === true) {
          return "/payment";
        }
        return "/employee/dashboard";
    }
  }
}

export const services = new Services();
