export default class ByteSliceTransformer {
  chunkSize: number;
  container: Uint8Array;

  constructor(chunkSize: number) {
    this.chunkSize = chunkSize;
    this.container = new TextEncoder().encode('')
  }

  * run(text: Uint8Array, size: number) {
    while (text.length > size) {
      const data = text.slice(0, size);
      text = text.slice(size)

      yield data
    }
    this.container = text
  }

  transform(chunk: Uint8Array, controller: { enqueue: (arg0: Uint8Array) => void }) {
    this.container = new Uint8Array([...this.container, ...chunk]);

    for (const data of this.run(this.container, this.chunkSize)) {
      controller.enqueue(data);
    }
  }

  flush(controller: { enqueue: (arg0: Uint8Array) => void }) {
    controller.enqueue(this.container);
  }
}
