import { messages as portugueseMessages } from "./ptBR";
import { messages as spanishMessages } from "./es";
import { messages as englishMessages } from "./en";
import { messages as frenchMessages } from "./fr";

const messages = {
  ...portugueseMessages,
  ...spanishMessages,
  ...englishMessages,
  ...frenchMessages,
};

export { messages };
