export const countries = [
  { code: "AFG", code2d: "AF", label: "Afghanistan", phone: "93" },
  { code: "ALB", code2d: "AL", label: "Albania", phone: "355" },
  { code: "DZA", code2d: "DZ", label: "Algeria", phone: "213" },
  { code: "ASM", code2d: "AS", label: "American Samoa", phone: "1-684" },
  { code: "AND", code2d: "AD", label: "Andorra", phone: "376" },
  { code: "AGO", code2d: "AO", label: "Angola", phone: "244" },
  { code: "AIA", code2d: "AI", label: "Anguilla", phone: "1-264" },
  { code: "ATA", code2d: "AQ", label: "Antarctica", phone: "672" },
  { code: "ATG", code2d: "AG", label: "Antigua and Barbuda", phone: "1-268" },
  {
    code: "ARG",
    code2d: "AR",
    label: "Argentina",
    phone: "54",
    currency: "USD",
    coin: "$",
  },
  { code: "ARM", code2d: "AM", label: "Armenia", phone: "374" },
  { code: "ABW", code2d: "AW", label: "Aruba", phone: "297" },
  { code: "AUS", code2d: "AU", label: "Australia", phone: "61" },
  { code: "AUT", code2d: "AT", label: "Austria", phone: "43" },
  { code: "AZE", code2d: "AZ", label: "Azerbaijan", phone: "994" },
  { code: "BHS", code2d: "BS", label: "Bahamas", phone: "1-242" },
  { code: "BHR", code2d: "BH", label: "Bahrain", phone: "973" },
  { code: "BGD", code2d: "BD", label: "Bangladesh", phone: "880" },
  { code: "BRB", code2d: "BB", label: "Barbados", phone: "1-246" },
  { code: "BLR", code2d: "BY", label: "Belarus", phone: "375" },
  { code: "BEL", code2d: "BE", label: "Belgium", phone: "32" },
  { code: "BLZ", code2d: "BZ", label: "Belize", phone: "501" },
  { code: "BEN", code2d: "BJ", label: "Benin", phone: "229" },
  { code: "BMU", code2d: "BM", label: "Bermuda", phone: "1-441" },
  { code: "BTN", code2d: "BT", label: "Bhutan", phone: "975" },
  {
    code: "BOL",
    code2d: "BO",
    label: "Bolivia, Plurinational State of",
    phone: "591",
  },
  { code: "BIH", code2d: "BA", label: "Bosnia and Herzegovina", phone: "387" },
  { code: "BWA", code2d: "BW", label: "Botswana", phone: "267" },
  { code: "BVT", code2d: "BV", label: "Bouvet Island", phone: "47" },
  {
    code: "BRA",
    code2d: "BR",
    label: "Brazil",
    phone: "55",
    currency: "BRL",
    coin: "R$",
  },
  {
    code: "IOT",
    code2d: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
  },
  { code: "BRN", code2d: "BN", label: "Brunei Darussalam", phone: "673" },
  { code: "BGR", code2d: "BG", label: "Bulgaria", phone: "359" },
  { code: "BFA", code2d: "BF", label: "Burkina Faso", phone: "226" },
  { code: "BDI", code2d: "BI", label: "Burundi", phone: "257" },
  { code: "KHM", code2d: "KH", label: "Cambodia", phone: "855" },
  { code: "CMR", code2d: "CM", label: "Cameroon", phone: "237" },
  { code: "CAN", code2d: "CA", label: "Canada", phone: "1" },
  { code: "CPV", code2d: "CV", label: "Cape Verde", phone: "238" },
  { code: "CYM", code2d: "KY", label: "Cayman Islands", phone: "1-345" },
  {
    code: "CAF",
    code2d: "CF",
    label: "Central African Republic",
    phone: "236",
  },
  { code: "TCD", code2d: "TD", label: "Chad", phone: "235" },
  { code: "CHL", code2d: "CL", label: "Chile", phone: "56" },
  { code: "CHN", code2d: "CN", label: "China", phone: "86" },
  { code: "CXR", code2d: "CX", label: "Christmas Island", phone: "61" },
  { code: "CCK", code2d: "CC", label: "Cocos (Keeling) Islands", phone: "61" },
  { code: "COL", code2d: "CO", label: "Colombia", phone: "57" },
  { code: "COM", code2d: "KM", label: "Comoros", phone: "269" },
  { code: "COG", code2d: "CG", label: "Congo", phone: "242" },
  {
    code: "COD",
    code2d: "CD",
    label: "Congo, the Democratic Republic of the",
    phone: "243",
  },
  { code: "COK", code2d: "CK", label: "Cook Islands", phone: "682" },
  { code: "CRI", code2d: "CR", label: "Costa Rica", phone: "506" },
  { code: "CIV", code2d: "CI", label: "Côte d'Ivoire", phone: "225" },
  { code: "HRV", code2d: "HR", label: "Croatia", phone: "385" },
  { code: "CUB", code2d: "CU", label: "Cuba", phone: "53" },
  { code: "CYP", code2d: "CY", label: "Cyprus", phone: "357" },
  { code: "CZE", code2d: "CZ", label: "Czech Republic", phone: "420" },
  { code: "DNK", code2d: "DK", label: "Denmark", phone: "45" },
  { code: "DJI", code2d: "DJ", label: "Djibouti", phone: "253" },
  { code: "DMA", code2d: "DM", label: "Dominica", phone: "1-767" },
  { code: "DOM", code2d: "DO", label: "Dominican Republic", phone: "1-809" },
  { code: "ECU", code2d: "EC", label: "Ecuador", phone: "593" },
  { code: "EGY", code2d: "EG", label: "Egypt", phone: "20" },
  {
    code: "SLV",
    code2d: "SV",
    label: "El Salvador",
    phone: "503",
    currency: "USD",
    coin: "$",
  },
  { code: "GNQ", code2d: "GQ", label: "Equatorial Guinea", phone: "240" },
  { code: "ERI", code2d: "ER", label: "Eritrea", phone: "291" },
  { code: "EST", code2d: "EE", label: "Estonia", phone: "372" },
  { code: "ETH", code2d: "ET", label: "Ethiopia", phone: "251" },
  {
    code: "FLK",
    code2d: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
  },
  { code: "FRO", code2d: "FO", label: "Faroe Islands", phone: "298" },
  { code: "FJI", code2d: "FJ", label: "Fiji", phone: "679" },
  { code: "FIN", code2d: "FI", label: "Finland", phone: "358" },
  { code: "FRA", code2d: "FR", label: "France", phone: "33" },
  { code: "GUF", code2d: "GF", label: "French Guiana", phone: "594" },
  { code: "PYF", code2d: "PF", label: "French Polynesia", phone: "689" },
  {
    code: "ATF",
    code2d: "TF",
    label: "French Southern Territories",
    phone: "262",
  },
  { code: "GAB", code2d: "GA", label: "Gabon", phone: "241" },
  { code: "GMB", code2d: "GM", label: "Gambia", phone: "220" },
  { code: "GEO", code2d: "GE", label: "Georgia", phone: "995" },
  { code: "DEU", code2d: "DE", label: "Germany", phone: "49" },
  { code: "GHA", code2d: "GH", label: "Ghana", phone: "233" },
  { code: "GIB", code2d: "GI", label: "Gibraltar", phone: "350" },
  { code: "GRC", code2d: "GR", label: "Greece", phone: "30" },
  { code: "GRL", code2d: "GL", label: "Greenland", phone: "299" },
  { code: "GRD", code2d: "GD", label: "Grenada", phone: "1-473" },
  { code: "GLP", code2d: "GP", label: "Guadeloupe", phone: "590" },
  { code: "GUM", code2d: "GU", label: "Guam", phone: "1-671" },
  { code: "GTM", code2d: "GT", label: "Guatemala", phone: "502" },
  { code: "GGY", code2d: "GG", label: "Guernsey", phone: "44" },
  { code: "GIN", code2d: "GN", label: "Guinea", phone: "224" },
  { code: "GNB", code2d: "GW", label: "Guinea-Bissau", phone: "245" },
  { code: "GUY", code2d: "GY", label: "Guyana", phone: "592" },
  { code: "HTI", code2d: "HT", label: "Haiti", phone: "509" },
  {
    code: "HMD",
    code2d: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
  },
  {
    code: "VAT",
    code2d: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
  },
  { code: "HND", code2d: "HN", label: "Honduras", phone: "504" },
  { code: "HKG", code2d: "HK", label: "Hong Kong", phone: "852" },
  { code: "HUN", code2d: "HU", label: "Hungary", phone: "36" },
  { code: "ISL", code2d: "IS", label: "Iceland", phone: "354" },
  { code: "IND", code2d: "IN", label: "India", phone: "91" },
  { code: "IDN", code2d: "ID", label: "Indonesia", phone: "62" },
  {
    code: "IRN",
    code2d: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
  },
  { code: "IRQ", code2d: "IQ", label: "Iraq", phone: "964" },
  { code: "IRL", code2d: "IE", label: "Ireland", phone: "353" },
  { code: "IMN", code2d: "IM", label: "Isle of Man", phone: "44" },
  { code: "ISR", code2d: "IL", label: "Israel", phone: "972" },
  { code: "ITA", code2d: "IT", label: "Italy", phone: "39" },
  { code: "JAM", code2d: "JM", label: "Jamaica", phone: "1-876" },
  { code: "JPN", code2d: "JP", label: "Japan", phone: "81" },
  { code: "JEY", code2d: "JE", label: "Jersey", phone: "44" },
  { code: "JOR", code2d: "JO", label: "Jordan", phone: "962" },
  { code: "KAZ", code2d: "KZ", label: "Kazakhstan", phone: "7" },
  { code: "KEN", code2d: "KE", label: "Kenya", phone: "254" },
  { code: "KIR", code2d: "KI", label: "Kiribati", phone: "686" },
  {
    code: "PRK",
    code2d: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
  },
  { code: "KOR", code2d: "KR", label: "Korea, Republic of", phone: "82" },
  { code: "KWT", code2d: "KW", label: "Kuwait", phone: "965" },
  { code: "KGZ", code2d: "KG", label: "Kyrgyzstan", phone: "996" },
  {
    code: "LAO",
    code2d: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
  },
  { code: "LVA", code2d: "LV", label: "Latvia", phone: "371" },
  { code: "LBN", code2d: "LB", label: "Lebanon", phone: "961" },
  { code: "LSO", code2d: "LS", label: "Lesotho", phone: "266" },
  { code: "LBR", code2d: "LR", label: "Liberia", phone: "231" },
  { code: "LBY", code2d: "LY", label: "Libyan Arab Jamahiriya", phone: "218" },
  { code: "LIE", code2d: "LI", label: "Liechtenstein", phone: "423" },
  { code: "LTU", code2d: "LT", label: "Lithuania", phone: "370" },
  { code: "LUX", code2d: "LU", label: "Luxembourg", phone: "352" },
  { code: "MAC", code2d: "MO", label: "Macao", phone: "853" },
  {
    code: "MKD",
    code2d: "MK",
    label: "Macedonia, the former Yugoslav Republic of",
    phone: "389",
  },
  { code: "MDG", code2d: "MG", label: "Madagascar", phone: "261" },
  { code: "MWI", code2d: "MW", label: "Malawi", phone: "265" },
  { code: "MYS", code2d: "MY", label: "Malaysia", phone: "60" },
  { code: "MDV", code2d: "MV", label: "Maldives", phone: "960" },
  { code: "MLI", code2d: "ML", label: "Mali", phone: "223" },
  { code: "MLT", code2d: "MT", label: "Malta", phone: "356" },
  { code: "MHL", code2d: "MH", label: "Marshall Islands", phone: "692" },
  { code: "MTQ", code2d: "MQ", label: "Martinique", phone: "596" },
  { code: "MRT", code2d: "MR", label: "Mauritania", phone: "222" },
  { code: "MUS", code2d: "MU", label: "Mauritius", phone: "230" },
  { code: "MYT", code2d: "YT", label: "Mayotte", phone: "262" },
  { code: "MEX", code2d: "MX", label: "Mexico", phone: "52" },
  {
    code: "FSM",
    code2d: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
  },
  { code: "MDA", code2d: "MD", label: "Moldova, Republic of", phone: "373" },
  { code: "MCO", code2d: "MC", label: "Monaco", phone: "377" },
  { code: "MNG", code2d: "MN", label: "Mongolia", phone: "976" },
  { code: "MNE", code2d: "ME", label: "Montenegro", phone: "382" },
  { code: "MSR", code2d: "MS", label: "Montserrat", phone: "1-664" },
  { code: "MAR", code2d: "MA", label: "Morocco", phone: "212" },
  {
    code: "MOZ",
    code2d: "MZ",
    label: "Mozambique",
    phone: "258",
    currency: "USD",
    coin: "$",
  },
  { code: "MMR", code2d: "MM", label: "Myanmar", phone: "95" },
  { code: "NAM", code2d: "NA", label: "Namibia", phone: "264" },
  { code: "NRU", code2d: "NR", label: "Nauru", phone: "674" },
  { code: "NPL", code2d: "NP", label: "Nepal", phone: "977" },
  { code: "NLD", code2d: "NL", label: "Netherlands", phone: "31" },
  { code: "ANT", code2d: "AN", label: "Netherlands Antilles", phone: "N" },
  { code: "NCL", code2d: "NC", label: "New Caledonia", phone: "687" },
  { code: "NZL", code2d: "NZ", label: "New Zealand", phone: "64" },
  { code: "NIC", code2d: "NI", label: "Nicaragua", phone: "505" },
  { code: "NER", code2d: "NE", label: "Niger", phone: "227" },
  { code: "NGA", code2d: "NG", label: "Nigeria", phone: "234" },
  { code: "NIU", code2d: "NU", label: "Niue", phone: "683" },
  { code: "NFK", code2d: "NF", label: "Norfolk Island", phone: "672" },
  {
    code: "MNP",
    code2d: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
  },
  { code: "NOR", code2d: "NO", label: "Norway", phone: "47" },
  { code: "OMN", code2d: "OM", label: "Oman", phone: "968" },
  { code: "PAK", code2d: "PK", label: "Pakistan", phone: "92" },
  { code: "PLW", code2d: "PW", label: "Palau", phone: "680" },
  {
    code: "PSE",
    code2d: "PS",
    label: "Palestinian Territory, Occupied",
    phone: "970",
  },
  { code: "PAN", code2d: "PA", label: "Panama", phone: "507" },
  { code: "PNG", code2d: "PG", label: "Papua New Guinea", phone: "675" },
  {
    code: "PRY",
    code2d: "PY",
    label: "Paraguay",
    phone: "595",
    currency: "USD",
    coin: "$",
  },
  { code: "PER", code2d: "PE", label: "Peru", phone: "51" },
  { code: "PHL", code2d: "PH", label: "Philippines", phone: "63" },
  { code: "PCN", code2d: "PN", label: "Pitcairn", phone: "870" },
  { code: "POL", code2d: "PL", label: "Poland", phone: "48" },
  { code: "PRT", code2d: "PT", label: "Portugal", phone: "351" },
  { code: "PRI", code2d: "PR", label: "Puerto Rico", phone: "1" },
  { code: "QAT", code2d: "QA", label: "Qatar", phone: "974" },
  { code: "REU", code2d: "RE", label: "Réunion", phone: "262" },
  { code: "ROU", code2d: "RO", label: "Romania", phone: "40" },
  { code: "RUS", code2d: "RU", label: "Russian Federation", phone: "7" },
  { code: "RWA", code2d: "RW", label: "Rwanda", phone: "250" },
  {
    code: "SHN",
    code2d: "SH",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    phone: "290",
  },
  { code: "KNA", code2d: "KN", label: "Saint Kitts and Nevis", phone: "1-869" },
  { code: "LCA", code2d: "LC", label: "Saint Lucia", phone: "1-758" },
  {
    code: "SPM",
    code2d: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
  },
  {
    code: "VCT",
    code2d: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
  },
  { code: "WSM", code2d: "WS", label: "Samoa", phone: "685" },
  { code: "SMR", code2d: "SM", label: "San Marino", phone: "378" },
  { code: "STP", code2d: "ST", label: "Sao Tome and Principe", phone: "239" },
  { code: "SAU", code2d: "SA", label: "Saudi Arabia", phone: "966" },
  { code: "SEN", code2d: "SN", label: "Senegal", phone: "221" },
  { code: "SRB", code2d: "RS", label: "Serbia", phone: "381" },
  { code: "SYC", code2d: "SC", label: "Seychelles", phone: "248" },
  { code: "SLE", code2d: "SL", label: "Sierra Leone", phone: "232" },
  { code: "SGP", code2d: "SG", label: "Singapore", phone: "65" },
  { code: "SVK", code2d: "SK", label: "Slovakia", phone: "421" },
  { code: "SVN", code2d: "SI", label: "Slovenia", phone: "386" },
  { code: "SLB", code2d: "SB", label: "Solomon Islands", phone: "677" },
  { code: "SOM", code2d: "SO", label: "Somalia", phone: "252" },
  { code: "ZAF", code2d: "ZA", label: "South Africa", phone: "27" },
  {
    code: "SGS",
    code2d: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "SSD", code2d: "SS", label: "South Sudan", phone: "211" },
  { code: "ESP", code2d: "ES", label: "Spain", phone: "34" },
  { code: "LKA", code2d: "LK", label: "Sri Lanka", phone: "94" },
  { code: "SDN", code2d: "SD", label: "Sudan", phone: "249" },
  { code: "SUR", code2d: "SR", label: "Suriname", phone: "597" },
  { code: "SJM", code2d: "SJ", label: "Svalbard and Jan Mayen", phone: "47" },
  { code: "SWZ", code2d: "SZ", label: "Swaziland", phone: "268" },
  { code: "SWE", code2d: "SE", label: "Sweden", phone: "46" },
  {
    code: "CHE",
    code2d: "CH",
    label: "Switzerland",
    phone: "41",
    currency: "USD",
    coin: "$",
  },
  { code: "SYR", code2d: "SY", label: "Syrian Arab Republic", phone: "963" },
  {
    code: "TWN",
    code2d: "TW",
    label: "Taiwan, Province of China",
    phone: "886",
  },
  { code: "TJK", code2d: "TJ", label: "Tajikistan", phone: "992" },
  {
    code: "TZA",
    code2d: "TZ",
    label: "Tanzania, United Republic of",
    phone: "255",
  },
  { code: "THA", code2d: "TH", label: "Thailand", phone: "66" },
  { code: "TLS", code2d: "TL", label: "Timor-Leste", phone: "670" },
  { code: "TGO", code2d: "TG", label: "Togo", phone: "228" },
  { code: "TKL", code2d: "TK", label: "Tokelau", phone: "690" },
  { code: "TON", code2d: "TO", label: "Tonga", phone: "676" },
  { code: "TTO", code2d: "TT", label: "Trinidad and Tobago", phone: "1-868" },
  { code: "TUN", code2d: "TN", label: "Tunisia", phone: "216" },
  { code: "TUR", code2d: "TR", label: "Turkey", phone: "90" },
  { code: "TKM", code2d: "TM", label: "Turkmenistan", phone: "993" },
  {
    code: "TCA",
    code2d: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
  },
  { code: "TUV", code2d: "TV", label: "Tuvalu", phone: "688" },
  { code: "UGA", code2d: "UG", label: "Uganda", phone: "256" },
  { code: "UKR", code2d: "UA", label: "Ukraine", phone: "380" },
  { code: "ARE", code2d: "AE", label: "United Arab Emirates", phone: "971" },
  { code: "GBR", code2d: "GB", label: "United Kingdom", phone: "44" },
  { code: "USA", code2d: "USD", label: "United States", phone: "1" },
  {
    code: "UMI",
    code2d: "UM",
    label: "United States Minor Outlying Islands",
    phone: "N",
  },
  {
    code: "URY",
    code2d: "UY",
    label: "Uruguay",
    phone: "598",
    currency: "USD",
    coin: "$",
  },
  { code: "UZB", code2d: "UZ", label: "Uzbekistan", phone: "998" },
  { code: "VUT", code2d: "VU", label: "Vanuatu", phone: "678" },
  {
    code: "VEN",
    code2d: "VE",
    label: "Venezuela, Bolivarian Republic of",
    phone: "58",
  },
  { code: "VNM", code2d: "VN", label: "Viet Nam", phone: "84" },
  {
    code: "VGB",
    code2d: "VG",
    label: "Virgin Islands, British",
    phone: "1-284",
  },
  { code: "VIR", code2d: "VI", label: "Virgin Islands, U.S.", phone: "1-340" },
  { code: "WLF", code2d: "WF", label: "Wallis and Futuna", phone: "681" },
  { code: "ESH", code2d: "EH", label: "Western Sahara", phone: "212" },
  { code: "YEM", code2d: "YE", label: "Yemen", phone: "967" },
  { code: "ZMB", code2d: "ZM", label: "Zambia", phone: "260" },
  { code: "ZWE", code2d: "ZW", label: "Zimbabwe", phone: "263" },
];
