import React, { useEffect } from "react";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { getActiveLanguage } from "../../../../utils/getActiveLanguage";
import { iFrameUrlMock } from "./mocks";

import { Iframe } from "./styles";

const Success = () => {
  const { setNavigationAction } = useAuth();

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("success.header"),
    });
  }, [setNavigationAction]);

  const activeLanguage = getActiveLanguage();

  return (
    <Iframe src={iFrameUrlMock[activeLanguage ?? "pt-BR"]} title="success" />
  );
};

export default Success;
