import { BsTrophy } from "react-icons/bs";
import { StyledTrophy, TrophyNumber } from "./styles";

interface TrophyPropertys {
  number: number;
}
export function TrophyWithNumber({ number }: TrophyPropertys) {
  return (
    <div>
      <StyledTrophy
        color={
          number === 1
            ? "#FFDD43"
            : number === 2
            ? "#C0C0C0"
            : number === 3
            ? "#B87333"
            : "var(--fixit)"
        }
      >
        <BsTrophy />
        <TrophyNumber
          color={
            number === 1
              ? "#FFDD43"
              : number === 2
              ? "#C0C0C0"
              : number === 3
              ? "#B87333"
              : "var(--fixit)"
          }
        >
          {number}
        </TrophyNumber>
      </StyledTrophy>
    </div>
  );
}
