import { useState } from "react";
import { RiEdit2Line, RiDeleteBin7Line } from "react-icons/ri";
import { AddressModal } from "../../../../../../components/AddressModal";
import { AddressTypes } from "../../../../../../components/AddressModal/mocks";
import { Modal } from "../../../../../../components/Modal";
import Popover from "../../../../../../components/Popover";
import { i18n } from "../../../../../../localization/i18n";
import { IAddress, ILicensee } from "../../dtos";
import { ActionItem, ActionsContainer, Container, Header, IconButtonContainer, ImageContainer, LeftContent, PrincipalInfos, RightContent } from "./styles";

interface IAddressItem {
  address: IAddress;
  licensee: ILicensee;
  editAddress?: IAddress;
  getLicenseeAddresses: (id: string) => Promise<void>;
  getAddressById: (id: string) => Promise<void>;
  deleteAddress: (address_id: string) => Promise<void>;
  address_id?: string;
}

export function AddressItem({ editAddress, getAddressById, address_id, licensee, address, getLicenseeAddresses, deleteAddress }: IAddressItem) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);

  return (
    <Container>
      <Header>
        <LeftContent>
          <ImageContainer>
            {AddressTypes.map((add) => {
              if (add.value === address.type) {
                return (
                  add.component
                )
              }
            })}
          </ImageContainer>
          <PrincipalInfos>
            <span className="solution">
              {AddressTypes.map((add) => {
                if (add.value === address.type) {
                  return (
                    add.optionText
                  );
                }
              })}
            </span>
            {address.billing_address &&
              <span className="date">{`${i18n.t("address.billing_address")}`}</span>
            }
            {address.deliver_address &&
              <span className="date">{`${i18n.t("address.deliver_address")}`}</span>
            }
            {address.company_address &&
              <span className="date">{`${i18n.t("address.company_address")}`}</span>
            }
          </PrincipalInfos>
        </LeftContent>
        <RightContent>
          <ActionsContainer>
            <ActionItem>
              <IconButtonContainer>
                <Popover position="left" label={`${i18n.t("address.address_options.edit")}`}>
                  <RiEdit2Line size={18} onClick={() => {
                    address_id && getAddressById(address_id);
                    setIsEditOpen(true);
                    setIsModalOpen(true);
                  }} />
                </Popover>
                <Popover position="left" label={`${i18n.t("address.address_options.delete")}`}>
                  <RiDeleteBin7Line size={18} onClick={() => {
                    deleteAddress(address.id);
                    getLicenseeAddresses(licensee.id);
                  }} />
                </Popover>
              </IconButtonContainer>
              {
                isModalOpen &&
                <Modal open={isModalOpen} modalWidth={500}>
                  <AddressModal
                    editAddress={editAddress}
                    address_id={address_id}
                    getLicenseeAddresses={getLicenseeAddresses}
                    isEditOpen={isEditOpen}
                    licensee={licensee}
                    closeModal={async () => {
                      setIsModalOpen(false);
                      setIsEditOpen(false);
                    }} />
                </Modal>
              }
            </ActionItem>
          </ActionsContainer>
        </RightContent>
      </Header>
    </Container>
  )
}
