import { Container, CheckContainer } from "./styles";
import { RiCheckFill } from "react-icons/ri";

interface CheckboxProps {
  check: boolean;
  handleChange: () => any;
  disabled?: boolean;
}

export default function Checkbox({
  check,
  handleChange,
  disabled = false,
}: CheckboxProps) {
  return (
    <Container
      onClick={() => {
        if (!disabled) {
          handleChange();
        }
      }}
    >
      <CheckContainer check={check}>
        {check && <RiCheckFill size={18} />}
      </CheckContainer>
    </Container>
  );
}
