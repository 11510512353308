import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  width: 190px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 1px 2px 2px 2px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: all 0.2s ease-in;

  :hover {
    transform: scale(1.1);
    box-shadow: 2px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    object-fit: contain;
    width: 100%;
    border-radius: 20px;
  }
`;
