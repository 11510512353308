import styled from "styled-components";
import global from "../../../../../../global";

export const PreferredMethodCardContainer = styled.div`
  position: relative;
  width: 380px;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  transition: all 0.3s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    width: 100%;
  }
`;

export const Content = styled.div`
  .preferred_method {
    font-size: 16px;
    font-weight: 500;
    color: var(--dark-gray);
  }

  .method {
    font-weight: 600;
  }

  .card_infos {
    margin-top: 10px;

    display: flex;
    align-items: center;
    gap: 4px;
  }

  .hidden_digits {
    color: var(--dark-gray);
  }

  .card_holder {
    font-weight: 500;
    color: var(--dark-gray);
    margin-right: 1rem;
  }

  .four_digits {
    font-weight: 500;
    color: var(--dark-gray);
  }

  img {
    width: 40px;
    height: 40px;
    margin-left: 10px;
  }

  .edit {
    position: absolute;
    top: 10px;
    right: 10px;

    svg {
      width: 16px;
      height: 16px;
      color: var(--fixit);
      cursor: pointer;
    }
  }
`;
