import { ClickAwayListener } from "@mui/material";
import { useEffect, useState } from "react";
import AbsoluteButton from "../../components/AbsoluteButton";
import { Modal } from "../../components/Modal";
import { useAuth } from "../../hooks/AuthContext";
import { i18n } from "../../localization/i18n";
import { EditSolutionModal } from "./components/EditSolutionModal";
import { SolutionItem } from "./components/SolutionItem";
import { ISolution } from "./dtos";
import { services } from "./services/services";
import { Container } from "./styles";

export default function Solutions () {
    const [solutions, setSolutions] = useState<ISolution[]>([]);
    const { setNavigationAction } = useAuth();
    const [editableSolution, setEditableSolution] = useState<Partial<ISolution>>();

    useEffect(() => {
        setNavigationAction({
            hasButton: false,
            description: i18n.t("creators.solutions")
        });

        const abortController = new AbortController();
        let signal = abortController.signal;  

        services.getSolutions(signal).then(solutions => {
            setSolutions(solutions);
        })
        
        return () => abortController.abort();
    }, []);

    return (
        <Container>
            {
                !!editableSolution && (
                    <Modal open={!!editableSolution} modalWidth={450}>
                      <EditSolutionModal
                        editableSolution={editableSolution}
                        closeModal={() => {
                            setEditableSolution(undefined);
                        }}
                      />
                    </Modal>
                )
            }
            {
                solutions.map(solution => (
                    <SolutionItem key={solution.id} editSolution={() => setEditableSolution(solution)} solution={solution} updateSolution={(newSolution) => {
                        setSolutions((prevState) => prevState.map(prevSolution => {
                            if (prevSolution.id === newSolution.id) {
                                return newSolution
                            }
                            return prevSolution
                        }))
                    }}/>
                ))
            }
            <AbsoluteButton
                absolute
                onClick={() => {
                    setEditableSolution({})
                }}
            />
        </Container>
    )
}