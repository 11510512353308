import { i18n } from "../../localization/i18n";

export const OperationalCountryCoins = [
    {
        value: `${i18n.t('coins.paraguai.country_name')}`,
        optionText: `₲ - PYG / ${i18n.t('coins.paraguai.coin')}`,
    },
    {
        value: `${i18n.t('coins.mozambique.country_name')}`,
        optionText: `MZN / ${i18n.t('coins.mozambique.coin')}`,
    },
    {
        value: `${i18n.t('coins.uruguai.country_name')}`,
        optionText: `$ - UYU / ${i18n.t('coins.uruguai.coin')}`,
    },
    {
        value: `${i18n.t('coins.swiss.country_name')}`,
        optionText: `SFr - CHF / ${i18n.t('coins.swiss.coin')}`,
    },
    {
        value: `${i18n.t('coins.europe.country_name')}`,
        optionText: `EUR / ${i18n.t('coins.europe.coin')}`,
    },
    {
        value: `${i18n.t('coins.argentina.country_name')}`,
        optionText: `$ - ARS / ${i18n.t('coins.argentina.coin')}`,
    },
    {
        value: `${i18n.t('coins.el_salvador.country_name')}`,
        optionText: `$ - USD / ${i18n.t('coins.el_salvador.coin')}`,
    },
    {
        value: `${i18n.t('coins.venezuela.country_name')}`,
        optionText: `Bs.F - VEF / ${i18n.t('coins.venezuela.coin')}`,
    },
    {
        value: `${i18n.t('coins.brazil.country_name')}`,
        optionText: `R$ / ${i18n.t('coins.brazil.coin')}`,
    },
]