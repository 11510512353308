import styled from 'styled-components';

export const Container = styled.div``;

export const Header = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    cursor: pointer;
    color: var(--fixit);
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  .image_container {
    position: relative;

    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &:hover {
      .hover_infos {
        opacity: 1;
      }
    }

    img {
      max-width: 100%;
      max-height: 400px;
      border-radius: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      position: absolute;
      top: 10px;
      right: 10px;

      color: var(--white);
      cursor: pointer;
      z-index: 2;

      transition: 0.2s;
    }

    .hover_infos {
      opacity: 0;
      transition: all 0.2s;
    }

    .image_overlay {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.8);

      z-index: 2;
      transition: 0.2s;
    }

    .image_name {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%,-50%);

      font-size: 18px;
      color: var(--white);

      z-index: 2;
      transition: 0.2s;
    }
  }


  .description {
    align-self: flex-start;
    font-size: 16px;
    color: var(--dark-gray);
  }

  .file_input_container {
    width: 100%;
    margin-top: 10px;
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  justify-content: center;
  gap: 16px;

  button {
    width: 100%;
  }
`;
