import styled from 'styled-components'
import dropdownArrowDown from '../../assets/dropdown-arrow-down.svg'

export const Container = styled.div`
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 30px 10px;
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  padding: 30px;
`

export const InfoWrapper = styled.div`
  padding: 0 0 0 30px;
  flex-direction: column;
`

export const PrintersWrapper = styled.div`
  padding: 0 30px 0 0;
`

export const Section = styled.ul`
  list-style-type: none;
  margin-bottom: 30px;
  min-width: 270px;
`

export const Field = styled.li`
  width: 100%;
  margin-bottom: 8px;
`

export const Label = styled.label`
  font-weight: bold;
  color: var(--dark-gray);
  display: flex;
  justify-content: space-between;
`

export const SubTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;

  h2 {
    color: var(--dark-gray);
  }
`

export const AddBtn = styled.button`
  font-size: 0;
  line-height: 0;
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--fixit);
  border-radius: 50%;
  cursor: pointer;

  :active {
    opacity: 0.8;
  }
`

export const DeleteBtn = styled.button`
  color: var(--notification);
  background: transparent;
  cursor: pointer;
`

export const EditBtn = styled.button`
  background-color: transparent;
  text-align: left;
  display: inline;
  color: var(--fixit);
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 8px;
`

export const PasswordReset = styled.a`
  color: var(--fixit);
  cursor: pointer;
`

export const PrinterCount = styled.input`
  height: 32px;
  width: 40px;
  color: var(--dark-gray);
`

export const Select = styled.select`
  border: 1px solid var(--light-gray);
  border-radius: 6px;

  color: var(--dark-gray);
  text-align: left;
  padding-left: 10px;
  margin: 0 10px;
  font-size: 16px;

  height: 32px;
  width: 100%;

  padding: 0 8px;
  margin-bottom: 4px;
  appearance: none;
  background-image: url(${dropdownArrowDown});
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  background-size: 4%;
`;

export const SelectOption = styled.option`
  font-size: 16px;
`;

export const PrinterSelect = styled.li`
  display: flex;
`
