import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { showToast } from "../../../CustomToast";
import DropZone from "../../../DropZone";
import { Modal } from "../../../Modal";
import { OutlinedButton } from "../../../OutlinedButton";
import CropImage from "../CropImage";
import { IFile, ModalProps } from "./dtos";
import { ButtonContainer, CropContainer, DropzoneContainer, File, Form, Subtitle, Title, UploadFilesContainer } from "./styles";

export function UplaodProfileImageModal({open, close, setReloadUser}: ModalProps) {
    const { user } = useAuth();
    const [file, setFile] = useState({} as IFile);
    const [readFile, setReadFile] = useState<any>();
    const [fileLink, setFileLink] = useState('');
    const [showDropzone, setShowDropzone] = useState(false);
    const [croppedFile, setCroppedFile] = useState<Blob>();
    const [cropDispatch, setCropDispatch] = useState<boolean>(false);
    
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
    } = useForm({ mode: "all" });

    useEffect(() => {
        if(file) {
            if(JSON.stringify(file) === '{}') {
                setShowDropzone(true);
            } else {
                setShowDropzone(false);
            }
        }
    }, [file]);

    useEffect(() => {
        if(JSON.stringify(file) !== '{}') toggleDispatch();
    }, [file])

    const clearStates = async () => {
        setCroppedFile({} as Blob);
    }

    const toggleDispatch = async () => {
        setCropDispatch(!cropDispatch);
    }

    useEffect(() => {
        if(readFile) {
            const objectUrl = URL.createObjectURL(readFile[0]);
            setFileLink(objectUrl);
        }
    }, [readFile]);

    async function submitForm () : Promise<void> {
        var formData = new FormData();
        if(croppedFile) formData.append("file", croppedFile);

        if(user) {
            await api.put(`/users/profile-picture/${user.id}`, formData).then(() => {
                showToast({
                    type: 'success',
                    message: `${i18n.t('userProfile.profile_picture.success')}`,
                })
                setReloadUser();
                clearStates();
                close();
            }).catch((err) => {
                showToast({
                    type: 'error',
                    message: `${i18n.t('userProfile.profile_picture.error')}`,
                })
            })
        } return
    }

    return (
        <Modal
            open={open}
            onRequestClose={close}
        >
            <Form onSubmit={handleSubmit(submitForm)}>
                <UploadFilesContainer>
                    <Title>{`${i18n.t('userProfile.profile_picture.register')}`}</Title>
                    {showDropzone ? 
                    <DropzoneContainer>
                        <DropZone
                        onUpload={(file) => {
                            const formattedFile = {
                            name: file[0].name,
                            size: file[0].size,
                            type: file[0].type,
                            file_data: file[0],
                            };
                            setReadFile(file);
                            setFile(formattedFile);
                        }}
                        />
                        <Subtitle>{`${i18n.t('userProfile.profile_picture.adjust')}`}</Subtitle>
                    </DropzoneContainer>
                    : 
                    <CropContainer>
                        <CropImage 
                            file_link={fileLink}
                            cropDispatch={cropDispatch}
                            setCroppedFile={setCroppedFile}
                        />
                    </CropContainer>
                    }

                    {file.name && (
                        <File>
                            <span>{file.name}</span>
                            <RiDeleteBin7Line
                                onClick={async () => {
                                    setFile({} as IFile);
                                }}
                                size={20}
                                color={"var(--fixit)"}
                            />
                        </File>
                )}
                    <ButtonContainer>
                        <OutlinedButton
                            type={"submit"}
                            buttonWidth={200}
                            text={`${i18n.t('userProfile.profile_picture.register_profile_picture')}`}
                        />
                    </ButtonContainer>
                </UploadFilesContainer>
            </Form>
        </Modal>
    )
}