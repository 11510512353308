import { useState } from "react";

import { i18n } from "../../../../localization/i18n";

import { Modal } from "../../../../components/Modal";
import { showToast } from "../../../../components/CustomToast";
import { SolidButton } from "../../../../components/SolidButton";
import { OutlinedInput } from "../../../../components/OutlinedInput";

import api from "../../../../services/api";

import { orderUrgencyTypes } from "../../../../utils/constants";

import { IOrder } from "../../dtos";

import { RiCloseLine } from "react-icons/ri";

import {
  ConfirmOrderUrgencyModalContainer,
  Content,
  Header,
  Footer,
} from "./styles";

interface ConfirmOrderUrgencyModalProps {
  order: IOrder;
  open: boolean;
  handleCloseModal: () => void;
  getOrders: (reload: boolean) => Promise<void>;
}

export function ConfirmOrderUrgencyModal({
  order,
  open,
  handleCloseModal,
  getOrders,
}: ConfirmOrderUrgencyModalProps) {
  const [loading, setLoading] = useState("");

  async function handleUpdateOrderUrgency(urgency: string) {
    setLoading("urgency");

    try {
      const body = {
        urgency_type: urgency,
      };

      await api.patch(`/orders/urgency/${order.id}`, body);

      await getOrders(true);
      handleCloseModal();
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao atualizar a urgência do pedido",
      });
    } finally {
      setLoading("");
    }
  }

  return (
    <Modal open={open} modalWidth={500}>
      <ConfirmOrderUrgencyModalContainer>
        <Header>
          <div className="confirm_title">
            {`${i18n.t("orders.urgency.requested")}`} #{order?.incremental_id}
          </div>

          <RiCloseLine onClick={handleCloseModal} />
        </Header>

        <Content>
          <div className="description">
            {`${i18n.t("orders.urgency.requestedInfo")}`} #
            {order?.incremental_id},{" "}
            {`${i18n.t("orders.urgency.requestedApprove")}`}
          </div>

          <div className="justification">
            <OutlinedInput
              inputName="justification"
              label={`${i18n.t("orders.confirmation.justification")}`}
              textArea
              disabled
              handleChange={() => {}}
              value={order?.urgency_reason ?? ""}
            />
          </div>
        </Content>

        <Footer>
          <SolidButton
            text={`${i18n.t("orders.urgency.refuse")}`}
            buttonColor="var(--notification)"
            buttonHoverColor="var(--formError)"
            loading={loading === orderUrgencyTypes.normal}
            onClick={() => handleUpdateOrderUrgency(orderUrgencyTypes.normal)}
          />
          <SolidButton
            text={`${i18n.t("orders.urgency.approve")}`}
            buttonColor="var(--finished)"
            buttonHoverColor="var(--active-green-hover)"
            loading={loading === orderUrgencyTypes.approved}
            onClick={() => handleUpdateOrderUrgency(orderUrgencyTypes.approved)}
          />
        </Footer>
      </ConfirmOrderUrgencyModalContainer>
    </Modal>
  );
}
