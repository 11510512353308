import { profileTypes } from "../../utils/constants";
import { FiUserCheck } from 'react-icons/fi';
import { RiAdminLine } from 'react-icons/ri';
import { i18n } from "../../localization/i18n";

export const ProfileTypes = [
  { 
    label: i18n.t('person.user'), 
    role: 'EMPLOYEE',
    id: profileTypes.employee, 
    component: (
      <RiAdminLine size={20} />
    )
  },
  { 
    label: i18n.t('person.manager'), 
    role: 'LICENSEE',
    id: profileTypes.admin, 
    component: (
      <FiUserCheck size={20}/>
    )
  },
];
