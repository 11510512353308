import styled from "styled-components";

export const Container = styled.div`
  padding: 20px 10px 30px 10px;

  @media (max-width: 768px) {
    padding: 20px 0px 30px 0px;
  }
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  svg {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    color: var(--dark-gray);
  }
`;

export const StepperContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding: 0 40px;

  @media (max-width: 500px) {
    padding: 0 16px;
  }
`;
