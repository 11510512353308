import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.span`
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  color: var(--dark-gray);
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  color: var(--dark-gray);
  font-weight: 500;
  justify-content: center;
  margin-top: 30px;
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;
