import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton, Snackbar } from "@mui/material";
import { IoMdClose } from "react-icons/io";
import { i18n } from "../../localization/i18n";

interface MenuProps {
  rows: [
    {
      onClick: () => void;
      label: string;
    }
  ];
  menuIcon: any;
  snackBarLabel: string;
  undoSnackBarAction: () => void;
}
export default function DropdownBasicMenu(menu: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const action = (
    <React.Fragment>
      <Button
        color="secondary"
        size="small"
        onClick={() => {
          menu.undoSnackBarAction();
          setOpenSnackbar(false);
        }}
      >
        {`${i18n.t("global.undo")}`}
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenSnackbar(false)}
      >
        <IoMdClose />
      </IconButton>
    </React.Fragment>
  );

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {menu.menuIcon}
      </Button>
      <Menu
        transitionDuration={{ exit: 0, enter: 200 }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {menu.rows.map((row) => (
          <MenuItem
            onClick={() => {
              row.onClick();
              setOpenSnackbar(true);
              handleClose();
            }}
          >
            {row.label}
          </MenuItem>
        ))}
      </Menu>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        message={menu.snackBarLabel}
        action={action}
      />
    </div>
  );
}
