import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";

import api from "../../../../services/api";

import { Content, TableContainer, Td, Button, StandardTag } from "./styles";

import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
export interface ISolution {
  id: string;
  name: string;
  category: string;
  is_standard: boolean;
  name_key: string;
}

export default function SolutionsCreator() {
  const [solutions, setSolutions] = useState<ISolution[]>([]);
  const { setNavigationAction } = useAuth();

  const navigate = useNavigate();

  function goToEditSolution(id: string) {
    navigate(
      "/creators/solutions/edit", {
        state: {
        solution_id: id,
      },
    });
  }

  function goToSolutionArchives(name_key: string) {
    navigate({
      pathname: `/creators/solutions/${name_key}/standards`,
    });
  }

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("creators.solutions")
    });

    const abortController = new AbortController();
    let signal = abortController.signal;  

    api.get("/solutions", { signal }).then((response) => {
      setSolutions(response.data);
    });
    
    return () => abortController.abort();
  }, []);

  return (
    <>
      <Content>
        <TableContainer>
          <table>
            <thead>
              <tr>
                <th>Nome</th>
                <th>Categoria</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {solutions.map((solution, index) => (
                <tr
                  key={solution.id}
                  onClick={() => goToEditSolution(solution.id)}
                >
                  <Td className="name">{solution.name}</Td>
                  <Td>{solution.category}</Td>
                    <Td></Td>
                </tr>
              ))}
            </tbody>
          </table>
        </TableContainer>
        <Link to="create">
          <Button type="button">
            <RiAddFill size={40} color="var(--white)" />
          </Button>
        </Link>
      </Content>
    </>
  );
}
