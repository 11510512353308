import React, { useState, useCallback, useEffect } from "react";
import Slider from "@mui/material/Slider";
import Cropper from "react-easy-crop";
import getCroppedImage from './utils/crop';
import { Point, Area } from "react-easy-crop/types";
import "./styles.ts";
import { Container } from "./styles";

interface CropProps {
  file_link: string;
  cropDispatch: boolean;

  setCroppedFile: (arg: Blob) => any;
}

export const CropImage = ({file_link, setCroppedFile, cropDispatch}: CropProps) => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>();
  
  useEffect(() => {
    if(croppedAreaPixels) {
      onCrop();
    }
  }, [croppedAreaPixels])

  const onCrop = async () => {
    const croppedImage: any = await getCroppedImage(file_link, croppedAreaPixels);
    
    if(croppedImage) {
      setCroppedFile(croppedImage.file);
    }
  }

  const onCropComplete =
    async (croppedArea: Area, croppedAreaPixels: Area) => {
      setCroppedAreaPixels(croppedAreaPixels);
      await onCrop();
    }

  return (
    <Container>
      <div className="App">
        <div className="crop-container">
          <Cropper
            image={file_link}
            crop={crop}
            zoom={zoom}
            aspect={1 / 1}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropShape={"round"}
          />
        </div>
        <div className="controls">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e: any, zoom: any) => setZoom(Number(zoom))}
            classes={{ root: "slider" }}
          />
        </div>
      </div>
    </Container>
    
  );
};

export default CropImage;
