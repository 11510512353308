import { useState, useCallback, useMemo, useEffect } from "react";

import { useAuth } from "../../../../../../hooks/AuthContext";

import * as _ from "lodash";
import * as Yup from "yup";

import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { OutlinedInput } from "../../../../../OutlinedInput";
import { OutlinedButton } from "../../../../../OutlinedButton";
import { SolidButton } from "../../../../../SolidButton";
import { showToast } from "../../../../../CustomToast";

import { validateCardNumber } from "../../../../../../utils/paymentsCards";
import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";

import api from "../../../../../../services/api";

import { PaymentInfosData, PaymentType } from "./dtos";

import { RiCloseLine } from "react-icons/ri";

import VisaSvg from "../../../../../../assets/icons/visa.svg";
import MaterCardSvg from "../../../../../../assets/icons/mastercard.svg";
import AmexSvg from "../../../../../../assets/icons/american-express.svg";
import DinersClubSvg from "../../../../../../assets/icons/diners-club-international.svg";
import DiscoverSvg from "../../../../../../assets/icons/discover.svg";
import JCBSvg from "../../../../../../assets/icons/jcb.svg";

import {
  CreatePaymentInfosModalContainer,
  Header,
  Content,
  InputLine,
  InputContainer,
  Footer,
} from "./styles";
import validateFormFields from "../../../../../../utils/validateFormFields";
import { i18n } from "../../../../../../localization/i18n";

interface CreatePaymentInfosModalProps {
  methodId: string | null;
  handleCloseModal: () => void;
  onMethodUpsert: () => Promise<void>;
  isCardConfirmation?: boolean;
}

export function CreatePaymentInfosModal({
  handleCloseModal,
  onMethodUpsert,
  methodId,
  isCardConfirmation,
}: CreatePaymentInfosModalProps) {
  const { user } = useAuth();
  const [errors, setErrors] = useState({} as any);

  const [loading, setLoading] = useState(false);
  const [cardFlag, setCardFlag] = useState<keyof typeof cardFlagsSvgs | null>(
    null
  );
  const [paymentData, setPaymentData] = useState<PaymentInfosData>({
    preferred_method: "credit",
    cardCode: "",
    cardExpiringDate: "",
    cardFlag: "",
    cardHolder: "",
    cardNumber: "",
  });

  const cardFlagsSvgs = useMemo(() => {
    return {
      Visa: <img src={VisaSvg} alt="Visa" />,
      Mastercard: <img src={MaterCardSvg} alt="Mastercard" />,
      Amex: <img src={AmexSvg} alt="Amex" />,
      DinersClub: <img src={DinersClubSvg} alt="DinersClub" />,
      Discover: <img src={DiscoverSvg} alt="Discover" />,
      JCB: <img src={JCBSvg} alt="JBC" />,
    };
  }, []);

  async function handleCreatePaymentInformations() {
    setLoading(true);

    try {
      const cardPreferred = ["debit", "credit"].includes(
        paymentData.preferred_method
      );
      const cardLastFourDigits = paymentData?.cardNumber?.slice(-4);

      const body = {
        licensee_id: user.person.licensee.id,
        preferred_method: paymentData.preferred_method,
        card_last_four_digits: cardPreferred ? cardLastFourDigits : null,
        card_flag: cardPreferred ? cardFlag : null,
        cardInfos: {
          number: paymentData.cardNumber,
          validationDate: paymentData.cardExpiringDate,
          securityCode: paymentData.cardCode,
        },
      };

      const yup_validation = Yup.object().shape({
        preferred_method: Yup.string().required(typesErrors.required),
        cardNumber: Yup.string().required(typesErrors.required),
        cardHolder: Yup.string().required(typesErrors.required),
        cardCode: Yup.string().required(typesErrors.required),
        cardExpiringDate: Yup.string().required(typesErrors.required),
        // cardFlag: Yup.string().when("preferred_method", {
        //   is: "debit" || "credit",
        //   then: (field) => field.required(typesErrors.required),
        //   otherwise: (field) => field.nullable().notRequired(),
        // }),
      });

      const validation = await validateFormFields(paymentData, yup_validation, {
        preferred_method: "",
        cardNumber: "",
        cardHolder: "",
        cardCode: "",
        cardExpiringDate: "",
        cardFlag: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        setLoading(false);
        return;
      }

      setErrors({});

      await api.post("/payment-infos", body).then((response) => {
        const cardLastFourDigits = paymentData?.cardNumber?.slice(-4);

        const encryptedCards = {
          ...response.data,
          card_holder: paymentData.cardHolder?.toUpperCase(),
        };

        localStorage.setItem(
          `${cardLastFourDigits}`,
          JSON.stringify(encryptedCards)
        );
      });

      await onMethodUpsert();

      handleCloseModal();

      showToast({
        type: "success",
        message: "Dados de pagamento atualizados com sucesso.",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao salvar os dados de pagamento",
      });
    } finally {
      setLoading(false);
      clearModalData();
    }
  }

  async function handleGetPaymentInfos() {
    try {
      const response = await api.get(
        `/payment-infos/${user.person.licensee.id}`
      );

      setPaymentData({
        preferred_method: response.data.preferred_method,
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao carregar as informações de pagamento",
      });
    } finally {
    }
  }

  function changeForm(data: Partial<PaymentInfosData>) {
    setPaymentData((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  function validateCard(cardNumber: string | undefined) {
    cardNumber && setCardFlag(validateCardNumber(cardNumber) as any);
  }

  function clearModalData() {
    setPaymentData({
      preferred_method: "credit",
      cardCode: "",
      cardExpiringDate: "",
      cardFlag: "",
      cardHolder: "",
      cardNumber: "",
    });
    setCardFlag(null);
  }

  const debounceCardValidation = useCallback(
    _.debounce((number) => validateCard(number), 500),
    []
  );

  useEffect(() => {
    if (methodId) {
      handleGetPaymentInfos();
    }
  }, [methodId]);

  return (
    <CreatePaymentInfosModalContainer>
      <Header>
        <span className="title">
          {isCardConfirmation
            ? "Confirmar dados de cartão"
            : methodId
            ? "Atualizar informações de pagamento"
            : "Cadastrar informações de pagamento"}
        </span>
        <RiCloseLine
          onClick={() => {
            handleCloseModal();
            clearModalData();
          }}
        />
      </Header>

      <Content>
        <div className="label">Método de preferência</div>
        <RadioGroup
          row
          onChange={(event) =>
            changeForm({
              preferred_method: event.target.value as PaymentType,
            })
          }
          value={paymentData.preferred_method}
        >
          <FormControlLabel
            value="credit"
            control={<Radio />}
            label="Crédito"
          />
          <FormControlLabel value="debit" control={<Radio />} label="Débito" />
        </RadioGroup>

        {["debit", "credit"].includes(paymentData.preferred_method) && (
          <>
            <div className="label">Informações do cartão</div>
            <InputLine>
              <InputContainer>
                <OutlinedInput
                  error={errors?.cardHolder}
                  inputName="cardHolder"
                  label="Nome impresso no cartão"
                  handleChange={(event) => changeForm({ cardHolder: event })}
                  value={paymentData?.cardHolder ?? ""}
                />
              </InputContainer>
            </InputLine>

            <InputLine className="center">
              <InputContainer>
                <OutlinedInput
                  error={errors?.cardNumber}
                  inputName="cardNumber"
                  label="Número do cartão"
                  handleChange={(event) => {
                    changeForm({ cardNumber: event });
                    debounceCardValidation(event);
                  }}
                  value={paymentData?.cardNumber ?? ""}
                />
              </InputContainer>

              {cardFlag && cardFlagsSvgs[cardFlag]}
            </InputLine>

            <InputLine>
              <InputContainer className="three_grid">
                <OutlinedInput
                  error={errors?.cardCode}
                  inputName="cardCode"
                  label="Código de segurança (CVV)"
                  handleChange={(event) => changeForm({ cardCode: event })}
                  value={paymentData?.cardCode ?? ""}
                />
              </InputContainer>
              <InputContainer>
                <OutlinedInput
                  mask={"99/99"}
                  error={errors?.cardExpiringDate}
                  inputName="cardExpiringDate"
                  label="Validade"
                  handleChange={(event) =>
                    changeForm({ cardExpiringDate: event })
                  }
                  value={paymentData?.cardExpiringDate ?? ""}
                />
              </InputContainer>
            </InputLine>
          </>
        )}
      </Content>

      <Footer>
        <OutlinedButton text="Cancelar" onClick={handleCloseModal} />
        <SolidButton
          text={isCardConfirmation ? "Confirmar" : "Salvar dados"}
          onClick={() => {
            handleCreatePaymentInformations();
          }}
          loading={loading}
          disabled={loading}
        />
      </Footer>
    </CreatePaymentInfosModalContainer>
  );
}
