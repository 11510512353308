import styled from 'styled-components';
import global from '../../../../global';

export const Container = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;


export const Header = styled.div`
  width: 100%;
  margin-top: 10px;
  gap: 10px;

  display: flex;
  align-items: end;
  justify-content: space-between;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    flex-direction: column-reverse;
    margin-top: 0px;
    gap: 10px;
  }
`;


export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-right: 0px;
  }
`;


export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 11px 8px 14px;
  border-radius: 25px;
  background: #eae8e8;
  background: var(--white);
  border: 1px solid var(--gray);

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: var(--gray);
  }

  svg {
    margin-left: 2px;
    cursor: pointer;
    color: var(--gray);
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    padding: 5px 9px 5px 10px;
    
    span {
      font-size: 12px;
      line-height: 12px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    flex-wrap: wrap;
  }
`

export const Section = styled.section`
  h2 {
    color: var(--dark-gray);
    padding-bottom: 12px;
  }

  margin-right: 20px;
  margin-bottom: 20px;

  padding: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  min-width: 300px;
  width: calc(50% - 30px);

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    margin-right: 0;
  }
`

export const Field = styled.div`
  color: var(--dark-gray);
  padding-top: 6px;
`

export const TableDownloadBtn = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  margin-right: 6px;

  border-radius: 6px;
  background-color: var(--light-gray);
  color: var(--dark-gray);
  cursor: pointer;
`

export const FlexButtons = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FileContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 14px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;