import { useEffect, useState } from "react";
import * as Yup from "yup";
import { RiCloseLine } from "react-icons/ri";
import {
  IEditSolutionModalProps,
  IOption,
  ISolutionCategory,
  ISolutionData,
  ISolutionField,
} from "./dtos";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../utils/validateFormFields";
import { showToast } from "../../../../components/CustomToast";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import {
  Container,
  Content,
  Footer,
  Header,
  InputContainer,
  InputLine,
} from "./styles";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { sides } from "../../../../utils/constants";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

export function EditSolutionModal({
  editableSolution,
  closeModal,
}: IEditSolutionModalProps) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});

  const [fields, setFields] = useState<Array<ISolutionField>>([]);
  const [field, setField] = useState<ISolutionField>();

  const [categories, setCategories] = useState<Array<ISolutionCategory>>([]);
  const [category, setCategory] = useState<ISolutionCategory>();

  const [numberSides, setNumberSides] = useState<number>(1);

  const formattedSides = Object.entries(sides).map((side) => ({
    optionText: i18n.t(`global.sides.${side[1]}`),
    value: side[1],
  }));

  const [solutionData, setSolutionData] = useState<ISolutionData>({
    name: "",
    suggested_price: "",
    form_type: {
      optionText: "",
      value: "",
    },
    sides: [],
  });

  const formTypes: IOption[] = [
    {
      value: "",
      optionText: "Default",
    },
    {
      value: "plan",
      optionText: "Plan it",
    },
  ];

  useEffect(() => {
    api
      .get(`/categories`)
      .then((response) => {
        setFields(
          response.data.map((f: any) => ({
            ...f,
            value: f.id,
            optionText: f.name,
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (editableSolution?.solution_category) {
      setField(
        fields.find(
          (item) =>
            item.value === editableSolution?.solution_category?.id ||
            item.value ===
              editableSolution?.solution_category?.solution_field_id
        )
      );
    }
  }, [fields]);

  useEffect(() => {
    if (!!field) {
      api
        .get(`/categories/field/${field.id}`)
        .then((response) => {
          setCategories(
            response.data.map((c: any) => ({
              ...c,
              value: c.id,
              optionText: c.name,
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [field]);

  useEffect(() => {
    setCategory(
      categories.find(
        (item) => item.value === editableSolution?.solution_category?.id
      )
    );
  }, [categories]);

  useEffect(() => {
    if (numberSides && numberSides > solutionData.sides.length) {
      setSolutionData((prevState) => ({
        ...prevState,
        sides: [...prevState.sides, { optionText: "", value: "" }],
      }));
      return;
    }

    if (numberSides && numberSides < solutionData.sides.length) {
      setSolutionData((prevState) => ({
        ...prevState,
        sides: prevState.sides.slice(0, numberSides),
      }));
      return;
    }
  }, [numberSides]);

  useEffect(() => {
    if (editableSolution?.id) {
      const solutionSides: IOption[] = [];

      editableSolution.sides?.forEach((side: string) => {
        const selectedSide = formattedSides.find((item) => item.value === side);
        if (selectedSide) solutionSides.push(selectedSide);
      });

      setSolutionData((prevState) => ({
        ...prevState,
        id: editableSolution.id,
        name: editableSolution.name ?? "",
        suggested_price: editableSolution.suggested_price,
        form_type: formTypes.find(
          (item) => item.value === editableSolution.form_type
        ),
        sides: solutionSides,
      }));

      setNumberSides(editableSolution.sides?.length || 1);
    }
  }, [editableSolution]);

  async function validateForm(event: React.FormEvent) {
    event.preventDefault();

    const body = {
      name: solutionData.name,
      suggested_prive: solutionData.suggested_price,
    };

    const tipSchema = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      suggested_price: Yup.number().notRequired(),
    });

    const validation = await validateFormFields(body, tipSchema, {
      name: "",
      suggested_price: "",
    });

    if (validation.status === 400) {
      console.log(validation.errors);
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });
      return;
    }

    setErrors({});
    handleSubmit().then(() => {
      clearModalData();
      closeModal();
    });
  }

  async function handleSubmit() {
    setLoading(true);

    try {
      let formData = new FormData();
      formData.append("name", solutionData.name);
      if (solutionData.form_type && solutionData.form_type?.value !== "") {
        formData.append("form_type", solutionData.form_type.value.toString());
      }

      solutionData.suggested_price &&
        formData.append("suggested_price", solutionData.suggested_price);

      const solutionCategory =
        field && !category && !categories.length ? field : category;

      solutionCategory &&
        formData.append("solution_category_id", solutionCategory.id);

      solutionData.sides.forEach((side) => {
        formData.append("sides", side.value.toString());
      });

      if (editableSolution?.id) {
        await api.put(`/solutions/${editableSolution?.id}`, formData);
      } else {
        await api.post(`/solutions`, formData);
      }

      showToast({
        type: "success",
        message: "A solução foi atualizado com sucesso",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("solutions.error"),
      });
    } finally {
      setLoading(false);
    }
  }

  function clearModalData() {
    setSolutionData({
      name: "",
      suggested_price: "",
      form_type: {
        optionText: "",
        value: "",
      },
      sides: [],
    });
    setErrors({});
  }

  return (
    <Container>
      <Header>
        <div className="title">{`${i18n.t("solutions.edit")}`}</div>
        <RiCloseLine
          onClick={() => {
            closeModal();
            clearModalData();
          }}
        />
      </Header>

      <Content onSubmit={validateForm}>
        <InputLine>
          <InputContainer>
            <OutlinedInput
              inputName="solutionName"
              label={i18n.t("solutions.name")}
              handleChange={(event) =>
                setSolutionData((prevState) => ({ ...prevState, name: event }))
              }
              value={solutionData.name}
              error={errors.name}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={i18n.t("solutions.field")}
              options={fields.map((item) => ({
                optionText:
                  item?.name[i18n.language as keyof I18NTextLanguageObject] ??
                  "",
                value: item.id,
              }))}
              handleSelect={(selected) => {
                const name: I18NTextLanguageObject = {
                  "pt-BR": selected.optionText.toString(),
                  en: selected.optionText.toString(),
                  es: selected.optionText.toString(),
                  fr: selected.optionText.toString(),
                };
                setField({
                  id: selected.value.toString(),
                  name: name,
                  value: selected.value.toString(),
                  optionText: selected.optionText.toString(),
                });
                setCategory(undefined);
                setCategories([]);
              }}
              selectedOption={
                field?.name[i18n.language as keyof I18NTextLanguageObject] ?? ""
              }
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={i18n.t("solutions.category")}
              options={categories.map((item) => ({
                optionText:
                  item?.name[i18n.language as keyof I18NTextLanguageObject] ??
                  "",
                value: item.id,
              }))}
              handleSelect={(selected) => {
                const name: I18NTextLanguageObject = {
                  "pt-BR": selected.optionText.toString(),
                  en: selected.optionText.toString(),
                  es: selected.optionText.toString(),
                  fr: selected.optionText.toString(),
                };
                setCategory({
                  id: selected.value.toString(),
                  name: name,
                  value: selected.value.toString(),
                  optionText: selected.optionText.toString(),
                });
              }}
              selectedOption={
                category?.name[i18n.language as keyof I18NTextLanguageObject] ??
                ""
              }
            />
          </InputContainer>
        </InputLine>
        <InputLine>
          <InputContainer>
            <OutlinedSelect
              label={i18n.t("solutions.form_type")}
              options={formTypes}
              handleSelect={(selected) => {
                setSolutionData((prevState) => ({
                  ...prevState,
                  form_type: selected,
                }));
              }}
              selectedOption={solutionData.form_type?.optionText ?? ""}
            />
          </InputContainer>

          <InputContainer width="70%">
            <OutlinedInput
              inputName="suggested_price"
              type="number"
              step="0.01"
              min="0.01"
              label={i18n.t("solutions.suggested_price")}
              handleChange={(event) =>
                setSolutionData((prevState) => ({
                  ...prevState,
                  suggested_price: event,
                }))
              }
              value={solutionData.suggested_price ?? ""}
              error={errors.suggested_price}
            />
          </InputContainer>
        </InputLine>

        <InputLine wrap={true}>
          <InputContainer width="70px">
            <OutlinedInput
              inputName="number_sides"
              type="number"
              min={1}
              label={i18n.t("solutions.number_sides")}
              handleChange={(event) => {
                if (Number(event) > 3) return;
                setNumberSides(Number(event));
              }}
              value={numberSides.toString()}
              error={errors.number_sides}
            />
          </InputContainer>

          {solutionData?.sides?.map((side, i) => (
            <InputContainer key={`side-${i}`} width="140px">
              <OutlinedSelect
                label={`${i18n.t("solutions.side")} ${i + 1}`}
                options={formattedSides}
                handleSelect={(selected) => {
                  const sideAlreadySelected = solutionData.sides.find(
                    (item) => item.value === selected.value
                  );

                  if (sideAlreadySelected) return;

                  let tmpSides = [...solutionData.sides];
                  tmpSides[i] = selected;

                  setSolutionData((prevState) => ({
                    ...prevState,
                    sides: [...tmpSides],
                  }));
                }}
                selectedOption={solutionData.sides[i].optionText ?? ""}
              />
            </InputContainer>
          ))}
        </InputLine>

        <Footer>
          <OutlinedButton
            type="submit"
            text={i18n.t("solutions.updateButton")}
            fullWidth={true}
            buttonColor="var(--finished)"
            loading={loading}
          />
        </Footer>
      </Content>
    </Container>
  );
}
