import styled from "styled-components";
import global from "../../../../../../global";

interface ISignature {
  situation?: string;
  is_activated?: boolean;
}

interface IPlan {
  is_active?: boolean;
}

interface IAddress {
  disabled?: boolean;
}

export const Container = styled.div<ISignature>`
  position: relative;
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
  border-left: 3px solid
    ${(ISignature) =>
      (ISignature.situation === "onboarding" && "var(--onboarding)") ||
      (ISignature.situation === "effected" && "var(--finished)") ||
      (ISignature.situation === "training" && "yellow") ||
      (ISignature.situation === "implementation" && "orange") ||
      (ISignature.situation === "operational" && "var(--fixit)") ||
      ISignature.situation};

  transition: all 0.3s;
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: var(--fixit);
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.manager {
    min-width: 120px;
  }

  &.phone {
    min-width: 100px;
  }

  &.document {
    min-width: 130px;
  }

  .data_label {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .data_value {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const ImageContainer = styled.div`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: var(--fixit);

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--white);
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export const LicenseeInfos = styled.div<ISignature>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .licensee_name {
    font-size: 12px;
    line-height: 15px;
    color: ${(ISignature) =>
      ISignature.is_activated ? "var(--finished)" : "var(--gray)"};
    font-weight: 500;
  }

  .licensee_location {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const StatusDesktopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }

  @media (max-width: ${global.breakpoints.mobile}) {
    display: none;
  }
`;

export const MoreOptionsContainer = styled.div`
  svg {
    cursor: pointer;
  }
`;

export const MobileActions = styled.div`
  position: absolute;
  width: 210px;
  top: 38px;
  right: 48px;
  border-radius: 10px;

  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10;
  overflow: hidden;
`;

export const MobileOption = styled.div<IAddress>`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

  &:hover {
    background: var(--light-gray);
  }

  .checked {
    color: var(--finished);
  }

  .change {
    width: 16px;
    height: 16px;
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--fixit);
    cursor: pointer;
  }

  span {
    font-size: 16px;
    line-height: 19px;
    color: #403e3e;
  }
`;

export const MobileActiveOption = styled.div<IPlan>`
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: white;

  cursor: pointer;

  &:hover {
    background: var(--light-gray);
  }

  .checked {
    color: var(--finished);
  }

  .deliver {
    color: var(--fixit);
  }

  .not_visible {
    color: orange;
  }

  .change {
    width: 16px;
    height: 16px;
  }

  svg {
    width: 18px;
    height: 18px;
    color: var(--fixit);
    cursor: pointer;
  }

  span {
    font-size: 16px;
    line-height: 19px;
    color: #403e3e;
  }
`;

export const StatusActions = styled.div`
  position: absolute;
  top: -120px;
  right: 260px;
  border-radius: 5px;

  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;

export const AddressActions = styled.div`
  position: absolute;
  top: 12rem;
  right: 260px;
  border-radius: 5px;

  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;

export const SignatureActions = styled.div`
  position: absolute;
  top: -2.5rem;
  right: 260px;
  border-radius: 5px;

  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;

export const SignatureOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdvancedOptionsAction = styled.div`
  position: absolute;
  top: 235px;
  right: 260px;
  border-radius: 5px;

  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;

export const ContractTypeOptions = styled.div`
  position: absolute;
  top: 115px;
  right: 260px;
  border-radius: 5px;

  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 20;
  overflow: hidden;
`;
