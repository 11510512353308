import styled from "styled-components";

interface IStatus {
  paid: string | undefined;
}

export const Container = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  &:nth-child(n + 2) {
    border-top: 1px solid var(--light-gray);
  }
`;

export const Content = styled.div`
  color: var(--fixit);
  width: 100%;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
`;

export const BillStatusIndicator = styled.div<IStatus>`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${(props) =>
    props.paid === "pago"
      ? "var(--active-green)"
      : props.paid === "aguardando"
      ? "var(--warning)"
      : "var(--canceled)"};
`;
