import { i18n } from "../../localization/i18n";

export const orderTypeOptions = [
  { optionText: "B2B", value: "is_partner" },
  { optionText: "B2C", value: "is_patient" },
  { optionText: "FIXITER", value: "is_fixiter" },
];

export const solutionTypeOptions = [
  {
    optionText: i18n.t("financial.prices.solution_type.standard"),
    value: "standard",
  },
  {
    optionText: i18n.t("financial.prices.solution_type.custom"),
    value: "custom",
  },
];

export const nullOption = {
  optionText: i18n.t("global.novalue"),
  value: "",
};

export const xlsxColumnsByUserRole = {
  FIXITER: [
    [
      i18n.t("address.number"),
      i18n.t("orders.solution"),
      i18n.t("orders.fields.status"),
      i18n.t("customSolution.filamentColor"),
      i18n.t("orders.materialWeight"),
      i18n.t("customSolution.observations"),
      i18n.t("orderBox.date"),
      i18n.t("orders.quantity"),
      i18n.t("userProfile.data_licensee.fixpoint"),
    ],
  ],
  EMPLOYEE: [
    [
      i18n.t("address.number"),
      i18n.t("orders.solution"),
      i18n.t("tips.type"),
      i18n.t("orders.fields.status"),
      i18n.t("customSolution.filamentColor"),
      i18n.t("orders.materialWeight"),
      i18n.t("customSolution.observations"),
      i18n.t("orderBox.date"),
      i18n.t("orders.confirmation.patient"),
      i18n.t("orders.confirmation.partner"),
      i18n.t("orders.quantity"),
    ],
  ],
  DEFAULT: [
    [
      i18n.t("address.number"),
      i18n.t("orders.solution"),
      i18n.t("tips.type"),
      i18n.t("orders.fields.status"),
      i18n.t("customSolution.filamentColor"),
      i18n.t("orders.materialWeight"),
      i18n.t("customSolution.observations"),
      i18n.t("orderBox.date"),
      i18n.t("orders.confirmation.patient"),
      i18n.t("orders.confirmation.partner"),
      i18n.t("orders.quantity"),
      i18n.t("orders.confirmation.totalValue"),
    ],
  ],
  AS_PROVIDER: [
    [
      i18n.t("address.number"),
      i18n.t("orders.solution"),
      i18n.t("tips.type"),
      i18n.t("orders.fields.status"),
      i18n.t("customSolution.filamentColor"),
      i18n.t("orders.materialWeight"),
      i18n.t("customSolution.observations"),
      i18n.t("orderBox.date"),
      i18n.t("orders.order_provider.requester"),
      i18n.t("orders.quantity"),
      i18n.t("orders.confirmation.totalValue"),
    ],
  ],
};
