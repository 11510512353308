//https://pt.wikipedia.org/wiki/Lista_de_pa%C3%ADses_por_c%C3%B3digo_do_COI

import { i18n } from "../i18n";

//https://pt.wikipedia.org/wiki/Lista_de_moedas
export const countries = [
    { optionText: 'Afeganistão', value: 'AFG', currency: 'AFN' },
    { optionText: 'África do Sul', value: 'ZAF', currency: 'ZAR' },
    { optionText: 'Albânia', value: 'ALB', currency: 'ALL' },
    { optionText: 'Alemanha', value: 'DEU', currency: 'EUR' },
    { optionText: 'Andorra', value: 'AND', currency: 'EUR' },
    { optionText: 'Angola', value: 'AGO', currency: 'AOA' },
    { optionText: 'Antiga e Barbuda', value: 'ATG', currency: 'XCD' },
    { optionText: 'Arábia Saudita', value: 'SAU', currency: 'SAR' },
    { optionText: 'Argélia', value: 'DZA', currency: 'DZD' },
    { optionText: 'Argentina', value: 'ARG', currency: 'ARS' },
    { optionText: 'Arménia', value: 'ARM', currency: 'AMD' },
    { optionText: 'Austrália', value: 'AUS', currency: 'AUD' },
    { optionText: 'Áustria', value: 'AUT', currency: 'EUR' },
    { optionText: 'Azerbaijão', value: 'AZE', currency: 'AZN' },
    { optionText: 'Bahamas', value: 'BHS', currency: 'BSD' },
    { optionText: 'Bangladexe', value: 'BGD', currency: 'BDT' },
    { optionText: 'Barbados', value: 'BRB', currency: 'BBD' },
    { optionText: 'Barém', value: 'BHR', currency: 'BHD' },
    { optionText: 'Bélgica', value: 'BEL', currency: 'EUR' },
    { optionText: 'Belize', value: 'BLZ', currency: 'BZD' },
    { optionText: 'Benim', value: 'BEN', currency: 'XOF' },
    { optionText: 'Bielorrússia', value: 'BLR', currency: 'BYN' },
    { optionText: 'Bolívia', value: 'BOL', currency: 'BOB' },
    { optionText: 'Bósnia e Herzegovina', value: 'BIH', currency: 'BAM' },
    { optionText: 'Botsuana', value: 'BWA', currency: 'BWP' },
    { optionText: 'Brasil', value: 'BRA', currency: 'BRL' },
    { optionText: 'Brunei', value: 'BRU', currency: 'BND' },
    { optionText: 'Bulgária', value: 'BGR', currency: 'BGN' },
    { optionText: 'Burquina Faso', value: 'BFA', currency: 'XOF' },
    { optionText: 'Burúndi', value: 'BDI', currency: 'BIF' },
    { optionText: 'Butão', value: 'BTN', currency: 'BTN' },
    { optionText: 'Cabo Verde', value: 'CPV', currency: 'CVE' },
    { optionText: 'Camarões', value: 'CMR', currency: 'XAF' },
    { optionText: 'Camboja', value: 'KHM', currency: 'KHR' },
    { optionText: 'Canadá', value: 'CAN', currency: 'CAD' },
    { optionText: 'Catar', value: 'QAT', currency: 'QAR' },
    { optionText: 'Cazaquistão', value: 'KAZ', currency: 'KZT' },
    { optionText: 'Chade', value: 'TCD', currency: 'XAF' },
    { optionText: 'Chile', value: 'CHL', currency: 'CLP' },
    { optionText: 'China', value: 'CHN', currency: 'CNY' },
    { optionText: 'Chipre', value: 'CYP', currency: 'EUR' },
    { optionText: 'Colômbia', value: 'COL', currency: 'COP' },
    { optionText: 'Comores', value: 'COM', currency: 'KMF' },
    { optionText: 'Congo-Brazzaville', value: 'COG', currency: 'XAF' },
    { optionText: 'Coreia do Norte', value: 'PRK', currency: 'KPW' },
    { optionText: 'Coreia do Sul', value: 'KOR', currency: 'KRW' },
    { optionText: 'Cosovo', value: 'KOS', currency: 'EUR' },
    { optionText: 'Costa do Marfim', value: 'CIV', currency: 'XOF' },
    { optionText: 'Costa Rica', value: 'CRI', currency: 'CRC' },
    { optionText: 'Croácia', value: 'HRV', currency: 'HRK' },
    { optionText: 'Cuaite', value: 'KWT', currency: 'KWD' },
    { optionText: 'Cuba', value: 'CUB', currency: 'CUP' },
    { optionText: 'Dinamarca', value: 'DNK', currency: 'DKK' },
    { optionText: 'Dominica', value: 'DMA', currency: 'XCD' },
    { optionText: 'Egito', value: 'EGY', currency: 'EGP' },
    { optionText: 'Emirados Árabes Unidos', value: 'ARE', currency: 'AED' },
    { optionText: 'Equador', value: 'ECU', currency: 'USD' },
    { optionText: 'Eritreia', value: 'ERI', currency: 'ERN' },
    { optionText: 'Eslováquia', value: 'SVK', currency: 'EUR' },
    { optionText: 'Eslovénia', value: 'SVN', currency: 'EUR' },
    { optionText: 'Espanha', value: 'ESP', currency: 'EUR' },
    { optionText: 'Essuatíni', value: 'SWZ', currency: 'SZL' },
    { optionText: 'Palestina', value: 'PSE', currency: 'ILS' },
    { optionText: 'Estados Unidos', value: 'USA', currency: 'USD' },
    { optionText: 'Estónia', value: 'EST', currency: 'EUR' },
    { optionText: 'Etiópia', value: 'ETH', currency: 'ETB' },
    { optionText: 'Fiji', value: 'FIJ', currency: 'FJD' },
    { optionText: 'Filipinas', value: 'PHL', currency: 'PHP' },
    { optionText: 'Finlândia', value: 'FIN', currency: 'EUR' },
    { optionText: 'França', value: 'FRA', currency: 'EUR' },
    { optionText: 'Gabão', value: 'GAB', currency: 'XAF' },
    { optionText: 'Gâmbia', value: 'GMB', currency: 'GMD' },
    { optionText: 'Gana', value: 'GHA', currency: 'GHS' },
    { optionText: 'Geórgia', value: 'GEO', currency: 'GEL' },
    { optionText: 'Granada', value: 'GRD', currency: 'XCD' },
    { optionText: 'Grécia', value: 'GRC', currency: 'EUR' },
    { optionText: 'Guatemala', value: 'GTM', currency: 'GTQ' },
    { optionText: 'Guiana', value: 'GUY', currency: 'GYD' },
    { optionText: 'Guiné', value: 'GIN', currency: 'GNF' },
    { optionText: 'Guiné Equatorial', value: 'GEQ', currency: 'XAF' },
    { optionText: 'Guiné-Bissau', value: 'GNB', currency: 'XOF' },
    { optionText: 'Haiti', value: 'HTI	', currency: 'HTG' },
    { optionText: 'Honduras', value: 'HND', currency: 'HNL' },
    { optionText: 'Hungria', value: 'HUN', currency: 'HUF' },
    { optionText: 'Iémen', value: 'YEM', currency: 'YER' },
    { optionText: 'Ilhas Marechal', value: 'MHL', currency: 'USD' },
    { optionText: 'Índia', value: 'IND', currency: 'INR' },
    { optionText: 'Indonésia', value: 'IDN', currency: 'IDR' },
    { optionText: 'Irão', value: 'IRN', currency: 'IRR' },
    { optionText: 'Iraque', value: 'IRQ', currency: 'IQD' },
    { optionText: 'Irlanda', value: 'IRL', currency: 'EUR' },
    { optionText: 'Islândia', value: 'ISL', currency: 'ISK' },
    { optionText: 'Israel', value: 'ISR', currency: 'ILS' },
    { optionText: 'Itália', value: 'ITA', currency: 'EUR' },
    { optionText: 'Jamaica', value: 'JAM', currency: 'JMD' },
    { optionText: 'Japão', value: 'JPN', currency: 'JPY' },
    { optionText: 'Jibuti', value: 'DJI', currency: 'DJF' },
    { optionText: 'Jordânia', value: 'JOR', currency: 'JOD' },
    { optionText: 'Laus', value: 'LAO', currency: 'LAK' },
    { optionText: 'Lesoto', value: 'LSO', currency: 'LSL' },
    { optionText: 'Letónia', value: 'LVA', currency: 'EUR' },
    { optionText: 'Líbano', value: 'LBN', currency: 'LBP' },
    { optionText: 'Libéria', value: 'LBR', currency: 'LRD' },
    { optionText: 'Líbia', value: 'LBY', currency: 'LYD' },
    { optionText: 'Listenstaine', value: 'LIE', currency: 'CHF' },
    { optionText: 'Lituânia', value: 'LTU', currency: 'EUR' },
    { optionText: 'Luxemburgo', value: 'LUX', currency: 'EUR' },
    { optionText: 'Macedónia do Norte', value: 'MKD', currency: 'MKD' },
    { optionText: 'Madagáscar', value: 'MDG', currency: 'MGA' },
    { optionText: 'Malásia', value: 'MYS', currency: 'MYR' },
    { optionText: 'Maláui', value: 'MWI', currency: 'MWK' },
    { optionText: 'Maldivas', value: 'MDV', currency: 'MVR' },
    { optionText: 'Mali', value: 'MLI', currency: 'XOF' },
    { optionText: 'Malta', value: 'MLT', currency: 'EUR' },
    { optionText: 'Marrocos', value: 'MAR', currency: 'MAD' },
    { optionText: 'Maurícia', value: 'MUS', currency: 'MUR' },
    { optionText: 'Mauritânia', value: 'MRT', currency: 'MRO' },
    { optionText: 'México', value: 'MEX', currency: 'MXN' },
    { optionText: 'Mianmar', value: 'MMR', currency: 'MMK' },
    { optionText: 'Micronésia', value: 'FSM', currency: 'USD' },
    { optionText: 'Moçambique', value: 'MOZ', currency: 'MZN' },
    { optionText: 'Moldávia', value: 'MDA', currency: 'MDL' },
    { optionText: 'Mónaco', value: 'MCO', currency: 'EUR' },
    { optionText: 'Mongólia', value: 'MNG', currency: 'MNT' },
    { optionText: 'Montenegro', value: 'MNE', currency: 'EUR' },
    { optionText: 'Namíbia', value: 'NAM', currency: 'NAD' },
    { optionText: 'Nauru', value: 'NRU', currency: 'AUD' },
    { optionText: 'Nepal', value: 'NPL', currency: 'NPR' },
    { optionText: 'Nicarágua', value: 'NIC', currency: 'NIO' },
    { optionText: 'Níger', value: 'NER', currency: 'XOF' },
    { optionText: 'Nigéria', value: 'NGA', currency: 'NGN' },
    { optionText: 'Noruega', value: 'NOR', currency: 'NOK' },
    { optionText: 'Nova Zelândia', value: 'NZL', currency: 'NZD' },
    { optionText: 'Omã', value: 'OMN', currency: 'OMR' },
    { optionText: 'Países Baixos', value: 'NLD', currency: 'EUR' },
    { optionText: 'Palau', value: 'PLW', currency: 'USD' },
    { optionText: 'Panamá', value: 'PAN', currency: 'PAB' },
    { optionText: 'Papua Nova Guiné', value: 'PNG', currency: 'PGK' },
    { optionText: 'Paquistão', value: 'PAK', currency: 'PKR' },
    { optionText: 'Paraguai', value: 'PRY', currency: 'PYG' },
    { optionText: 'Peru', value: 'PER', currency: 'PEN' },
    { optionText: 'Polónia', value: 'POL', currency: 'PLN' },
    { optionText: 'Portugal', value: 'PRT', currency: 'EUR' },
    { optionText: 'Quénia', value: 'KEN', currency: 'KES' },
    { optionText: 'Quirguistão', value: 'KGZ', currency: 'KGS' },
    { optionText: 'Quiribáti', value: 'KIR', currency: 'AUD' },
    { optionText: 'Reino Unido', value: 'GBR', currency: 'GBP' },
    { optionText: 'República Centro-Africana', value: 'CAF', currency: 'XAF' },
    { optionText: 'República Checa', value: 'CZE', currency: 'CZK' },
    { optionText: 'República Democrática do Congo', value: 'COD', currency: 'CDF' },
    { optionText: 'República Dominicana', value: 'DOM', currency: 'DOP' },
    { optionText: 'Roménia', value: 'ROU', currency: 'RON' },
    { optionText: 'Ruanda', value: 'RWA', currency: 'RWF' },
    { optionText: 'Rússia', value: 'RUS', currency: 'RUB' },
    { optionText: 'Salomão', value: 'SOL', currency: 'SBD' },
    { optionText: 'Salvador', value: 'SLV', currency: 'USD' },
    { optionText: 'Samoa', value: 'WSM', currency: 'WST' },
    { optionText: 'Santa Lúcia', value: 'LCA', currency: 'XCD' },
    { optionText: 'São Cristóvão e Neves', value: 'KNA', currency: 'XCD' },
    { optionText: 'São Marinho', value: 'SMR', currency: 'EUR' },
    { optionText: 'São Tomé e Príncipe', value: 'STP', currency: 'STD' },
    { optionText: 'São Vicente e Granadinas', value: 'VCT', currency: 'XCD' },
    { optionText: 'Seicheles', value: 'SYC', currency: 'SCR' },
    { optionText: 'Senegal', value: 'SEN', currency: 'XOF' },
    { optionText: 'Serra Leoa', value: 'SLE', currency: 'SLL' },
    { optionText: 'Sérvia', value: 'SRB', currency: 'RSD' },
    { optionText: 'Singapura', value: 'SGP', currency: 'SGD' },
    { optionText: 'Síria', value: 'SYR', currency: 'SYP' },
    { optionText: 'Somália', value: 'SOM', currency: 'SOS' },
    { optionText: 'Sri Lanca', value: 'SRI', currency: 'LKR' },
    { optionText: 'Sudão', value: 'SDN', currency: 'SDG' },
    { optionText: 'Sudão do Sul', value: 'SSD', currency: 'SSP' },
    { optionText: 'Suécia', value: 'SWE', currency: 'SEK' },
    { optionText: 'Suíça', value: 'CHE', currency: 'CHF' },
    { optionText: 'Suriname', value: 'SUR', currency: 'SRD' },
    { optionText: 'Tailândia', value: 'THA', currency: 'THB' },
    { optionText: 'Taiuã', value: 'TWN', currency: 'TWD' },
    { optionText: 'Tajiquistão', value: 'TJK', currency: 'TJS' },
    { optionText: 'Tanzânia', value: 'TZA', currency: 'TZS' },
    { optionText: 'Timor-Leste', value: 'TLS', currency: 'USD' },
    { optionText: 'Togo', value: 'TGO', currency: 'XOF' },
    { optionText: 'Tonga', value: 'TON', currency: 'TOP' },
    { optionText: 'Trindade e Tobago', value: 'TTO', currency: 'TTD' },
    { optionText: 'Tunísia', value: 'TUN', currency: 'TND' },
    { optionText: 'Turcomenistão', value: 'TKM', currency: 'TMT' },
    { optionText: 'Turquia', value: 'TUR', currency: 'TRY' },
    { optionText: 'Tuvalu', value: 'TUV', currency: 'AUD' },
    { optionText: 'Ucrânia', value: 'UKR', currency: 'UAH' },
    { optionText: 'Uganda', value: 'UGA', currency: 'UGX' },
    { optionText: 'Uruguai', value: 'URY', currency: 'UYU' },
    { optionText: 'Usbequistão', value: 'UZB', currency: 'UZS' },
    { optionText: 'Vanuatu', value: 'VUT', currency: 'VUV' },
    { optionText: 'Vaticano', value: 'VAT', currency: 'EUR' },
    { optionText: 'Venezuela', value: 'VEN', currency: 'VEF' },
    { optionText: 'Vietname', value: 'VNM', currency: 'VND' },
    { optionText: 'Zâmbia', value: 'ZMB', currency: 'ZMW' },
    { optionText: 'Zimbábue', value: 'ZWE', currency: 'USD' },
    { optionText: 'Porto Rico', value: 'PRI', currency: 'USD' }
];

export const orderStatus = [
    {
        value: i18n.t("orders.status.processing.value"),
        optionText: i18n.t("orders.status.processing.optionText")
    },
    {
        value: i18n.t("orders.status.development.value"),
        optionText: i18n.t("orders.status.development.optionText")
    },
    {
        value: i18n.t("orders.status.review.value"),
        optionText: i18n.t("orders.status.review.optionText")
    },
    {
        value: i18n.t("orders.status.cancelled.value"),
        optionText: i18n.t("orders.status.cancelled.optionText")
    },
    {
        value: i18n.t("orders.status.completed.value"),
        optionText: i18n.t("orders.status.completed.optionText")
    }
];