import { useMemo } from "react";

import { CardFlags, PaymentType } from "../../dtos";

import VisaSvg from "../../../../../../assets/icons/visa.svg";
import MaterCardSvg from "../../../../../../assets/icons/mastercard.svg";
import AmexSvg from "../../../../../../assets/icons/american-express.svg";
import DinersClubSvg from "../../../../../../assets/icons/diners-club-international.svg";
import DiscoverSvg from "../../../../../../assets/icons/discover.svg";
import JCBSvg from "../../../../../../assets/icons/jcb.svg";

import { PreferredMethodCardContainer, Content } from "./styles";
import { RiPencilFill } from "react-icons/ri";
import { BsTrashFill } from "react-icons/bs";

interface PreferredMethodCardProps {
  card_holder?: string;
  preferred_method: PaymentType;
  card_last_four_digits?: string;
  card_flag?: CardFlags;
  onDeleteRequest: () => void
}

export function PreferredMethodCard({
  preferred_method,
  card_flag,
  card_last_four_digits,
  onDeleteRequest,
}: PreferredMethodCardProps) {
  const cardFlagsSvgs = useMemo(() => {
    return {
      Visa: <img src={VisaSvg} alt="Visa" />,
      Mastercard: <img src={MaterCardSvg} alt="Mastercard" />,
      Amex: <img src={AmexSvg} alt="Amex" />,
      DinersClub: <img src={DinersClubSvg} alt="DinersClub" />,
      Discover: <img src={DiscoverSvg} alt="Discover" />,
      JCB: <img src={JCBSvg} alt="JBC" />,
    };
  }, []);

  function getPaymentMethodInfos() {
    switch (preferred_method) {
      case "credit": {
        return {
          text: "Cartão de crédito",
          icon: card_flag && cardFlagsSvgs[card_flag],
        };
      }

      case "debit": {
        return {
          text: "Cartão de débito",
          icon: card_flag && cardFlagsSvgs[card_flag],
        };
      }
    }
  }

  return (
    <PreferredMethodCardContainer>
      <Content>
        <div className="preferred_method">
          Método preferido:{" "}
          <span className="method">{getPaymentMethodInfos().text}</span>
        </div>

        {["credit", "debit"].includes(preferred_method) && (
          <div className="card_infos">
            <span className="card_holder">{localStorage.getItem(`${card_last_four_digits}`) && JSON.parse(localStorage.getItem(`${card_last_four_digits}`) ?? "").card_holder}</span>
            <span className="hidden_digits">****</span>
            <span className="four_digits">{card_last_four_digits}</span>
            {card_flag && cardFlagsSvgs[card_flag]}
          </div>
        )}

        <div className="edit">
          <BsTrashFill onClick={onDeleteRequest}/>
        </div>
      </Content>
    </PreferredMethodCardContainer>
  );
}
