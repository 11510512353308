import styled from 'styled-components';

const mobileWidth = 720;

export const Flex = styled.div`
  padding: 40px 0 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 40px;

  @media(max-width: ${mobileWidth}px) {
    flex-direction: column;
    align-items: center;
    margin-left: 0px;
  }
`

export const PageTile = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 23px;
  color: var(--fixit);
  margin-left: 15px;

  @media (max-width: 900px) {
    font-size: 26px;
  }
  
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;
