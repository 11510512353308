import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { FiTrash } from 'react-icons/fi';
import { SolidButton } from "../../../../components/SolidButton";
import api from "../../../../services/api";
import { showToast } from "../../../../components/CustomToast";
import { AxiosError } from "axios";
import * as Yup from "yup";

import {
  Container,
  Title,
  InputContainer,
  FormContainer,
  Footer,
  SolutionsOrderContainer,
  SolutionsOrder,
  Text,
  SecondText,
  DragItem,
  NewMeasure,
  Content,
  Icon,
} from "./styles";
import { Modal } from "../../../../components/Modal";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { ISolution, ISolutionStandard } from "../../pages/StandardSolutionsList/dtos";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { size } from "lodash";
import validateFormFields from "../../../../utils/validateFormFields";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import { i18n } from "../../../../localization/i18n";

interface StandardConfigModalProps {
  solutionId: string;
  solution?: ISolution;
  open: boolean;
  onSave: () => void;
  closeModal: () => void;
  reloadFiles: () => Promise<void>;
}

export function StandardConfigModal({ open, solutionId, solution, reloadFiles, onSave, closeModal }: StandardConfigModalProps) {
  const [initialState, setInitialState] = useState<ISolution>();
  const [disabled, setDisabled] = useState(true);
  const [newPosition, setNewPosition] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const [newMeasure, setNewMeasure] = useState<ISolutionStandard>({
    id: 'newMeasure',
    order: "",
    size: "",
  });

  let items: any = [];

  useEffect(() => {
    if(solution) {
      setInitialState(solution);
    }
  }, [solution]);

  useEffect(() => {
    if(newMeasure.size !== ''){
      setDisabled(false);
    };
  }, [newMeasure]);

  useEffect(() => {
    if(solution) {
      setNewMeasure({
        ...newMeasure,
        order: String(solution.standards.length + 1),
      })
    }
  }, [solution])

  const clearState = () => {
    setNewMeasure({
      id: 'newMeasure',
      order: "",
      size: "",
    });
    setErrors({});
    setDisabled(true);
  }

  const onDragEnd = (result: any) => {
    if(!result.destination) return;
    if(initialState) {
      items = Array.from(initialState.standards);
    }
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    initialState && setInitialState({
      ...initialState,
      standards: items,
    })
  };

  function addNewMeasure() {
    let newArray: any = []
    
    if(initialState && newMeasure) {
      setNewMeasure({
        ...newMeasure,
        order: (String(initialState?.standards.length + 1))
      })

      newArray = Array.from(initialState.standards);
      newArray.push(newMeasure);
      
      initialState && setInitialState({
        ...initialState,
        standards: newArray,
      })
    }
  }

  useEffect(() => {
    if (initialState && newPosition === true) {
      initialState.standards.find((measure, index) => {
        if(measure.size === newMeasure.size) {
          setNewMeasure({
            ...newMeasure,
            order: String(index + 1),
          })
        }
      })

    }
  }, [initialState])

  function getNewMeasurePosition() {
    setNewPosition(true);
  }

  const submitForm = function (order: string, size: string) {

    const body = {
      order: order,
      size: size,
    }

    api.put(`/solutions/${solutionId}/standard`, {
      standard: body,
    }).then(() => {
      showToast({ type: "success", message: "Tamanho adicionado com sucesso!" });
      onSave();
      closeModal();
      clearState();
      setNewPosition(false);
    }).catch((error: AxiosError) => {
      showToast({
        type: "error",
        message: `Erro ao criar a solução! Detalhes: ${error.message}`,
      });
    });
  }

  const validateInput = async () => {
    const yup_validation = Yup.object().shape({
      size: Yup.string().required(typesErrors.required),
    });

    const newMeasureSize = {
      size: newMeasure.size.replace(/\s/g,""),
    }

    const validation = await validateFormFields(newMeasureSize, yup_validation, {
      size: "",
    });
  
    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });
    } else {
      addNewMeasure();
      getNewMeasurePosition();
    }
  }
  

  async function deleteMeasure(id: string) {
    await api.delete(`/solutionStandards/${id}`).then(() => {
      showToast({
        type: 'success',
        message: 'Medida excluída com sucesso!'
      })
    }).catch((err) => {
      showToast({
        type: 'error',
        message: 'Não foi possível excluir a medida!'
      })
    }).finally(() => {
      clearState();
    })
  }

  return (
    <Modal
      open={open}
      onRequestClose={() => {
        closeModal();
        setNewPosition(false);
        clearState();
      }}
    >
      <Container>
        <Title>Configurar Nova Medida</Title>

        <FormContainer>
          <NewMeasure>
            <InputContainer>
              <OutlinedInput
                inputName={'size'}
                label={'Nova Medida'}
                value={newMeasure.size}
                handleChange={(event) => {
                  setNewMeasure((prevState) => ({
                    ...prevState,
                    size: event,
                  }));
                }}
                error={errors.size}
              />
            </InputContainer>
            <SolidButton
              disabled={disabled} 
              buttonHeight={20}
              buttonWidth={70}
              text='Adicionar'
              onClick={ async () => {
                await validateInput();
                
              }}
            />
          </NewMeasure>
        
          {
          solution?.standards ? 
          <>
            <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={solution.id}>
              {(provided) => (
                <SolutionsOrderContainer {...provided.droppableProps} ref={provided.innerRef}>
                  <SecondText>Ao preencher a nova medida e adicionar, basta arrastar para a ordem que deseja e clicar em salvar.</SecondText>

                  {initialState && initialState.standards.map((standard, index) => {
                        return (
                          <Draggable key={standard.id} draggableId={standard.id} index={index}>
                            {(provided) => (
                              <DragItem id={standard.id} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                <Content>{`${index + 1} - ${standard.size}`}</Content>
                                <Icon 
                                  onClick={ async () => {
                                    await deleteMeasure(standard.id);
                                    closeModal();
                                    reloadFiles();
                                  }}
                                >
                                  <FiTrash />
                                </Icon>
                              </DragItem>
                            )}
                          </Draggable>
                        );
              })}
               {provided.placeholder}
                </SolutionsOrderContainer>
              )}
            </Droppable>
            </DragDropContext>
            </>
          : null
        }
        </FormContainer>

        <Footer>
          <OutlinedButton 
            buttonWidth={220}
            text="Confirmar e Salvar" 
            onClick={async () => {
              submitForm(newMeasure.order, newMeasure.size);
              await reloadFiles();
            }}/>
        </Footer>
      </Container>
    </Modal>
  );
}
