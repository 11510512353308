import { useEffect, useState } from "react";

import { i18n } from "../../../../../../localization/i18n";

import { ClickAwayListener } from "@mui/material";

import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../../../components/OutlinedSelect";
import { CheckboxNullable } from "../../../../../../components/CheckboxNullable";
import { SolidButton } from "../../../../../../components/SolidButton";

import { nullOption } from "./mocks";

import { RiFilter3Fill } from "react-icons/ri";

import {
  Container,
  FilterContent,
  InputContainer,
  InputLine,
  Footer,
  CheckboxContainer,
} from "./styles";
import { countries } from "../../../../../../localization/data/mocks";
import { IFilterData, IOption } from "../../../../dtos";
import { licenseesContractTypes } from "../../../../../../utils/constants";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { ILicensee, ISearchableLicensee } from "../../../../../Orders/dtos";
import api, { isAxiosCancel } from "../../../../../../services/api";
import { showToast } from "../../../../../../components/CustomToast";
import { Checkbox } from "../../../../../../components/Checkbox";
interface FilterProps {
  onFilterApply: (filterData: IFilterData) => void;
  filter?: IFilterData;
}

export function Filter({ filter, onFilterApply }: FilterProps) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [licenseesStatus, setLicenseesStatus] = useState<boolean | undefined>();
  const [licenseeContractType, setLicenseeContractType] = useState<IOption>();
  const [licenseeSituation, setLicenseeSituation] = useState<IOption>();
  const [selectedCountry, setSelectedCountry] = useState<IOption>();
  const [licensees, setLicensees] = useState<ISearchableLicensee[]>([]);
  const [licenseeSearchValue, setLicenseeSearchValue] = useState("");
  const [selectLoading, setSelectedLoading] = useState(false);
  const [selectedLicensee, setSelectedLicensee] = useState<ILicensee>();
  const [includeFixiters, setIncludeFixiters] = useState<boolean | undefined>(
    false
  );

  const situationOptions = [
    {
      value: "operational",
      optionText: "Em operação",
    },
    {
      value: "training",
      optionText: "Em treinamento",
    },
    {
      value: "blocked",
      optionText: "Bloqueado",
    },
  ];

  async function searchLicensees(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      const { data } = await api.get(
        `/licensees/search?term=${licenseeSearchValue}`,
        { signal }
      );
      setLicensees(
        data.map((p: any) => ({
          id: p.id,
          label: p.name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os licenciados.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  function applyFilterData() {
    const filterData: IFilterData = {
      licensee: selectedLicensee,
      licensee_status: licenseesStatus,
      country: selectedCountry,
      contract_type: licenseeContractType,
      situation: licenseeSituation,
      include_fixiters: includeFixiters,
    };

    setFilterOpen(false);
    onFilterApply(filterData);
  }

  useEffect(() => {
    setSelectedLicensee(filter?.licensee);
    setSelectedCountry(filter?.country);
    setLicenseesStatus(filter?.licensee_status);
    setLicenseeContractType(filter?.contract_type);
    setLicenseeSituation(filter?.situation);
    setIncludeFixiters(filter?.include_fixiters);
  }, [filter]);

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (licenseeSearchValue !== "") {
      searchLicensees(signal);
    }

    return () => abortController.abort();
  }, [licenseeSearchValue]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        setFilterOpen(false);
      }}
    >
      <Container>
        <RiFilter3Fill
          size={34}
          color={filterOpen ? "var(--fixit)" : "var(--gray)"}
          onClick={() => setFilterOpen((prevState) => !prevState)}
        />

        {filterOpen && (
          <FilterContent>
            <div className="filter_title">
              Filtrar licenciados
              <CheckboxContainer checked={includeFixiters}>
                <Checkbox
                  checked={Boolean(includeFixiters)}
                  handleChange={() => setIncludeFixiters(!includeFixiters)}
                />
                <span>Incluir Fixiters</span>
              </CheckboxContainer>
            </div>

            <InputLine>
              <InputContainer>
                <SearchableSelect
                  label={`${i18n.t("profile.unit")}`}
                  options={licensees}
                  defaultValue={licenseeSearchValue}
                  loading={selectLoading}
                  onSelect={(selected) => {
                    if (typeof selected === "string") {
                      setSelectedLicensee(undefined);
                      return;
                    }

                    setSelectedLicensee(
                      licensees?.find((s) => s.model.id === selected.id)?.model
                    );
                  }}
                  onChange={(value) => {
                    setLicenseeSearchValue(value);
                  }}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedSelect
                  label={`${i18n.t("financial.prices.field.country")}`}
                  options={[nullOption, ...countries]}
                  handleSelect={(selected) => {
                    if (selected.value === "") {
                      setSelectedCountry(undefined);
                      return;
                    }
                    setSelectedCountry(selected);
                  }}
                  selectedOption={selectedCountry?.optionText ?? ""}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedSelect
                  label={"Tipo de contrato"}
                  options={[
                    {
                      optionText: "Anual",
                      value: licenseesContractTypes.annual,
                    },
                    {
                      optionText: "Mensal",
                      value: licenseesContractTypes.monthly,
                    },
                  ]}
                  handleSelect={(selected) => {
                    if (selected.value === "") {
                      setLicenseeContractType(undefined);
                      return;
                    }
                    setLicenseeContractType(selected);
                  }}
                  selectedOption={licenseeContractType?.optionText ?? ""}
                />
              </InputContainer>
              <InputContainer>
                <OutlinedSelect
                  label={"Situação"}
                  options={situationOptions}
                  handleSelect={(selected) => {
                    if (selected.value === "") {
                      setLicenseeSituation(undefined);
                      return;
                    }
                    setLicenseeSituation(selected);
                  }}
                  selectedOption={licenseeSituation?.optionText ?? ""}
                />
              </InputContainer>
            </InputLine>

            <Footer>
              <CheckboxContainer checked={licenseesStatus}>
                <CheckboxNullable
                  checked={licenseesStatus}
                  handleChange={() => {
                    if (licenseesStatus) setLicenseesStatus(false);
                    else if (licenseesStatus === false)
                      setLicenseesStatus(undefined);
                    else setLicenseesStatus(true);
                  }}
                />
                <span>{`${i18n.t("global.status.label")}`}</span>
              </CheckboxContainer>

              <SolidButton text="Aplicar" onClick={applyFilterData} />
            </Footer>
          </FilterContent>
        )}
      </Container>
    </ClickAwayListener>
  );
}
