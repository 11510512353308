
import { i18n } from "../../../../../../localization/i18n";
import { IPatientData } from "../../../../../../modules/Orders/CreateOrder/dtos";
import { CenterContent, Container, ContentInfos, Header, ImageContainer, LeftContent, MobileContent, PatientsInfos } from "./styles";


interface PatientItemProps {
  onClick?: () => void;
  patient: IPatientData;
}

export function PatientItem({
  patient,
  onClick = () => {},
}: PatientItemProps) {

  return (
    <>
    <Container
      onClick={() => {
        onClick();
      } }
    >
      <Header>
        <LeftContent>
          <ImageContainer>
            <span>{patient.name?.charAt(0)}</span>
          </ImageContainer>
          <PatientsInfos>
            <span className="patient_name">{`${i18n?.t("evaluation.name")} (${i18n.t("evaluation.age")})`}</span>
            <span className="patient_location">
              {patient.name} ({patient.age})
            </span>
          </PatientsInfos>
        </LeftContent>

        <CenterContent>
          <ContentInfos className="email">
            <span className="data_label">
              {`${i18n?.t("login.email")}`}
            </span>
            <span className="data_value">{patient.email}</span>
          </ContentInfos>
          <ContentInfos className="responsible">
            <span className="data_label">
              {`${i18n?.t("customSolution.responsibleName")}`}
            </span>
            <span className="data_value">
              {patient.responsible}
            </span>
          </ContentInfos>
          <ContentInfos className="phone">
            <span className="data_label">
              {`${i18n?.t("customSolution.contact")}`}
            </span>
            <span className="data_value">{patient.phone}</span>
          </ContentInfos>
        </CenterContent>
      </Header>
      <MobileContent>
        <LeftContent>
          <ContentInfos>
            <span className="data_value">{patient.responsible}</span>
          </ContentInfos>
          <ContentInfos>
            <span className="data_value">{patient.phone}</span>
          </ContentInfos>
        </LeftContent>
      </MobileContent>
    </Container></>
  );
}
