import styled from "styled-components";


export const PinText = styled.p`
    background: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 10px;
    font-size: 1.3em;
    color: var(--fixit);
    display: none;
    position: absolute;
    top: -20px; 
    left: -10px;
`;

export const Pin = styled.div`
    display: flex;
    align-items: center;
    color: var(--fixit);
    position: relative;

    :hover {
        cursor: pointer;
    }

    &:hover ${PinText} {
        width: 180px;
        display: block;
    }
`;

export const PinIcon = styled.div`
    font-size: 1.5rem !important;
`;