import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
`