import styled, { css } from "styled-components";

interface ContainerProps {
  open: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  transition: visibility 225ms;
  visibility: hidden;
  opacity: 0;
  font-size: 14px;
  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  .modal_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 300px;
    width: 450px;
    max-height: calc(100% - 64px);
    color: #575757;
    background: #ffffff;
    border-radius: 20px;
    position: relative;
    overflow-y: auto;
  }

  @media (max-width: 650px) {
    .modal_wrapper {
      min-width: 350px;
      width: 350px;
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;
`;

export const Header = styled.div`
  .logo_container {
    width: 100%;
    height: 150px;
    background: var(--white);

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 150px;
    }
  }

  .image_container {
    position: relative;
    width: 100%;
    height: 150px;
    background: var(--white);

    .effect {
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        #4f1e6b 0%,
        rgba(152, 53, 209, 0) 41.15%
      );
    }

    img {
      width: 100%;
      height: 150px;
      object-fit: cover;
    }
  }

  .close_modal {
    svg {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 10;
      cursor: pointer;
    }
  }
`;

export const SolutionDescription = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;

  .solution_name {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }

  .solution_category {
    margin-top: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }

  .solution_price {
    display: flex;
    gap: 5px;
    margin-top: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }

  .description {
    margin-top: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: #000000;
  }

  .solution_description {
    margin-top: 12px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
`;

export const Footer = styled.div`
  width: 100%;
  padding: 10px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .footer_item {
    margin-top: 10px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: var(--fixit);
    cursor: pointer;
  }
`;
