/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthContext";
import { useOnboard } from "../hooks/OnboardContext";
import Logon from "../modules/Logon";
import { roles } from "../utils/constants";

interface IAuthGuard {
  children: JSX.Element;
  redirectTo?: string;
}

export default function AuthGuard({ children, redirectTo }: IAuthGuard) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { licensee, checkBlocked } = useOnboard();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );
  const [block, setBlock] = useState(false);

  useEffect(() => {
    if (licensee && pathname) {
      const accessCheck = checkBlocked(pathname);
      setBlock(!accessCheck.hasAccess);
    }
  }, [licensee, pathname]);

  useEffect(() => {
    if (user && pathname === "/") {
      if (user.role === roles.licensee) {
        navigate("/dashboard");
      } else if (user.role === roles.creator) {
        navigate("/creators/dashboard");
      } else navigate(`/${user.role.toLocaleLowerCase()}/dashboard`);
    }
  }, [navigate, pathname, user]);

  if (pathname !== "/" && !user) {
    return null;
  }

  if (!user) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Logon />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  if (block && redirectTo) {
    return <Navigate to={redirectTo} />;
  }

  return <>{children}</>;
}
