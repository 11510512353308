import styled from 'styled-components'

interface IInputProps {
  value: string
}

export const Input = styled.input<IInputProps>`
  margin-right: 4px;
  text-align: center;
  color: var(--dark-gray);
  background: var(--light-gray);
  font-size: 0.9rem;
  font-weight: bold;
  width: ${props => props.value.length + 2}ch;
  border-radius: 6px;

  :disabled {
    background: transparent;
    font-weight: normal;
    text-align: right;
  }
`
