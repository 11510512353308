import { addressTypes } from "../../utils/constants";
import { AiOutlineHome } from 'react-icons/ai';
import { MdWorkOutline } from 'react-icons/md';
import { FaRegAddressCard } from 'react-icons/fa';


export const AddressTypes = [
  { optionText: "Casa", 
    value: addressTypes.home, 
    component: (
      <AiOutlineHome size={16} />
    )
  },
  { optionText: "Trabalho", 
    value: addressTypes.work, 
    component: (
      <MdWorkOutline size={16}/>
    )
  },
  { optionText: "Outro", 
    value: addressTypes.other, 
    component: (
      <FaRegAddressCard size={16}/>
    )
  },
];
