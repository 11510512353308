import { format } from "date-fns";
import { BiDollar } from "react-icons/bi";
import Popover from "../../../../../../components/Popover";
import { ITransaction } from "../../dtos";
import {
  Header,
  LeftContent,
  ImageContainer,
  PrincipalInfos,
  RightContent,
  PriceInfos,
  Container,
} from "./styles";

interface TransactionProps {
  transaction: ITransaction;
}

export function TransactionItem({ transaction }: TransactionProps) {
  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' 'HH:mm");
  }

  return (
    <Container>
      <Header>
        <LeftContent>
          <ImageContainer className={`${transaction.type}`}>
            <Popover position="right" label={"Transaction"}>
              <BiDollar color="white" size={20} />
            </Popover>
          </ImageContainer>
          <PrincipalInfos className={`${transaction.type}`}>
            <span className="solution">{transaction.description}</span>
            <span className="date">
              {formatDate(String(transaction.transaction_date))}
            </span>
          </PrincipalInfos>
        </LeftContent>

        <RightContent>
          <PriceInfos type={`${transaction.type}`}>
            <span className="price_name">{`Total`}</span>
            <span className="price_location">
              {transaction.type === "outcome" ? "- " : "+ "}
              {parseFloat(transaction.ammount.toString()).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )}
            </span>
          </PriceInfos>
        </RightContent>
      </Header>
    </Container>
  );
}
