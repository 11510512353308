import { useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/material";

import {
  Container,
  LeftContent,
  ImageContainer,
  RightContent,
  Header,
  MoreOptionsContainer,
  CenterContent,
  ContentInfos,
  MobileContent,
} from "./styles";
import { i18n } from "../../../../localization/i18n";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { AiOutlineFileAdd, AiOutlineFileDone } from "react-icons/ai";
import { LicenseeInfos } from "../../../Licensee/pages/LicenseesList/components/LicenseeItem/styles";
import UploadStandardFilesModal from "../../../../components/UploadStandardFilesModal";
import { IStandardFile, SolutionItemProps } from "./dtos";

export function SolutionsItem({
  standard,
  part,
  side,
  printer,
  solution,
  updateStandard,
  reloadFiles = async () => { },
  getStandardFiles,
}: SolutionItemProps) {
  const [editFile, setEditFile] = useState<IStandardFile>();
  const [hasEditFile, setHasEditFile] = useState(false);
  const [iconState, setIconState] = useState(false);
  const [correctLink, setCorrectLink] = useState(false);
  const [open, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!open);
  };

  useEffect(() => {
    if (iconState === true) {
      setHasEditFile(true);
    }
  }, [iconState]);

  useEffect(() => {
    setIconStatus();
  }, [printer]);

  async function setIconStatus() {
    await getStandardFiles(standard.id, printer.value, side, part).then(
      (response) => {
        if (response !== undefined) {
          setIconState(true);
          if (response.link_gcode?.indexOf("standards") !== -1) {
            setCorrectLink(true);
          }
        } else {
          setIconState(false);
        }
      }
    );
  }

  async function deleteStandardFile(id: string) {
    try {
      await api
        .delete(`/standard-files/standard/${id}`)
        .then(() => {
          showToast({
            type: "success",
            message: "Arquivo excluído com sucesso!",
          });
        })
        .then(async () => {
          await setIconStatus();
          reloadFiles();
        });
    } catch {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar excluir o arquivo",
      });
    }
  }

  async function getFileComponent(
    standard_id: string,
    printer_id: string,
    side: string,
    part: string
  ) {
    const response = await getStandardFiles(
      standard_id,
      printer_id,
      side,
      part
    );
    setEditFile(response);
  }

  return (
    <>
      <UploadStandardFilesModal
        editFile={editFile}
        file_id={editFile?.id}
        hasEditFile={hasEditFile}
        setHasEditFile={setHasEditFile}
        deleteStandardFile={deleteStandardFile}
        open={open}
        solution_name={solution.name}
        close={toggleModal}
        printer={printer}
        standard_id={standard.id}
        standard_size={standard.size}
        part={part}
        side={side}
        reloadFiles={reloadFiles}
        setIconStatus={setIconStatus}
      />
      <Container
        onClick={() => {
          //  onClick();
        }}
      >
        <Header>
          <LeftContent>
            <ImageContainer hasFile={iconState} correctLink={correctLink}>
              <span>{solution.name?.charAt(0)}</span>
            </ImageContainer>
            <LicenseeInfos>
              <span className="licensee_name">{solution?.name}</span>
              <span className="licensee_location">{solution.category}</span>
            </LicenseeInfos>
          </LeftContent>

          <CenterContent>
            <ContentInfos className="document">
              <span className="data_label">
                {`${i18n?.t("creators.side")}`}
              </span>
              <span className="data_value">{side}</span>
            </ContentInfos>

            <ContentInfos className="phone">
              <span className="data_label">
                {`${i18n?.t("creators.size")}`}
              </span>
              <span className="data_value">{standard.size}</span>
            </ContentInfos>

            <ContentInfos className="manager">
              <span className="data_label">
                {`${i18n?.t("creators.part")}`}
              </span>
              <span className="data_value">{part}</span>
            </ContentInfos>
          </CenterContent>

          <RightContent>
            <ClickAwayListener
              onClickAway={(event) => {
                event.stopPropagation();
              }}
            >
              <MoreOptionsContainer>
                {iconState ? (
                  <AiOutlineFileDone
                    size={20}
                    color={"var(--gray)"}
                    onClick={(event) => {
                      event.stopPropagation();
                      getFileComponent(standard.id, printer.value, side, part);
                      toggleModal();
                    }}
                  />
                ) : (
                  <AiOutlineFileAdd
                    size={20}
                    color={"var(--active-orange)"}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggleModal();
                    }}
                  />
                )}
              </MoreOptionsContainer>
            </ClickAwayListener>
          </RightContent>
        </Header>
        <MobileContent>
          <LeftContent>
            <ContentInfos>
              <span className="data_value">{solution.quantity}</span>
            </ContentInfos>
            <ContentInfos>
              <span className="data_value">{solution.sides}</span>
            </ContentInfos>
          </LeftContent>
        </MobileContent>
      </Container>
    </>
  );
}
