import { useState } from "react";
import { i18n } from "../../../localization/i18n";

import * as Yup from "yup";

import { SolidButton } from "../../../components/SolidButton";
import { OutlinedInput } from "../../../components/OutlinedInput";

import logoImg from "../../../assets/logo.svg";

import {
  Form,
  FormContent,
  Footer,
  InputContainer,
} from "../styles";

interface IChangePassworProps {
  validateNewPasswordStep: (event: any) => void;
  newPassword: string;
  setNewPassword: (event: any) => void;
  confirmNewPassword: string
  setConfirmNewPassword: (event: any) => void;
}

export default function ChangePassword({confirmNewPassword, newPassword, setNewPassword, setConfirmNewPassword, validateNewPasswordStep}: IChangePassworProps) {
  const [errors, setErrors] = useState({} as any);

  return (
    <Form onSubmit={validateNewPasswordStep}>
      <img className="logo" src={logoImg} alt="FixIt logo" />

      <h1>{`${i18n.t('login.new_password')}`}</h1>
      <FormContent>
        <InputContainer errorMargin={!!errors.email}>
          <OutlinedInput
            label={`${i18n.t('login.new_password')}`}
            inputName="new-password"
            type="password"
            value={newPassword}
            defaultValue={""}
            handleChange={(event: any) => {
              setNewPassword(event);
              setErrors((prevState: any) => ({
                ...prevState,
                newPassword: "",
              }));
            }}
            error={errors.newPassword}
            marginOnError={false}
          />
        </InputContainer>

        <InputContainer errorMargin={!!errors.email}>
          <OutlinedInput
            label={`${i18n.t('login.new_password_confirmation')}`}
            inputName="new-password-confirmation"
            type="password"
            defaultValue={""}
            value={confirmNewPassword}
            handleChange={(event: any) => {
              setConfirmNewPassword(event);  
              setErrors((prevState: any) => ({
                ...prevState,
                confirmNewPassword: "",
              }));
            }}
            error={errors.confirmNewPassword}
            marginOnError={false}
          />
        </InputContainer>

        <Footer>
          <SolidButton type="submit" text={`${i18n.t('login.change_password')}`} />
        </Footer>
      
      </FormContent>
    </Form>
  );
}
