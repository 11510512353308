import { BiBookOpen } from "react-icons/bi";
import { FaLock } from "react-icons/fa";
import {
  RiDownloadCloud2Line,
  RiLineChartLine,
  RiMenuLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { useOnboard } from "../../../hooks/OnboardContext";
import { i18n } from "../../../localization/i18n";
import { customerSituations } from "../../../utils/constants";
import { LinkItem, LinkTitle, RouteButton } from "../styles";

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FixiterMenuList = ({ toggleMenu }: MenuListProps) => {
  const { licensee, planHasExpired } = useOnboard();

  return (
    <>
      <LinkItem>
        <Link
          to={
            licensee?.completed_onboard &&
            licensee?.situation !== customerSituations.onboarding &&
            !planHasExpired
              ? "/fixiter/dashboard"
              : "/fixiter/dashboard"
          }
          onClick={(e) => licensee?.completed_onboard && toggleMenu(e)}
        >
          <RouteButton disabled={!licensee?.completed_onboard}>
            <FaLock
              className="is_over"
              size={16}
              display={licensee?.completed_onboard ? "none" : ""}
            />
            <RiLineChartLine size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.reports")}`}</LinkTitle>
        </Link>
      </LinkItem>

      <LinkItem>
        <Link
          to={
            licensee?.completed_onboard &&
            licensee?.situation !== customerSituations.onboarding &&
            !planHasExpired
              ? "/fixiter/solutions"
              : "/fixiter/dashboard"
          }
          onClick={(e) => licensee?.completed_onboard && toggleMenu(e)}
        >
          {" "}
          {/* Go to Solutions */}
          <RouteButton disabled={!licensee?.completed_onboard}>
            <FaLock
              className="is_over"
              size={16}
              display={licensee?.completed_onboard ? "none" : ""}
            />
            <RiDownloadCloud2Line size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.solutions")}`}</LinkTitle>
        </Link>
      </LinkItem>

      <LinkItem>
        <Link
          to={
            licensee?.completed_onboard &&
            licensee?.situation !== customerSituations.onboarding &&
            !planHasExpired
              ? "/fixiter/orders"
              : "/fixiter/dashboard"
          }
          onClick={(e) => licensee?.completed_onboard && toggleMenu(e)}
        >
          {" "}
          {/* Go to Orders */}
          <RouteButton disabled={!licensee?.completed_onboard}>
            <FaLock
              className="is_over"
              size={16}
              display={licensee?.completed_onboard ? "none" : ""}
            />
            <RiMenuLine size={26} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.requests")}`}</LinkTitle>
        </Link>
      </LinkItem>

      <LinkItem>
        <Link
          to={
            licensee?.completed_onboard &&
            licensee?.situation !== customerSituations.onboarding &&
            !planHasExpired
              ? "/fixiter/courses"
              : "/fixiter/dashboard"
          }
          onClick={(e) => licensee?.completed_onboard && toggleMenu(e)}
        >
          {" "}
          <RouteButton disabled={!licensee?.completed_onboard}>
            {" "}
            <FaLock
              className="is_over"
              size={16}
              display={licensee?.completed_onboard ? "none" : ""}
            />
            <BiBookOpen size={26} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("sidebar.courses")}`}</LinkTitle>
        </Link>
      </LinkItem>
    </>
  );
};
