import { RiCheckFill, RiSubtractLine } from "react-icons/ri";

import { Container } from "./styles";

interface ICheckboxProps {
  checked?: boolean;
  handleChange: (event: any) => any;
  backgroundCheckedColor?: string;
  backgroundNullableColor?: string;
  svgCheckColor?: string;
}

export function CheckboxNullable({
  checked,
  handleChange,
  backgroundCheckedColor = "var(--fixit)",
  backgroundNullableColor = "var(--gray)",
  svgCheckColor = "var(--white)",
}: ICheckboxProps) {
  return (
    <Container
      onClick={handleChange}
      checked={checked}
      backgroundCheckedColor={backgroundCheckedColor}
      backgroundNullableColor={backgroundNullableColor}
    >
      {checked === undefined && <RiSubtractLine size={16} color={svgCheckColor} />}
      {checked && <RiCheckFill size={16} color={svgCheckColor} />}
    </Container>
  );
}
