const TOKEN = '.';
const SEPARATOR = ' ';

export const abridgedControl = (fullName: string, limit: number) => {
    if (fullName.length > limit) {
        fullName = removePrepositions(fullName).trim()
    }

    var nameArr = fullName.split(SEPARATOR)
    var abrevIndex : number = nameArr.length - 1;

    while (fullName.length > limit && abrevIndex > 0) {
        nameArr[abrevIndex] = toAbridged(nameArr[abrevIndex]);
        fullName = nameArr.join(SEPARATOR);
        abrevIndex--;
    }

    /*
    abrevIndex = nameArr.length - 2;
    while (fullName.length > limit && abrevIndex > 0) {
        nameArr = nameArr.splice(abrevIndex, 1)
        fullName = nameArr.join(SEPARATOR);
        abrevIndex--;
    }
    */

    return fullName;
}

export const toAbridged = (name: string) => { 
    return `${name.charAt(0)}${TOKEN}`;
}

const removePrepositions = (fullName: string) => {
    return fullName.replace(/\ dos|\ das|\ dos|\ das|\ de|\ d\'/gi, '');
}