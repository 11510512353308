import { useCallback, useEffect, useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";

import * as _ from "lodash";

import { Modal } from "../../../../../../components/Modal";
import { showToast } from "../../../../../../components/CustomToast";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";
import { OrderItem } from "./components/OrderItem";

import { getActiveLanguage } from "../../../../../../utils/getActiveLanguage";

import api from "../../../../../../services/api";

import { DiagnosisOption, Order, Solution } from "./dtos";

import { RiCheckFill, RiCloseLine } from "react-icons/ri";

import {
  OrthesesPerDiagnosisModalContainer,
  Header,
  Content,
  InputContainer,
  ListContainer,
} from "./styles";

interface OrthesesPerDiagnosisModalProps {
  open: boolean;
  handleCloseModal: () => void;
}

const REQUEST_OFFSET = 10;

export function OrthesesPerDiagnosisModal({
  open,
  handleCloseModal,
}: OrthesesPerDiagnosisModalProps) {
  const lastOrderRef = useRef<HTMLDivElement>(null);

  const [loading, setLoading] = useState(false);
  const [diagnosisSearch, setDiagnosisSearch] = useState("");
  const [diagnosis, setDiagnosis] = useState<DiagnosisOption[]>([]);
  const [selectedDiagnosisId, setSelectedDiagnosesId] = useState("");
  const [orders, setOrders] = useState<Order[]>([]);
  const [ordersTotal, setOrdersTotal] = useState<number | null>(null);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [ordersSolutions, setOrdersSolutions] = useState<Solution[]>([]);

  async function handleGetDiagnosis(search: string) {
    try {
      const activeLanguage = getActiveLanguage();

      const params = { language: activeLanguage, multi: search };
      const response = await api.get("/diagnosis", { params });

      const formattedDiagnosis = formatDiagnosis(response.data);

      setDiagnosis(formattedDiagnosis);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao carregar os diagnósticos",
      });
    }
  }

  async function handleGetOrdersPerDiagnosis(
    mode: "get" | "paginate",
    diagnosisId: string
  ) {
    setLoading(true);

    try {
      const response = await api.get(
        `/orders/reports/diagnosis/${diagnosisId}`,
        {
          params: {
            page: mode === "get" ? 1 : page + 1,
            limit: REQUEST_OFFSET,
          },
        }
      );

      if (mode === "get") {
        setOrders(response.data.orders);
        setOrdersTotal(response.data.total);
        setTotalPage(response.data.total / REQUEST_OFFSET);
        setOrdersSolutions(response.data.solutions_count);
        setPage(1);
      } else {
        setOrders((prevState) => [...prevState, ...response.data.orders]);
        setPage((prevState) => prevState + 1);
      }
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao carregar os pedidos com esse diagnóstico",
      });
    } finally {
      setLoading(false);
    }
  }

  function formatDiagnosis(data: any[]): DiagnosisOption[] {
    return data.map((item) => {
      return {
        id: item.id,
        label: item.name[getActiveLanguage()],
        verified: item.verified,
      };
    });
  }

  function clearModalData() {
    setDiagnosisSearch("");
    setSelectedDiagnosesId("");
    setOrders([]);
    setPage(1);
    setOrdersSolutions([]);
    setOrdersTotal(null);
  }

  const debounceDiagnosis = useCallback(
    _.debounce((search) => handleGetDiagnosis(search), 500),
    []
  );

  function entriesCallback(entries: IntersectionObserverEntry[]) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.intersectionRatio >= 0.75 && page < totalPage) {
          handleGetOrdersPerDiagnosis("paginate", selectedDiagnosisId);
        }
      }
    });
  }

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 1.0,
    };

    const observer = new IntersectionObserver(entriesCallback, options);

    lastOrderRef?.current && observer.observe(lastOrderRef?.current);

    return () => {
      observer.disconnect();
    };
  }, [orders]);

  useEffect(() => {
    handleGetDiagnosis("");
  }, []);

  return (
    <Modal open={open} modalWidth={550}>
      <OrthesesPerDiagnosisModalContainer>
        <Header>
          <span className="title">Pesquisa de pedidos por diagnóstico</span>
          <RiCloseLine
            onClick={() => {
              handleCloseModal();
              clearModalData();
            }}
          />
        </Header>

        <Content>
          <InputContainer>
            <SearchableSelect
              label="Diagnóstico"
              options={diagnosis}
              defaultValue={diagnosisSearch}
              onChange={(value) => {
                debounceDiagnosis(value);
                setDiagnosisSearch(value);
              }}
              onSelect={(selected) => {
                if (typeof selected !== "string" && selected?.id) {
                  handleGetOrdersPerDiagnosis("get", selected.id.toString());
                  setSelectedDiagnosesId(selected.id.toString());
                }
              }}
              renderOption={(props, option) => {
                return (
                  <Box
                    component="li"
                    {...props}
                    key={option.id}
                    display="flex"
                    flexDirection="row"
                    alignItems="center !important"
                    sx={{
                      svg: {
                        marginLeft: "8px",
                      },
                    }}
                  >
                    <span>{option.label}</span>
                    {option?.verified && (
                      <RiCheckFill color="var(--finished)" size={20} />
                    )}
                  </Box>
                );
              }}
            />
          </InputContainer>

          <ListContainer>
            {ordersTotal !== null && ordersTotal > 0 && (
              <div className="helpers">
                <div className="helper">
                  {ordersTotal > 1
                    ? `${ordersTotal} pedidos feitos com esse diagnóstico`
                    : `${ordersTotal} pedido feito com esse diagnóstico`}
                </div>

                {ordersSolutions.map((item, index) => {
                  return (
                    <div className="helper" key={index}>
                      <strong>{item.solution_name}</strong> - {item.count}{" "}
                      {Number(item.count) > 1 ? "pedidos" : "pedido"}
                    </div>
                  );
                })}
              </div>
            )}

            {orders.map((order, index) => {
              const lastItem = orders.length - 1 === index;

              return (
                <div
                  key={order.id}
                  ref={lastItem ? lastOrderRef : undefined}
                  className="item_container"
                >
                  <OrderItem order={order} />
                </div>
              );
            })}

            {loading && <CircularProgress size="30px" />}
          </ListContainer>
        </Content>
      </OrthesesPerDiagnosisModalContainer>
    </Modal>
  );
}
