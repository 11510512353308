import styled from "styled-components";

type StatusProps = {
    active?: boolean;
};
  

export const Container = styled.div`
  margin-left: 10px;
  position: relative;
`;

export const Svg = styled.img<StatusProps>`
  object-fit: contain;
  width: 15px;
  height: 15px;
  
  cursor: pointer;

  filter: ${(props) => (!props.active ? 
    "invert(90%) sepia(69%) saturate(0%) hue-rotate(114deg) brightness(85%) contrast(100%)" :
    "invert(24%) sepia(86%) saturate(1904%) hue-rotate(263deg) brightness(98%) contrast(95%)"
  )};
`
export const IconButtonContainer = styled.div<StatusProps>`
  position: relative;
  display: flex;

  svg {
    color: ${(props) => (!props.active ? "var(--light-gray)" : "var(--fixit)")};
    cursor: pointer;
  };
`;
