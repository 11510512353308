import styled from "styled-components";

interface OptionProps {
  warning: boolean;
}

export const Container = styled.div`
  position: absolute;
  width: 210px;
  top: 8px;
  right: 20px;
  border-radius: 10px;

  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 10;
  overflow: hidden;
`;

export const Option = styled.div<OptionProps>`
  width: 100%;
  height: 40px;
  padding: 4px 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  cursor: pointer;
  transition: all 0.3s;

  svg {
    width: 18px;
    height: 18px;
    color: ${({ warning }) =>
      warning ? "var(--notification)" : "var(--fixit)"} !important;
    cursor: pointer;
  }

  span {
    font-size: 16px;
    line-height: 19px;
    color: ${({ warning }) => (warning ? "var(--notification)" : "#403e3e")};
  }

  .active_icon {
    width: fit-content;
    height: fit-content;
    margin-left: auto;
    display: flex;
    align-items: center;

    svg {
      color: var(--finished) !important;
    }
  }

  &:hover {
    background: #ccc;
  }
`;
