export const contractOptions = [
  { optionText: "Licença Anual", value: "annual" },
  { optionText: "Licença Mensal", value: "monthly" },
];

export const paymentRecurrencyMocks = {
  annual: "Anual",
  monthly: "Mensal",
  semester: "Semestral",
  quarterly: "Trimestral",
};
