import styled from "styled-components";

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form_title {
    font-size: 16px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  label {
    font-size: 14px;
    color: var(--dark-gray);
  }

  @media (max-width: 900px) {
    .form_title {
      font-size: 16px;
      margin-left: 2px;
    }
  }
`;

export const FileContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 300px;
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 14px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const UploadButton = styled.button`
  width: 250px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  background: var(--fixit);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #6e00ad;
  }
`;

export const DropzoneContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`