import { ReactNode, SyntheticEvent, useState } from "react";

import { Tab, Tabs as MuiTabs } from "@mui/material";

import { Container, TabsContainer, TabsComponentContainer } from "./styles";

type TabsProps = {
  initialIndex?: number;
  centerTabs?: boolean;
  tabs: Array<{
    tabName: string;
    component: ReactNode;
  }>;
  activeTab?: (index: number) => any;
};

export function Tabs({
  tabs,
  initialIndex = 0,
  centerTabs = true,
  activeTab = () => {},
}: TabsProps) {
  const [value, setValue] = useState(initialIndex);

  function tabProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  function handleChange(event: SyntheticEvent, newValue: number) {
    setValue(newValue);
    activeTab(newValue);
  }

  return (
    <Container>
      <TabsContainer>
        <MuiTabs value={value} onChange={handleChange} centered={centerTabs}>
          {tabs.map((tab, index) => {
            return <Tab key={index} label={tab.tabName} {...tabProps(index)} />;
          })}
        </MuiTabs>
      </TabsContainer>

      {tabs.map((tab, index) => {
        return (
          <TabsComponentContainer
            key={tab?.tabName}
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
          >
            {tab.component}
          </TabsComponentContainer>
        );
      })}
    </Container>
  );
}
