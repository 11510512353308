import styled, { css } from "styled-components";

type ButtonProps = {
  disabled?: boolean;
  connecting?: boolean;
};

type FieldProps = {
  fieldWidth?: string;
};

export const Container = styled.div`
  > svg {
    position: absolute;
    top: 32px;
    right: 32px;
    cursor: pointer;
  }
`;

export const Form = styled.form``;

export const MenuContainer = styled.div`
  display: flex;
  width: 40px;
`;

export const ConnectContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;

  span {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  padding-top: 20px;

  .section_title {
    font-size: 18px;
    color: var(--primary);
    text-align: center;
  }
`;

export const CheckboxLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 10px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-size: 18px;
    color: var(--dark-gray);
  }
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;

  h5 {
    text-align: justify;
  }
`;

export const InputContainer = styled.div<FieldProps>`
  width: ${({ fieldWidth }) => fieldWidth ?? "100%"};
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const PrinterActionButton = styled.button<ButtonProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--active-orange)"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 50%;
  background-color: var(--white);
  border: 1px solid
    ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--active-orange)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--active-orange);
        color: var(--white);
        background: var(--active-orange);
      }
    `}
`;

export const SaveText = styled.button<ButtonProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled, connecting }) =>
    disabled ? "var(--light-gray)" : connecting ? "var(--fixit)" : "var(--finished)"};
  cursor: ${({ disabled, connecting }) => (disabled || connecting ? "default" : "pointer")};
  width: 100%;
  background-color: var(--white);
  border: 1px solid
    ${({ disabled, connecting }) => (disabled ? "var(--light-gray)" : connecting ? "var(--fixit)" : "var(--finished)")};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;

export const LoaderLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const CustomDeviceLine = styled.div`
  font-size: 12px;
  color: var(--dark-gray);
  text-align: center;

  span {
    font-weight: 500;
    color: var(--fixit);
    cursor: pointer;
  }
`;

export const UploadFilesContainer = styled.div`
  margin-top: 5px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  label {
    font-size: 18px;
    color: var(--dark-gray);
  }
`;

export const DropzoneContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span {
    color: var(--gray);
    position: relative;
    top: 10px;
    right: 70px;
    background-color: var(--white);
  }
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 10px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;
