import { useEffect, useState } from "react";

import { i18n } from "../../../../localization/i18n";

import { Modal } from "../../../../components/Modal";

import api from "../../../../services/api";

import { RiCloseLine } from "react-icons/ri";

import {
  CloseButton,
  InputHeader,
  CardName,
  RowFarContainer,
  LicenseeSelectedText,
  GeneralContainer,
} from "./styles";

interface IProvisionerList {
  handleProviderSelected?: () => void;
  orderIdentification: string;
  trigger: boolean;
  onClose: () => any;
  order?: any;
}
export interface IOption {
  optionText?: string;
  value: string | number;
  currency?: string;
}

interface ILicensee {
  id: string;
  name: string;
  completed_onboard: boolean;
  country: string;
  state: string;
  city: string;
  contract: string;
  situation: string;
  manager_name: string;
  phone: string;
  document_number: string;
  person_type: string;
  instagram: string;
  created_at: string;
  is_blocked: boolean;
  is_fixpoint: boolean;
  is_test: boolean;
  ordered_initial_kit: boolean;
  companyName: string;
  email: string;
}

interface IProviderData {
  cellphone?: string;
  city: string;
  country: string;
  cpf: string;
  expertise: string;
  id: string;
  instagram: string;
  licensee: ILicensee;
  name: string;
  professional_number: string;
  state: string;
}

export function OrderProviderModal({
  orderIdentification,
  trigger,
  handleProviderSelected = () => {},
  onClose = () => {},
  order,
}: IProvisionerList) {
  // const [licensees, setLicensees] = useState<ILicenseeData[]>([]);
  const [provider, setProvider] = useState<IProviderData>();
  // const [loading, setLoading] = useState(false);

  async function getPersonByUserId(userId: string) {
    try {
      await api.get(`/person/user/${userId}`).then((response) => {
        setProvider(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    trigger && getPersonByUserId(order.user_id);
  }, [trigger]);

  return (
    <Modal open={trigger} modalWidth={500}>
      <InputHeader>
        {`${i18n.t("orders.order_provider.requester")}`}
      </InputHeader>
      <CloseButton
        onClick={() => {
          onClose();
        }}
      >
        <RiCloseLine size={24} />
      </CloseButton>
      <GeneralContainer>
        <LicenseeSelectedText>{`${i18n.t(
          "orders.order_provider.order_data"
        )}`}</LicenseeSelectedText>
        <RowFarContainer>
          <CardName>
            {`${i18n.t("orders.order_provider.company_name")}`}
            <label>{provider?.licensee.companyName}</label>
          </CardName>
        </RowFarContainer>
        <CardName>
          {`${i18n.t("userProfile.personal_data.phone")}:`}
          <label>{provider?.cellphone}</label>
        </CardName>
        <CardName>
          {`${i18n.t("orders.order_provider.responsible")}`}
          <label>{provider?.name}</label>
        </CardName>
        <CardName>
          {`${i18n.t("orders.order_provider.location")}`}
          <label>
            {provider?.city}-{provider?.state}
          </label>
        </CardName>
      </GeneralContainer>
    </Modal>
  );
}
