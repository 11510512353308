import { useState } from "react";

import { SolidButton } from "../../../components/SolidButton";
import logoImg from "../../../assets/logo.svg";

import {
  Form,
  FormContent,
  Footer,
  InputContainer,
} from "../styles";
import { Code } from "../../../components/CodeComponent";

interface IValidateCodeStepProps {
  validateCodeStep: () => void;
  setExpiredCode: (arg: boolean) => void;
  setCode: (arg: {}) => void;
  handleDeleteCode: () => void;
  resendCode: () => void;
  loading: boolean;
}

export default function ValidateCode({loading, validateCodeStep, handleDeleteCode, resendCode,  setExpiredCode, setCode }: IValidateCodeStepProps) {
  const [errors, setErrors] = useState({} as any);

  return (
    <Form onSubmit={validateCodeStep}>
      <img className="logo" src={logoImg} alt="FixIt logo" />

      <FormContent>
        <InputContainer errorMargin={!!errors.code}>
          <Code setExpiredCode={setExpiredCode} setCode={setCode} loading={loading} deleteCode={handleDeleteCode} resendCode={resendCode} submitCode={validateCodeStep} />
        </InputContainer>

        <Footer>
          <SolidButton type="submit" text='Avançar' />
        </Footer>
      
      </FormContent>
    </Form>
  );
}
