export const SignaturePeriodMocks = [
  { optionText: "Mensal", value: "monthly" },
  { optionText: "Anual", value: "annual" },
  { optionText: "Semestral", value: "semester" },
  { optionText: "Trimestral", value: "quarterly" },
];

export interface ISearchableOption {
  label: string;
  id?: number | string;
}

export interface ISearchableSolution extends ISearchableOption {
  model: IUserTerms;
}

export interface IUserTerms {
  id: string;
  name: string;
  type: string;
}
