export const LicenseeSituation = [
    {
        value: 'effected',
        optionText: 'Efetivado',
    },
    {
        value: 'implementation',
        optionText: 'Em Implantação',
    },
    {
        value: 'operational',
        optionText: 'Em Operação',
    },
    {
        value: 'training',
        optionText: 'Em Treinamento',
    },
    {
        value: 'onboarding',
        optionText: 'Onboarding',
    },
]