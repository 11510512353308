/* eslint-disable jsx-a11y/iframe-has-title */
import axios from "axios";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import ReCAPTCHA from "react-google-recaptcha";
import { useAuth } from "../../hooks/AuthContext";
import { i18n } from "../../localization/i18n";
import api from "../../services/api";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";
import { Checkbox } from "../Checkbox";
import { OutlinedInput } from "../OutlinedInput";
import * as Yup from "yup";

import {
  CloseButton,
  CancelButton,
  Container,
  ModalContent,
  Footer,
  InputContainer,
} from "./styles";
import validateFormFields from "../../utils/validateFormFields";
import { showToast } from "../CustomToast";
import { useOnboard } from "../../hooks/OnboardContext";

interface Person {
  id: string;
  licensee: {
    contract: string;
    user_term: ITermosUso;
  };
}

interface IUserData {
  name: string;
  email: string;
  role: string;
  id: string;
  person: Person;
}

interface IUserTermsProps {
  user: IUserData;
  signOut: () => void;
  userTerm: ITermosUso;
  loadLicensee: () => void;
}

export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
}

interface TermsAcceptanceDataInterface {
  document?: string;
  user_name?: string;
}

export function TermsOfUseModal({
  user,
  userTerm,
  signOut,
  loadLicensee,
}: IUserTermsProps) {
  const [errors, setErrors] = useState({} as any);
  const [terms, setTerms] = useState<string>();
  const [open, setOpen] = useState(true);
  const [IP, setIP] = useState("");
  const [showTerms, setShowTerms] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [reCaptcha, setReCaptcha] = useState<any>();
  const { licensee } = useOnboard();
  const [termsAcceptanceData, setTermsAcceptanceData] =
    useState<TermsAcceptanceDataInterface>();

  useEffect(() => {
    if (showTerms && !!userTerm) {
      fetch(
        `${process.env.REACT_APP_STORAGE_BASE_URL}${userTerm.document_link}`
      )
        .then((response) => response.text())
        .then((html) => {
          setTerms(html);
          // var parser = new DOMParser();
          // var doc = parser.parseFromString(html, "text/html");
        })
        .catch((err) => {
          // writer.abort({reason: 'Problema na impressão'});
          console.error(err);
        });
    }
  }, [showTerms, userTerm]);

  async function getIpData() {
    const IPData = await axios.get("https://api.ipify.org/?format=json");

    setIP(IPData.data.ip);
  }

  useEffect(() => {
    getIpData();
  }, []);

  async function acceptUserTerm() {
    const yupValidation = Yup.object().shape({
      user_name: Yup.string().required(typesErrors.required),
      document: Yup.number()
        .min(0, typesErrors.minRequired)
        .required(typesErrors.required),
    });

    const validation = await validateFormFields(
      termsAcceptanceData,
      yupValidation,
      {
        user_name: "",
        document: "",
      }
    );

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});

    try {
      if (!!userTerm && accepted) {
        api
          .post(`/terms-acceptance/${licensee?.signature[0].id}`, {
            ip_address: IP,
            document: termsAcceptanceData?.document,
            user_name: termsAcceptanceData?.user_name,
          })
          .then(() => loadLicensee());
      }
    } catch (err) {
      console.error("err", err);
    }
  }

  function getButtonStatus() {
    if (accepted && reCaptcha && userTerm) {
      return false;
    } else return true;
  }

  return ReactDOM.createPortal(
    <Container open={open}>
      <div className="modal_wrapper">
        <ModalContent showTerms={!!terms}>
          <div className="header">
            <div className="title">Termos de uso</div>
          </div>

          {!!terms && (
            <iframe
              srcDoc={terms}
              width="100%"
              id="docFrame"
              scrolling="no"
              style={{ overflow: "hidden" }}
              onLoad={() => {
                var obj = document.getElementById(
                  "docFrame"
                ) as HTMLIFrameElement;
                if (obj && obj.contentWindow) {
                  obj.style.height =
                    obj.contentWindow.document.body.scrollHeight + 60 + "px";
                }
              }}
            ></iframe>
          )}

          <InputContainer>
            <OutlinedInput
              handleChange={(event) => {
                setTermsAcceptanceData((prevState) => ({
                  ...prevState,
                  user_name: event,
                }));
              }}
              inputName={"user_name"}
              label={i18n.t("userProfile.data_user.name")}
              value={termsAcceptanceData?.user_name ?? ""}
              error={errors.user_name}
            />

            <OutlinedInput
              error={errors.document}
              handleChange={(event) => {
                setTermsAcceptanceData((prevState) => ({
                  ...prevState,
                  document: event,
                }));
              }}
              type="number"
              inputName={"user_document"}
              label={i18n.t("userProfile.personal_data.document")}
              value={termsAcceptanceData?.document ?? ""}
            />
          </InputContainer>

          <div className="accept_section">
            <div className="accept_check">
              <Checkbox
                checked={accepted}
                handleChange={() => setAccepted((prevState) => !prevState)}
              />
              <span>
                Li e estou de acordo com os{" "}
                <strong onClick={() => setShowTerms(true)}>
                  Termos de uso
                </strong>
              </span>
            </div>
            {process.env.REACT_APP_RECAPTCHA_SITE_KEY && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={(value) => setReCaptcha(value)}
              />
            )}
          </div>

          <Footer>
            <CancelButton
              type="button"
              onClick={() => {
                signOut();
                setOpen(false);
              }}
            >
              {`${i18n.t("sidebar.signout")}`}
            </CancelButton>
            <CloseButton
              type="button"
              disabled={getButtonStatus()}
              onClick={() => {
                acceptUserTerm();
              }}
            >
              {`${i18n.t("userRegistration.confirm")}`}
            </CloseButton>
          </Footer>
        </ModalContent>
      </div>
    </Container>,
    document.body
  );
}
