import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import NavBtn from "../NavBtn";
import Topbar from "../Topbar";
import CustomToast from "../CustomToast";

import { FooterContainer, PageContainer } from "./styles";
import DesktopSideBar from "../DesktopSideBar";
import MobileSideBar from "../MobileSideBar";
import GlobalLayoutFooter from "../GlobalLayoutFooter";

interface LayoutProps {
  noPadding?: boolean;
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children, noPadding }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/saf") {
      setIsIframe(true);
    } else {
      setIsIframe(false);
    }
  }, [location]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <MobileSideBar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <NavBtn toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <DesktopSideBar />
      <PageContainer
        noPadding={noPadding ?? false}
        isMenuOpen={isMenuOpen}
        isIframe={isIframe}
      >
        <Topbar />
        <CustomToast />
        <div className="content-area">{children}</div>
        <FooterContainer>
          <GlobalLayoutFooter />
        </FooterContainer>
      </PageContainer>
    </>
  );
};

export default Layout;
