import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 50px);

  padding: 200px 20px 0px 20px;

  padding-top: 200px;
  margin-bottom: auto;
`

export const PrincipalText = styled.h1`
  color: var(--fixit);
  text-align: center;
`
export const SecondaryText = styled.h3`
  color: var(--gray);
  margin-top: 20px;
  margin-bottom: 20px;
`
export const WhatsappSvg = styled.img`
  width: 10px;
  height: 10px;
`
