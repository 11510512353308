import styled from 'styled-components';

export const DropzoneContainer = styled.div`
    margin-top: 8px;
`;

export const UploadFilesContainer = styled.div`
    margin-top: 20px;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    label {
    font-size: 14px;
    color: var(--dark-gray);
    }
`;

export const File = styled.div`
    display: flex;
    align-items: center;
    gap: 16px;
    width: fit-content;
    padding: 8px 10px;
    border-radius: 6px;
    background: var(--light-gray);
    span {
        font-size: 14px;
        color: var(--dark-gray);
    }
    svg {
        cursor: pointer;
    }
`;

export const CropContainer = styled.div`
    position: relative;
    
    width: 100%;
    height: 300px;
`;

export const Title = styled.h1`
    color: var(--fixit);
    font-size: 20px;

    margin-bottom: 15px;
`

export const Subtitle = styled.div`
    color: var(--gray);
    font-size: 12px;
    text-align: center;
    margin-top: 10px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form_title {
    font-size: 16px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  label {
    font-size: 14px;
    color: var(--dark-gray);
  }

  @media (max-width: 900px) {
    .form_title {
      font-size: 16px;
      margin-left: 2px;
    }
  }
`;

