import styled, { css } from "styled-components";

interface ContainerProps {
  checked: boolean;
  backgroundCheckedColor: string;
  readOnly?: boolean;
  error: boolean;
}

export const Container = styled.div<ContainerProps>`
  min-width: 20px;
  min-height: 20px;
  border-radius: 5px;
  border: 2px solid var(--gray);
  background: ${({ checked, backgroundCheckedColor, readOnly }) =>
    checked ? `${readOnly ? "var(--dark-gray)" : backgroundCheckedColor}` : "var(--white)"};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  ${({ error }) => error && css`
    border-color: var(--formError);
  `}
`;
