import { useEffect, useState } from "react";
import { RiCloseLine, RiDeleteBin7Line, RiSaveLine } from "react-icons/ri";
import DropZone from "../../../../components/DropZone";
import { i18n } from "../../../../localization/i18n";
import { Container, DropzoneContainer, File, FileLabel, Header, LinkButton, ProgressBar, Subtitle, Title, TitleContainer } from "./styles";

interface IBoxImageUploadParams {
    uploadFase?: string;
    progress: number;
    file?: File;
    setFile: (file?: File) => void;
    submit: () => void;
    close: () => void;
}

export function BoxImageUpload ({ file, setFile, uploadFase, progress, submit, close } : IBoxImageUploadParams) {
    return (
        <>
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>{`${i18n.t('solutions.image_upload_title')}`}</Title>
                        <Subtitle>{`${i18n.t('solutions.image_upload_subtitle')}`}</Subtitle>
                    </TitleContainer>
                    {
                        progress === 100 ? (
                            <LinkButton onClick={() => {
                                /* validatePatientForm(tmpPatientData).then(valid => {
                                    valid && updatePatientData()
                                }); */
                                    close();
                                }} >
                                <RiCloseLine size={16} />
                                {`${i18n.t('orders.actions.close')}`}
                            </LinkButton>

                        ) : (
                            <LinkButton disabled={!file || !!uploadFase} onClick={() => {
                                /* validatePatientForm(tmpPatientData).then(valid => {
                                    valid && updatePatientData()
                                }); */
                                    submit();
                                }} >
                                <RiSaveLine size={16} />
                                {`${i18n.t('orders.actions.save')}`}
                            </LinkButton>
                        )
                    }
                    
                </Header>

                <DropzoneContainer>
                    {
                        !file ? (
                            <DropZone
                            multiple={false}
                            onUpload={(fileArr) => {
                                // setReadFile(file);
                                setFile(fileArr[0]);
                            }}
                          />
                        ) : 
                        ( 
                            <File>
                                <ProgressBar className={"progress " + (progress === 100 ? 'done' : '')} progress={progress}></ProgressBar>
                                <FileLabel disabled={!!uploadFase}>
                                    <span>{file.name}</span>
                                    {
                                        !progress ? (
                                            <RiDeleteBin7Line
                                                onClick={async () => {
                                                    setFile(undefined);
                                                }}
                                                size={18}
                                            />
                                        ) : (
                                            <span>{progress}%</span>
                                        )
                                    }
                                    
                                </FileLabel>
                            </File>
                        )
                    }
                </DropzoneContainer>
            </Container>
        </>
    )
}