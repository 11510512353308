import Popover from "../../../../../components/Popover";
import { GoBook } from 'react-icons/go';
import { Header, ImageContainer, LeftContent, PrincipalInfos, VideoContainer } from "./styles";
import { useState } from "react";

interface ICourseItem {
    name: string;
    description: string;
    onClick: () => void;
    selected: boolean;
}

export function CourseItem({ name, description, onClick, selected }: ICourseItem) {
    
    
    return (
        <VideoContainer
            selected={selected}
            onClick={() => {
                onClick();
            }}
        >
            <Header>
                <LeftContent>
                    <ImageContainer>
                        <Popover
                        position="right"
                        label={name}
                        styles={{ minWidth: "120px", maxWidth: "200px", whiteSpace: "normal" }}
                        >
                          <GoBook />
                        </Popover>
                    </ImageContainer>
                    <PrincipalInfos>
                        <span className="solution">
                        { name }
                        </span>
                        <span className="date">{description}</span>
                    </PrincipalInfos>
                </LeftContent>
            </Header>
        </VideoContainer>
    );
}