import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;

  .App {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .controls {
    width: 60%;
    display: flex;
    align-items: center;

    position: absolute;
    top: 220px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .slider {
    padding: 10px 0px;
  }
`;
  
  