import { useEffect, useMemo, useState } from "react";

import { useAuth, ICurrencyInfos } from "../../../../../hooks/AuthContext";

import { i18n } from "../../../../../localization/i18n";

import { Modal } from "../../../../../components/Modal";
import { OutlinedButton } from "../../../../../components/OutlinedButton";
import { SolidButton } from "../../../../../components/SolidButton";
import { Checkbox } from "../../../../../components/Checkbox";
import { OutlinedCheckbox } from "../../../../../components/OutlinedCheckbox";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import { AddressModal } from "../../../../../components/AddressModal";
import { Separator } from "../../../../../components/Topbar/styles";
import { showToast } from "../../../../../components/CustomToast";
import Dropdown from "../../../../Admin/components/Dropdown";
import { CustomOrderInfos } from "./components/CustomOrderInfos";
import { StandardOrderInfos } from "./components/StandardOrderInfos";
import { PlanitOrderInfos } from "./components/PlanitOrderInfos";

import api from "../../../../../services/api";

import {
  orderUrgencyTypes,
  roles,
  solutionTypes,
} from "../../../../../utils/constants";

import { CustomSolutionForm, IPatientData, IPrice, Solution } from "../../dtos";
import { IAddress, Licensee } from "../../../../Licensee/dtos";

import { RiCloseFill } from "react-icons/ri";

import {
  ConfirmOrderModalContainer,
  Header,
  Content,
  Footer,
  CheckboxContainer,
  ConfirmationContainer,
  JustifyContainer,
} from "./styles";
import { usePrinters } from "../../../../../hooks/PrintersContext";

interface ConfirmOrderModalProps {
  open: boolean;
  order: CustomSolutionForm;
  solution: Solution;
  solutionType?: string;
  price?: IPrice;
  handleCloseModal: () => void;
  changeOrder: (data: Partial<CustomSolutionForm>) => void;
  onConfirmOrder: () => Promise<(() => void) | undefined>;
  currency: ICurrencyInfos | undefined;
  patientInfo: IPatientData;
  loading: boolean
}

interface IOptions {
  optionText: string;
  value: string;
}

export function ConfirmOrderModal({
  open,
  order,
  solutionType,
  price,
  solution,
  handleCloseModal,
  changeOrder,
  onConfirmOrder,
  currency,
  patientInfo,
  loading,
}: ConfirmOrderModalProps) {
  const { user } = useAuth();
  const { userHasPrinter } = usePrinters()

  const [confirmedMeasures, setConfirmedMeasures] = useState(false);
  const [addNewAddress, setAddNewAddress] = useState(false);
  const [totalValue, setTotalValue] = useState<number | undefined>();
  const [userAddresses, setUserAddresses] = useState<IOptions[]>([]);
  const [selectedAddress, setSelectedAddress] = useState<IOptions>();

  async function getLicenseeAddresses(id: string) {
    try {
      const response = await api.get(`/address/all/${id}`);
      setUserAddresses(
        response.data.map((address: IAddress) => {
          if (address.deliver_address) {
            setSelectedAddress({
              optionText: `${address?.address}, ${address?.number}`,
              value: address.id,
            });
            changeOrder({ delivery_address: address.id });
          }
          return {
            optionText: `${address?.address}, ${address?.number}`,
            value: address.id,
          };
        })
      );
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar seus endereços.",
      });
    }
  }

  function GrossValueCalc(value: number, quantity: number, aditional: number) {
    const gross = Number(aditional) + value * quantity;
    return gross;
  }

  const formatedTodalValue = useMemo(() => {
    if (totalValue && currency) {
      const valueToFormat =
        (solutionType === "custom" || solutionType === solutionTypes.plan) &&
          order.urgencyType === orderUrgencyTypes.normal
          ? GrossValueCalc(
            totalValue,
            order.quantity,
            Number(patientInfo.age) <= 12 ? 0 : currency.custom_tax
          )
          : order.urgencyType === orderUrgencyTypes.requested
            ? GrossValueCalc(
              totalValue,
              order.quantity,
              (Number(patientInfo.age) <= 12 ? 0 : currency.custom_tax) +
              Number(currency.urgency_tax)
            )
            : GrossValueCalc(totalValue, order.quantity, 0);

      return valueToFormat.toLocaleString("pt-br", {
        style: "currency",
        currency: currency?.code ?? "BRL",
      });
    }
    return "";
  }, [
    totalValue,
    currency,
    solutionType,
    order.urgencyType,
    order.quantity,
    patientInfo.age,
  ]);

  const solutionForm = useMemo(() => {
    switch (solutionType) {
      case "custom":
        return <CustomOrderInfos order={order} solution={solution} />;

      case "plan":
        return <PlanitOrderInfos order={order} />;

      case "standard":
        return <StandardOrderInfos solution={solution} order={order} />;
    }
  }, [order, solution, solutionType]);

  useEffect(() => {
    if (!userHasPrinter) {
      getLicenseeAddresses(user.person.licensee.id);
    }
    if (price?.file_price) {
      setTotalValue(price?.file_price);
    }
  }, [price]);

  return (
    <Modal open={open} modalWidth={500}>
      <ConfirmOrderModalContainer>
        <Header>
          <span className="title">{`${i18n.t(
            "orders.confirmation.confirm"
          )}`}</span>
          <RiCloseFill onClick={handleCloseModal} />
        </Header>

        <Content>
          {solutionForm}

          {!userHasPrinter && (
            <>
              {!addNewAddress ? (
                <div className="address">
                  <strong>Endereço:</strong>
                  <Dropdown
                    options={userAddresses}
                    handleSelect={(selected) => {
                      setSelectedAddress({
                        optionText: selected.optionText,
                        value: selected.value,
                      });
                      changeOrder({ delivery_address: selected.value });
                    }}
                    placeholder={"Selecione o endereço de entrega"}
                    selectedOption={selectedAddress?.optionText}
                  />
                  <div
                    className="newAddress"
                    onClick={() => setAddNewAddress(!addNewAddress)}
                  >
                    <span>Não encontrou seu endereço?</span>
                    <strong>Cadastre um</strong>
                  </div>
                </div>
              ) : (
                <div className="addAddress">
                  <Separator />
                  <AddressModal
                    closeModal={() => setAddNewAddress(!addNewAddress)}
                    licensee={user.person.licensee as unknown as Licensee}
                    getLicenseeAddresses={getLicenseeAddresses}
                  />
                  <Separator />
                </div>
              )}
            </>
          )}

          {solutionType === "custom" && (
            <div className="urgency">
              <CheckboxContainer>
                <OutlinedCheckbox
                  label={i18n.t("orders.confirmation.markAsUrgency")}
                  checked={order.urgencyType === orderUrgencyTypes.requested}
                  handleChange={() => {
                    if (order.urgencyType === orderUrgencyTypes.requested) {
                      changeOrder({
                        urgencyType: orderUrgencyTypes.normal as any,
                      });

                      return;
                    }

                    changeOrder({
                      urgencyType: orderUrgencyTypes.requested as any,
                    });
                  }}
                />
              </CheckboxContainer>

              {/* <UrgencyInfo>
                <RiInformationFill />
                <span>{`${i18n.t(
                  "orders.urgency.additionalValueJustification",
                  {
                    currency: `${currency}`,
                    value: `${currency === currencyValue.BRL ? 0 : 0}`,
                    doubleValue: `${currency === currencyValue.BRL ? 0 : 0}`,
                  }
                )}`}</span>
              </UrgencyInfo> */}

              {/* {order.urgencyType === orderUrgencyTypes.requested && (
               <UrgencyInfo> vinha aqui dentro
              )} */}
            </div>
          )}

          {order.urgencyType === orderUrgencyTypes.requested && (
            <JustifyContainer>
              <OutlinedInput
                inputName="urgency_justify"
                label={`${i18n.t("orders.confirmation.justification")}`}
                textArea
                handleChange={(event) => changeOrder({ urgencyReason: event })}
                value={order.urgencyReason}
              />
            </JustifyContainer>
          )}

          {!userHasPrinter || user.role === roles.employee ? null : (
            <div className="total">
              <strong>{`${i18n.t("orders.confirmation.totalValue")}`}: </strong>
              <span>{formatedTodalValue}</span>
            </div>
          )}
        </Content>

        <ConfirmationContainer>
          <Checkbox
            checked={confirmedMeasures}
            handleChange={() => setConfirmedMeasures((prevState) => !prevState)}
          />
          {`${i18n.t("orders.confirmation.responsabilityConfirmation")}`}
        </ConfirmationContainer>

        <Footer>
          <OutlinedButton
            text={`${i18n.t("orders.confirmation.cancel")}`}
            onClick={handleCloseModal}
          />
          <SolidButton
            text={`${i18n.t("orders.confirmation.confirmAndFinish")}`}
            disabled={
              !confirmedMeasures ||
              (!selectedAddress && !userHasPrinter) ||
              loading
            }
            onClick={() => {
              onConfirmOrder()
            }}
          />
        </Footer>
      </ConfirmOrderModalContainer>
    </Modal>
  );
}
