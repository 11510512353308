import styled from "styled-components";

export const OrderItemContainer = styled.div`
  width: 100%;
  height: 130px;
  min-height: 130px;
  padding: 0px 10px;
  border-radius: 8px;
  border: 2px solid var(--fixit);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 8px;

  color: var(--dark-gray);
  font-size: 16px;
`;
