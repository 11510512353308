import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { showToast } from "../../../../components/CustomToast";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { Footer, LoadMore } from "../../../Orders/styles";
import { TransactionItem } from "./components/TransactionItem";
import { ITransaction } from "./dtos";
import { Container } from "./styles";

export function Transactions() {
  const { setNavigationAction, user, reloadUser } = useAuth();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit] = useState(10);

  function loadMore() {
    setPage(page + 1);
  }

  async function GetLicenseeTransactions(reload: boolean) {
    setLoading(true);

    const queryList = [];

    queryList.push(`licensee_id=${user.person.licensee.id}`);
    queryList.push(`limit=${limit}`);
    !reload && queryList.push(`page=${page}`);

    try {
      const data = await api.get(`/transactions?${queryList.join("&")}`);
      setTransactions(
        reload
          ? data.data.transactions
          : [...transactions, ...data.data.transactions]
      );
      setTotalPage(data.data.total / limit);
    } catch (error) {
      showToast({
        message: "Não foi possível resgatar suas transações",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setPage(1);
    GetLicenseeTransactions(true);
    reloadUser();
  }, []);

  useEffect(() => {
    if (page > 1) {
      GetLicenseeTransactions(false);
    }
  }, [page]);

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("transactions.header"),
    });
  }, []);

  return (
    <Container>
      {transactions.map((transaction) => (
        <TransactionItem transaction={transaction} />
      ))}
      <Footer>
        {loading ? (
          <ReactLoading
            type={"bubbles"}
            color={"var(--fixit)"}
            height={64}
            width={64}
          />
        ) : page < totalPage ? (
          <LoadMore onClick={loadMore}>Carregar mais...</LoadMore>
        ) : (
          ""
        )}
      </Footer>
    </Container>
  );
}
