import { Container, Header, Title, Content, Footer } from "./styles";

import { Loader } from "../../../../components/Loader";

import { RiUpload2Line, RiCheckLine } from "react-icons/ri";
import { SolidButton } from "../../../../components/SolidButton";

interface UploadingFileModalProps {
  filesUploaded: boolean;
  closeModal: () => void;
  percentage: string;
}

export function UploadingFileModal({
  closeModal,
  filesUploaded,
  percentage
}: UploadingFileModalProps) {
  return (
    <Container>
      <Header>
        <RiUpload2Line size={32} color="var(--fixit)" />
        <Title>
          {filesUploaded ? "Tudo pronto!" : "Realizando o upload do arquivo..."}
        </Title>
      </Header>

      <Content>
        {filesUploaded ? <RiCheckLine size={64} color="#17e83a" /> : <Loader />}
        <span>{percentage}</span>
      </Content>

      {filesUploaded && (
        <Footer>
          <SolidButton
            onClick={() => {
              closeModal();
              window.location.href = "/creators/orders/";
            }}
            text="Fechar"
          />
        </Footer>
      )}
    </Container>
  );
}
