import styled, { css } from "styled-components";

type InputContainerProps = {
  containerWidth?: string;
};

type ButtonProps = {
  disabled?: boolean;
};

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 40px;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: var(--printed);
  }

  svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;

  margin-bottom: 40px;
  padding: 0 10px;
`;

export const AccordionContainer = styled.div`
  width: 700px;
  margin-bottom: 30px;

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const AccordionContentContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 0 26px;
`;

export const SectionFormTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: var(--gray);
  margin-top: 30px;
  text-align: center;
`;

export const ChecksContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    text-transform: uppercase;
  }
`;

export const InputLine = styled.div`
  display: flex;
  align-items: flex-center;
  justify-content: center;
  gap: 20px;
  margin-top: 26px;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const InputBlock = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: end;
  gap: 20px;
  margin-top: 26px;

  @media (max-width: 560px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MeasuresInputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 26px;
`;

export const InputContainer = styled.div<InputContainerProps>`
  width: ${({ containerWidth }) => containerWidth ?? "100%"};

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const HelperInputLine = styled.div`
  width: 100%;
  margin-top: 26px;
`;

export const InputHelper = styled.div`
  margin-top: 6px;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: var(--gray);

  span {
    margin-left: 4px;
    font-weight: 500;
    color: var(--fixit);
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SelectContainer = styled.div<InputContainerProps>`
  position: relative;
  width: ${({ containerWidth }) => containerWidth ?? "100%"};

  @media (max-width: 560px) {
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 3px;
  border-radius: 2px;
  margin-top: 26px;
  background: #989898;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  margin-top: 26px;
`;

export const SaveText = styled.span<ButtonProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--finished)"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 100%;
  border: 1px solid
    ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--finished)")};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;

export const FooterButton = styled.button<ButtonProps>`
  width: 100%;
  height: 46px;
  border-radius: 10px;
  background: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--fixit)"};
  color: var(--white);
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: background-color 0.2s;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;

      &:hover {
        background: #6e00ad;
      }
    `}
`;

export const AlertMessage = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: var(--error);
  padding: 10px 10px;
  width: 100%;
  border: 1px solid var(--error);
  border-radius: 10px;
`;

export const AccordionActionsContainer = styled.div`
  display: flex;
  align-self: center;
  gap: 5px;
`;

export const CircleButton = styled.button<ButtonProps>`
  border-radius: 50%;
  min-width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#fff")};
  color: #777;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
      &:active {
        transform: translateY(-1px);
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
      }

      &::after {
        content: "";
        display: inline-block;
        height: 100%;
        width: 100%;
        border-radius: 100px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        transition: all 0.4s;
        background-color: #fff;
      }

      &:hover::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
      }

      @keyframes moveInBottom {
        0% {
          opacity: 0;
          transform: translateY(30px);
        }

        100% {
          opacity: 1;
          transform: translateY(0px);
        }
      }
    `}
`;

export const LinkButton = styled.button<ButtonProps>`
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
  gap: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ disabled }) => (disabled ? "#ccc" : "#fff")};
  color: #777;
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}

  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: absolute;
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  &::after {
    content: "";
    display: inline-block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: all 0.4s;
    background-color: #fff;
  }

  &:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
  }

  @keyframes moveInBottom {
    0% {
      opacity: 0;
      transform: translateY(30px);
    }

    100% {
      opacity: 1;
      transform: translateY(0px);
    }
  }
`;

export const DropzoneContainer = styled.div`
  margin-bottom: 30px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 18px;
    color: var(--dark-gray);
  }
  span.deleted {
    text-decoration: line-through;
  }
  svg {
    cursor: pointer;
  }
`;
