export const customerSituations = {
  effected: "effected",
  implementation: "implementation",
  operational: "operational",
  training: "training",
  onboarding: "onboarding",
};

export const licenseesContractTypes = {
  annual: "annual",
  monthly: "monthly",
};

export const planTypes = {
  demo: "demo",
  advanced: "advanced",
  basic: "basic",
  old_signatures: "old_signatures",
};

export const orderTypes = {
  fixiter: "is_fixiter",
  partner: "is_partner",
  patient: "is_patient",
};

export const orderStatus = {
  processing: "processing",
  development: "development",
  review: "review",
  cancelled: "cancelled",
  completed: "completed",
};

export const orderSituation = {
  paid: "paid",
  not_paid: "not_paid",
  courtesy: "courtesy",
};

export const orderUrgencyTypes = {
  normal: "normal",
  requested: "requested",
  approved: "approved",
};

export const personTypes = {
  FISICA: "FISICA",
  JURIDICA: "JURIDICA",
};

export const addressTypes = {
  home: "home",
  work: "work",
  other: "outro",
};

export const profileTypes = {
  employee: "Account User",
  admin: "Account Manager",
};

export const roles = {
  licensee: "LICENSEE",
  creator: "CREATOR",
  admin: "ADMIN",
  saf: "SAF",
  fixiter: "FIXITER",
  employee: "EMPLOYEE",
};

export const videos = {
  fixit: "fixit",
  punhofix: "punhofix",
};

export const sides = {
  DIR: "DIR",
  ESQ: "ESQ",
  UNI: "UNI",
  // INT: "INT",
  // EXT: "EXT"
};

export const ALGO = {
  name: "AES-CTR",
  length: 128,
};
export const solutionTypes = {
  custom: "custom",
  standard: "standard",
  plan: "plan",
};

export const Slicers = {
  simplify: "Simplify3D",
  cura: "Cura",
  prusa: "PrusaSlicer",
};

export const SlicersOptions = [
  {
    optionText: "Simplify3D",
    value: "simplify"
  },
  {
    optionText: "Cura"
    , value: "cura"
  },
  {
    optionText: "PrusaSlicer",
    value: "prusa"
  },
];

export const SlicersVariables = {
  cura: {
    curaFilamentWheightPerMeter: 2.98507463,
    valuePerKilogramBR: 90,
    valuePerKilogramUS: 25,
  },
};

export const filamentConstants = {
  densityGramsPerMeter: 2.98507463,
  metersPerKilogram: 335,
};

export const cardFlags = {
  Visa: "Visa",
  Mastercard: "Mastercard",
  Amex: "Amex",
  DinersClub: "DinersClub",
  Discover: "Discover",
  JCB: "JCB",
};

export const brazilianStates = [
  { optionText: "Acre", value: "AC" },
  { optionText: "Alagoas", value: "AL" },
  { optionText: "Amapá", value: "AP" },
  { optionText: "Amazonas", value: "AM" },
  { optionText: "Bahia", value: "BA" },
  { optionText: "Ceará", value: "CE" },
  { optionText: "Distrito Federal", value: "DF" },
  { optionText: "Espírito Santo", value: "ES" },
  { optionText: "Goiás", value: "GO" },
  { optionText: "Maranhão", value: "MA" },
  { optionText: "Mato Grosso", value: "MT" },
  { optionText: "Mato Grosso do Sul", value: "MS" },
  { optionText: "Minas Gerais", value: "MG" },
  { optionText: "Pará", value: "PA" },
  { optionText: "Paraíba", value: "PB" },
  { optionText: "Paraná", value: "PR" },
  { optionText: "Pernambuco", value: "PE" },
  { optionText: "Piauí", value: "PI" },
  { optionText: "Rio de Janeiro", value: "RJ" },
  { optionText: "Rio Grande do Norte", value: "RN" },
  { optionText: "Rio Grande do Sul", value: "RS" },
  { optionText: "Rondônia", value: "RO" },
  { optionText: "Roraima", value: "RR" },
  { optionText: "Santa Catarina", value: "SC" },
  { optionText: "São Paulo", value: "SP" },
  { optionText: "Sergipe", value: "SE" },
  { optionText: "Tocantins", value: "TO" },
];

export const brazilianStatesLabel = {
  AC: "Acre",
  AL: "Alagoas",
  AP: "Amapá",
  AM: "Amazonas",
  BA: "Bahia",
  CE: "Ceará",
  DF: "Distrito Federal",
  ES: "Espírito Santo",
  GO: "Goiás",
  MA: "Maranhão",
  MT: "Mato Grosso",
  MS: "Mato Grosso do Sul",
  MG: "Minas Gerais",
  PA: "Pará",
  PB: "Paraíba",
  PR: "Paraná",
  PE: "Pernambuco",
  PI: "Piauí",
  RJ: "Rio de Janeiro",
  RN: "Rio Grande do Norte",
  RS: "Rio Grande do Sul",
  RO: "Rondônia",
  RR: "Roraima",
  SC: "Santa Catarina",
  SP: "São Paulo",
  SE: "Sergipe",
  TO: "Tocantins",
};

export const countries = {
  BRA: "BRA",
  default: "USA",
};
