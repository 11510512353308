import styled from "styled-components";

export const UpdatedAt = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 10px;
  color: #989898;
`;
