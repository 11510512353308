import { useEffect, useState } from "react";

import { i18n } from "../../localization/i18n";

import { StepFour } from "./components/StepFour";
import { StepOne } from "./components/StepOne";
import { StepThree } from "./components/StepThree";
import { StepTwo } from "./components/StepTwo";
import { Stepper } from "../../components/Stepper";
import { showToast } from "../../components/CustomToast";

import api from "../../services/api";

import { customerSituations, personTypes } from "../../utils/constants";

import { IAuthFormData, ILicenseeFormData, IPersonFormData } from "./dtos";

import { RiCloseFill } from "react-icons/ri";

import { Container, Header, StepperContainer } from "./styles";

interface NewLicenseeModalProps {
  closeModal: () => void;
  getLicensees: () => void;
}

export interface Plans {
  currency: string;
  id: string;
  is_active: boolean;
  link_image: string;
  description: any;
  orders_quantity: string;
  plan_name: string;
  price: string;
  solutions_quantity: number | null;
  trial_period: number | null;
}

export function NewLicenseeModal({
  closeModal,
  getLicensees,
}: NewLicenseeModalProps) {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [plans, setPlans] = useState<Plans[]>([]);
  const [authFormData, setAuthFormData] = useState<IAuthFormData>();
  const [personFormData, setPersonFormData] = useState<IPersonFormData>();
  const [licenseeFormData, setLicenseeFormData] = useState<ILicenseeFormData>();
  const [userId, setUserId] = useState("");
  const [personId, setPersonId] = useState("");
  const [licenseeId, setLicenseeId] = useState("");
  const [selectedLanguage] = useState(localStorage.getItem("i18nextLng"));

  const steps = [
    {
      label: i18n.t("userRegistration.stepOne"),
      component: (
        <StepOne
          formData={authFormData}
          setFormData={setAuthFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          loading={loading}
          createUser={handleCreateUser}
        />
      ),
    },
    {
      label: i18n.t("userRegistration.stepTwo"),
      component: (
        <StepTwo
          setFormData={setPersonFormData}
          formData={personFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          createPerson={handleCreatePerson}
        />
      ),
    },
    {
      label: i18n.t("userRegistration.stepThree"),
      component: (
        <StepThree
          setFormData={setLicenseeFormData}
          formData={licenseeFormData}
          nextStep={nextStep}
          prevStep={prevStep}
          createLicensee={handleCreateLicensee}
          loading={loading}
        />
      ),
    },
    {
      label: i18n.t("userRegistration.stepFour"),
      component: (
        <StepFour
          plans={plans}
          selectedLanguage={selectedLanguage ?? ""}
          asigneePlan={handleAssigneePlanAndPerson}
          updateLicenseeList={getLicensees}
          closeModal={closeModal}
        />
      ),
    },
  ];

  async function getPlans() {
    try {
      const response = await api.get("/plans");
      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function handleCreateUser() {
    try {
      setLoading(true);

      const body = {
        email: authFormData?.email,
        password: authFormData?.password,
        role: authFormData?.role?.value,
      };

      const response = await api.post("/users", body);

      setUserId(response.data.id);
      nextStep();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleCreatePerson() {
    setLoading(true);
    try {
      const body = {
        user: {
          id: userId,
        },
        name: personFormData?.name ?? "",
        cpf: personFormData?.cpf ?? "",
        country: personFormData?.country.id ?? "",
        cellphone: personFormData?.cellphone ?? "",
        state: personFormData?.state ?? "",
        city: personFormData?.city ?? "",
        expertise: personFormData?.expertise ?? "",
        professional_number: personFormData?.professional_number ?? "",
        instagram: personFormData?.instagram ?? "",
      };

      const response = await api.post("/person", body);

      setPersonId(response.data.id);
      nextStep();
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.errorCreatePerson"),
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleCreateLicensee(contiuneAsNatural: boolean) {
    setLoading(true);
    try {
      const body = {
        person: {
          id: personId,
        },
        printers: [],
        email: contiuneAsNatural
          ? authFormData?.email
          : licenseeFormData?.email,
        instagram: contiuneAsNatural
          ? personFormData?.instagram
          : licenseeFormData?.instagram,
        country: contiuneAsNatural
          ? personFormData?.country?.id
          : licenseeFormData?.country?.id,
        phone: contiuneAsNatural
          ? personFormData?.cellphone
          : licenseeFormData?.phone ?? "",
        document_number: contiuneAsNatural
          ? personFormData?.cpf
          : licenseeFormData?.cnpj,
        situation: customerSituations.onboarding,
        city: contiuneAsNatural ? personFormData?.city : licenseeFormData?.city,
        state: contiuneAsNatural
          ? personFormData?.state
          : licenseeFormData?.state,
        name: contiuneAsNatural
          ? personFormData?.name
          : licenseeFormData?.fantasyName,
        manager_name: personFormData?.name,
        companyName: contiuneAsNatural
          ? personFormData?.name
          : licenseeFormData?.companyName,
        contract: "monthly",
        person_type: contiuneAsNatural
          ? personTypes.FISICA
          : personTypes.JURIDICA,
        is_public: true,
        is_test: licenseeFormData?.is_test,
      };

      const response = await api.post("/licensees", body);

      setLicenseeId(response.data.id);
      nextStep();
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.errorCreatePerson"),
      });
    } finally {
      setLoading(false);
    }
  }

  async function handleAssigneePlanAndPerson(planId: string) {
    setLoading(true);
    try {
      const body = {
        licensee_id: licenseeId,
        plan_id: planId,
      };

      await api.post("/signatures", body);

      closeModal();
      setActiveStep(1);
      getLicensees();
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("userRegistration.errorAsigneePlan"),
      });
    } finally {
      setLoading(false);
    }
  }

  function nextStep() {
    if (activeStep === 4) return;
    setActiveStep((prevstate) => prevstate + 1);
  }

  function prevStep() {
    if (activeStep === 1) return;
    setActiveStep((prevstate) => prevstate - 1);
  }

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Container>
      <Header>
        <span className="title">Novo licenciado</span>
        {activeStep !== 4 ? (
          <RiCloseFill
            size={24}
            color="var(--fixit)"
            onClick={() => {
              closeModal();
            }}
          />
        ) : (
          <></>
        )}
      </Header>

      <StepperContainer>
        <Stepper activeStep={activeStep} steps={steps} />
      </StepperContainer>
    </Container>
  );
}
