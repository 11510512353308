import styled from "styled-components";

type ContaierProps = {
  loaderSize: number;
};

export const Container = styled.div<ContaierProps>`
  .loader {
    border: 4px solid var(--light-gray);
    border-top: 4px solid var(--fixit);
    border-radius: 50%;
    width: ${({ loaderSize }) => loaderSize}px;
    height: ${({ loaderSize }) => loaderSize}px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
