import { useEffect, useState } from "react";
import { RiSaveLine } from "react-icons/ri";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { i18n } from "../../../../localization/i18n";
import { ISolution } from "../../dtos";
import { Container, Header, LinkButton, Subtitle, SwitchFormGroup, SwitchFormControlLabel, Title, TitleContainer, FixitSwitch, Content, InputLine, InputContainer } from "./styles";

interface IBBoxCustomSolutionParams {
    solution: ISolution;
    uploadFase?: string;
    submit: (enable: boolean, number_measures: number, link_guide: string) => void;
    close: () => void;
}

interface ISolutionCustomData {
  number_measures: number;
  link_guide?: string;
}

export function BoxCustomSolution ({ solution, uploadFase, submit, close } : IBBoxCustomSolutionParams) {
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    const [enable, setEnable] = useState(solution.is_custom);
    const [solutionCustomData, setSolutionCustomData] = useState<ISolutionCustomData>()

    useEffect(() => {
        setEnable(solution.is_custom)
    }, [solution.is_custom])

    useEffect(() => {
      if (solution) {
        setSolutionCustomData({
          number_measures: solution.number_measures ?? 0,
          link_guide: solution.link_guide
        })
      }
    }, [ solution ])

    return (
        <>
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>{`${i18n.t('solutions.custom_update_title')}`}</Title>
                        <Subtitle>{`${i18n.t('solutions.custom_update_subtitle')}`}</Subtitle>
                    </TitleContainer>
                    <LinkButton disabled={!!uploadFase || (enable === solution.is_custom && 
                            (solution.number_measures ?? 0) === solutionCustomData?.number_measures && 
                            solution.link_guide === solutionCustomData?.link_guide)} onClick={() => {
                            submit(enable, solutionCustomData?.number_measures ?? 0, solutionCustomData?.link_guide ?? "");
                        }} >
                        <RiSaveLine size={16} />
                        {`${i18n.t('orders.actions.save')}`}
                    </LinkButton>
                    
                </Header>
                <SwitchFormGroup>
                    <SwitchFormControlLabel labelPlacement="start" control={
                        <FixitSwitch checked={enable} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEnable(event.target.checked)
                        }} />
                        } label={`${i18n.t('solutions.download_file_enable')}`} />
                </SwitchFormGroup>
                {
                   <Content>
                    <InputLine width="320px" wrap={true}>
                        <InputContainer width="70px">
                            <OutlinedInput
                                inputName="number_measures"
                                type="number"
                                min={0}
                                label={`${i18n.t("solutions.number_measures")}`}
                                handleChange={(event) => {
                                    setSolutionCustomData({
                                        link_guide: solutionCustomData?.link_guide,
                                        number_measures: Number(event)
                                    })
                                }}
                                value={solutionCustomData?.number_measures?.toString() ?? "0"}
                            />
                        </InputContainer>
                    </InputLine>
                    <InputLine width="320px" wrap={true}>
                        <InputContainer>
                            <OutlinedInput
                            inputName="link_guide"
                            textArea={true}
                            value={solutionCustomData?.link_guide ?? ""}
                            label={`${i18n.t("solutions.link_guide")}`}
                            handleChange={(event) => {
                              setSolutionCustomData({
                                link_guide: event,
                                number_measures: solutionCustomData?.number_measures ?? 0,
                              });
                            }}
                          />
                        </InputContainer>
                    </InputLine>
                </Content>
                }
                
            </Container>
        </>
    )
}