import { useRoutes } from "react-router-dom";
import Layout from "../../components/Layout";
import Profile from "../../modules/Profile";
import { Orders } from "../../modules/Orders";
import Success from "../../modules/licensees/pages/Success";
import Payment from "../../modules/licensees/pages/Payment";
import Problem from "../../modules/licensees/pages/Problem";
import Evaluation from "../../modules/licensees/pages/Evaluation";
import LayoutIframe from "../../components/LayoutIframe";
import { InDevelopment } from "../../modules/InDevelopment";
import { CreateOrder } from "../../modules/Orders/CreateOrder";
import { useAuth } from "../../hooks/AuthContext";
import { SolutionsCategory } from "../../modules/Fixiter/pages/SolutionsCategory";
import BracingTypes from "../../modules/licensees/pages/BracingTypes";
import Solutions from "../../modules/Orders/Solutions";
import Address from "../../modules/Fixiter/pages/Address";
import Users from "../../modules/Fixiter/pages/UsersList";
import { ProfileOptions } from "../../modules/licensees/pages/ProfileOptions";
import UsersProfile from "../../components/UsersProfile";
import NotFound from "./NotFound";
import { Transactions } from "../../modules/licensees/pages/Transactions";
import { NewDashboard } from "../../modules/licensees/pages/NewDashboard";
import { useOnboard } from "../../hooks/OnboardContext";
import PlanExpired from "../../modules/Fixiter/pages/PlanExpired";
import Logon from "../../modules/Logon";

function LicenseesRoutes() {
  const { user } = useAuth();
  const { planHasExpired } = useOnboard();

  const licenseBlockedRoutes = useRoutes([
    { path: "/", element: <Logon /> },
    {
      path: "/payment",
      element: (
        <LayoutIframe>
          <Payment />
        </LayoutIframe>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <LayoutIframe>
          <PlanExpired />
        </LayoutIframe>
      ),
    },
  ]);

  const licenseeRoutes = useRoutes([
    {
      path: "/solutions",
      element: (
        <Layout>
          <SolutionsCategory />
        </Layout>
      ),
    },
    {
      path: "/categories/:field_key",
      element: (
        <Layout>
          <BracingTypes />
        </Layout>
      ),
    },
    {
      path: "/solutions/:category_key",
      element: (
        <Layout>
          <Solutions />
        </Layout>
      ),
    },
    {
      path: "/order/:solution_key/:solution_type",
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
      ),
    },
    {
      path: "/order/:solution_key/:solution_type/:size",
      element: (
        <Layout>
          <CreateOrder />
        </Layout>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <Layout>
          <NewDashboard />
        </Layout>
      ),
    },
    {
      path: "/profile",
      element: (
        <Layout>
          <Profile />
        </Layout>
      ),
    },
    {
      path: "/orders",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
    {
      path: "/orders/:as_provider",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
    {
      path: "/licensee/orders/:closure",
      element: (
        <Layout>
          <Orders />
        </Layout>
      ),
    },
    {
      path: "/problem",
      element: (
        <Layout>
          <Problem />
        </Layout>
      ),
    },
    {
      path: "/success",
      element: (
        <Layout noPadding>
          <Success />
        </Layout>
      ),
    },
    {
      path: "/evaluation/:order_id",
      element: (
        <Layout>
          <Evaluation />
        </Layout>
      ),
    },
    {
      path: "/licensee/address",
      element: (
        <Layout>
          <Address />
        </Layout>
      ),
    },
    {
      path: "/licensee/my-users",
      element: (
        <Layout>
          <Users />
        </Layout>
      ),
    },
    {
      path: "/licensee/profile",
      element: (
        <Layout>
          <ProfileOptions />
        </Layout>
      ),
    },
    {
      path: "/licensee/transactions",
      element: (
        <Layout>
          <Transactions />
        </Layout>
      ),
    },
    {
      path: "/development",
      element: (
        <Layout>
          <InDevelopment />
        </Layout>
      ),
    },
    {
      path: "/licensee/profile/:options",
      element: (
        <Layout>
          <UsersProfile />
        </Layout>
      ),
    },
    { path: "/development", element: <NotFound /> },
  ]);

  if (user?.person?.licensee?.is_blocked === true || planHasExpired === true) {
    return licenseBlockedRoutes;
  }

  return licenseeRoutes;
}

export default LicenseesRoutes;
