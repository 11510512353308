import styled from "styled-components";
import global from "../../../../global";
import dropdownArrowDown from "../../../../assets/dropdown-arrow-down.svg";

interface InputProps {
  readonly inputWidth: string;
}

interface ActvieIndexProps {
  active: boolean;
}

export const Container = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.section`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: flex;
    flex-direction: column;
    height: inherit;
  }
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin-bottom: 10px;
  }
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: row;
  color: var(--dark-gray);
  font-size: 18px;
  margin-bottom: 8px;
`;

export const InputContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;

  padding-right: 12px;

  position: relative;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-bottom: 14px;
    padding: 0;
    width: 100%;
  }
`;

export const Input = styled.input<InputProps>`
  height: 40px;
  width: ${(props) => props.inputWidth};

  padding-left: 12px;

  border: 1px solid var(--light-gray);
  border-radius: 6px;

  font-size: 20px;
  color: var(--dark-gray);

  ::placeholder {
    color: var(--light-gray);
    @media (max-width: ${global.responsive.small.width.value}${global.responsive
        .small.width.unit}) {
      padding-left: 0px;
    }
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
  }
`;

export const SideSelectContainer = styled.div`
  position: relative;

  width: 150px;
  padding-right: 12px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-bottom: 14px;
    padding: 0;
    width: 100%;
  }
`;

export const CheckboxFlex = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 12px;
  gap: 8px;
`;

export const Checkbox = styled.input`
  width: 18px;
  height: 18px;
  margin-right: 6px;
`;

export const FlexButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
`;

export const Submit = styled.button`
  font-size: 16px;
  background-color: var(--fixit);
  color: var(--white);

  border-radius: 6px;
  width: 100px;
  height: 45px;

  cursor: pointer;

  transition: background-color 0.2s;

  :hover {
    background-color: var(--gray);
  }
`;

export const Cancel = styled.button`
  width: 100px;
  height: 45px;
  background: transparent;

  font-size: 16px;
  color: var(--dark-gray);

  margin-right: 8px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;

  cursor: pointer;

  transition: border 0.2s;

  :hover {
    border: 1px solid var(--gray);
  }
`;

export const UploadFilesContainer = styled.div`
  margin-top: 30px;

  label {
    font-size: 18px;
    color: var(--dark-gray);
  }
`;

export const DropzoneContainer = styled.div`
  margin-top: 8px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 18px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;
