import { RiDownloadCloud2Line, RiLineChartLine, RiMenuLine, RiUserStarFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { i18n } from "../../../localization/i18n";
import { LinkItem, LinkTitle, RouteButton } from "../styles";

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const EmployeeMenuList = ({ toggleMenu }: MenuListProps) => (
  <>
    <LinkItem onClick={toggleMenu}>
      <Link to="/employee/dashboard">
        <RouteButton>
          <RiLineChartLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.reports")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem>
        <Link to="/employee/solutions" onClick={toggleMenu}>
          {" "}
          {/* Go to Solutions */}
          <RouteButton>
            <RiDownloadCloud2Line size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>{`${i18n.t("navMenu.solutions")}`}</LinkTitle>
        </Link>
      </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/employee/orders">
        <RouteButton>
          <RiMenuLine size={26} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.requests")}`}</LinkTitle>
      </Link>
    </LinkItem>
  </>
);
