import styled from "styled-components";
import global from "../../../../../../global";

type ButtonProps = {
  disabled?: boolean;
  providerSelected?: boolean;
};

type PriceInfosProps = {
  type: "income" | "outcome";
};

export const Container = styled.div`
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  transition: all 0.3s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    padding: 16px 20px 16px 22px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const IdendityInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;

  span {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  padding: 3px;
  border-radius: 50%;

  &.income {
    background-color: var(--finished);
  }

  &.outcome {
    background-color: var(--error);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    filter: invert(100%) sepia(0%) saturate(7455%) hue-rotate(293deg)
      brightness(108%) contrast(106%);
  }
`;

export const PrincipalInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  min-width: 140px;

  .identity {
    font-size: 12px;
    line-height: 12px;
    color: var(--gray);
    font-weight: bolder;
  }

  .date {
    font-size: 10px;
    line-height: 10px;
    color: var(--gray);
    font-weight: 500;
    margin-top: 5px;
  }

  .solution {
    font-size: 14px;
    line-height: 14px;
    color: var(--active-green);
    font-weight: bolder;
  }

  &.income {
    .identity {
      color: var(--finished);
    }

    .solution {
      color: var(--finished);
    }
  }

  &.outcome {
    .identity {
      color: var(--error);
    }

    .solution {
      color: var(--error);
    }
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    min-width: 100px;
    max-width: 100px;
  }
`;

export const PriceInfos = styled.div<PriceInfosProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  min-width: 50px;

  .price_name {
    font-size: 10px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .price_location {
    font-size: 14px;
    line-height: 10px;
    color: ${({ type }) =>
      type === "outcome" ? "var(--error)" : "var(--active-green)"};
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const QuantityInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 30px;

  .quanitity_name {
    font-size: 10px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .quanitity_value {
    font-size: 14px;
    line-height: 10px;
    color: var(--active-green);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const StatusDesktopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.order_type {
    min-width: 100px;
    max-width: 100px;
  }

  &.solution_type {
    min-width: 100px;
    max-width: 100px;
  }

  &.licensee {
    min-width: 120px;
    max-width: 120px;
  }

  &.patient {
    min-width: 100px;
    max-width: 100px;
  }

  .data_label {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .data_value {
    font-size: 14px;
    line-height: 14px;
    color: var(--gray);
    margin-top: 2px;
    font-weight: bolder;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    &.desktop {
      display: none;
    }

    .data_label {
      font-size: 10px;
      line-height: 12px;
      color: var(--gray);
    }

    .data_value {
      margin-top: 2px;
    }
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    gap: 15px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }
`;

export const ActionItem = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    &.print {
      display: none;
    }

    &.download {
      display: none;
    }
  }
`;

export const IconButtonContainer = styled.div<ButtonProps>`
  position: relative;
  display: flex;

  svg {
    color: ${(props) => (props.disabled ? "var(--light-gray)" : "var(--gray)")};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;

export const ProvisionerContainer = styled.div<ButtonProps>`
  position: relative;
  display: flex;

  svg {
    color: ${(props) =>
      props.providerSelected ? "var(--fixit)" : "var(--gray)"};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;

export const ContentContainer = styled.div`
  margin-top: 20px;
`;

export const BoxFileList = styled.div`
  position: absolute;
  right: 0px;
  top: 30px;
  width: max-content;

  border: 1px solid var(--light-gray);
  border-radius: 10px;

  padding: 10px 0px 5px 0;

  background-color: var(--white);
  box-shadow: 4px 3px 8px var(--gray);

  list-style-type: none;
  z-index: 5;
`;

export const Item = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-decoration: none;

  align-items: end;

  font-size: 14px;
  padding: 10px 10px 10px 20px;
  margin: 5px;

  border-radius: 10px;

  cursor: pointer;

  transition: background-color 0.5s;

  svg {
    color: var(--fixit);
    margin-left: 2px;
  }

  :hover {
    background-color: var(--fixit);
    transition: background-color 0.5s;
    p,
    svg {
      color: var(--white);
    }
  }
`;

export const ItemText = styled.p`
  margin-right: 5px;

  color: var(--dark-gray);

  user-select: none;
`;

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
`;
