import styled from "styled-components";

export const Container = styled.div`
  height: calc(100vh - 90px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  img {
    width: 600px;
    height: 350px;
  }

  @media (max-width: 650px) {
    height: auto;

    img {
      width: 350px;
      height: 250px;
    }
  }
`;

export const PageTitle = styled.h2`
  font-size: 28px;
  color: var(--fixit);

  @media (max-width: 650px) {
    font-size: 22px;
  }
`;
