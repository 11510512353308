import styled, { css } from "styled-components";

type InputProps = {
  error?: string;
}

export const Container = styled.form`
  width: fit-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-top: 20px;

  @media (max-width: 530px) {
    width: 100%;
  }
`;

export const StepTitle = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-gray);
  width: 100%;
`;

export const StepSubtitle = styled.span`
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-gray);
  margin-bottom: 10px;
`;

export const RegisterForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding-bottom: 20px;
`;

export const InputLine = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const NotRobotLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const InputContainer = styled.div`
  width: 400px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CodeInputContainer = styled.div`
  min-width: 40px;
  width: 40px;
`;

export const CodeInput = styled.input<InputProps>`
  min-width: 45px;
  width: 45px;
  height: 46px;
  border: 2px solid #989898;
  border-radius: 10px;
  background: var(--white);
  font-size: 16px;
  text-align: center;
  color: var(--dark-gray);

  transition: all 0.4s ease;

  &::placeholder {
    color: var(--light-gray);
  }

  &:focus {
    border: 2px solid var(--fixit);
  }

  ${({ error }) => error && css`
  border: 2px solid var(--formError);
  `}
`;

export const SendEmailText = styled.span`
  margin-top: 6px;
  align-self: flex-end;
  font-size: 12px;
  text-decoration: underline;
  color: var(--fixit);
  cursor: pointer;
`;

export const Footer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;

    button {
      flex: 1;
    }
  }
`;
