import { useState } from "react";
import { MdOutlinePersonPinCircle } from "react-icons/md";
import { RiEyeLine, RiPrinterLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Modal } from "../../../components/Modal";
import Popover from "../../../components/Popover";
import { i18n } from "../../../localization/i18n";
import {
  ActionItem,
  ActionsContainer,
  BoxFileList,
  IconButtonContainer,
  Item,
  ItemText,
  Overlay,
  ProvisionerContainer,
} from "../components/OrderItem/styles";
import { RowFarContainer } from "../components/ProvisionerList/styles";
import { IOrder } from "../dtos";
import { FixiterActionsText, FixiterCardName } from "../styles";

interface ActionsProps {
  order: IOrder;
  printOrder: (order: any) => void;
  setIsPrintOptionDropdownOpen: (value: React.SetStateAction<boolean>) => void;
  isPrintOptionDropdownOpen: boolean;
}

export const FixiterActions = ({
  order,
  printOrder,
  setIsPrintOptionDropdownOpen,
  isPrintOptionDropdownOpen,
}: ActionsProps) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  function toggleModal() {
    setIsModalOpen(!isModalOpen);
  }

  return (
    <>
      {order.provider && (
        <Modal open={isModalOpen} onRequestClose={toggleModal}>
          <FixiterActionsText>Dados do Provedor</FixiterActionsText>
          <RowFarContainer>
            <FixiterCardName>
              {" "}
              Cidade:
              <label>{order?.provider.city}</label>
            </FixiterCardName>
            <FixiterCardName>
              {" "}
              Estado:
              <label>{order?.provider.state}</label>
            </FixiterCardName>
          </RowFarContainer>
          <FixiterCardName>
            {" "}
            Responsável:
            <label>{order?.provider?.manager_name}</label>
          </FixiterCardName>
          <FixiterCardName>
            {" "}
            Nome-Fantasia:
            <label>{order?.provider?.name}</label>
          </FixiterCardName>
        </Modal>
      )}
      <ActionsContainer>
        {(order.solution_type === 'standard') && (
          <ActionItem className="print">
            <Popover position="left" label={`${i18n.t('orders.print')}`}>
              <IconButtonContainer disabled={order.status !== i18n.t("orders.status.completed.value")} >
                <RiPrinterLine size={18} onClick={() => {
                  order.status === i18n.t("orders.status.completed.value") &&
                    setIsPrintOptionDropdownOpen(true)
                }} />
                {isPrintOptionDropdownOpen &&
                  (
                    <>
                      <BoxFileList>
                        {
                          <Item onClick={() => {
                            setIsPrintOptionDropdownOpen(false)
                            printOrder(order)
                          }}>
                            <ItemText>{`${i18n.t('orders.printChoose.header')}`}</ItemText>
                            <RiPrinterLine size={18} />
                          </Item>
                        }
                        {/* <Item onClick={() => {            
                            setIsPrintOptionDropdownOpen(false)
                            openPrintHistory({
                                orderId: order.id,
                                quantity: order.quantity
                            })
                        }}>
                            <ItemText>{`${i18n.t('orders.printHistory')}`}</ItemText>
                            <RiHistoryLine size={18} />
                        </Item> */}
                      </BoxFileList>
                      <Overlay onClick={() => {
                        setIsPrintOptionDropdownOpen(false)
                      }} />
                    </>
                  )}
              </IconButtonContainer>
            </Popover>
          </ActionItem>
        )}
        <ActionItem>
          <Popover position="left" label={`${i18n.t("orders.view")}`}>
            <IconButtonContainer>
              <RiEyeLine
                size={18}
                onClick={() => {
                  navigate({
                    pathname: `/fixiter/evaluation/${order.id}`,
                  });
                }}
              />
            </IconButtonContainer>
          </Popover>
        </ActionItem>
        <ActionItem>
          <Popover position="left" label={"FixPoint"}>
            <ProvisionerContainer providerSelected={!!order.provider}>
              <MdOutlinePersonPinCircle
                color={`${!order.provider ? "var(--light-gray)" : "var(--fixit)"}`}
                size={18}
                onClick={() => (!!order.provider ? toggleModal() : {})}
              />
            </ProvisionerContainer>
          </Popover>
        </ActionItem>
      </ActionsContainer>
    </>
  );
};
