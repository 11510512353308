import { InputHTMLAttributes, useEffect } from "react";

import { Container, Error, Label, ExtraInfo } from "./styles";
import { Checkbox } from "../Checkbox";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  handleChange: (event: any) => any;
  onDisabled?: () => any;
  label: string;
  extralabel?: string;
  checked: boolean;
  error?: string;
  marginOnError?: boolean;
  mask?: string;
  streched?: boolean;
  disabled?: boolean;
  inactiveBgColor?: string;
  activeBgColor?: string;
}

export function OutlinedCheckbox({
  label,
  extralabel,
  streched,
  checked,
  handleChange,
  error,
  disabled = false,
  marginOnError = true,
  onDisabled = () => {},
  inactiveBgColor = "var(--white)",
  activeBgColor = "var(--fixit)",
  ...rest
}: InputProps) {

  useEffect(() => {
    if (disabled) {
      onDisabled();
    }
  }, [disabled]);

  return (
    <Container
      inactiveBgColor={inactiveBgColor}
      activeBgColor={activeBgColor}
      streched={streched}
      checked={checked}
      onClick={(event) => {
        if (disabled) {
          return;
        }
        handleChange(event)
      }}
    >
      <Checkbox {...rest}
        checked={checked}
        handleChange={() => {}}
      />
      <Label>
        <span>{label}</span>
        {
          extralabel && (
            <ExtraInfo>{extralabel}</ExtraInfo>
          )
        }
      </Label>
      {error && <Error>{error}</Error>}
    </Container>
  );
}
