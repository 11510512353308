import { Container } from "./styles";

import { Box, Skeleton, Stack } from "@mui/material";

export function DiagnosisSkeleton() {
  return (
    <Container>
      <Stack direction="row" alignItems="center">
        <Skeleton variant="circular" width={50} height={50} />
        <Skeleton variant="text" width={170} sx={{ marginLeft: "20px" }} />

        <Box component="div" sx={{ marginLeft: "50px" }}>
          <Skeleton variant="text" width={40} />
          <Skeleton variant="text" width={70} />
        </Box>
      </Stack>

      <Box component="div">
        <Skeleton variant="rectangular" width={8} height={30} />
      </Box>
    </Container>
  );
}
