import styled from "styled-components"

interface IContainerProps {
  width?: string
  height?: string
}

interface IContentProps {
  clickCss: string
  position?: "top" |
    "bottom" |
    "left" |
    "right"
  triangle?: boolean
  clickable?: boolean
  offset?: number
}

export const Container = styled.div<IContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => props.width &&
    `
      width: ${props.width};
    `
  }

  ${props => props.height &&
    `
      height: ${props.height};
    `
  }
`

export const Content = styled.div<IContentProps>`
  position: absolute;
  padding: 10px;
  background: var(--white);
  border-radius: 6px;
  border: 1px solid var(--light-gray);

  color: var(--dark-gray);
  font-weight: bold;
  min-width: 120px;

  transition: opacity 300ms, margin 300ms ease-out;
  visibility: hidden;
  opacity: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 10;

  ::before {
    content: "";
    background-color: var(--white);
    position: absolute;
    width: 10px;
    height: 10px;
    border-top: 1px solid var(--light-gray);
    border-left: 1px solid var(--light-gray);
  }

  ${Container}:hover & {
    transition-delay: 200ms;
    visibility: visible;
    opacity: 1;
    margin: initial;
  }

  ${props => props.clickable &&
    `
      display: none;
    `
  }

  ${props => props.position === "right" &&
    `
      left: 150%;
      margin-left: -20px;

      ::before {
        left: 0;
        transform: translateX(-50%) rotate(-45deg);
      }

      transform: translateY(${props.offset}px);
    `
  }

  ${props => props.position === "left" &&
    `
      right: 150%;
      margin-right: -20px;

      ::before {
        right: 0;
        transform: translateX(50%) rotate(135deg);
      }
    `
  }

  ${props => props.position === "top" &&
    `
      bottom: 150%;
      margin-bottom: -20px;

      ::before {
        bottom: 0;
        transform: translateY(50%) rotate(-135deg);
      }
    `
  }

  ${props => props.position === "bottom" &&
    `
      top: 150%;
      margin-top: -20px;

      ::before {
        top: 0;
        transform: translateY(-50%) rotate(45deg);
      }
    `
  }

  ${props => props.triangle === false &&
    `
      ::before {
        content: initial;
      }
    `
  }


  ${props => props.clickCss}
`
