import { ReactNode } from "react";

import {
  Container,
  StepsContainer,
  Step,
  StepConnector,
  ComponentContainer,
} from "./styles";

interface step {
  label: string;
  component: ReactNode;
}

interface StepperProps {
  steps: step[];
  activeStep: number;
}

export function Stepper({ steps, activeStep }: StepperProps) {
  return (
    <Container>
      <StepsContainer>
        {steps?.map((step, index) => {
          const lastStep = index === steps.length - 1;

          return (
            <>
              <Step filled={activeStep >= index + 1} key={index}>
                <span className="step_number">{index + 1}</span>
                <span className="step_label">{step.label}</span>
              </Step>

              {!lastStep && <StepConnector key={step.label} />}
            </>
          );
        })}
      </StepsContainer>

      <ComponentContainer>
        {steps[activeStep - 1]?.component}
      </ComponentContainer>
    </Container>
  );
}
