import CreatorsRoutes from "./pages/creators.routes";
import LicenseesRoutes from "./pages/licensees.routes";
import AdminRoutes from "./pages/admin.routes";
import SafRoutes from "./pages/saf.routes";
import FixiterRoutes from "./pages/fixiter.routes";
import OpenPagesRoutes from "./pages/openPages.routes";
import EmployeeRoutes from "./pages/employee.routes";
import { Fragment } from "react";
import AuthGuard from "./AuthGuard";

function Router() {
  return (
    <Fragment>
      <OpenPagesRoutes />
      <AuthGuard>
        <Fragment>
          <AdminRoutes />
          <CreatorsRoutes />
          <FixiterRoutes />
          <LicenseesRoutes />
          <SafRoutes />
          <EmployeeRoutes />
        </Fragment>
      </AuthGuard>
    </Fragment>
  );
}

export default Router;
