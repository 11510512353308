import { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

interface SolidButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  loading?: boolean;
  startWithMinWidth?: boolean;
  fullWidth?: boolean;
  buttonWidth?: number;
  buttonHeight?: number;
  buttonColor?: string;
  buttonHoverColor?: string;
  fontSize?: number;
}

export function SolidButton({
  text,
  loading = false,
  buttonWidth = undefined,
  buttonHeight = undefined,
  startWithMinWidth = false,
  fullWidth = false,
  buttonColor = "var(--fixit)",
  buttonHoverColor = "var(--fixit-hover)",
  fontSize = 16,
  ...rest
}: SolidButtonProps) {
  return (
    <Container
      startWithMinWidth={startWithMinWidth}
      buttonHeight={buttonHeight}
      buttonWidth={buttonWidth}
      buttonColor={buttonColor}
      buttonHoverColor={buttonHoverColor}
      fullWidth={fullWidth}
      fontSize={fontSize}
      {...rest}
    >
      {loading ? <div className="loading" /> : <>{text}</>}
    </Container>
  );
}
