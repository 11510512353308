import styled, { css } from "styled-components";

interface ContainerProps {
  is_loading: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  display: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: rgba(215, 192, 228, 0.56);
  transition: visibility 225ms;
  visibility: hidden;
  opacity: 0;
  font-size: 14px;

  img {
    width: 100px;
    height: 100px;
    position: relative;
  }

  ${({ is_loading }) =>
    is_loading &&
    css`
      visibility: visible;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        animation: loader 2s infinite linear;
      }
    `}

  @keyframes loader {
    0% {
      transform: rotateZ(0deg);
    }
    50% {
      transform: rotateZ(180deg);
    }
    100% {
      transform: rotateZ(0deg);
    }
  }
`;

export const LoadingText = styled.div`
  position: relative;
  margin-top: 20px;
`;

export const Dots = styled.h2`
  position: absolute;
  color: var(--fixit);
  text-shadow: 1px 2px 2px #706579;
  left: 100%;
  top: 0;
  margin-left: 5px;
`;

export const Text = styled.h2`
  color: var(--fixit);
  text-shadow: 1px 2px 2px #706579;
`;