import { useEffect, useState } from "react";
import { format } from "date-fns";

import { i18n } from "../../../../localization/i18n";

import {
  Container,
  EvaluationWrapper,
  Section,
  Field,
  FlexButtons,
  Header,
  TagsContainer,
  Tag,
  FileContainer,
  File,
} from "./styles";
import { useParams } from "react-router-dom";
import api from "../../../../services/api";
import { RiDeleteBin7Line } from "react-icons/ri";
import { useAuth } from "../../../../hooks/AuthContext";
import { showToast } from "../../../../components/CustomToast";
import {
  getActiveLanguage,
  I18NTextLanguageObject,
} from "../../../../utils/getActiveLanguage";
import { orderUrgencyTypes } from "../../../../utils/constants";

export interface IEvaluation {
  order_id: string;
}

export interface IOrder {
  id: string;
  status: string;
  category: string;
  incremental_id: number;
  notes: string;
  urgency_type: string;
  urgency_reason: string | null;
  exam: {
    patient_name: string;
    age: string;
    months: string;
    weight: string;
    height: string;
    phone: string;
    email: string;
    professional_name: string;
    professional_job: string;
    diagnosis?: string;
    diagnosis_list: {
      name: I18NTextLanguageObject;
      cid: string;
      description: I18NTextLanguageObject;
    }[];
    prior_use: boolean;
    any_indication: boolean;
    side: string;
    measures: string[];
    note: string;
  };
  patient: {
    name: string;
    weight: string;
    height: string;
    age: number;
    months: number;
    phone: string;
    email: string;
  };
  partner: {
    name: string;
  };
  planit_id: string;
  planit: {
    patient_name: string;
    age: string;
    months: string;
    weight: string;
    height: string;
    phone: string;
    email: string;
    doctor_name: string;
    doctor_contact: string;
    body_part: string;
    target: string;
    injury_type: string;
    surgery_date: string;
    files_links: string[];
    any_indication: boolean;
    responsible_name: string;
    note: string;
  };
  solution: {
    name: string;
  };
  user: {
    name: string;
  };
  printer: {
    name: string;
  };
}

export default function Evaluation() {
  const { setNavigationAction } = useAuth();
  let { order_id } = useParams<keyof IEvaluation>();

  const [order, setOrder] = useState<IOrder>({} as IOrder);

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy");
  }

  const deletePlanitFile = async (planit_id: string, file: string) => {
    try {
      await api.delete(`/planits/${planit_id}/files?file=${encodeURI(file)}`)
      getOrder(order_id)
    } catch (error) {
      showToast({ type: "error", message: `${error}` });
    }
  }

  function formatDiagnosis() {
    const diagnosis: any[] = [];

    order?.exam?.diagnosis_list?.forEach((item) => {
      diagnosis.push({
        name: `${item.name[getActiveLanguage()]} - ${item.cid}`,
        description: item.description[getActiveLanguage()],
      });
    });

    if (order.exam?.diagnosis) {
      diagnosis.push({
        name: order.exam?.diagnosis,
      });
    }

    return diagnosis;
  }

  const mock = [
    {
      title: i18n.t('orders.patient'),
      fields: [
        {
          name: i18n.t('evaluation.name'),
          data: order.patient
            ? order.patient.name
            : order.exam
              ? order.exam.patient_name
              : order.planit?.patient_name,
        },
        {
          name: i18n.t('evaluation.age'),
          data: order.patient
            ? order.patient.age
            : order.exam
              ? order.exam.age
              : order.planit?.age,
        },
        {
          name: i18n.t('evaluation.months'),
          data: order.patient
            ? order.patient.months
            : order.exam
              ? order.exam.months
              : order.planit?.months,
        },
        {
          name: i18n.t('evaluation.weight'),
          data: `${order.patient
            ? order.patient.weight
            : order.exam
              ? order.exam.weight
              : order.planit?.weight
            }kg`,
        },
        {
          name: i18n.t('evaluation.height'),
          data: `${order.patient
            ? order.patient.height
            : order.exam
              ? order.exam.height
              : order.planit?.height
            }cm`,
        },
        {
          name: i18n.t('profile.phone'),
          data: `${order.patient?.phone ?? i18n.t('orders.patientNotSet')}`,
        },
        {
          name: i18n.t('profile.email'),
          data: `${order.patient?.email ?? i18n.t('orders.patientNotSet')}`,
        }
      ],
    },
    order.exam
      ? {
        title: i18n.t('evaluation.evaluation'),
        fields: [
          {
            name: i18n.t('evaluation.diagnosis'),
            data: formatDiagnosis(),
          },
          {
            name: i18n.t('evaluation.previousOrthesis'),
            data:
              order.exam &&
              order.exam.prior_use != null &&
              (order.exam.prior_use ? "Sim" : "Não"),
          },
          {
            name: i18n.t('evaluation.indication'),
            data: (
              order.exam
                ? order.exam.any_indication
                : order.planit?.any_indication
            )
              ? "Sim"
              : "Não",
          }
        ],
      }
      : {
        title: i18n.t('evaluation.planning'),
        fields: [
          { name: i18n.t('evaluation.diagnosis'), data: order.planit?.injury_type },
          {
            name: i18n.t('evaluation.professional'),
            data: `${order.planit?.doctor_name} - ${order.planit?.doctor_contact}`,
          },
          { name: i18n.t('evaluation.bodyPart'), data: order.planit?.body_part },
          { name: i18n.t('evaluation.segmentaion'), data: order.planit?.target },
          {
            name: i18n.t('evaluation.surgeryDate'),
            data: order.planit && formatDate(order.planit.surgery_date),
          },
          {
            name: i18n.t('evaluation.observations'),
            data: order.notes,
          },
        ],
      }
  ];

  let measures = (order.exam?.measures || []).map((measure, i) => (
    <Field>
      <strong>{`${i18n.t('evaluation.measure')} ${i + 1}: `}</strong>
      {`${measure} cm`}
    </Field>
  ));

  const getOrder = async (id?: string) => {
    if (!id) {
      showToast({ type: "error", message: `Empty order id` });
      return;
    }
    api
      .get(`/orders/${id}`)
      .then((response) => {
        setOrder(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("evaluation.header"),
    });
  }, []);

  useEffect(() => {
    if (order_id) {
      getOrder(order_id);
    }
  }, [order_id])

  return (
    <>
      <Container>
        <Header>
          <div></div>
          <TagsContainer>
            <Tag key="status">
              <span>{`${i18n.t(
                `orders.status.${order.status}.optionText`
              )}`}</span>
            </Tag>

            {order.urgency_type === orderUrgencyTypes.requested && (
              <Tag key="urgency">
                <span>Urgência solicitada</span>
              </Tag>
            )}

            {order.urgency_type === orderUrgencyTypes.approved && (
              <Tag key="urgency">
                <span>Urgência aprovada</span>
              </Tag>
            )}

            <Tag key="order_type">
              <span>{`${i18n.t(
                `financial.prices.order_type.${order.category}`
              )}`}</span>
            </Tag>
            <Tag key="solution">
              <span>{order.solution?.name}</span>
            </Tag>
          </TagsContainer>
        </Header>
        <EvaluationWrapper>
          {(order.exam || order.planit) &&
            mock.map((section, index) => {
              return (
                <Section key={index}>
                  <h2>{section.title}</h2>
                  {section.fields.map((field) => {
                    if (field.data instanceof Array) {
                      return (
                        <>
                          <Field>
                            <strong>{field.name}:</strong>
                          </Field>
                          {field.data.map((item) => (
                            <Field>{item.name}</Field>
                          ))}
                        </>
                      );
                    }
                    return field.data === undefined ? null : (
                      <Field>
                        <strong>{field.name}:</strong> {field.data}
                      </Field>
                    );
                  })}
                </Section>
              );
            })}
          <Section>
            <h2>{`${i18n.t("evaluation.requirements")}`}</h2>
            <Field>
              <strong>{`${i18n.t("evaluation.printer")}`} </strong>
              {order.printer?.name}
            </Field>
            {!!order.exam && (
              <Field>
                <strong>{`${i18n.t("evaluation.side")}`} </strong>
                {order.exam?.side}
              </Field>
            )}
            {measures}
          </Section>
        </EvaluationWrapper>
        {!!order.planit && (
          <Section>
            <h2>{`${i18n.t('evaluation.images')}`}</h2>
            <FlexButtons>
              {order.planit?.files_links?.map((file) => {
                return (
                  <FileContainer>
                    <File>
                      <span>{`${file.split('/').pop()}`}</span>
                      <RiDeleteBin7Line
                        onClick={async () => {
                          if (
                            window.confirm(
                              "Tem certeza que deseja exluir o arquivo?"
                            )
                          ) {
                            await deletePlanitFile(order.planit_id, file);
                          }
                        }}
                        size={20}
                        color="var(--fixit)"
                      />
                    </File>
                  </FileContainer>
                );
              })}
            </FlexButtons>
          </Section>
        )}

        { /* order.status !== i18n.t("orders.status.completed.value") && (
          <AbsoluteButton
            color="var(--error)"
            absolute
            onClick={() => {
              if (window.confirm(i18n.t("orders.cancelConfirmation"))) {
                cancelOrder(order.id);
              }
            }}
          >
            <RiDeleteBin6Line />
          </AbsoluteButton>
        )
        */}
      </Container>
    </>
  );
}
