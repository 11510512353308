
import { i18n } from "../../../../../../localization/i18n";
import { IPartnerData } from "../../../../../../modules/Orders/CreateOrder/dtos";
import { CenterContent, Container, ContentInfos, Header, ImageContainer, LeftContent, MobileContent, PartnersInfos } from "./styles";


interface PartnertemProps {
  onClick?: () => void;
  partner: IPartnerData;
}

export function PartnerItem({
  partner,
  onClick = () => {},
}: PartnertemProps) {

  return (
    <>
    <Container
      onClick={() => {
        onClick();
      } }
    >
      <Header>
        <LeftContent>
          <ImageContainer>
            <span>{partner.name?.charAt(0)}</span>
          </ImageContainer>
          <PartnersInfos>
            <span className="partner_name">{`${i18n?.t("evaluation.name")} (${i18n.t("userRegistration.companyName")})`}</span>
            <span className="partner_location">
              {partner.name} {partner.company_name ? `(${partner.company_name})` : ''}
            </span>
          </PartnersInfos>
        </LeftContent>

        <CenterContent>
          <ContentInfos className="email">
            <span className="data_label">
              {`${i18n?.t("userRegistration.email")}`}
            </span>
            <span className="data_value">{partner.email}</span>
          </ContentInfos>
          <ContentInfos className="professional_number">
            <span className="data_label">
              {`${i18n?.t("customSolution.professional_number")}`}
            </span>
            <span className="data_value">
              {partner.professional_number}
            </span>
          </ContentInfos>
          <ContentInfos className="phone">
            <span className="data_label">
              {`${i18n?.t("customSolution.contact")}`}
            </span>
            <span className="data_value">{partner.phone}</span>
          </ContentInfos>
        </CenterContent>
      </Header>
      <MobileContent>
        <LeftContent>
          <ContentInfos>
            <span className="data_value">{partner.email}</span>
          </ContentInfos>
          <ContentInfos>
            <span className="data_value">{partner.phone}</span>
          </ContentInfos>
        </LeftContent>
      </MobileContent>
    </Container></>
  );
}
