import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    cursor: pointer;
  }

  h2 {
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 30px;
    margin-left: 25px;
  }

  @media (max-width: 900px) {
    h2 {
      font-size: 26px;
      margin-left: 15px;
    }
  }
`;

export const Form = styled.div`
  width: 100%;
  margin: 30px 0;
  padding: 30px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form_title {
    font-size: 24px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  label {
    font-size: 18px;
    color: var(--dark-gray);
  }

  input {
    margin-top: 6px;
  }

  @media (max-width: 900px) {
    .form_title {
      font-size: 20px;
      margin-left: 2px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 700px;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 300px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 30px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  position: relative;
  flex: 1;

  @media (max-width: 900px) {
    width: 296px;
  }
`;

export const CheckContainer = styled.div`
  position: relative;
  flex: 1;
  align-self: flex-start;

  .checks {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 12px;
  }

  .check {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;

    span {
      font-size: 18px;
      color: var(--dark-gray);
    }
  }

  @media (max-width: 900px) {
    width: 296px;
  }
`;

export const PrintersForm = styled.div`
  .title {
    font-size: 24px;
    font-weight: bold;
    color: var(--dark-gray);
    margin-top: 20px;
  }
`;

export const SelectedPrintersContainer = styled.div`
  .sub_title {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-gray);
    margin-top: 20px;
  }
`;

export const Printer = styled.div`
  display: flex;
  align-items: center;

  span {
    font-size: 18px;
    color: var(--dark-gray);
    margin-top: 8px;
  }

  svg {
    margin-left: 8px;
    margin-top: 6px;
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  align-self: flex-end;
  margin-top: 30px;

  button {
    width: 170px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: var(--white);
    font-size: 16px;
    background: var(--fixit);
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
      background: #6e00ad;
    }
  }
`;
