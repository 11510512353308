const setTheNestedObjectValue = (object: any, keys: any, errorMessage: any) => {
  let [key, ...otherKeys] = keys;
  if (otherKeys.length)
    object[key] = setTheNestedObjectValue(object[key], otherKeys, errorMessage);
  else {
    if (key) object[key] = errorMessage;
    else object = errorMessage;
  }
  return object;
};

export default async function validateFormFields(
  data: any,
  validator: any,
  errors: any
) {
  try {
    await validator.validate(data, { abortEarly: false });
    return { status: 200 };
  } catch (error: any) {
    if (error.errors) {
      let yupErrors = errors;

      error.errors.map((e: string) => {
        const [field, ...erro] = e.split(" ");
        setTheNestedObjectValue(
          yupErrors,
          field
            .replace(/\[/g, ".")
            .replace(/\]/g, ".")
            .replace(/\.\./g, ".")
            .split("."),
          erro.join(" ")
        );
      });
      return { status: 400, errors: yupErrors };
    }
    return { status: 404 };
  }
}
