import styled from "styled-components";

interface InfoContainer {
  display: boolean;
}

export const MiniCardBox = styled.div`
  width: 170px;
  height: 140px;
  padding: 10px 20px;
  background-color: white;
  border: 1px solid var(--light-gray);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const Info = styled.div<InfoContainer>`
  display: ${(props) => (props.display ? "flex" : "none")};
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0rem;
  top: 1px;
`;

export const MiniCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const IconContainer = styled.div`
  font-size: 30px;
  color: var(--fixit);
`;

export const MiniHeader = styled.span`
  font-size: 18px;
  color: var(--gray);
`;

export const CardValue = styled.label`
  margin-top: 6px;
  font-size: 25px;
  font-weight: bold;
  color: var(--fixit);
`;
