import styled from "styled-components";
import global from "../../../global";
import dropdownArrowDown from "../../../assets/dropdown-arrow-down.svg"

export const Content = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 40px;
  svg {
    cursor: pointer;
  }

  @media (max-width: 900px) {
    margin-top: 20px;
  }

  @media (max-width: 600px) {
    margin-top: 0px;
    margin-left: 5px;
    svg {
      width: 25px;
    }
  }
  
`;

export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 23px;
  color: var(--fixit);
  margin-left: 15px;

  @media (max-width: 600px) {
    font-size: 25px;
  }
`;

export const CardsContainer = styled.div`
  margin-top: 40px;
  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px;

  @media (max-width: 600px) {
    margin-top: 20px;
  }
`;

export const Select = styled.select`
  border: 1px solid var(--light-gray);
  border-radius: 6px;

  font-size: 18px;
  color: var(--dark-gray);
  text-align: left;
  padding-left: 10px;

  height: 40px;
  width: 160px;
  max-width: 300px;

  padding: 0 8px;
  appearance: none;
  background-image: url(${dropdownArrowDown});
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    max-width: unset;
  }
`;

export const SelectOption = styled.option`
  font-size: 16px;
`;
