import { Container } from "./styles";

interface LoaderProps {
  loaderSize?: number;
}

export function Loader({ loaderSize = 60 }: LoaderProps) {
  return (
    <Container loaderSize={loaderSize}>
      <div className="loader"></div>
    </Container>
  );
}
