import { ReactNode } from "react";
import { i18n } from "../../localization/i18n";

import {
  Container,
  CardImageContainer,
  CardTitle,
  Divider,
  SelectText,
} from "./styles";

interface IBracingCardProps {
  ilustration: ReactNode;
  title: string;
  onClick: () => any;
}

export function BracingCard({
  ilustration,
  title,
  onClick,
}: IBracingCardProps) {
  return (
    <Container onClick={onClick}>
      <CardImageContainer>{ilustration}</CardImageContainer>

      <CardTitle>{title}</CardTitle>

      <Divider />

      <SelectText>{`${i18n.t("solutions.select")}`}</SelectText>
    </Container>
  );
}
