import styled from "styled-components";

export const Container = styled.div`
  height: 220px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 20px;
  border: 1px solid var(--light-gray);
`;

export const Header = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 18px;
  color: var(--gray);

  svg {
    color: var(--fixit);
    font-size: 24px;
  }
`;

export const List = styled.div`
  color: var(--fixit);
  size: 16px;
`;
