import styled from "styled-components";

export const Container = styled.div`
    margin-top: 20px;
`

export const FilesContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
`

export const DeleteAllFilesButtonContainer = styled.div`
    position: absolute;
    display: flex;
    right: 20px;
    gap: 20px;
  `

export const SelectContainer = styled.div`
    display: flex;
    gap: 20px;
`

export const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 20px;
    color: var(--gray);
`

export const UploadFilesContainer = styled.div`
  margin-bottom: 20px;
`;