import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid var(--light-gray);
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;

  svg {
    cursor: pointer;
  }

  h2 {
    color: var(--dark-gray);
    font-weight: bold;
    font-size: 16px;
    margin-left: 25px;
  }

  @media (max-width: 900px) {
    h2 {
      font-size: 26px;
      margin-left: 15px;
    }
  }
`;

export const SolutionName = styled.h2`
  font-size: 14px;
  color: var(--fixit);
  margin-top: 3px;
  margin-bottom: 10px;
`;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .form_title {
    font-size: 16px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  label {
    font-size: 14px;
    color: var(--dark-gray);
  }

  @media (max-width: 900px) {
    .form_title {
      font-size: 16px;
      margin-left: 2px;
    }
  }
`;

export const FileContainer = styled.div`
  display: flex;
  margin-bottom: 15px;
`

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 900px) {
    width: 300px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  gap: 16px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  width: 100%;

  @media (max-width: 900px) {
    width: 296px;
  }
`;

export const UploadFilesContainer = styled.div`
  margin-bottom: 20px;

  label {
    font-size: 14px;
    color: var(--dark-gray);
  }
`;

export const DropzoneContainer = styled.div`
  margin-top: 8px;
`;

export const File = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  width: fit-content;
  padding: 8px 10px;
  border-radius: 6px;
  margin-top: 12px;
  background: var(--light-gray);
  span {
    font-size: 14px;
    color: var(--dark-gray);
  }
  svg {
    cursor: pointer;
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  justify-content: center;
`;

export const UploadButton = styled.button`
  width: 250px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: var(--white);
  font-size: 14px;
  background: var(--fixit);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #6e00ad;
  }

  :disabled{
    background-color: var(--light-gray);
    cursor: not-allowed;
  }
`;

export const ProgressContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: var(--gray);
`