import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 260px;
  }

  .title {
    margin-top: 30px;
    font-size: 28px;
    font-weight: 500;
    color: var(--dark-gray);
  }
`;

export const ChangeLanguageContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

export const LanguageSelect = styled.select`
  font-size: 16px;
  padding: 4px 10px;
  background: var(--white);
  color: var(--dark-gray);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  direction: rtl;

  display: flex;
  align-items: center;
  justify-content: center;

  option {
    color: var(--dark-gray);
    font-size: 16px;
  }
`;

export const Loading = styled.div`
  border: 3px solid var(--white);
  border-top: 3px solid var(--gray);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-top: 100px;
  animation: spin 0.4s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
