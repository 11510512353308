import { useEffect, useState } from "react";
import { BsCloudUpload } from "react-icons/bs";
import { RiDeleteBin7Line } from "react-icons/ri";
import { showToast } from "../../../../components/CustomToast";
import DropZone from "../../../../components/DropZone";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { ProgressBar } from "../../../../components/ProgressBar";
import { IStandardFileData } from "../../../../components/UploadStandardFilesModal/dtos";
import {
    DropzoneContainer,
    File,
} from "../../../../components/UploadStandardFilesModal/styles";
import { useAuth } from "../../../../hooks/AuthContext";
import { usePrinters } from "../../../../hooks/PrintersContext";
import { Slicers, SlicersOptions, SlicersVariables } from "../../../../utils/constants";
import FileService from "./fileService";
import { Container, DeleteAllFilesButtonContainer, FilesContainer, LoadingContainer, SelectContainer, UploadFilesContainer } from "./styles";
import { Loader } from "../../../../components/Loader";
import Button from "@mui/material/Button";

interface FileData {
    name: string;
    size: number;
    type: string;
    file_data: any;
}

export default function MultiStandardSolutionsUpload() {
    const { setNavigationAction } = useAuth();
    const [files, setFiles] = useState<FileData[]>([]);
    const [slicer, setSlicer] = useState<any>("PrusaSlicer");
    const { defaultPrinter } = usePrinters();

    const [fileSizeUploaded, setFileSizeUploaded] = useState(0);
    const [fileSizes, setFileSizes] = useState(0)

    const [datas, setDatas] = useState<IStandardFileData[]>([]);

    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        setNavigationAction({
            hasButton: false,
            description: 'Upload Múltiplo de Soluções Padronizadas',
        });
    }, []);

    useEffect(() => {
        if (((fileSizeUploaded ?? 0) /
            (fileSizes)) *
            100 >= 100) {
            showToast({
                type: "success",
                message: `Upload de arquivos concluído`,
            });

            window.location.reload();
        }
    }, [fileSizes, fileSizeUploaded])

    useEffect(() => {
        const fetchData = async () => {
            if (slicer?.length && files.length > 0) {
                let totalFileSizes = 0;
                const dataArray: IStandardFileData[] = [];

                for (const file of files) {
                    totalFileSizes += file.size;

                    const readableFile = new FileReader();
                    const automatedFileData = file.name.split(".")[0].split("_");

                    await new Promise<void>((resolve) => {
                        readableFile.onload = async function () {
                            const result = await readAndGetFileData(readableFile.result);
                            dataArray.push({
                                solution_name: automatedFileData[0].toUpperCase(),
                                material_weight: result.filament_weight,
                                material_cost: result.cost,
                                print_time: result.time,
                                side: automatedFileData[1].toUpperCase(),
                                size: automatedFileData[2],
                                part: automatedFileData[3] ?? "unique",
                                printer: {
                                    id: defaultPrinter?.value ?? "",
                                    name: defaultPrinter?.optionText ?? "",
                                },
                            });
                            resolve();
                        };
                        readableFile.readAsText(file.file_data);
                    });
                }

                setDatas(dataArray);
                setFileSizes(totalFileSizes);
            }
        };

        fetchData();
    }, [files, slicer, defaultPrinter]);

    async function readAndGetFileData(content: any): Promise<any> {
        if (content !== undefined && slicer === Slicers.cura) {
            let hours;
            let arred_minutes;
            let filament;
            let materialCost_cura;

            let time_position = content && content.indexOf("TIME:");

            let filament_position = content && content.indexOf("Filament");

            let time = content.slice(time_position + 5, filament_position - 2);

            filament = content.slice(filament_position + 15, filament_position + 22);

            let weight_cura =
                filament * SlicersVariables.cura.curaFilamentWheightPerMeter;

            materialCost_cura =
                filament *
                ((SlicersVariables.cura.curaFilamentWheightPerMeter / 1000) *
                    SlicersVariables.cura.valuePerKilogramBR);

            // setWeight(String(weight_cura.toFixed(2)));
            // setMaterialCost(String(materialCost_cura.toFixed(2)) + "");

            if (time > 3600) {
                hours = Math.round(time / 3600);
                arred_minutes = Math.trunc((time / 3600 - hours) * 60);
            }

            if (time < 3600) {
                arred_minutes = Math.trunc(time / 60);
            }

            if (hours && hours >= 1) {
                // content && setTime(`${hours}h, ${arred_minutes}m`);
                return { time: `${hours}h, ${arred_minutes}m`, filament_weight: String(weight_cura.toFixed(2)), cost: String(materialCost_cura.toFixed(2)) + "" }
            } else {
                // content && setTime(`${arred_minutes}m`);
                return { time: `${arred_minutes}m`, filament_weight: String(weight_cura.toFixed(2)), cost: String(materialCost_cura.toFixed(2)) + "" }
            }
        }

        if (content !== undefined && slicer === Slicers.prusa) {
            let filament_weight;
            let time;
            let cost;

            let time_position = content && content.indexOf("time");
            let filament_position = content && content.indexOf("used [g]");
            let cost_position = content.indexOf("filament cost");

            time = content.slice(time_position + 20, time_position + 27);
            filament_weight = content.slice(
                filament_position + 11,
                filament_position + 16
            );
            cost = content.slice(cost_position + 16, cost_position + 20);

            // setWeight(filament_weight);
            // setTime(time);
            // setMaterialCost(cost);

            return { time, filament_weight, cost }
        }

        if (content !== undefined && slicer === Slicers.simplify) {
            let time_position = content && content.indexOf("time:");
            let weight_position = content && content.indexOf("weight:");
            let cost_position = content && content.indexOf("cost:");

            // content && setTime(content.slice(time_position + 5, time_position + 20));
            // content &&
            //     setWeight(content.slice(weight_position + 7, weight_position + 13));
            // content &&
            //     setMaterialCost(content.slice(cost_position + 5, cost_position + 20));

            return {
                time: content.slice(time_position + 5, time_position + 20),
                filament_weight: content.slice(weight_position + 7, weight_position + 13),
                cost: content.slice(cost_position + 5, cost_position + 20)
            }
        }
    }

    async function startMultiUpload() {

        try {
            const uploadPromises = files.map(async (file, index) => {
                if (!file?.file_data) {
                    return;
                }

                const fileService = new FileService({
                    file,
                    data: datas[index],
                    setStateCallback: setFileSizeUploaded
                });

                await fileService.submitChunckedForm();
            });

            await Promise.allSettled(uploadPromises)

            setFiles([]);
            setDatas([]);

        } catch (error) {
            console.error("Erro durante o upload:", error);
        }
    }



    return (
        <Container>
            <UploadFilesContainer>
                <SelectContainer>
                    <OutlinedSelect
                        label={'Fatiador'}
                        options={SlicersOptions}
                        handleSelect={(selected) => setSlicer(selected.optionText.toString())}
                        selectedOption={slicer || ""}
                    />
                    {uploading
                        ?
                        <LoadingContainer>
                            <ProgressBar
                                text={
                                    !fileSizeUploaded
                                        ? `Adicione um arquivo para iniciar o upload`
                                        : `Realizando upload... ${fileSizeUploaded
                                            ? Math.ceil(
                                                ((fileSizeUploaded ?? 0) /
                                                    (fileSizes)) *
                                                100
                                            )
                                            : 0
                                        }%`
                                }
                                percentage={
                                    fileSizeUploaded
                                        ? ((fileSizeUploaded ?? 0) /
                                            (fileSizes)) *
                                        100
                                        : 0
                                }
                            />
                            <Loader loaderSize={40} />
                        </LoadingContainer>
                        :
                        <>
                            {
                                files.length > 0 && (
                                    <DeleteAllFilesButtonContainer>
                                        <Button
                                            component="label"
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<BsCloudUpload />}
                                            onClick={() => {
                                                startMultiUpload()
                                                setUploading(true)
                                            }
                                            }
                                        >
                                            Realizar upload de arquivos

                                        </Button>
                                        <Button
                                            component="label"
                                            variant="contained"
                                            color="error"
                                            startIcon={<RiDeleteBin7Line />}
                                            onClick={() => {
                                                setFiles([])
                                                setDatas([])
                                            }}
                                        >
                                            Remover todos arquivos
                                        </Button>
                                    </DeleteAllFilesButtonContainer>
                                )
                            }
                        </>
                    }
                </SelectContainer>

                {!uploading
                    &&
                    <DropzoneContainer>
                        <DropZone
                            multiple={true}
                            onUpload={(upFiles) => {
                                setFiles([]);
                                setDatas([]);

                                const formattedFiles = upFiles.map((fileData: any) => ({
                                    name: fileData.name,
                                    size: fileData.size,
                                    type: fileData.type,
                                    file_data: fileData,
                                }))

                                setFiles((prevState) => [...prevState, ...formattedFiles]);
                            }}
                        />
                    </DropzoneContainer>
                }
                {files.length > 0 && (
                    <FilesContainer>
                        {
                            files.map((file, index) => (
                                <File key={index}>
                                    <span>{file?.name}</span>
                                    <RiDeleteBin7Line
                                        onClick={() => {
                                            const updatedFiles = [...files];
                                            updatedFiles.splice(index, 1);
                                            setFiles(updatedFiles);
                                        }}
                                        size={20}
                                        color="var(--fixit)"
                                    />
                                </File>
                            ))
                        }
                    </FilesContainer>
                )}
            </UploadFilesContainer>
        </Container>
    );
}