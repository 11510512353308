import React, { useState, useEffect } from "react";

import api from "../../../services/api";

import { Content, CardsContainer } from "./styles";
import { i18n } from "../../../localization/i18n";

import { useParams } from "react-router-dom";
import { NewProductBox } from "../../../components/NewProductBox";
import { SolutionModal } from "../../../components/SolutionModal";
import { useAuth } from "../../../hooks/AuthContext";

import { showToast } from "../../../components/CustomToast";
import { useOnboard } from "../../../hooks/OnboardContext";
import { countries } from "../../../utils/constants";
export interface IPrinter {
  id: string;
  name: string;
}

export interface ISolution {
  id: string;
  name: string;
  name_key: string;
  category_key: string;
  field_key: string;
  form_type?: string;
  link_img: string;
  download_link: string;
  category: string;
  link_guide: string;
  number_measures: number;
  sample: ISample;
  solution_category: ICategory;
  is_custom: boolean;
  is_standard: boolean;
  is_download: boolean;
  standards: Array<any>;
}

export interface SelectedSolution {
  id: string;
  name: string;
  solution_category: any;
  application_video_link?: string;
  background_image_link?: string;
  link_guide?: string;
  description?: string;
}

export interface ISample {
  id?: string;
  solution_id?: string;
  printer_id?: string;
  link?: string;
}

export interface ICategory {
  id: string;
  name: string;
  category_key: string;
  field_key: string;
}

export default function Solutions() {
  const { setNavigationAction } = useAuth();
  const { licensee } = useOnboard();

  const [solutions, setSolutions] = useState<ISolution[]>([]);
  const [selectedSolution, setSelectedSolution] = useState<SelectedSolution>();
  const [solutionModal, setSolutionModal] = useState(false);
  const [ordersCount, setOrdersCount] = useState(false);

  const [planSolutions, setPlanSolutions] = useState<ISolution[]>([]); // Aqui é onde eu vou setar o array de ID que vier do back

  let { category_key } = useParams<keyof ICategory>();

  async function getAvailableSolutionsFromSignature() {
    try {
      await api
        .get(`/signatures/solutions?category_key=${category_key}`)
        .then((response) => {
          setPlanSolutions(response.data);
        });
    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("financial.error.get_signature"),
      });
    }
  }

  async function getSolutions() {
    try {
      api.get(`/categories/key/${category_key}`).then((response) => {
        setSolutions(response.data.solutions);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel carregar as soluções",
      });
    }
  }

  useEffect(() => {
    getUserOrdersCount();
  }, []);

  async function getUserOrdersCount() {
    const date = new Date();
    const queryList = [];
    queryList.push(`month=${date.getMonth() + 1}`);
    queryList.push(`year=${date.getFullYear()}`);

    try {
      await api
        .get(`/orders/user/verify?${queryList.join("&")}`)
        .then((response) => {
          setOrdersCount(response.data);
        });
    } catch (error) {
      return;
    } finally {
      if (ordersCount) {
        showToast({
          type: "error",
          message: "Você atingiu seu limite de pedidos por mês, contate o SAF!",
        });
      }
    }
  }

  useEffect(() => {
    if (category_key && solutions?.length) {
      getAvailableSolutionsFromSignature();
    }
  }, [category_key, solutions]);

  useEffect(() => {
    if (category_key && licensee) {
      getSolutions();
    }
  }, [category_key, licensee]);

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: i18n.t("solutions.solutions.header"),
    });
  }, [setNavigationAction]);

  return (
    <>
      <Content>
        {selectedSolution && (
          <SolutionModal
            open={solutionModal}
            closeModal={() => setSolutionModal(false)}
            solution={selectedSolution}
            licenseeCountry={
              licensee?.country &&
              countries[licensee.country as keyof typeof countries]
                ? countries[licensee.country as keyof typeof countries]
                : countries.default
            }
          />
        )}

        <CardsContainer>
          {solutions?.map((solution) => {
            const availableSolution = planSolutions?.find(
              (planSolution) => planSolution.id === solution.id
            );
            return (
              <>
                <NewProductBox
                  licenseeCountry={
                    licensee?.country &&
                    countries[licensee.country as keyof typeof countries]
                      ? countries[licensee.country as keyof typeof countries]
                      : countries.default
                  }
                  solutionId={solution.id}
                  link_img={solution.link_img}
                  solutionName={solution.name}
                  solutionCategory={solution.category}
                  solutionKey={solution.name_key}
                  solutionForm={solution.form_type}
                  is_custom={solution.is_custom}
                  is_standard={solution.is_standard}
                  is_download={solution.is_download}
                  download_link={solution.download_link}
                  standards={solution.standards}
                  isAvailable={!planSolutions || !!availableSolution}
                  ordersCount={ordersCount}
                  onInfoClick={() => {
                    setSelectedSolution({
                      id: solution.id,
                      name: solution.name,
                      solution_category: solution.solution_category,
                      link_guide: solution.link_guide,
                    });
                    setSolutionModal(true);
                  }}
                />
              </>
            );
          })}
        </CardsContainer>
      </Content>
    </>
  );
}
