import styled, { css } from "styled-components";

interface Props {
  open: boolean;
  inputWidth?: number;
  inputHeight?: number;
  error?: string;
}

export const Container = styled.div``;

export const DropdownContainer = styled.div<Props>`
  position: relative;
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "100%")};
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  input {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    font-weight: 500;
    text-align: left;
    padding: 0 35px 0 8px;
    cursor: pointer;
    outline: none;
    border: 1px solid #ced4da;

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    color: #575757;
    line-height: 8px;
  }

  .arrow_icon {
    position: absolute;
    right: 12px;
    top: 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: 0.2s;
  }

  ${({ open }) =>
    open &&
    css`
      .arrow_icon {
        transform: rotate(-180deg);
      }

      input {
        border: 1px solid var(--fixit);
      }
    `}

  ${({ error }) =>
    error &&
    css`
      input {
        border: 1px solid var(--formError);
      }
    `}
`;

export const OptionsContainer = styled.div<Props>`
  position: absolute;
  width: ${({ inputWidth }) => (inputWidth ? `${inputWidth}px` : "100%")};
  max-width: 100%;
  z-index: 2;
  margin-top: 4px;

  .dropdown_options {
    width: 100%;
    max-width: 100%;
    max-height: calc(5 * 29px);
    overflow-y: auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: #2b0d481f 0px 2px 10px;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  min-height: 16px;
  height: 32px;
  width: 100%;
  padding: 0 16px;
  margin: 6px 0;
  transition: background-color 0.5s;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: #eaeef4;
  }
  &:active:hover {
    background-color: #ccd5e2;
  }

  .option_text {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    color: var(--dark-gray);
    margin-left: 8px;
  }
`;

export const Error = styled.div`
  position: absolute;
  bottom: -16px;
  left: 2px;

  font-size: 12px;
  color: var(--formError);
`;
