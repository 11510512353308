import styled from "styled-components";

export const Container = styled.div`
  margin-top: 20px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;  
  margin-top: 40px;
  margin-left: 40px;
  svg {
    cursor: pointer;
  }

  @media (max-width: 900px) {
    margin-top: 20px;
  }
  
  @media (max-width: 600px) {
    margin-top: 0px;
    margin-left: 5px;
    svg {
      width: 25px;
    }
  }
`;

export const Title = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 23px;
  color: var(--fixit);
  margin-left: 15px;

  @media (max-width: 900px) {
    font-size: 26px;
  }
  
  @media (max-width: 600px) {
    font-size: 20px;
  }
`;

export const CardsContainer = styled.div`
  margin-top: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 32px;

  @media (max-width: 980px) {
    justify-content: center;
  }
`;
