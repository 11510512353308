import { Order } from "../../dtos";

import { OrderItemContainer, Item } from "./styles";

interface OrderItemProps {
  order: Order;
}

export function OrderItem({ order }: OrderItemProps) {
  return (
    <OrderItemContainer>
      <div>
        <Item>
          <strong>Pedido: </strong>#{order.incremental_id}
        </Item>
        <Item>
          <strong>Solução: </strong>
          {order.solution.name} - Personalizada
        </Item>
        <Item>
          <strong>Profissional: </strong>
          {order.exam.professional_name}
        </Item>
        <Item>
          <strong>Paciente: </strong>
          {order.patient.name}
        </Item>
      </div>
    </OrderItemContainer>
  );
}
