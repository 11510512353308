import { useCallback, useEffect, useState } from "react";

import { Box } from "@mui/material";

import * as _ from "lodash";

import { Modal } from "../../../../../components/Modal";
import { OutlinedButton } from "../../../../../components/OutlinedButton";
import { showToast } from "../../../../../components/CustomToast";
import { MultipleSearchableSelect } from "../../../../../components/MultipleSearchableSelect";

import { getActiveLanguage } from "../../../../../utils/getActiveLanguage";

import api from "../../../../../services/api";

import { IOrder } from "../../dtos";

import { RiCloseLine, RiCheckLine } from "react-icons/ri";

import {
  Container,
  Header,
  Content,
  InputLine,
  InputContainer,
  Footer,
} from "./styles";

export interface ISearchData {
  label: string;
  id: string;
}

interface EditDiagnosisModalProps {
  open: boolean;
  exam: IOrder["exam"];
  handleCloseModal: () => void;
  onDiagnosisEdited: () => Promise<void>;
}

export function EditDiagnosisModal({
  open,
  exam,
  handleCloseModal,
  onDiagnosisEdited,
}: EditDiagnosisModalProps) {
  const [diagnosis, setDiagnosis] = useState<ISearchData[]>([]);
  const [diagnosisSearch, setDiagnosisSearch] = useState("");
  const [examDiagnosisList, setExamDiagnosisList] = useState<ISearchData[]>([]);
  const [loading, setLoading] = useState(false);

  async function getDiagnoses(search: string) {
    try {
      const activeLanguage = getActiveLanguage();

      const params = { language: activeLanguage, multi: search };
      const response = await api.get("/diagnosis", { params });

      const formattedDiagnoses = formatDiagnosis(response.data);

      setDiagnosis(formattedDiagnoses);
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível carregar os diagnósticos",
      });
    }
  }

  async function handleUpdateExam() {
    setLoading(true);

    try {
      const body = {
        ...exam,
        diagnosis_list: examDiagnosisList.map((item) => {
          return {
            id: item.id,
          };
        }),
      };

      await api.patch(`/exams/diagnosis/${exam.id}`, body);
      await onDiagnosisEdited();

      handleCloseModal();

      showToast({
        type: "success",
        message: "Diagnósticos atualizados com sucesso.",
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao atualizar os diagnósticos.",
      });
    } finally {
      setLoading(false);
    }
  }

  function formatDiagnosis(data: any[]) {
    const activeLanguage = getActiveLanguage();

    return data.map((item: any) => {
      return {
        label: `${item.name[activeLanguage]} - ${item.cid}`,
        description: item?.description[activeLanguage],
        verified: item.verified,
        id: item.id,
      };
    });
  }

  function setInitialData() {
    if (exam?.diagnosis_list) {
      const formattedDiagnosis = formatDiagnosis(exam?.diagnosis_list);
      setExamDiagnosisList(formattedDiagnosis);
    }
  }

  const debounceDiagnosis = useCallback(
    _.debounce((search) => getDiagnoses(search), 500),
    []
  );

  useEffect(() => {
    setInitialData();
  }, [exam]);

  useEffect(() => {
    getDiagnoses("");
  }, []);

  return (
    <Modal open={open}>
      <Container>
        <Header>
          <span className="title">Editar Diagnósticos</span>
          <RiCloseLine
            onClick={() => {
              handleCloseModal();
              setInitialData();
            }}
          />
        </Header>

        <Content>
          <InputLine>
            <InputContainer>
              <MultipleSearchableSelect
                label="Diagnóstico"
                options={diagnosis}
                defaultValue={diagnosisSearch}
                value={examDiagnosisList}
                onChange={(event) => {
                  setDiagnosisSearch(event);
                  debounceDiagnosis(event);
                }}
                onSelect={(selected) => {
                  setExamDiagnosisList([...(selected as ISearchData[])]);
                }}
                onBlur={() => setDiagnosisSearch("")}
                filterOptions={(x) => x}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    {...props}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start !important"
                    sx={{
                      ".diagnosis_desc": {
                        color: "var(--dark-gray)",
                        fontSize: "14px",
                      },
                      svg: {
                        marginLeft: "4px",
                      },
                    }}
                  >
                    <p>
                      {option.label}
                      {option?.verified && (
                        <RiCheckLine color="var(--finished)" />
                      )}
                    </p>
                    <p className="diagnosis_desc">{option?.description}</p>
                  </Box>
                )}
              />
            </InputContainer>
          </InputLine>
        </Content>

        <Footer>
          <OutlinedButton
            text="Salvar alterações"
            buttonColor="var(--finished)"
            loading={loading}
            onClick={handleUpdateExam}
          />
        </Footer>
      </Container>
    </Modal>
  );
}
