import React, { useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import CustomToast, { showToast } from "../../../../components/CustomToast";

import Head from "../../../../components/Head";
import DropZone from "../../../../components/DropZone";
import FileList from "../../../../components/FileList";

import {
  Container,
  Label,
  Form,
  FilesWrapper,
  Flex,
  Submit,
  Comment
} from "./styles";

const Problem = () => {
  const [uploadedFiles, setUploadedFiles] = useState<Array<any>>([]);

  const handleUpload = (files: Array<File>) => {
    files.forEach((file) => {
      setUploadedFiles(prevState => [...prevState, file])
    })
  }

  const handleDeleteFile = (index: number) => {
    const newUploadedFiles = uploadedFiles.filter((file) => file !== uploadedFiles[index])
    setUploadedFiles(newUploadedFiles)
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    showToast({ type: "success", message: "Menssagem enviada com sucesso"})
    e.preventDefault()
  }

  const handleSelect = () => {

  }

  const problems = [
    "faltou energia durante a impressão",
    "erro2",
    "erro3",
    "erro4"
  ];

  const [selectedProblem, setSelectedProblem] = useState(problems[0])

  const selectPrinter = (index: number): void => {
    setSelectedProblem(problems[index]);
  }

  return (
    <>
      <CustomToast />
      <Head title="Tive um problema"/>
      <Container>
        <Form onSubmit={e => handleSubmit(e)}>
          <Label>O que aconteceu?</Label>
          <Dropdown
            selectHandler={handleSelect}
            selectItem={selectPrinter}
            selectionList={problems}
            selected={selectedProblem}
          />
          <Label>Adicione alguma foto ou vídeo de apoio</Label>
          <FilesWrapper>
            <DropZone onUpload={handleUpload}/>
            { !!uploadedFiles.length && (
              <FileList files={uploadedFiles} handleDelete={handleDeleteFile}/>
            )}
          </FilesWrapper>
          <Label>Deseja adicionar algum comentário?</Label>
          <Comment />
          <Flex>
            <Submit type="submit" />
          </Flex>
        </Form>
      </Container>
    </>
  )
}

export default Problem;
