import WhatsLogo from "../../../../../assets/whatsapp-logo.svg"
import {WhatsappButton, WhatsappForm} from "./styles";

const WhatsappBtn = () => {

    return(
        <WhatsappForm action="https://wa.link/z72pp2" target="_blanck">
            <WhatsappButton>
                <img src={WhatsLogo} alt="Logo Whatsapp"/>SAF Fix It
            </WhatsappButton>
        </WhatsappForm>
        
    );

}

export default WhatsappBtn;