import { useEffect, useState } from "react";
import { ClickAwayListener } from "@mui/material";

import { OutlinedCheckbox } from "../../../../../../components/OutlinedCheckbox";
import { OutlinedButton } from "../../../../../../components/OutlinedButton";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";

import { RiFilter3Fill } from "react-icons/ri";

import { FilterOptions } from "../../dtos";

import {
  Container,
  FilterContent,
  InputLine,
  InputContainer,
  Footer,
} from "./styles";

interface FilterProps {
  filterOptions?: FilterOptions;
  onFilterApply: (filterOptions: FilterOptions) => void;
}

export function Filter({ filterOptions, onFilterApply }: FilterProps) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [prevFilterOptions, setPrevFilterOptions] = useState<FilterOptions>({
    verified: true,
  });

  function changeFilterOptions(data: Partial<FilterOptions>) {
    setPrevFilterOptions((prevState) => ({
      ...prevState,
      ...data,
    }));
  }

  useEffect(() => {
    setPrevFilterOptions({ ...filterOptions });
  }, [filterOptions]);

  return (
    <Container>
      <RiFilter3Fill
        onClick={(event) => {
          event.preventDefault();
          setFilterOpen((prevState) => !prevState);
        }}
      />

      {filterOpen && (
        <FilterContent>
          <ClickAwayListener onClickAway={() => setFilterOpen(false)}>
            <>
              <InputLine>
                <InputContainer>
                  <OutlinedInput
                    inputName="name"
                    label="Nome"
                    handleChange={(event) =>
                      changeFilterOptions({ name: event })
                    }
                    value={prevFilterOptions.name ?? ""}
                  />
                </InputContainer>
              </InputLine>

              <InputLine>
                <InputContainer>
                  <OutlinedInput
                    inputName="cid"
                    label="CID"
                    handleChange={(event) =>
                      changeFilterOptions({ cid: event })
                    }
                    value={prevFilterOptions?.cid ?? ""}
                  />
                </InputContainer>
              </InputLine>

              <InputLine>
                <InputContainer>
                  <OutlinedCheckbox
                    label="Verificado"
                    handleChange={() =>
                      changeFilterOptions({
                        verified: !prevFilterOptions?.verified,
                      })
                    }
                    checked={!!prevFilterOptions.verified}
                  />
                </InputContainer>
              </InputLine>

              <Footer>
                <OutlinedButton
                  text="Aplicar filtros"
                  buttonColor="var(--finished)"
                  onClick={() => {
                    onFilterApply(prevFilterOptions);
                    setFilterOpen(false);
                  }}
                />
              </Footer>
            </>
          </ClickAwayListener>
        </FilterContent>
      )}
    </Container>
  );
}
