import styled, { keyframes } from "styled-components";

const lateralPadding = 8;
const mobileWidth = 720;

export const Container = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileWidth}px) {
    width: 100%;
  }

  h2 {
    display: flex;
    justify-content: center;
    margin: 18px 0;

    color: var(--dark-gray);
    font-size: 28px;
    font-weight: 500;
  }
`;

export const FormContent = styled.div`
  width: 100%;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  width: 100%;
  
  margin-bottom: 20px;
`;

export const Branding = styled.div`
  img {
    width: 150px;
    height: 60px;
  }
  display: flex;
  justify-content: center;
  margin-top: 18px;
`;

export const CloseBtn = styled.button`
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 2rem;
  cursor: pointer;
  color: var(--dark-gray);
  transition: color 100ms ease-in-out;

  :hover {
    color: var(--fixit);
  }
`;
