import { useEffect, useState } from "react";

import { RiFilter3Fill } from "react-icons/ri";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";

import {
  Container,
  FilterContainer,
  SectionHeader,
  SectionName,
  Footer,
  ApplyButton,
  InputLine,
  InputContainer,
  PeriodInputsContainer,
  PeriodInput,
  DatePickerContainer,
  InputSeparator,
} from "./styles";
import { IFilter, IPeriod } from "../../../../../Orders/dtos";
import { FiChevronDown, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { i18n } from "../../../../../../localization/i18n";
import { localeMocks } from "./mocks";
import { getActiveLanguage } from "../../../../../../utils/getActiveLanguage";

interface FilterProps {
  onChange: (filterData: IFilter) => void;
  filter?: IFilter;
}

export function Filter({ onChange, filter }: FilterProps) {
  const activeLanguage = getActiveLanguage();

  const [filterOpen, setFilterOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(new Date());

  const [selectedPeriod, setSelectedPeriod] = useState<IPeriod>({
    from: startOfMonth(new Date()),
    to: endOfMonth(new Date()),
  });

  const [isPeriod, setIsPeriod] = useState(false);

  function prevDate(e: React.MouseEvent<SVGElement>) {
    e.preventDefault();
    const prev = selectedMonth.setMonth(selectedMonth.getMonth() - 1);
    setSelectedMonth(new Date(prev));
  }

  function nextDate(e: React.MouseEvent<SVGElement>) {
    e.preventDefault();
    const next = selectedMonth.setMonth(selectedMonth.getMonth() + 1);
    setSelectedMonth(new Date(next));
  }

  function applyFilterData() {
    const filterData: IFilter = {
      month: !isPeriod ? selectedMonth : undefined,
      period: isPeriod ? selectedPeriod : undefined,
    };

    onChange(filterData);
    setFilterOpen(false);
  }

  useEffect(() => {
    filter?.month && setSelectedMonth(filter.month);
    filter?.period && setSelectedPeriod(filter.period);

    if (filter && !filter.month && !filter.period) {
      setIsPeriod(true);
      setSelectedPeriod({
        from: startOfDay(new Date()),
        to: endOfDay(new Date()),
      });
    }
  }, [filter]);

  return (
    <Container>
      <RiFilter3Fill
        size={34}
        color={filterOpen ? "var(--fixit)" : "var(--gray)"}
        onClick={() => setFilterOpen((prevState) => !prevState)}
      />

      <FilterContainer open={filterOpen}>
        <InputLine>
          <InputContainer>
            <PeriodInputsContainer className={isPeriod ? "period" : "month"}>
              <SectionHeader
                onClick={() => setIsPeriod((prevState) => !prevState)}
              >
                <FiChevronDown size={16} color="#9E9E9E" />
                <SectionName>
                  {isPeriod
                    ? `${i18n.t("dashboard.period")}`
                    : `${i18n.t("dashboard.month")}`}
                </SectionName>
              </SectionHeader>
              <InputSeparator></InputSeparator>
              {isPeriod ? (
                <>
                  <PeriodInput>
                    <InputContainer>
                      <DatePicker
                        selected={selectedPeriod.from}
                        onChange={(date: Date) => {
                          date &&
                            setSelectedPeriod((prevState) => ({
                              ...prevState,
                              from: startOfDay(date),
                            }));
                        }}
                        dateFormat="dd/MM/yyyy"
                        locale={localeMocks[activeLanguage ?? "en"]}
                      />
                    </InputContainer>
                  </PeriodInput>
                  <span className="separator"> - </span>
                  <PeriodInput>
                    <InputContainer>
                      <DatePicker
                        selected={selectedPeriod.to}
                        onChange={(date: Date) => {
                          setSelectedPeriod((prevState) => ({
                            ...prevState,
                            to: endOfDay(date),
                          }));
                        }}
                        minDate={selectedPeriod?.from}
                        dateFormat="dd/MM/yyyy"
                        locale={localeMocks[activeLanguage ?? "en"]}
                      />
                    </InputContainer>
                  </PeriodInput>
                </>
              ) : (
                <PeriodInput>
                  <DatePickerContainer>
                    <FiChevronLeft onClick={(e) => prevDate(e)} size={18} />
                    <DatePicker
                      selected={selectedMonth}
                      onChange={(date: Date) => setSelectedMonth(date)}
                      dateFormat="MM/yyyy"
                      locale={localeMocks[activeLanguage ?? "en"]}
                      showMonthYearPicker
                      showTwoColumnMonthYearPicker
                    />
                    <FiChevronRight onClick={(e) => nextDate(e)} size={18} />
                  </DatePickerContainer>
                </PeriodInput>
              )}
            </PeriodInputsContainer>
          </InputContainer>
        </InputLine>

        <Footer>
          <ApplyButton type="button" onClick={applyFilterData}>
            {`${i18n.t("dashboard.apply")}`}
          </ApplyButton>
        </Footer>
      </FilterContainer>
    </Container>
  );
}
