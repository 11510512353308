import styled from "styled-components";

export const ConfirmOrderUrgencyModalContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 24px;
    height: 24px;
    color: var(--fixit);
    cursor: pointer;
  }

  .confirm_title {
    font-size: 18px;
    color: var(--dark-gray);
    font-weight: 500;
  }
`;

export const Content = styled.div`
  margin-top: 20px;

  .description {
    font-size: 16px;
    color: var(--primary);
  }

  .justification {
    margin-top: 20px;

    div {
      height: 200px;
    }

    textarea {
      height: 200px;
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  button {
    width: 140px;
  }
`;
