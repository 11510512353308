import styled from "styled-components";

export const Container = styled.div``;

export const Actions = styled.div`
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    margin-top: 100px;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
    /* border-style: ridge; */
    border-bottom: 4px;
    border-style: solid;

    font-size: 26px;
    font-weight: 600;
    color: var(--fixit);
    text-transform: uppercase;
  }

  @media (max-width: 720px) {
    > span {
      font-size: 20px;
    }
  }
`;

export const CardsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  margin-top: 30px;
`;
