import styled, { css } from "styled-components";

type PopoverProps = {
  position: "left" | "right" | undefined;
};

export const PopoverModal = styled.label<PopoverProps>`
  position: absolute;
  ${({ position }) =>
    position && position === "left"
      ? css`
          top: -36px;
          right: -10px;
          text-align: end;
        `
      : css`
          top: -15px;
          left: 29px;
        `}
  display: none;
  align-items: center;
  min-height: 30px;
  min-width: fit-content;

  border: 1px solid var(--light-gray);
  border-radius: 10px;
  padding: 13px 13px 10px 15px;
  
  background-color: var(--white);
  box-shadow: 4px 3px 8px var(--light-gray);
  z-index: 5;
  
  white-space: nowrap;
  text-align: left;
  font-size: 12px;
  color: var(--gray);
  &::before {
    content: "";
    position: absolute;
    ${({ position }) =>
      position && position === "left"
        ? css`
            top: 21px;
            right: 0;
          `
        : css`
            top: 21px;
            left: 0;
          `}
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  &:hover {
    ${PopoverModal} {
      display: flex;
    }
  };
`;
