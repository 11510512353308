import { useEffect, useState } from "react";

import { BracingCard } from "../../../../components/BracingCard";

import { Container, CardsContainer } from "./styles";
import { useNavigate, useParams } from "react-router";
import api from "../../../../services/api";

import Logo from "../../../../assets/symbol-purple_animation.svg";
import { i18n } from "../../../../localization/i18n";
import { useAuth } from "../../../../hooks/AuthContext";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

export interface ICategory {
  id: string;
  name: I18NTextLanguageObject;
  field_key: string;
  name_key: string;
  image_link: string;
  description: I18NTextLanguageObject;
  autoopen: boolean;
  solutions: any[];
}

export interface IParams {
  field_key: string;
}

interface ISolutionField {
  id: string;
  name: string;
  name_key: string;
  image_link: string;
  description: string;
}

export default function BracingTypes() {
  const navigate = useNavigate();
  const { setNavigationAction } = useAuth();
  const [field, setField] = useState<ISolutionField>({} as ISolutionField);
  const [categories, setCategories] = useState<Array<ICategory>>([]);

  let { field_key } = useParams<keyof IParams>();

  useEffect(() => {
    if (field_key) {
      api.get(`/categories/key/${field_key}`).then((response) => {
        setField(response.data);
      });
    }
  }, [field_key]);

  useEffect(() => {
    if (field.id) {
      api.get(`/categories/field/${field.id}?is_active=true`).then((response) => {
        setCategories(response.data);
      });
    }
  }, [field]);

  useEffect(() => {
    setNavigationAction({
      action: 'back',
      hasButton: true,
      description: i18n.t("solutions.categories.header")
    });
  }, []);

  return (
    <Container>

      <CardsContainer>
        {categories.map((item) => {
          const language = i18n.language;
          var link : string
                        
          if (item.solutions.length && item.autoopen) {
            const solution = item.solutions[0]
            link = `/employee/order/${solution.name_key}/${solution.form_type || "custom"}`
          } else {
            link = `/employee/solutions/${item.name_key}`
          }

          return (
            <BracingCard
              ilustration={
                <embed src={
                  item.image_link ?
                    `${process.env.REACT_APP_STORAGE_BASE_URL}${item.image_link}` :
                    Logo
                  } />
              }
              title={item.name[language as keyof I18NTextLanguageObject]}
              onClick={() => navigate(link)}
            />
          );
        })}
      </CardsContainer>
    </Container>
  );
}
