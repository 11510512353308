import styled, { css } from "styled-components";

interface CheckContainerProps {
  check: boolean;
}

export const Container = styled.div``;

export const CheckContainer = styled.div<CheckContainerProps>`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 7px;
  border: 1px solid #ced4da;
  background: var(--white);

  cursor: pointer;

  svg {
    color: var(--white);
  }

  ${({ check }) =>
    check &&
    css`
      background: var(--fixit);
    `}
`;
