import { useEffect, useState } from "react";
import { RiDeleteBin7Line } from "react-icons/ri";
import Dropdown from "../../../Admin/components/Dropdown";
import DropZone from "../../../../components/DropZone";

import api from "../../../../services/api";

import { Checkbox } from "../../../../components/Checkbox";
import { showToast } from "../../../../components/CustomToast";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";
import { useAuth } from "../../../../hooks/AuthContext";

import { sides as solutionSides } from "../../../../utils/constants";

import {
  Container,
  Section,
  Flex,
  SubContainer,
  Input,
  InputLabel,
  InputContainer,
  CheckboxFlex,
  FlexButtons,
  Submit,
  Cancel,
  UploadFilesContainer,
  DropzoneContainer,
  File,
  SideSelectContainer,
} from "./styles";
import { i18n } from "../../../../localization/i18n";
const FormData = require("form-data");

export interface IStandard {
  order: number;
  size: string;
}

export interface IOption {
  value: string;
  optionText: string;
}

export interface ISolutionCategory extends IOption {
  id: string;
  name?: string;
}

export interface ISolutionField extends IOption {
  id: string;
  name?: string;
}

interface IFile {
  name: string;
  size: number;
  type: string;
  file_data: File;
}

export default function CreateSolution() {
  const { setNavigationAction } = useAuth();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "all" });

  const [fields, setFields] = useState<Array<ISolutionField>>([]);
  const [field, setField] = useState<ISolutionField>();

  const [categories, setCategories] = useState<Array<ISolutionCategory>>([]);
  const [category, setCategory] = useState<ISolutionCategory>();

  const [isStandard, setIsStandard] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [name, setName] = useState("");
  const [formType, setFormType] = useState("");
  const [filePrice, setFilePrice] = useState("");
  const [suggestedPrice, setSuggestedPrice] = useState("");
  const [numberParts, setNumberParts] = useState("1");
  const [numberSides, setNumberSides] = useState("1");
  const [measures, setMeasures] = useState("");
  const [linkGuide, setLinkGuide] = useState("");
  const [file, setFile] = useState({} as IFile);
  const [downloadFile, setDownloadFile] = useState({} as IFile);
  const [parts, setParts] = useState<string[]>([]);
  const [sides, setSides] = useState<IOption[]>([]);

  const formattedSides = Object.entries(solutionSides).map((side) => ({
    optionText: i18n.t(`global.sides.${side[1]}`),
    value: side[1],
  }));

  useEffect(() => {
    api
      .get(`/categories`)
      .then((response) => {
        setFields(
          response.data.map((f: any) => ({
            ...f,
            value: f.id,
            optionText: f.name[i18n.language],
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!!field) {
      api
        .get(`/categories/field/${field.id}`)
        .then((response) => {
          setCategories(
            response.data.map((c: any) => ({
              ...c,
              value: c.id,
              optionText: c.name[i18n.language],
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [field]);

  useEffect(() => {
    if (numberSides && +numberSides > sides.length) {
      setSides((prevState) => [...prevState, { optionText: "", value: "" }]);
      return;
    }

    if (numberSides && +numberSides < sides.length) {
      setSides((prevState) => prevState.slice(0, +numberSides));
      return;
    }
  }, [numberSides]);

  useEffect(() => {
    if (numberParts && +numberParts > parts.length) {
      setParts((prevState) => [
        ...prevState,
        ...Array.from({ length: +numberParts - parts.length }, () => ""),
      ]);
      return;
    }

    if (numberParts && +numberParts < parts.length) {
      setParts((prevState) => prevState.slice(0, +numberParts));
      return;
    }
  }, [numberParts]);

  const submitForm = async (): Promise<void> => {
    if (name.length < 1) {
      showToast({ type: "error", message: "Digite um nome para a solução" });
      return;
    }

    const solutionCategory =
      field && !category && !categories.length ? field : category;

    if (!solutionCategory) {
      showToast({ type: "error", message: "Informe a categoria da solução" });
      return;
    }

    var formData = new FormData();
    formData.append("name", name);
    formData.append("form_type", formType);
    formData.append("suggested_price", suggestedPrice);
    formData.append("solution_category", JSON.stringify(solutionCategory));
    formData.append("link_guide", linkGuide);
    formData.append("number_measures", measures);
    formData.append("is_custom", `${isCustom}`);
    formData.append("is_standard", `${isStandard}`);
    formData.append("is_download", `${isDownload}`);
    formData.append("file", file.file_data);
    formData.append("downloadFile", downloadFile.file_data);
    formData.append("number_parts", numberParts);

    parts.forEach((part) => {
      formData.append("parts", part);
    });

    sides.forEach((side) => {
      formData.append("sides", side.value);
    });

    api
      .post("/solutions", formData)
      .then(() => {
        showToast({ type: "success", message: "Solução criada com sucesso!" });
        navigate("/creators/solutions");
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao criar a solução! Detalhes: ${error.message}`,
        });
      });
  };

  const backPage = () => {
    navigate("/creators/solutions");
  };

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: `Cadastrar solução`,
    });
  }, []);

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(submitForm)}>
          <Flex>
            <SubContainer>
              <CheckboxFlex>
                <Checkbox
                  handleChange={() => {
                    if (isCustom === false) {
                      setIsDownload(false);
                    }
                    setIsCustom(!isCustom);
                  }}
                  checked={isCustom}
                />
                <InputLabel>Personalizada</InputLabel>
              </CheckboxFlex>

              <CheckboxFlex>
                <Checkbox
                  handleChange={() => {
                    if (isStandard === false) {
                      setIsDownload(false);
                    }
                    setIsStandard(!isStandard);
                  }}
                  checked={isStandard}
                />
                <InputLabel>Padronizada</InputLabel>
              </CheckboxFlex>

              <CheckboxFlex>
                <Checkbox
                  handleChange={() => {
                    if (isDownload === false) {
                      setIsStandard(false);
                      setIsCustom(false);
                    }
                    setIsDownload(!isDownload);
                  }}
                  checked={isDownload}
                />
                <InputLabel>É download</InputLabel>
              </CheckboxFlex>
            </SubContainer>

            <SubContainer>
              <InputContainer>
                <InputLabel>Categoria</InputLabel>
                <Dropdown
                  options={fields}
                  handleSelect={(selected) => {
                    setField((prevState) => ({
                      ...prevState,
                      value: selected.value.toString(),
                      optionText: selected.value.toString(),
                      id: selected.value.toString(),
                      name: selected.optionText.toString(),
                    }));
                    setCategory((prevState) => undefined);
                  }}
                  selectedOption={field?.name}
                  placeholder="Selecione uma área ..."
                />
              </InputContainer>

              {!!field && !!categories && !!categories.length && (
                <InputContainer>
                  <InputLabel>Sub-categoria</InputLabel>
                  <Dropdown
                    options={categories}
                    handleSelect={(selected) => {
                      setCategory((prevState) => ({
                        ...prevState,
                        value: selected.value.toString(),
                        optionText: selected.value.toString(),
                        id: selected.value.toString(),
                        name: selected.optionText.toString(),
                      }));
                    }}
                    selectedOption={category ? category.name : ""}
                    placeholder="Selecione uma categoria..."
                  />
                </InputContainer>
              )}
            </SubContainer>

            <SubContainer>
              <InputContainer>
                <InputLabel>Nome</InputLabel>
                <Input
                  type="text"
                  id="name"
                  name="name"
                  inputWidth="380px"
                  onChange={(event) => {
                    setName(event.target.value);
                  }}
                />
              </InputContainer>

              <InputContainer>
                <InputLabel>R$ Venda</InputLabel>
                <Input
                  type="number"
                  id="suggested_price"
                  name="suggested_price"
                  step="0.01"
                  min="0.01"
                  inputWidth="100px"
                  onChange={(event) => {
                    setSuggestedPrice(event.target.value);
                  }}
                />
              </InputContainer>
            </SubContainer>

            <SubContainer>
              <InputContainer>
                <InputLabel>Medidas</InputLabel>
                <Input
                  type="number"
                  id="number_measures"
                  name="number_measures"
                  inputWidth="70px"
                  onChange={(event) => {
                    setMeasures(event.target.value);
                  }}
                />
              </InputContainer>
              <InputContainer>
                <InputLabel>Formulário</InputLabel>
                <Input
                  type="text"
                  id="form_type"
                  name="form_type"
                  inputWidth="100px"
                  onChange={(event) => {
                    setFormType(event.target.value);
                  }}
                />
              </InputContainer>
            </SubContainer>
            <SubContainer>
              <InputContainer>
                <InputLabel>Link do guia</InputLabel>
                <Input
                  type="text"
                  id="link_guide"
                  name="link_guide"
                  inputWidth="480px"
                  onChange={(event) => {
                    setLinkGuide(event.target.value);
                  }}
                  ref={register()}
                />
              </InputContainer>
            </SubContainer>

            <Section>
              <SubContainer>
                <InputContainer>
                  <InputLabel>Lados</InputLabel>
                  <Input
                    type="number"
                    id="number_sides"
                    name="number_sides"
                    defaultValue={1}
                    min={1}
                    inputWidth="70px"
                    onChange={(event) => {
                      if (Number(event.target.value) > 3) return;
                      setNumberSides(event.target.value);
                    }}
                    value={numberSides}
                  />
                </InputContainer>

                {sides?.map((side, i) => (
                  <SideSelectContainer>
                    <InputLabel>Lado {i + 1}</InputLabel>
                    <Dropdown
                      options={formattedSides}
                      handleSelect={(selected) => {
                        setSides((prevState) => {
                          const sideAlreadySelected = prevState.find(
                            (item) => item.value === selected.value
                          );
                          if (sideAlreadySelected) return prevState;

                          let tmpSides = [...prevState];
                          tmpSides[i] = selected;

                          return tmpSides;
                        });
                      }}
                      selectedOption={side?.optionText}
                    />
                  </SideSelectContainer>
                ))}
              </SubContainer>
            </Section>

            <Section>
              <SubContainer>
                <InputContainer>
                  <InputLabel>Partes</InputLabel>
                  <Input
                    type="number"
                    id="number_parts"
                    name="number_parts"
                    defaultValue={1}
                    min={1}
                    inputWidth="70px"
                    onChange={(event) => {
                      setNumberParts(event.target.value);
                    }}
                  />
                </InputContainer>

                {parts?.map((_, i) => (
                  <InputContainer key={i}>
                    <InputLabel>Parte {i + 1}</InputLabel>
                    <Input
                      type="text"
                      id="parts"
                      name="parts"
                      defaultValue={parts[i]}
                      onChange={(e) => {
                        setParts((prevState) =>
                          prevState.map((part, index) =>
                            index === i ? e.target.value : part
                          )
                        );
                      }}
                      inputWidth="100px"
                      ref={register()}
                    />
                  </InputContainer>
                ))}
              </SubContainer>
            </Section>

            <Section>
              {isDownload && (
                <SubContainer>
                  <UploadFilesContainer>
                    <label>Arquivo para download</label>
                    <DropzoneContainer>
                      <DropZone
                        onUpload={(file) => {
                          const formattedFile = {
                            name: file[0].name,
                            size: file[0].size,
                            type: file[0].type,
                            file_data: file[0],
                          };
                          setDownloadFile(formattedFile);
                        }}
                      />
                    </DropzoneContainer>

                    {downloadFile.name && (
                      <File>
                        <span>{downloadFile.name}</span>
                        <RiDeleteBin7Line
                          onClick={() => setDownloadFile({} as IFile)}
                          size={20}
                          color="var(--fixit)"
                        />
                      </File>
                    )}
                  </UploadFilesContainer>
                </SubContainer>
              )}
              <SubContainer>
                <UploadFilesContainer>
                  <label>Imagem de demonstração</label>
                  <DropzoneContainer>
                    <DropZone
                      accept={{'image/*': [".gif", ".jpeg",".jpg",".png"]}}
                      onUpload={(file) => {
                        const formattedFile = {
                          name: file[0].name,
                          size: file[0].size,
                          type: file[0].type,
                          file_data: file[0],
                        };
                        setFile(formattedFile);
                      }}
                    />
                  </DropzoneContainer>

                  {file.name && (
                    <File>
                      <span>{file.name}</span>
                      <RiDeleteBin7Line
                        onClick={() => setFile({} as IFile)}
                        size={20}
                        color="var(--fixit)"
                      />
                    </File>
                  )}
                </UploadFilesContainer>
              </SubContainer>
            </Section>

            <FlexButtons>
              <div>
                <Cancel onClick={backPage} type="button">
                  Voltar
                </Cancel>
                <Submit type="submit">Cadastrar</Submit>
              </div>
            </FlexButtons>
          </Flex>
        </form>
      </Container>
    </>
  );
}
