import styled from "styled-components";

type LicenseeButtonProps = {
  is_blocked: boolean;
};

export const ButtonInput = styled.div`
  width: 100%;
  justify-content: center;
`;

export const GeneralContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 2px solid var(--fixit);
  border-radius: 10px;
  padding: 7px;
`;

export const InputContainer = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  justify-items: center;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 10px;
  padding: 0;
  transition: all 0.3s;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const LicenseeListContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const LicenseeButton = styled.button<LicenseeButtonProps>`
  width: 100%;
  height: 120px;
  gap: 5px;
  border: 2px solid
    ${({ is_blocked }) => (is_blocked ? "var(--error)" : "var(--finished)")};
  border-radius: 10px;
  display: flex;
  justify-items: center;
  flex-direction: column;
  background-color: white;

  padding: 10px 10px;

  margin-top: 10px;
  transition: all 0.3s;
  cursor: pointer;

  @media (max-width: 480px) {
    width: 100%;
  }

  :active {
    box-shadow: 0 3px #666;
    transform: translateY(4px);
  }
`;

export const CardName = styled.span`
  font-size: 16px;
  color: var(--dark-gray);
  margin: 10px 0px 10px 0px;

  label {
    color: black;
    margin-left: 5px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const RowFarContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
`;

export const RowNearContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  margin: 10px 0px 10px 0px;
`;

export const LicenseeButtonContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  height: 250px;

  overflow-y: scroll;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const SubmitButton = styled.div`
  background-color: var(--fixit);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: 35px;
  font-size: 20px;
  color: var(--fixit);
  cursor: pointer;
  border: 2px solid var(--gray);
  border-radius: 20px;
  transition: 0.5s;
  color: white;

  &:hover {
  }
`;

export const JustifyInput = styled.textarea`
  padding: 5px 5px;
  height: 100%;
  width: 100%;
  border: 2px solid var(--gray);
  border-radius: 12px;
  font-size: 16px;
  color: var(--dark-gray);
  transition: 0.5s;
  resize: none;
  &:focus {
    border-color: var(--fixit);
  }
`;

export const InputHeader = styled.header`
  font-weight: 500;
  text-align: center;
  color: var(--fixit);
  font-size: 22px;
  padding: 10px 0 0 0;
`;

export const LicenseeSelectedText = styled.span`
  text-align: center;
  font-weight: 500;
  color: var(--dark-gray);
  font-size: 20px;
  margin: 10px 0px 10px 0px;
`;

export const NewFixPointContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const Text = styled.h2`
  font-weight: 400;
  text-align: center;
  color: var(--dark-gray);
  font-size: 16px;
  padding: 10px 0 0 0;
`;

export const CloseButton = styled.button`
  background: inherit;
  color: var(--fixit);
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const Language = styled.select`
  width: fit-content;
  cursor: pointer;
  color: var(--gray);
  font-size: 12px;
  font-weight: 500;
  background-color: var(--background);
  border: 1px solid var(--gray);
  border-radius: 15px;
  padding: 2px 5px 2px 5px;
  margin-left: 10px;

  margin-top: 10px;

  option {
    color: var(--dark-gray);
    font-size: 12px;
    width: fit-content;
  }

  &:hover {
    color: var(--fixit);
    border: 1px solid var(--fixit);
  }
`;
