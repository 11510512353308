import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { TipsModal } from "../components/TipsModal";
import { ITips } from "../modules/Admin/pages/Tips/dtos";
import api from "../services/api";
import { useAuth } from "./AuthContext";

interface TipsContextData {}

interface ProviderProps {
  children?: ReactNode;
}

const TipsContext = createContext<TipsContextData>({} as TipsContextData);

function TipsProvider({ children }: ProviderProps) {
  const { user } = useAuth();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [tips, setTips] = useState<ITips[]>([]);
  const [globalTip, setGlobalTip] = useState<ITips>();

  async function getPersonTipsRelation(id: string) {
    try {
      const response = await api.get(
        `/person/check-tips?tips_id=${id}`
      );

      return response.data.dont_show_again;
    } catch (error) {
      return;
    }
  }

  async function CreatePersonTipsRelation(
    currentTip: ITips,
    dontShowAgain: boolean
  ) {
    try {
      const body = {
        person_id: user.person.id,
        tips_id: currentTip.id,
        dont_show_again: dontShowAgain,
      };

      await api.post("/person/person-tips", body);

      if (dontShowAgain) {
        setGlobalTip(undefined);
        setTips([]);
      }
    } catch (error) {
      return;
    }
  }

  async function getTipsByLocation(route: string) {
    try {
      const response = await api.get(
        `/tips/routes?routes=${route}&is_active=${true}&type=specific`
      );

      setTips([response.data]);
    } catch (error) {
      return;
    }
  }

  async function getGlobalTips() {
    try {
      const response = await api.get(
        `/tips/routes?type=global&is_active=${true}`
      );

      setGlobalTip(response.data);
    } catch (error) {
      return;
    }
  }

  const toggleModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    getTipsByLocation(location.pathname.slice(1));
  }, [location]);

  useEffect(() => {
    if (tips?.length > 0 && tips[0] !== null) {
      tips?.map(async (tip) => {
        if (tip.route === location.pathname.slice(1)) {
          const dont_show_again = await getPersonTipsRelation(tip.id);
          !dont_show_again && setOpen(true);
        }
      });
    }
  }, [tips]);

  useEffect(() => {
    if (globalTip && globalTip !== null) {
      if (user) {
        getPersonTipsRelation(globalTip.id).then((response) => {
          !response && setOpen(true);
        });
      }
    }
  }, [globalTip]);

  useEffect(() => {
    getGlobalTips();
  }, [user]);

  return (
    <TipsContext.Provider value={tips}>
      {globalTip && (
        <TipsModal
          createTipsRelation={CreatePersonTipsRelation}
          tip={globalTip}
          open={open}
          closeModal={toggleModal}
        />
      )}

      {!globalTip &&
        tips[0] !== null &&
        tips.map((tip) => {
          return (
            <TipsModal
              createTipsRelation={CreatePersonTipsRelation}
              tip={tip}
              open={open}
              closeModal={toggleModal}
            />
          );
        })}

      {children}
    </TipsContext.Provider>
  );
}

function useTips(): TipsContextData {
  const context = useContext(TipsContext);

  if (!context) {
    throw new Error("useTips must be used within an TipsProvider");
  }

  return context;
}

export { TipsProvider, useTips };
