import styled from 'styled-components';
import global from '../../../../global';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
`;

export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 15px;

`

export const ButtonIcon = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color .2s;

  &.active, &:hover {
    background-color: var(--light-gray);
  }

  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-right: 18px;
  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    margin-right: 10px;
  }
`;