import { useState } from "react";

import { differenceInMinutes } from "date-fns";

import { UpdatedAt } from "./styles";
import { i18n } from "../../localization/i18n";

interface UpdatedAtTextProps {
  lastUpdate: string | Date;
}

export function UpdatedAtText({ lastUpdate }: UpdatedAtTextProps) {
  const [verifiedDate, setVerifiedDate] = useState(new Date());

  setInterval(() => {
    setVerifiedDate(new Date());
  }, 60000);

  return (
    <UpdatedAt>
      {`${i18n.t('customSolution.updated_at')}`} {differenceInMinutes(verifiedDate, new Date(lastUpdate))}{" "} {`${i18n.t('customSolution.updated_at_suffix')}`}
    </UpdatedAt>
  );
}
