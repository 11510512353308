import { AxiosError } from "axios";
import { size } from "lodash";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FiTrash } from "react-icons/fi";
import { RiSaveLine } from "react-icons/ri";
import { showToast } from "../../../../components/CustomToast";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { ISolution, ISolutionStandard } from "../../dtos";
import { Container, Header, LinkButton, Subtitle, SwitchFormGroup, SwitchFormControlLabel, Title, TitleContainer, FixitSwitch, InputLine, InputContainer, Content, SectionFormTitle, DragItem, Icon, DragContainer } from "./styles";

interface IBoxStandardSolutionParams {
    solution: ISolution;
    uploadFase?: string;
    submit: (enable: boolean, parts: string[]) => void;
    close: () => void;
}

interface ISolutionParts {
    number: number;
    parts: string[];
}

export function BoxStandardSolution ({ solution, uploadFase, submit, close } : IBoxStandardSolutionParams) {
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    const [enable, setEnable] = useState(solution.is_standard);
    const [solutionParts, setSolutionParts] = useState<ISolutionParts>();

    const [standards, setStandards] = useState<ISolutionStandard[]>();

    const [editableSize, setEditableSize] = useState<ISolutionStandard>(); 
    const [newSize, setNewSize] = useState<ISolutionStandard>();

    useEffect(() => {
        setEnable(solution.is_standard)
    }, [solution.is_standard])

    useEffect(() => {
        setSolutionParts({
            number: solution.parts.length,
            parts: solution.parts
        })
    }, [solution.parts])

    useEffect(() => {
        setStandards(solution.standards)
    }, [solution.standards])

    useEffect(() => {
        setEditableSize(undefined)
        if (standards && JSON.stringify(standards) != JSON.stringify(solution.standards)) {
            api.put(`/solutions/${solution.id}/sort_standards`, {
                standards: standards,
              }).then(() => {
                solution.standards = standards;
                showToast({ type: "success", message: "Padrões atualizados com sucesso!" });
              }).catch((error: AxiosError) => {
                setStandards([...solution.standards])
                showToast({
                  type: "error",
                  message: `Erro ao editar os padrões! Detalhes: ${error.message}`,
                });
              });
        }
    }, [standards])

    useEffect(() => {
        if (solutionParts && solutionParts.number > solutionParts.parts.length) {
          setSolutionParts({
            number: solutionParts?.number ?? 0,
            parts: [
              ...solutionParts?.parts ?? [],
              ...Array.from(
                { length: solutionParts.number - solutionParts.parts.length },
                (v, k) => solution.parts[solutionParts.parts.length + k] ?? ""
              ),
            ]
          });
          return;
        }
    
        if (solutionParts && solutionParts.number < solutionParts.parts.length) {
         setSolutionParts({
            number: solutionParts.number ?? 0,
            parts: solutionParts.parts.slice(0, solutionParts.number),
          });
          return;
        }
      }, [solutionParts]);


    const onDragEnd = (result: any) => {
        if(!result.destination || !standards) return;

        const items = [...standards];

        const [reorderedItem] = items.splice(result.source.index, 1);

        items.splice(result.destination.index, 0, reorderedItem)

        setStandards(items.map((item, index) => ({...item, order: index})));
    };


    return (
        <>
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>{`${i18n.t('solutions.standard_update_title')}`}</Title>
                        <Subtitle>{`${i18n.t('solutions.standard_update_subtitle')}`}</Subtitle>
                    </TitleContainer>
                    <LinkButton disabled={!!uploadFase || (enable === solution.is_standard && 
                            JSON.stringify(solution.parts) == JSON.stringify(solutionParts?.parts ?? []))}
                        onClick={() => {
                            submit(enable, solutionParts?.parts ?? []);
                        }} >
                        <RiSaveLine size={16} />
                        {`${i18n.t('orders.actions.save')}`}
                    </LinkButton>
                    
                </Header>
                <SwitchFormGroup>
                    <SwitchFormControlLabel labelPlacement="start" control={
                        <FixitSwitch checked={enable} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEnable(event.target.checked)
                        }} />
                        } label={`${i18n.t('solutions.download_file_enable')}`} />
                </SwitchFormGroup>
                
                {
                    <Content>
                        <InputLine width="320px" wrap={true}>
                            <InputContainer width="70px">
                                <OutlinedInput
                                    inputName="number_parts"
                                    type="number"
                                    min={1}
                                    label={i18n.t("solutions.number_parts")}
                                    handleChange={(event) => {
                                        setSolutionParts({
                                            parts: solutionParts?.parts ?? [],
                                            number: Number(event)
                                        })
                                    }}
                                    value={solutionParts?.number?.toString() ?? "0"}
                                />
                            </InputContainer>

                            {solutionParts?.parts?.map((part, i) => (
                                <InputContainer key={`part-${i}`} width="100px">
                                    <OutlinedInput
                                        inputName={`part-${i}`}
                                        type="text"
                                        
                                        label={`${i18n.t("solutions.part")} ${i + 1}`}
                                        handleChange={(event) => {
                                            setSolutionParts({
                                                number: solutionParts.number ?? 0,
                                                parts: solutionParts.parts.map((part, index) =>
                                                    index === i ? event : part
                                                )
                                            })
                                        }}
                                        value={solutionParts.parts[i]}
                                    />
                                </InputContainer>
                            ))}
                        </InputLine>

                        <SectionFormTitle>{`${i18n.t('solutions.size_patterns')}`}</SectionFormTitle>
                        <DragContainer>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId={solution.id}>
                                    {
                                        (provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {
                                                    standards?.map((standard, index) => (<Draggable key={standard.id} draggableId={standard.id} index={index}>
                                                        {(provided) => (
                                                            <DragItem id={standard.id} {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                                <Content>
                                                                    <OutlinedInput
                                                                        label={`Order ${standard.order + 1}`}
                                                                        inputName={`${standard.id}`}
                                                                        type="text"
                                                                        onFocus={() => {
                                                                            setEditableSize({...standard})
                                                                        }}
                                                                        onBlur={() => {
                                                                            if (editableSize && standard.order === editableSize.order && standard.size !== editableSize.size) {
                                                                                setStandards((prevState) => prevState?.map(s => 
                                                                                    s.order === editableSize?.order ? {...editableSize} : s
                                                                                ))
                                                                            }
                                                                        }}
                                                                        readOnly={!editableSize || editableSize?.order !== standard.order}
                                                                        handleChange={(event) => {
                                                                            if(editableSize?.id && editableSize.order === standard.order) {
                                                                            setEditableSize({
                                                                                    id: standard.id,
                                                                                    order: standard.order,
                                                                                    size: event
                                                                            }) 
                                                                            }
                                                                        }}
                                                                        value={`${editableSize && editableSize.order === standard.order ? editableSize.size : standard.size}`}
                                                                    />
                                                                </Content>
                                                            </DragItem>
                                                        )}
                                                    </Draggable>
                                                    )
                                                )
                                                }
                                                {provided.placeholder}
                                            </div>
                                        )
                                    }
                                </Droppable>
                            </DragDropContext>
                        </DragContainer>
                        <OutlinedInput
                            inputName={`New size`}
                            type="text"
                            label={`Novo tamanho`}
                            onFocus={() => {
                                setNewSize({
                                    id: "",
                                    order: standards?.length ?? 0,
                                    size: ""
                                })
                            }}
                            onBlur={() => {
                                if (newSize && newSize.size.trim() !== "") {
                                    api.put(`/solutions/${solution.id}/append_standard`, {
                                        standard: { ...newSize, id: undefined },
                                      }).then(({ data }) => {
                                        setNewSize(undefined)
                                        const standard : ISolutionStandard = data;
                                        solution.standards = [...solution.standards, standard];
                                        // setStandards((prevState) => prevState ? [...prevState, standard] : [standard])
                                        showToast({ type: "success", message: "Padrões atualizados com sucesso!" });
                                      }).catch((error: AxiosError) => {
                                        showToast({
                                          type: "error",
                                          message: `Erro ao editar os padrões! Detalhes: ${error.message}`,
                                        });
                                      });
                                }
                            }}
                            readOnly={!newSize}
                            handleChange={(event) => {
                                if (newSize) {
                                    setNewSize({
                                        id: newSize.id,
                                        order: newSize.order,
                                        size: event
                                    }) 
                                }
                            }}
                            value={`${newSize?.size ?? ""}`}
                        />
                    </Content>
                }
                
            </Container>
        </>
    )
}