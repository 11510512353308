import { useNavigate } from "react-router";

import {
  Container,
  IconContainer,
  CardInfosContainer,
  CardTitle,
  CardDescription,
  NextText,
  Separator,
} from "./styles";
import Logo from "../../assets/symbol-purple_animation.svg";
import { i18n } from "../../localization/i18n";

interface HomeCardProps {
  title: string;
  icon: any;
  description: string;
  pageRoute: string;
  disabled?: boolean;
}

export function HomeCard({
  title,
  icon,
  description,
  pageRoute,
  disabled,
}: HomeCardProps) {
  const navigate = useNavigate();

  return (
    <Container onClick={() => !disabled && navigate(pageRoute)} disabled={disabled}>
      <IconContainer>
        {
          process.env.NODE_ENV === 'development' ? 
            (<img alt="HomeCard img" src={icon ?
              `${process.env.REACT_APP_STORAGE_BASE_URL}${icon}` :
              Logo} />) :
              (
                <embed src={
                  icon ?
                    `${process.env.REACT_APP_STORAGE_BASE_URL}${icon}` :
                    Logo
                  } />
              )
        }
      </IconContainer>

      <Separator />

      <CardInfosContainer>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>

        <NextText>{`${i18n.t("orders.clickToProceed")}` + ">"}</NextText>
      </CardInfosContainer>
    </Container>
  );
}
