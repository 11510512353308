import styled from 'styled-components'

export const Iframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  display: block;
  border: none;
  position: relative;
  overflow-y: hidden;
`
