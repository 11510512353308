import GoogleMapReact from "google-map-react";
import { FaMapPin } from "react-icons/fa";
import { Pin, PinIcon, PinText } from "./styles";

interface ILocationPinProps {
    lat: number;
    lng: number;
    text: string;
}

interface IReferentialProps {
    center: {
        lat: number,
        lng: number
    },
    zoom: number
}

interface IMapProps {
    locations: ILocationPinProps[]
    referential: IReferentialProps
}

const LocationPin = ({ text }: any) => (
    <Pin>
        <PinIcon>
            <FaMapPin />
        </PinIcon>
        <PinText>{text}</PinText>
    </Pin>
);

export default function GoogleMap(props: IMapProps) {

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <GoogleMapReact
                options={{
                    panControl: false,
                    mapTypeControl: false,
                    scrollwheel: false,
                    zoomControl: false
                }}
                bootstrapURLKeys={{ key: "AIzaSyBUk9IAVJMU_7XYDBA-colAOxiZ3_pBfGU", language: 'pt-BR', region: 'BR' }}
                defaultCenter={props.referential.center}
                defaultZoom={props.referential.zoom}
            >

                {props.locations.map((loc) => (
                    <LocationPin lat={loc.lat} lng={loc.lng} text={loc.text} />
                ))}

            </GoogleMapReact>
        </div>
    )
}