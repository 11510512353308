import styled from "styled-components";

export const Container = styled.div``;

export const StepTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  color: var(--dark-gray);
  text-align: center;
  margin-bottom: 20px;
`;

export const InputLine = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div`
  flex: 1;
  max-width: 100%;
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
`;
