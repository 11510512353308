import { UpdatedAtText } from "../UpdatedAtText";

import { RiArrowDownSLine } from "react-icons/ri";

import {
  Container,
  Header,
  IconContainer,
  CloseDivider,
  OpenDivider,
  InfosContentContainer,
  InfosContainer,
  EmptyTitle,
  Title,
  EmptySubtitle,
  Subtitle,
  PendingInfos,
  ContentContainer,
  OpenInfosContentContainer,
  UpdatedAt,
  OpenDividerText,
  Link,
  InfoContainer
} from "./styles";
import { ReactNode } from "react";
import { i18n } from "../../localization/i18n";

interface AccordionOrderProps {
  open: boolean;
  emptyTitle: string;
  emptySubtitle: string;
  title: string;
  subTitle: string;
  pendingInfos: boolean;
  disabled?: boolean;
  icon: string;
  openDividerText?: string;
  openDividerLink?: string;
  lastUpdate?: string | Date;
  handleChange: () => void;
  children?: JSX.Element;
  toogleComponent?: ReactNode;
  handleDiscard?: () => void;
  discardLabel?: string;
}

export function AccordionOrder({
  open,
  emptyTitle,
  emptySubtitle,
  title,
  subTitle,
  pendingInfos,
  icon,
  lastUpdate,
  handleChange,
  handleDiscard,
  discardLabel,
  children,
  openDividerText,
  openDividerLink,
  disabled = false,
  toogleComponent = (<RiArrowDownSLine size={30} color="#989898" />)
}: AccordionOrderProps) {

  return (
    <Container disabled={disabled}>
      <Header
        open={disabled ? false : open}
        disabled={disabled}
        onClick={() => {
          if (!disabled) {
            handleChange();
          }
        }}
      >
        <IconContainer>
          <img src={icon} alt="" />
        </IconContainer>

        {open && !disabled ? (
          <OpenInfosContentContainer>
            <EmptyTitle>{emptyTitle}</EmptyTitle>
            <OpenDivider />
            {openDividerText && (
              <OpenDividerText
                onClick={(event) => event.stopPropagation()}
                href={openDividerLink}
                target="_blank"
              >
                {openDividerText}
              </OpenDividerText>
            )}
          </OpenInfosContentContainer>
        ) : (
          <>
            <CloseDivider disabled={disabled} />

            <InfosContentContainer open={open}>
              <InfosContainer>
                {title ? (
                  <Title>{title}</Title>
                ) : (
                  <EmptyTitle>{emptyTitle}</EmptyTitle>
                )}

                {subTitle ? (
                  <Subtitle>{subTitle}</Subtitle>
                ) : (
                  <EmptySubtitle>{emptySubtitle}</EmptySubtitle>
                )}

                {pendingInfos && (
                  <PendingInfos>{`${i18n.t('orders.pending_info.text')}`}</PendingInfos>
                )}

                <InfoContainer>
                  {!pendingInfos && lastUpdate && (
                    <UpdatedAt>
                      <UpdatedAtText lastUpdate={lastUpdate} />
                    </UpdatedAt>
                  )}
                  {
                    ( handleDiscard && !pendingInfos) && (
                      <Link onClick={handleDiscard}>{discardLabel || 'Discard?'}</Link>
                    )
                  }
                </InfoContainer>                
              </InfosContainer>
            </InfosContentContainer>
          </>
        )}

        { toogleComponent }
      </Header>

      <ContentContainer open={disabled ? false : open}>
        {children}
      </ContentContainer>
    </Container>
  );
}
