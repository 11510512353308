import { useEffect, useState } from "react";

import { useAuth } from "../../../../hooks/AuthContext";

import { showToast } from "../../../../components/CustomToast";
import { DiagnosisModal } from "./components/DiagnosisModal";
import { DiagnosisItem } from "./components/DiagnosisItem";
import { DiagnosisSkeleton } from "./components/DiagnosisItem/Skeleton";
import { Filter } from "./components/Filter";
import AbsoluteButton from "../../../../components/AbsoluteButton";

import { getActiveLanguage } from "../../../../utils/getActiveLanguage";

import api from "../../../../services/api";

import { Diagnosis, FilterOptions } from "./dtos";

import { RiAddLine, RiCloseLine, RiFolderChartFill } from "react-icons/ri";

import { Container, ListContainer, Header, ActiveFilters } from "./styles";
import { OrthesesPerDiagnosisModal } from "./components/OrthesesPerDiagnosisModal";

export default function DiagnosisList() {
  const { setNavigationAction } = useAuth();

  const [filterOptions, setFilterOptions] = useState<FilterOptions | undefined>(
    { verified: true }
  );
  const [diagnoses, setDiagnoses] = useState<Diagnosis[]>([]);
  const [diagnosesToEdit, setDiagnosesToEdit] = useState<
    Diagnosis | undefined
  >();
  const [loading, setLoading] = useState(false);
  const [openDiagnosisModal, setOpenDiagnosisModal] = useState(false);
  const [orthesesPerDiagnosisModal, setOrthesesPerDiagnosisModal] =
    useState(false);

  function renderSkeleton() {
    const skeletonsQuantity = [];

    for (var i = 0; i < 5; i++) {
      skeletonsQuantity.push(<DiagnosisSkeleton />);
    }

    return skeletonsQuantity;
  }

  async function getDiagnosis() {
    setLoading(true);

    try {
      const params = {
        name: filterOptions?.name ?? undefined,
        cid: filterOptions?.cid ?? undefined,
        verified: filterOptions?.verified,
        language: getActiveLanguage(),
      };
      const response = await api.get("/diagnosis", { params });

      setDiagnoses(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao carregar os diagnósticos",
      });
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getDiagnosis();
  }, [filterOptions]);

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: "Diagnósticos",
    });
  }, []);

  return (
    <Container>
      <DiagnosisModal
        open={openDiagnosisModal}
        diagnosisToEdit={diagnosesToEdit}
        getDiagnosis={getDiagnosis}
        handleCloseModal={() => {
          setOpenDiagnosisModal(false);
          if (diagnosesToEdit) setDiagnosesToEdit(undefined);
        }}
      />

      <OrthesesPerDiagnosisModal
        open={orthesesPerDiagnosisModal}
        handleCloseModal={() => setOrthesesPerDiagnosisModal(false)}
      />

      <AbsoluteButton
        absolute
        onClick={() => {
          if (diagnosesToEdit) setDiagnosesToEdit(undefined);
          setOpenDiagnosisModal(true);
        }}
      >
        <RiAddLine />
      </AbsoluteButton>

      <Header>
        <ActiveFilters>
          {filterOptions?.name && (
            <div className="filter">
              <span>{filterOptions?.name}</span>

              <RiCloseLine
                onClick={() => {
                  setFilterOptions((prevState) => ({
                    ...prevState,
                    name: undefined,
                  }));
                }}
              />
            </div>
          )}

          {filterOptions?.cid && (
            <div className="filter">
              <span>{filterOptions?.cid}</span>

              <RiCloseLine
                onClick={() => {
                  setFilterOptions((prevState) => ({
                    ...prevState,
                    cid: undefined,
                  }));
                }}
              />
            </div>
          )}

          {filterOptions?.verified && (
            <div className="filter">
              <span>{filterOptions?.verified && "Verificados"}</span>

              <RiCloseLine
                onClick={() => {
                  setFilterOptions((prevState) => ({
                    ...prevState,
                    verified: false,
                  }));
                }}
              />
            </div>
          )}
        </ActiveFilters>

        <Filter
          filterOptions={filterOptions}
          onFilterApply={(filterOptions) => setFilterOptions(filterOptions)}
        />

        <RiFolderChartFill
          size={26}
          onClick={() => setOrthesesPerDiagnosisModal(true)}
        />
      </Header>

      <ListContainer>
        {diagnoses.map((item) => (
          <DiagnosisItem
            key={item.id}
            diagnosis={item}
            getDiagnosis={getDiagnosis}
            onDiagnosisEdit={(diagnosis) => {
              setDiagnosesToEdit(diagnosis);
              setOpenDiagnosisModal(true);
            }}
          />
        ))}

        {loading && diagnoses.length < 1 && (
          <>{renderSkeleton().map((item) => item)}</>
        )}
      </ListContainer>
    </Container>
  );
}
