import { useEffect, useRef, useState } from "react";
import { addMinutes, differenceInMilliseconds, format } from "date-fns";

import {
  Container,
  InputLine,
  CodeInputContainer,
  StepTitle,
  SendEmailText,
  StepSubtitle,
  CodeInput,
} from "./styles";
import { i18n } from "../../localization/i18n";

interface CodeProps {
  submitCode: () => void;
  deleteCode: () => void;
  resendCode: () => void;
  validateEmail?: () => Promise<boolean>;
  loading: boolean;
  setCode: (arg: {}) => void;
  setExpiredCode: (arg: boolean) => void;
}

let COUNTDOWN_TIMEOUT: NodeJS.Timeout;

export function Code({
  setCode = () => {},
  setExpiredCode = () => {},
  loading,
  resendCode,
}: CodeProps) {
  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);
  const ref6 = useRef<any>(null);

  const [errors, setErrors] = useState({} as any);
  const [timer, setTimer] = useState("");
  const [finalDate, setFinalDate] = useState<Date | null>(null);
  const [reCaptcha, setReCaptcha] = useState<any>();

  const [emailCode, setEmailCode] = useState([
    {
      value: "",
      ref: ref1
    },
    {
      value: "",
      ref: ref2
    },
    {
      value: "",
      ref: ref3
    },
    {
      value: "",
      ref: ref4
    },
    {
      value: "",
      ref: ref5
    },
    {
      value: "",
      ref: ref6
    },
  ])

  useEffect(() => {
    setCode(emailCode.map((item) => item.value).join(""));
  }, [emailCode])

  function clearError(prop: string) {
    setErrors((prevState: any) => ({
      ...prevState,
      [prop]: "",
    }));
  }

  useEffect(() => {
    setFinalDate(addMinutes(new Date(), 3));
  }, [])

  function resetDate() {
    setFinalDate(addMinutes(new Date(), 3));
    setExpiredCode(false);
  }

  function formatCountdown() {
    if (finalDate) {
      const difference = differenceInMilliseconds(finalDate, new Date());
      const differenceDate = new Date(difference);

      setTimer(format(differenceDate, "mm:ss"));
    }
  }

  useEffect(() => {
    if (timer !== "00:00") {
      COUNTDOWN_TIMEOUT = setTimeout(() => {
        formatCountdown();
      }, 1000);
    } else {
      setTimer("00:00");
      clearTimeout(COUNTDOWN_TIMEOUT);
      setExpiredCode(true);
    }
  }, [timer]);

  useEffect(() => {
    formatCountdown();
  }, [finalDate]);

  return (
    <Container>
        <>
          <StepTitle>{`${i18n.t("userRegistration.fillCodes")}`}</StepTitle>
          <StepSubtitle>
            {`${i18n.t("userRegistration.codeExpires")}`} {timer}
          </StepSubtitle>
          <InputLine>
            {emailCode.map((item, index) => {
              return (
                <CodeInputContainer key={index}>
                  <CodeInput
                    ref={item.ref}
                    maxLength={1}
                    onPaste={(event) => {
                      const codes = event.clipboardData.getData('text').split("");
                      if (codes.length === 6) {
                        const tmpEmailCode = emailCode.map((item, pos) => ({
                          ...item,
                          value: codes[pos]
                        }));

                        setEmailCode([...tmpEmailCode]);
                      }
                    }}
                    onChange={(event) => {
                      setEmailCode((prevstate) => {
                        const tmpCodes = [...prevstate];
                        tmpCodes[index].value = event.target.value.toUpperCase()

                        return tmpCodes
                      });

                      if (event.target.value === "") {
                        emailCode[index - 1]?.ref?.current?.focus()
                        return
                      }
                      emailCode[index + 1]?.ref?.current?.focus()
                    }}
                    value={item.value}
                    error={errors?.codes ? errors?.codes[index] : ""}
                  />
                </CodeInputContainer>
              )
            })}
          </InputLine>
          <SendEmailText
            onClick={() => {
              resetDate();
              resendCode();
            }}
          >
            {`${i18n.t("userRegistration.sendCode")}`}
          </SendEmailText>
        </>
    </Container>
  );
}
