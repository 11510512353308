import { Link } from "react-router-dom";

import { i18n } from "../../../localization/i18n";

import {
  RiLineChartLine,
  RiMenuLine,
  RiToolsLine,
  RiUserStarFill,
} from "react-icons/ri";

import { RouteButton } from "../styles";

interface MenuListProps {
  extendedMode: boolean;
}

export const SafMenuList = ({ extendedMode }: MenuListProps) => (
  <>
    <Link to="/saf/dashboard">
      <RouteButton extendedMode={extendedMode}>
        <RiLineChartLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.reports")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/saf/orders">
      <RouteButton extendedMode={extendedMode}>
        <RiMenuLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.requests")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/saf/licensees">
      <RouteButton extendedMode={extendedMode}>
        <RiUserStarFill size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.licensees")}`}</span>}
      </RouteButton>
    </Link>

    <Link to="/saf/configs">
      <RouteButton extendedMode={extendedMode}>
        <RiToolsLine size={28} />

        {extendedMode && <span>{`${i18n.t("sidebar.config")}`}</span>}
      </RouteButton>
    </Link>
  </>
);
