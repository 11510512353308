import { useState } from "react";

import { i18n } from "../../../../../../localization/i18n";
import * as Yup from "yup";

import { showToast } from "../../../../../../components/CustomToast";
import { OutlinedInput } from "../../../../../../components/OutlinedInput";
import { SolidButton } from "../../../../../../components/SolidButton";
import { SearchableSelect } from "../../../../../../components/SearchableSelect";

import { typesErrors } from "../../../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../../../utils/validateFormFields";
import { countries } from "../../../../../../utils/countries";

import {
  IAddressFormData,
  ILicenseeFormData,
  IPersonFormData,
} from "../../dtos.d";

import {
  Container,
  StepTitle,
  InputContainer,
  InputLine,
  Footer,
  CheckboxContainer,
  CheckBoxLine,
  CompanyContainer,
} from "./styles";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  Link,
} from "@mui/material";
import apiViaCep from "../../../../../../services/apiViaCep";

interface StepTwoProps {
  personFormData: IPersonFormData | undefined;
  licenseeFormData: ILicenseeFormData | undefined;
  addressFormData: IAddressFormData | undefined;
  setAddressFormData: any;
  companyAddressFormData: IAddressFormData | undefined;
  setCompanyAddressFormData: any;
  setPersonFormData: any;
  setLicenseeFormData: any;
  executeUserCreation: (createAsPerson: boolean) => Promise<any>;
  loading: boolean;
}

export function StepTwo({
  personFormData,
  licenseeFormData,
  addressFormData,
  companyAddressFormData,
  setPersonFormData,
  setLicenseeFormData,
  setAddressFormData,
  setCompanyAddressFormData,
  executeUserCreation,
  loading,
}: StepTwoProps) {
  const [errors, setErrors] = useState({} as any);

  const [country, setCountry] = useState("");
  const [companyCountry, setCompanyCountry] = useState("");
  const [isPersonRegistration, setIsPersonRegistration] = useState(true);

  const [registerCompanyAddress, setRegisterCompanyAddress] = useState(false)

  const formattedCountries = countries.map((country) => ({
    label: country.label,
    id: country.code,
    currency: country.currency,
    coin: country.coin,
  }));

  async function validateFields() {
    const body = {
      name: personFormData?.name,
      country: personFormData?.country?.id,
      companyCountry: addressFormData?.country.id,
      expertise: personFormData?.expertise,
      professional_number: personFormData?.professional_number,
      cpf: personFormData?.cpf,
      cellphone: personFormData?.cellphone,
      state: personFormData?.state,
      city: personFormData?.city,
      companyNumber: licenseeFormData?.cnpj,
      companyName: licenseeFormData?.companyName,
      fantasyName: licenseeFormData?.fantasyName,
      phone: licenseeFormData?.phone,
      email: licenseeFormData?.email,
      termsAccepted: licenseeFormData?.termsAccepted,
      address: addressFormData?.address,
      number: addressFormData?.number,
      cep: addressFormData?.cep,
      complement: addressFormData?.complement,
      district: addressFormData?.district,
      companyCep: companyAddressFormData?.cep,
      companyCity: companyAddressFormData?.city,
      companyState: companyAddressFormData?.state,
      companyAddress: companyAddressFormData?.address,
      companyAddressNumber: companyAddressFormData?.number,
      companyDistrict: companyAddressFormData?.district,
      billing_address: addressFormData?.billing_address,
      deliver_address: addressFormData?.deliver_address,
      registerCompanyAddress: registerCompanyAddress,
      isPersonRegistration: isPersonRegistration
    };

    const validationSchema = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      country: Yup.string().required(typesErrors.required),
      cpf: Yup.string().required(typesErrors.required),
      cellphone: Yup.string().required(typesErrors.required),
      expertise: Yup.string().required(typesErrors.required),
      professional_number: Yup.string().required(typesErrors.required),
      state: Yup.string().required(typesErrors.required),
      city: Yup.string().required(typesErrors.required),
      registerCompanyAddress: Yup.boolean(),
      isPersonRegistration: Yup.boolean(),
      companyNumber: Yup.string().when("isPersonRegistration", {
        is: false,
        then: Yup.string().required(typesErrors.required).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      companyName: Yup.string().when("isPersonRegistration", {
        is: false,
        then: Yup.string().required(typesErrors.required).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      fantasyName: Yup.string().when("isPersonRegistration", {
        is: false,
        then: Yup.string().required(typesErrors.required).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      phone: Yup.string().when("isPersonRegistration", {
        is: false,
        then: Yup.string().required(typesErrors.required).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      email: Yup.string().when("isPersonRegistration", {
        is: false,
        then: Yup.string().required(typesErrors.required).nullable(),
        otherwise: Yup.string().nullable(),
      }),
      termsAccepted: Yup.boolean()
        .test(
          "is-true",
          "O campo deve ser verdadeiro",
          (value) => value === true
        )
        .required(typesErrors.required),
      address: Yup.string().required(typesErrors.required),
      number: Yup.string().required(typesErrors.required),
      cep: Yup.string().required(typesErrors.required),
      district: Yup.string().required(typesErrors.required),
      companyAddress: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string().nullable(),
      }),
      companyCep: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string().nullable(),
      }),
      companyCity: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string().nullable(),
      }),
      companyState: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string().nullable(),
      }),
      companyAddressNumber: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string(),
      }),
      companyDistrict: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string().nullable(),
      }),
      companyCountry: Yup.string().when("registerCompanyAddress", {
        is: true,
        then: Yup.string().required(typesErrors.required),
        otherwise: Yup.string().nullable(),
      }),

    });

    const validation = await validateFormFields(body, validationSchema, {
      name: "",
      country: "",
      companyCountry: "",
      cpf: "",
      cellphone: "",
      expertise: "",
      state: "",
      professional_number: "",
      city: "",
      companyNumber: "",
      companyName: "",
      fantasyName: "",
      phone: "",
      email: "",
      termsAccepted: false,
      complement: "",
      cep: "",
      district: "",
      address: "",
      number: "",
      companyCep: "",
      companyCity: "",
      companyState: "",
      companyAddress: "",
      companyAddressNumber: "",
      companyDistrict: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });
      return;
    }

    executeUserCreation(isPersonRegistration);
    setErrors({});
  }
  // async function validateTermsOfUse() {
  //   const body = {
  //     termsAccepted: licenseeFormData?.termsAccepted ?? false,
  //   };

  //   const TermsOfUseSchema = Yup.object().shape({
  //     termsAccepted: Yup.bool().oneOf([true], typesErrors.required),
  //   });

  //   const validation = await validateFormFields(body, TermsOfUseSchema, {
  //     termsAccepted: "",
  //   });

  //   if (validation.status === 400) {
  //     setLicenseeErrors(validation.errors);
  //     return;
  //   }
  //   setLicenseeErrors({});
  // }

  // function startFieldsValidation() {
  //   isPersonRegistration
  //     ? validatePersonCreation().then(() =>
  //         validateTermsOfUse().then(() => validateAddressCreation())
  //       )
  //     : validatePersonCreation().then(() =>
  //         validateLicenseeCreation().then(() => validateAddressCreation())
  //       );
  // }
  // async function validatePersonCreation() {
  //   const body = {
  //     name: personFormData?.name,
  //     country: personFormData?.country?.id,
  //     expertise: personFormData?.expertise,
  //     professional_number: personFormData?.professional_number,
  //     cpf: personFormData?.cpf,
  //     cellphone: personFormData?.cellphone,
  //     state: personFormData?.state,
  //     city: personFormData?.city,
  //   };

  //   const stepTwoSchema = Yup.object().shape({
  //     name: Yup.string().required(typesErrors.required),
  //     country: Yup.string().required(typesErrors.required),
  //     cpf: Yup.string().required(typesErrors.required),
  //     cellphone: Yup.string().required(typesErrors.required),
  //     expertise: Yup.string().required(typesErrors.required),
  //     professional_number: Yup.string().required(typesErrors.required),
  //     state: Yup.string().required(typesErrors.required),
  //     city: Yup.string().required(typesErrors.required),
  //   });

  //   const validation = await validateFormFields(body, stepTwoSchema, {
  //     name: "",
  //     country: "",
  //     cpf: "",
  //     cellphone: "",
  //     expertise: "",
  //     state: "",
  //     professional_number: "",
  //     city: "",
  //   });

  //   if (validation.status === 400) {
  //     setPersonErrors(validation.errors);
  //     return;
  //   }
  //   setPersonErrors({});
  // }

  // async function validateLicenseeCreation() {
  //   const body = {
  //     companyNumber: licenseeFormData?.cnpj,
  //     companyName: licenseeFormData?.companyName,
  //     fantasyName: licenseeFormData?.fantasyName,
  //     country: licenseeFormData?.country?.id,
  //     state: licenseeFormData?.state,
  //     city: licenseeFormData?.city,
  //     phone: licenseeFormData?.phone,
  //     email: licenseeFormData?.email,
  //     termsAccepted: licenseeFormData?.termsAccepted,
  //   };

  //   const stepThreeSchema = Yup.object().shape({
  //     companyNumber: Yup.string().required(typesErrors.required).nullable(),
  //     companyName: Yup.string().required(typesErrors.required).nullable(),
  //     fantasyName: Yup.string().required(typesErrors.required).nullable(),
  //     country: Yup.string().required(typesErrors.required).nullable(),
  //     state: Yup.string().required(typesErrors.required).nullable(),
  //     city: Yup.string().required(typesErrors.required).nullable(),
  //     phone: Yup.string().required(typesErrors.required).nullable(),
  //     email: Yup.string().required(typesErrors.required).nullable(),
  //     termsAccepted: Yup.boolean()
  //       .test(
  //         "is-true",
  //         "O campo deve ser verdadeiro",
  //         (value) => value === true
  //       )
  //       .required(typesErrors.required),
  //   });

  //   const validation = await validateFormFields(body, stepThreeSchema, {
  //     companyNumber: "",
  //     companyName: "",
  //     fantasyName: "",
  //     country: "",
  //     state: "",
  //     city: "",
  //     phone: "",
  //     email: "",
  //     termsAccepted: false,
  //   });

  //   if (validation.status === 400) {
  //     setLicenseeErrors(validation.errors);
  //     return;
  //   }
  //   setLicenseeErrors({});
  // }

  // async function validateAddressCreation() {
  //   const body = {
  //     address: addressFormData?.address,
  //     number: addressFormData?.number,
  //     cep: addressFormData?.cep,
  //     complement: addressFormData?.complement,
  //     district: addressFormData?.district,
  //     city: addressFormData?.city,
  //     state: addressFormData?.state,
  //     country: addressFormData?.country.id,
  //     billing_address: addressFormData?.billing_address,
  //     deliver_address: addressFormData?.deliver_address,
  //   };

  //   const yup_validation = Yup.object().shape({
  //     address: Yup.string().required(typesErrors.required),
  //     number: Yup.string().required(typesErrors.required),
  //     cep: Yup.string().required(typesErrors.required),
  //     district: Yup.string().required(typesErrors.required),
  //     state: Yup.string().required(typesErrors.required),
  //     city: Yup.string().required(typesErrors.required),
  //     country: Yup.string().required(typesErrors.required),
  //   });

  //   const validation = await validateFormFields(body, yup_validation, {
  //     complement: "",
  //     cep: "",
  //     district: "",
  //     address: "",
  //     number: "",
  //     state: "",
  //     city: "",
  //     country: "",
  //   });

  //   if (validation.status === 400) {
  //     setAddressesErrors(validation.errors);
  //     showToast({
  //       type: "error",
  //       message: i18n.t("form_errors.alert"),
  //     });

  //     return;
  //   }

  //   executeUserCreation(isPersonRegistration);
  //   setAddressesErrors({});
  // }

  async function searchCEP(cep: string) {
    return await apiViaCep.get(`/${cep}/json`)
  }

  function clearError(prop: string) {
    setErrors((prevState: any) => ({
      ...prevState,
      [prop]: "",
    }));
  }

  return (
    <Container>
      <StepTitle>
        {isPersonRegistration
          ? `${i18n.t("userRegistration.fillData")}`
          : `${i18n.t("userRegistration.fillManagerData")}`}
      </StepTitle>
      <CheckboxContainer>
        <FormGroup row={true}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                checked={isPersonRegistration}
                onChange={() => setIsPersonRegistration(true)}
                sx={{
                  color: `var(--fixit)`,
                  "&.Mui-checked": {
                    color: `var(--fixit)`,
                  },
                }}
              />
            }
            label="Pessoa Física"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!isPersonRegistration}
                onChange={() => {
                  setIsPersonRegistration(false)
                  setRegisterCompanyAddress(false)
                  setCompanyAddressFormData()
                }}
                sx={{
                  color: `var(--fixit)`,
                  "&.Mui-checked": {
                    color: `var(--fixit)`,
                  },
                }}
              />
            }
            label="Pessoa Jurídica"
          />
        </FormGroup>
      </CheckboxContainer>
      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="name"
            label={`${i18n.t("userRegistration.name")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                name: event,
              }));
              clearError("name");
            }}
            value={personFormData?.name ?? ""}
            error={errors?.name}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="expertise"
            label={`${i18n.t("userRegistration.expertise")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                expertise: event,
              }));
              clearError("expertise");
            }}
            value={personFormData?.expertise ?? ""}
            error={errors?.expertise}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="councilNumber"
            label={`${i18n.t("userRegistration.professional_number")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                professional_number: event,
              }));
              clearError("professional_number");
            }}
            value={personFormData?.professional_number ?? ""}
            error={errors?.professional_number}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="cpf"
            label={`${i18n.t("userRegistration.cpf")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                cpf: event,
              }));
              clearError("cpf");
            }}
            type="number"
            value={personFormData?.cpf ?? ""}
            error={errors?.cpf}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="cellphone"
            label={`${i18n.t("userRegistration.cellphone")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                cellphone: event,
              }));
              clearError("cellphone");
            }}
            type="number"
            value={personFormData?.cellphone ?? ""}
            error={errors?.cellphone}
          />
        </InputContainer>
      </InputLine>

      {!isPersonRegistration && (
        <CompanyContainer>
          <StepTitle>{`${i18n.t("userRegistration.companyData")}`}</StepTitle>
          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="company_name"
                label={`${i18n.t("userRegistration.companyName")}`}
                handleChange={(event) => {
                  setLicenseeFormData((prevState: ILicenseeFormData) => ({
                    ...prevState,
                    companyName: event,
                  }));
                  clearError("companyName");
                }}
                value={licenseeFormData?.companyName ?? ""}
                error={errors.companyName}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                inputName="fantasy_name"
                label={`${i18n.t("userRegistration.tradingName")}`}
                handleChange={(event) => {
                  setLicenseeFormData((prevState: ILicenseeFormData) => ({
                    ...prevState,
                    fantasyName: event,
                  }));
                  clearError("fantasyName");
                }}
                value={licenseeFormData?.fantasyName ?? ""}
                error={errors.fantasyName}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="phone"
                label={`${i18n.t("userRegistration.cellphone")}`}
                handleChange={(event) => {
                  setLicenseeFormData((prevState: ILicenseeFormData) => ({
                    ...prevState,
                    phone: event,
                  }));
                  clearError("phone");
                }}
                type="number"
                value={licenseeFormData?.phone ?? ""}
                error={errors?.phone}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                inputName="licenseeEmail"
                label={`${i18n.t("userRegistration.email")}`}
                handleChange={(event) => {
                  setLicenseeFormData((prevState: ILicenseeFormData) => ({
                    ...prevState,
                    email: event,
                  }));
                  clearError("email");
                }}
                value={licenseeFormData?.email ?? ""}
                error={errors?.email}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="cnpj"
                label={`${i18n.t("userRegistration.companyNumber")}`}
                handleChange={(event) => {
                  setLicenseeFormData((prevState: ILicenseeFormData) => ({
                    ...prevState,
                    cnpj: event,
                  }));
                  clearError("companyNumber");
                }}
                value={licenseeFormData?.cnpj ?? ""}
                error={errors.companyNumber}
              />
            </InputContainer>
          </InputLine>
        </CompanyContainer>
      )}

      <InputLine>
        <InputContainer>
          <SearchableSelect
            label={`${i18n.t("userRegistration.country")}`}
            options={formattedCountries}
            onChange={(value) => setCountry(value)}
            onSelect={(selected) => {
              if (typeof selected === "string" || !selected?.id) {
                setErrors((prevState: any) => ({
                  ...prevState,
                  country: typesErrors.validCountry,
                }));
                setPersonFormData((prevState: IPersonFormData) => ({
                  ...prevState,
                  country: undefined,
                }));
                setLicenseeFormData((prevState: ILicenseeFormData) => ({
                  ...prevState,
                  country: undefined,
                }));
                setAddressFormData((prevState: IAddressFormData) => ({
                  ...prevState,
                  country: undefined,
                }));

                return;
              }

              setErrors((prevState: any) => ({
                ...prevState,
                country: "",
              }));
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                country: selected,
              }));
              setLicenseeFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                country: selected,
              }));
              setAddressFormData((prevState: IAddressFormData) => ({
                ...prevState,
                country: selected,
              }));

              clearError("country");
            }}
            defaultValue={country}
            error={errors?.country}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            label={`${i18n.t("address.cep")}`}
            inputName="cep"
            onBlur={() =>
              searchCEP(addressFormData?.cep.replace(/[^\d]/g, "") ?? "").then((response: any) => {
                setAddressFormData((prevState: IAddressFormData) => ({
                  ...prevState,
                  city: response.data.localidade,
                  state: response.data.uf,
                  address: response.data.logradouro,
                  district: response.data.bairro,
                }));
                setLicenseeFormData((prevState: ILicenseeFormData) => ({
                  ...prevState,
                  city: response.data.localidade,
                  state: response.data.uf,
                }));
                setPersonFormData((prevState: IPersonFormData) => ({
                  ...prevState,
                  city: response.data.localidade,
                  state: response.data.uf,
                }));

                clearError("district");
                clearError("address");
                clearError("state");
                clearError("city");
              })
            }

            handleChange={(event: any) => {
              setAddressFormData((prevState: IAddressFormData) => ({
                ...prevState,
                cep: event,
              }));
              clearError("cep");
            }}
            value={addressFormData?.cep || ""}
            mask={"99.999-999"}
            error={errors?.cep}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="city"
            label={`${i18n.t("userRegistration.city")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                city: event,
              }));
              setLicenseeFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                city: event,
              }));
              clearError("city");
            }}
            value={personFormData?.city ?? ""}
            error={errors?.city}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            inputName="state"
            label={`${i18n.t("userRegistration.state")}`}
            handleChange={(event) => {
              setPersonFormData((prevState: IPersonFormData) => ({
                ...prevState,
                state: event,
              }));
              setLicenseeFormData((prevState: ILicenseeFormData) => ({
                ...prevState,
                state: event,
              }));
              clearError("state");
            }}
            value={personFormData?.state ?? ""}
            error={errors?.state}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            label={`${i18n.t("address.address")}`}
            inputName="address"
            handleChange={(event) => {
              setAddressFormData((prevState: IAddressFormData) => ({
                ...prevState,
                address: event,
              }));
              clearError("address");
            }}
            value={addressFormData?.address || ""}
            error={errors?.address}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            label={`${i18n.t("address.number")}`}
            inputName="number"
            handleChange={(event) => {
              setAddressFormData((prevState: IAddressFormData) => ({
                ...prevState,
                number: event,
              }));
              clearError("number");
            }}
            value={addressFormData?.number || ""}
            error={errors?.number}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            label={`${i18n.t("address.complement")}`}
            inputName="complement"
            handleChange={(event: any) => {
              setAddressFormData((prevState: IAddressFormData) => ({
                ...prevState,
                complement: event,
              }));
            }}
            value={addressFormData?.complement || ""}
          />
        </InputContainer>

        <InputContainer>
          <OutlinedInput
            label={`${i18n.t("address.district")}`}
            inputName="district"
            handleChange={(event) => {
              setAddressFormData((prevState: IAddressFormData) => ({
                ...prevState,
                district: event,
              }));
              clearError("district");
            }}
            value={addressFormData?.district || ""}
            error={errors?.district}
          />
        </InputContainer>
      </InputLine>

      {isPersonRegistration &&
        <div style={{ width: "100%", textAlign: "end", padding: "1rem 0 0 0" }}>
          <p style={{ color: "var(--fixit)", cursor: "pointer" }} onClick={() => {
            setRegisterCompanyAddress(!registerCompanyAddress)
            setCompanyAddressFormData()
          }}>
            {registerCompanyAddress ? "-" : "+"}{`${i18n.t("userRegistration.registerCompanyAddress")}`}
          </p>
        </div>
      }

      {registerCompanyAddress &&
        <>
          <InputLine>
            <InputContainer>
              <SearchableSelect
                label={`${i18n.t("userRegistration.country")}`}
                options={formattedCountries}
                onChange={(value) => setCompanyCountry(value)}
                onSelect={(selected) => {
                  if (typeof selected === "string" || !selected?.id) {
                    setErrors((prevState: any) => ({
                      ...prevState,
                      companyCountry: typesErrors.validCountry,
                    }));
                    setCompanyAddressFormData((prevState: IAddressFormData) => ({
                      ...prevState,
                      country: undefined,
                    }));

                    return;
                  }

                  setErrors((prevState: any) => ({
                    ...prevState,
                    companyCountry: "",
                  }));
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    country: selected,
                  }));

                  clearError("companyCountry");
                }}
                defaultValue={companyCountry}
                error={errors?.companyCountry}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("address.cep")}`}
                inputName="companyCep"
                onBlur={() =>
                  searchCEP(companyAddressFormData?.cep.replace(/[^\d]/g, "") ?? "").then((response: any) => {
                    setCompanyAddressFormData((prevState: IAddressFormData) => ({
                      ...prevState,
                      city: response.data.localidade,
                      state: response.data.uf,
                      address: response.data.logradouro,
                      district: response.data.bairro,
                    }));

                    clearError("companyDistrict");
                    clearError("companyAddress");
                    clearError("companyState");
                    clearError("companyCity");
                  })
                }
                handleChange={(event: any) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    cep: event,
                  }));
                  clearError("companyCep");
                }}
                value={companyAddressFormData?.cep || ""}
                mask={"99.999-999"}
                error={errors?.companyCep}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                inputName="companyCity"
                label={`${i18n.t("userRegistration.city")}`}
                handleChange={(event) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    city: event
                  }))
                  clearError("companyCity");
                }}
                value={companyAddressFormData?.city ?? ""}
                error={errors?.companyCity}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                inputName="companyState"
                label={`${i18n.t("userRegistration.state")}`}
                handleChange={(event) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    state: event
                  }))
                  clearError("companyState");
                }}
                value={companyAddressFormData?.state ?? ""}
                error={errors?.companyState}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("address.address")}`}
                inputName="companyAddress"
                handleChange={(event) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    address: event,
                  }));
                  clearError("companyAddress");
                }}
                value={companyAddressFormData?.address || ""}
                error={errors?.companyAddress}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("address.number")}`}
                inputName="companyAddressNumber"
                handleChange={(event) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    number: event,
                  }));
                  clearError("companyAddressNumber");
                }}
                value={companyAddressFormData?.number || ""}
                error={errors?.companyAddressNumber}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("address.complement")}`}
                inputName="companyComplement"
                handleChange={(event: any) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    complement: event,
                  }));
                }}
                value={companyAddressFormData?.complement || ""}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                label={`${i18n.t("address.district")}`}
                inputName="companyDistrict"
                handleChange={(event) => {
                  setCompanyAddressFormData((prevState: IAddressFormData) => ({
                    ...prevState,
                    district: event,
                  }));
                  clearError("companyDistrict");
                }}
                value={companyAddressFormData?.district || ""}
                error={errors?.companyDistrict}
              />
            </InputContainer>
          </InputLine>
        </>
      }

      {/* <CheckBoxLine>
        <Checkbox
          checked={formData?.termsAccepted ?? false}
          handleChange={() => {
            setFormData((prevState: IPersonFormData) => ({
              ...prevState,
              termsAccepted: !prevState?.termsAccepted ?? true,
            }));
          }}
          error={errors?.termsAccepted}
        />
        <span>
          {`${i18n.t("userRegistration.termsOfUse")} `}
          <a href="/docs/user_terms" target="_blank">
            {`${i18n.t("userRegistration.terms")}`}
          </a>{" "}
          {`${i18n.t("userRegistration.connector")}`}{" "}
          <a href="/docs/privacy_policy" target="_blank">
            {`${i18n.t("userRegistration.policy")}`}
          </a>
          .
        </span>
      </CheckBoxLine> */}

      <CheckBoxLine>
        <FormControl required error={errors?.termsAccepted}>
          <FormControlLabel
            label={
              <div>
                {`${i18n.t("userRegistration.termsOfUse")} `}
                <Link
                  href="/docs/privacy_policy"
                  target="_blank"
                  sx={{
                    color: "var(--fixit)",
                  }}
                  underline="none"
                >
                  {`${i18n.t("userRegistration.policy")}`}
                </Link>
              </div>
            }
            labelPlacement="end"
            control={
              <Checkbox
                onChange={(event) => {
                  setLicenseeFormData((prevState: ILicenseeFormData) => ({
                    ...prevState,
                    termsAccepted: event.target.checked,
                  }));
                  event.target.checked && clearError("termsAccepted");
                }}
                name="termsAccepted"
                sx={{
                  color: errors?.termsAccepted
                    ? "var(--formError)"
                    : "var(--fixit)",
                  "&.Mui-checked": {
                    color: errors?.termsAccepted
                      ? "var(--formError)"
                      : "var(--fixit)",
                  },
                }}
              />
            }
          />
        </FormControl>
      </CheckBoxLine>

      <Footer>
        {/* <OutlinedButton
          buttonWidth={220}
          buttonHeight={42}
          text={i18n.t('userRegistration.prevStep')}
          onClick={prevStep}
        /> */}

        <SolidButton
          text={i18n.t("userRegistration.nextStep")}
          buttonWidth={220}
          buttonHeight={42}
          onClick={validateFields}
        />
      </Footer>
    </Container>
  );
}
