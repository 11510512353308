export default class JsonRpcReaderTransformer implements Transformer<string, string> {
  transform(chunk: string, controller: TransformStreamDefaultController<string>): void {
    let jsonData: { result?: string };

    try {
      jsonData = JSON.parse(chunk);
    } catch (e) {
      console.error('Erro ao fazer o parsing do JSON:', e);
      return;
    }

    if (jsonData.result === "ok") {
      controller.enqueue(jsonData.result);
    }
  }
}

