import { Container } from "./styles";
import Logo from "../../assets/logo.svg";
import { useAuth } from "../../hooks/AuthContext";
import { useEffect, useState } from "react";
import { Modal } from "../Modal";

export default function GlobalLayoutFooter() {
    const { user } = useAuth()
    const [terms, setTerms] = useState<string>();
    const [privacy, setPrivacy] = useState<string>();
    const [showTerms, setShowTerms] = useState(false);
    const [showPrivacy, setShowPrivacy] = useState(false);


    const userTerm = user?.person?.licensee?.signature[0]?.user_terms

    useEffect(() => {
        if (showTerms && !!userTerm) {
            fetch(
                `${process.env.REACT_APP_STORAGE_BASE_URL}${userTerm.document_link}`
            )
                .then((response) => response.text())
                .then((html) => {
                    setTerms(html);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [showTerms, userTerm]);

    useEffect(() => {
        if (showPrivacy && !privacy) {
            fetch(
                `${process.env.REACT_APP_STORAGE_BASE_URL}docs/user_terms/user_terms_2024.html`
            )
                .then((response) => response.text())
                .then((html) => {
                    setPrivacy(html);
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }, [showPrivacy]);


    return (
        <>
            <Modal open={showTerms} onRequestClose={() => setShowTerms(false)} modalWidth={1000}>
                {!!terms && (
                    <iframe
                        srcDoc={terms}
                        width="100%"
                        id="docFrame"
                        style={{ overflow: "hidden" }}
                        title="Terms and Conditions"
                        onLoad={() => {
                            var obj = document.getElementById(
                                "docFrame"
                            ) as HTMLIFrameElement;
                            if (obj && obj.contentWindow) {
                                obj.style.height =
                                    obj.contentWindow.document.body.scrollHeight + 60 + "px";
                            }
                        }}
                    ></iframe>
                )}
            </Modal>
            <Modal open={showPrivacy} onRequestClose={() => setShowPrivacy(false)} modalWidth={1000}>
                {!!terms && (
                    <iframe
                        srcDoc={privacy}
                        width="100%"
                        id="docFrame"
                        style={{ overflow: "hidden" }}
                        title="Privacy Policy"
                        onLoad={() => {
                            var obj = document.getElementById(
                                "docFrame"
                            ) as HTMLIFrameElement;
                            if (obj && obj.contentWindow) {
                                obj.style.height =
                                    obj.contentWindow.document.body.scrollHeight + 60 + "px";
                            }
                        }}
                    ></iframe>
                )}
            </Modal>
            <Container>
                <img src={Logo} alt="Fix it Logo" />
                <p>© {String(new Date().getFullYear())} Fix it Comercial LTDA</p>
                <a onClick={() => setShowTerms(true)}>Termos de uso</a>
                <a onClick={() => setShowPrivacy(true)}>Política de privacidade</a>
                <a href={`http://wa.me/+5511981669461`}
                    target="_blank"
                    rel="noreferrer"
                >Contato</a>
                <a href="https://api.usefixit.com/" target='_blank' rel="noopener noreferrer">Status</a>
            </Container>
        </>
    )
}