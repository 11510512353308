import React, { lazy, Suspense } from "react";
import { Loader } from "../../../../../../components/Loader";
import { i18n } from "../../../../../../localization/i18n";
import { Container, Header } from "./styles";

const ReactApexChart = lazy(() => import("react-apexcharts"));
interface IDashboardCard {
  header: string;
  icon: React.ReactNode;
  data: number[];
}
export function BarVerticalChartCard({ icon, header, data }: IDashboardCard) {
  const optionsYearByMonth = {
    colors: ["#9835D1"],
    labels: [
      i18n.t("dashboard.months.jan"),
      i18n.t("dashboard.months.feb"),
      i18n.t("dashboard.months.mar"),
      i18n.t("dashboard.months.apr"),
      i18n.t("dashboard.months.may"),
      i18n.t("dashboard.months.jun"),
      i18n.t("dashboard.months.jul"),
      i18n.t("dashboard.months.aug"),
      i18n.t("dashboard.months.sep"),
      i18n.t("dashboard.months.oct"),
      i18n.t("dashboard.months.nov"),
      i18n.t("dashboard.months.dec"),
    ],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: "var(--gray)",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined,
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false,
        },
      },
    },
    yaxis: {
      show: true,
    },
  };

  const seriesYearByMonth = [{ name: "Pedidos", data }];

  return (
    <Container>
      <Header>
        {icon}
        {header}
      </Header>
      <Suspense fallback={<Loader />}>
        <ReactApexChart
          options={optionsYearByMonth}
          series={seriesYearByMonth}
          type="bar"
          height={165}
          width={370}
        />
      </Suspense>
    </Container>
  );
}
