import ReactDOM from "react-dom";
import { ButtonHTMLAttributes, ReactNode } from "react";
import { Button } from "./styles";
import { RiAddFill } from "react-icons/ri";

interface AbsoluteButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  mobile?: boolean;
  absolute: boolean;
  onClick: () => any;
  children?: ReactNode;
  color?: string;
}

export default function AbsoluteButton({
  text,
  mobile,
  absolute,
  onClick,
  color = "var(--fixit)",
  children = <RiAddFill />
}: AbsoluteButtonProps) {
  return ReactDOM.createPortal(
    <Button color={color} onClick={onClick} absolute={absolute} circle={!text} >
      { children }
      {
       text && (
        <div className="button_text">{text}</div>
       ) 
      }
    </Button>,
    document.body
  );
}
