import styled, { css } from "styled-components";
import { FormControlLabel, FormGroup, styled as mui_styled, Switch } from "@mui/material";

type ButtonProps = {
  disabled?: boolean;
};

interface InputContainerProps {
  readonly width?: string;
  readonly wrap?: boolean;
}

interface DragItemProps {
  id: string;
}

export const Container = styled.div`
    position: absolute;
    right: 0px;
    top: 20px;
    width: max-content;

    border: 1px solid var(--light-gray);
    border-radius: 10px;

    padding: 20px 10px 10px 10px;

    background-color: var(--white);
    box-shadow: 4px 3px 8px var(--light-gray);

    list-style-type: none;
    z-index: 5;
`;

export const Title = styled.h1`
    color: var(--gray);
    font-size: 16px;
    padding-left: 5px;
`;

export const Subtitle = styled.div`
    padding-left: 5px;
    color: var(--light-gray);
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 15px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
`;

export const LinkButton = styled.button<ButtonProps>`
  font-size: 12px;
  border-radius: 6px;
  padding: 2px 5px 0px 5px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  color: ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--gray)")};
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `};

  svg {
    color: ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--gray)")};
  };

  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: absolute;
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  };
`;


export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`

export const SwitchFormControlLabel = mui_styled(FormControlLabel)(({ theme }) => ({
  color: "var(--gray)",
  fontSize: "16px"
}));

export const SwitchFormGroup = mui_styled(FormGroup)(({ theme }) => ({
  alignItems: "start",
  width: "310px"
}));

export const FixitSwitch = mui_styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: "var(--fixit)"
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: "var(--fixit)"
      }
    }
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main),
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  }
}));

export const Content = styled.div`
  margin-top: 5px;
  padding-bottom: 10px;
  width: 100%;
`;

export const InputLine = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom:27px;
  flex-wrap: ${(props) => props.wrap ? "wrap" : "nowrap"};
  width: ${(props) => props.width ?? "100%"};
`;

export const InputContainer = styled.div<InputContainerProps>`
  width: ${(props) => props.width ?? "100%"};
`;

export const SectionFormTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 14px;
  color: var(--gray);
  margin-top: 30px;
  margin-bottom: 10px;
  text-align: center;
`;

export const DragContainer = styled.div`
  max-height: 300px;
  overflow-y: auto;
`

export const DragItem = styled.div<DragItemProps>`
  width: 100%;

  display: flex;
  flex-direction: row;
  padding-left: 5px;

  align-items: center;
  justify-content: space-between;
`;

export const Icon = styled.div`
  width: 10%;
  color: var(--fixit);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    cursor: pointer;
  };
`