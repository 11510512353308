import styled from "styled-components";

export const BillsContainer = styled.div`
  background-color: white;
  border: 1px solid var(--light-gray);
  border-radius: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7px;
`;

export const Header = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  font-size: 18px;
  color: var(--gray);

  svg {
    color: var(--fixit);
    font-size: 24px;
  }
`;

export const ListContent = styled.div`
  padding: 10px 0;
  overflow: auto;
  height: 180px;
  width: 70%;
`;
