import styled from "styled-components";
import global from "../../../../global";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0 0 0;
  gap: 10px;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    margin-left: 0px;
    justify-content: end;
    gap: 10px;
  }
`;

export const FilterContainer = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
  }
`;

export const SolutionsContainer = styled.div`
  background-color: white;
  border-radius: 20px;
  height: 170px;
  border: 1px solid var(--light-gray);

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    height: fit-content;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem;
  gap: 10px;
`;

export const NumbersContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
`;

export const CarrouselContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
`;

export const Header = styled.span`
  font-size: 18px;
  color: var(--gray);
`;

export const SolutionsCarrousel = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
    padding: 0 0 1rem;
  }
`;

export const InfoGraphsContainer = styled.div`
  display: flex;
  height: fit-content;
  flex-direction: row;
  width: 100%;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    flex-direction: column;
  }
`;
