import { useState, useEffect } from "react";

import { useAuth } from "../../../../hooks/AuthContext";

import AbsoluteButton from "../../../AbsoluteButton";
import { showToast } from "../../../CustomToast";
import { CreatePaymentInfosModal } from "./components/CreatePaymentInfosModal";
import { PreferredMethodCard } from "./components/PreferredMethodCard";

import api from "../../../../services/api";

import { PaymentInfosData, PaymentMethodModal } from "./dtos";

import { PaymentInfosContainer, Content } from "./styles";
import { Modal } from "../../../Modal";
import NoInfo from "../../../NoInfo";
import { i18n } from "../../../../localization/i18n";

export function PaymentInfos() {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);
  const [createPaymentInfosModal, setCreatePaymentInfosModal] =
    useState<PaymentMethodModal>({
      open: false,
      methodId: null,
    });
  const [paymentInfos, setPaymentInfos] = useState<PaymentInfosData[] | null>(
    []
  );

  async function handleGetUserPaymentInfos() {
    setLoading(true);

    try {
      const response = await api.get(`/payment-infos`);

      if (response.data) {
        setPaymentInfos(response.data);
      }
    } catch (error) {
      showToast({
        message: "Ocorreu um erro ao carregar as informações de pagamento",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  async function deletePaymentInfo(
    payment_id: string,
    last_four_digits: string | undefined
  ) {
    try {
      await api.delete(`/payment-infos/${payment_id}`).then((response) => {
        setPaymentInfos(response.data);
        localStorage.removeItem(`${last_four_digits}`);
        showToast({
          type: "success",
          message: "Cartão removido com sucesso",
        });
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possivel deletar esse cartão",
      });
    }
  }

  useEffect(() => {
    handleGetUserPaymentInfos();
  }, []);

  return (
    <PaymentInfosContainer>
      <AbsoluteButton
        absolute
        onClick={() =>
          setCreatePaymentInfosModal({ open: true, methodId: null })
        }
      />

      <Modal open={createPaymentInfosModal.open} modalWidth={500}>
        <CreatePaymentInfosModal
          handleCloseModal={() =>
            setCreatePaymentInfosModal({ open: false, methodId: null })
          }
          onMethodUpsert={handleGetUserPaymentInfos}
          methodId={createPaymentInfosModal.methodId}
        />
      </Modal>
      {paymentInfos?.length === 0 ? (
        <NoInfo noInfo={`${i18n.t(`noInfo.card`)}`} />
      ) : (
        paymentInfos &&
        paymentInfos.map((paymentInfo) => (
          <Content>
            <PreferredMethodCard
              onDeleteRequest={() =>
                deletePaymentInfo(
                  paymentInfo.id,
                  paymentInfo.card_last_four_digits
                )
              }
              card_holder={paymentInfo?.card_holder}
              preferred_method={paymentInfo?.preferred_method}
              card_flag={paymentInfo?.card_flag}
              card_last_four_digits={paymentInfo?.card_last_four_digits}
            />
          </Content>
        ))
      )}
    </PaymentInfosContainer>
  );
}
