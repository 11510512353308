import styled from "styled-components";


interface InputContainerProps {
  readonly width?: string;
  readonly wrap?: boolean;
}

export const Container = styled.div`
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.form`
  margin-top: 26px;
`;

export const InputLine = styled.div<InputContainerProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom:27px;
  flex-wrap: ${(props) => props.wrap ? "wrap" : "nowrap"}
`;

export const InputContainer = styled.div<InputContainerProps>`
  width: ${(props) => props.width ?? "100%"}
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 26px;

  display: flex;
  justify-content: center;
`;
