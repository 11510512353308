import styled from 'styled-components'
import global from '../../../../global';

interface IChartAreaProps {
  maxWidth: number
}

export const Container = styled.div`
  padding: 16px;
  border-radius: 6px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
`

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;
`

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  justify-content: center;
  align-items: center;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    flex-direction: row;
  }
`

export const ChartArea = styled.div<IChartAreaProps>`
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 12px;
  background: var(--white);
  display: flex;
  flex-direction: column;

  justify-content: center;

  margin: 11px;

  width: 100%;
  max-width: ${props => props.maxWidth}px;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    max-width: 100%;

    margin: 10px;
  }
`

export const ResultArea = styled.div`
  display: flex;
  flex-direction: column;

`

export const ResultLabel = styled.label`
  margin-top: 6px;
  font-size: 40px;
  font-weight: bold;
  color: var(--fixit);

`


export const ChartTitle = styled.label`
  font-size: 18px;
  font-weight: 100;
  color: var(--gray);
`

export const ChartAreaOverlay = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  z-index: 1;
  border-radius: 6px;
  padding: 10px;

  background-color: #FFF;
  height: 220px;
`

export const Soon = styled.div`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100px;
  height: 30px;

  border-radius: 4px;
  background-color: var(--dark-gray);

  font-size: 16px;
  font-weight: 500;
  color: var(--white);
`
