import { InputHTMLAttributes } from "react";

import InputMask from "react-input-mask";

import { Container, Error } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  handleChange: (event: any) => any;
  value: string | number;
  error?: string;
  mask?: string;
  inputWidth?: number;
  inputHeight?: number;
}

export default function Input({
  handleChange,
  value,
  mask,
  error,
  inputWidth,
  inputHeight,
  ...rest
}: InputProps) {
  return (
    <Container inputHeight={inputHeight} inputWidth={inputWidth} error={error}>
      <InputMask
        className="input_element"
        mask={mask ?? ""}
        onChange={(event) => handleChange(event?.target?.value)}
        value={value}
        {...rest}
      />

      {error && <Error>{error}</Error>}
    </Container>
  );
}
