import styled from "styled-components";
import global from "../../global";

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  gap: 30px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    flex-direction: column;
    gap: 15px;
  }
`;

export const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 26rem;
  height: 40rem;
  padding: 15px;
  border-radius: 10px;
  gap: 10px;

  border: 1.5px solid var(--light-gray);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  background-color: var(--white);

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
  }
`;

export const UserCard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 10rem;
  border-radius: 10px;
  padding: 15px;
  gap: 10px;

  border: 1.5px solid var(--light-gray);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
`;

export const LicenseeDataContainer = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
`;

export const UserDivContent = styled.div`
  width: 100%;

  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  gap: 7px;
`;

export const LicenseeDataFlexPrincipal = styled.h2`
  font-size: 15px;
  font-weight: 400;
  color: var(--fixit);
`;

export const LicenseeDataFlexSecondary = styled.h2`
  font-size: 15px;
  font-weight: 400;
  color: var(--gray);
`;

export const UserCardTitle = styled.h1`
  font-size: 16px;
  font-weight: 600;

  color: var(--fixit);
`;

export const PictureContainer = styled.div`
  width: 100%;
  height: 12rem;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
`;

export const Avatar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 8.125rem;
  height: 8.125rem;

  img {
    width: 8rem;
    height: 8rem;
    border-radius: 100px;
  }

  border: 2px solid var(--fixit);
  border-radius: 100px;
`;

export const AvatarOptionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  padding: 2px;

  svg {
    color: var(--fixit);
  }
`;

export const Username = styled.h2`
  font-size: 14px;
  font-weight: 500;
  color: var(--fixit);
`;

export const MainLicenseeData = styled.h3`
  font-size: 13px;
  font-weight: 400;
  font-style: italic;
  color: var(--gray);
`;

export const Separator = styled.div`
  width: 80%;
  height: 1px;
  background-color: var(--fixit);
  margin-top: 15px;
`;

export const StatusContainer = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
  gap: 25px;
`;
export const StatusDivColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1px;
`;

export const StatusGrayText = styled.h2`
  color: var(--gray);
  font-size: 12px;
  font-weight: 400;
`;

export const StatusFixitText = styled.h2`
  color: var(--dark-gray);
  font-size: 15px;
  font-weight: 400;
`;

export const StatusDivRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  margin-bottom: 10px;

  svg {
    color: var(--fixit);

    :hover {
      cursor: pointer;
      border-bottom: 1px solid var(--fixit);
      transform: scale(1.1);
    }
  }
`;

export const DivColumnLicenseeAndUserCards = styled.div`
  width: 100%;
  display: flex;

  flex-direction: column;
  gap: 17px;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;

  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
export const LicenseeCard = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 29rem;
  padding: 15px;
  border-radius: 10px;
  gap: 20px;

  border: 1.5px solid var(--light-gray);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  background-color: var(--white);
`;

export const EditUserDiv = styled.div`
  display: flex;
  flex-direction: row;

  gap: 20px;

  svg {
    color: var(--fixit);
    :hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
`;
