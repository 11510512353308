import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1rem 0 0;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
`;

export const Header = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: var(--fixit);
`;
