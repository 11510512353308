import { i18n } from "../../../localization/i18n";
import { IOption } from "./dtos";

export const colors = [
  {
    optionText: i18n.t("customSolution.colors.aluminum"),
    value: "aluminum",
  },
  {
    optionText: i18n.t("customSolution.colors.yellow"),
    value: "yellow",
  },
  {
    optionText: i18n.t("customSolution.colors.skyBlue"),
    value: "skyBlue",
  },
  {
    optionText: i18n.t("customSolution.colors.cobaltBlue"),
    value: "cobaltBlue",
  },
  {
    optionText: i18n.t("customSolution.colors.darkBlue"),
    value: "darkBlue",
  },
  {
    optionText: i18n.t("customSolution.colors.metalicBlue"),
    value: "metalicBlue",
  },
  {
    optionText: i18n.t("customSolution.colors.oceanBlue"),
    value: "oceanBlue",
  },
  {
    optionText: i18n.t("customSolution.colors.white"),
    value: "white",
  },
  {
    optionText: i18n.t("customSolution.colors.bronze"),
    value: "bronze",
  },
  {
    optionText: i18n.t("customSolution.colors.gray"),
    value: "gray",
  },
  {
    optionText: i18n.t("customSolution.colors.golden"),
    value: "golden",
  },
  {
    optionText: i18n.t("customSolution.colors.silver"),
    value: "silver",
  },
  {
    optionText: i18n.t("customSolution.colors.black"),
    value: "black",
  },
  {
    optionText: i18n.t("customSolution.colors.orange"),
    value: "orange",
  },
  {
    optionText: i18n.t("customSolution.colors.oilOrange"),
    value: "oilOrange",
  },
  {
    optionText: i18n.t("customSolution.colors.lilac"),
    value: "lilac",
  },
  {
    optionText: i18n.t("customSolution.colors.ivory"),
    value: "ivory",
  },
  {
    optionText: i18n.t("customSolution.colors.natural"),
    value: "natural",
  },
  {
    optionText: i18n.t("customSolution.colors.pistachio"),
    value: "pistachio",
  },
  {
    optionText: i18n.t("customSolution.colors.pink"),
    value: "pink",
  },
  {
    optionText: i18n.t("customSolution.colors.purple"),
    value: "purple",
  },
  {
    optionText: i18n.t("customSolution.colors.darkPurple"),
    value: "darkPurple",
  },
  {
    optionText: i18n.t("customSolution.colors.metalicPurple"),
    value: "metalicPurple",
  },
  {
    optionText: i18n.t("customSolution.colors.green"),
    value: "green",
  },
  {
    optionText: i18n.t("customSolution.colors.darkGreen"),
    value: "darkGreen",
  },
  {
    optionText: i18n.t("customSolution.colors.forestGreen"),
    value: "forestGreen",
  },
  {
    optionText: i18n.t("customSolution.colors.tiffanyGreen"),
    value: "tiffanyGreen",
  },
  {
    optionText: i18n.t("customSolution.colors.red"),
    value: "red",
  },
];

export const sides = [
  {
    optionText: i18n.t("customSolution.sides.right"),
    value: "DIR",
  },
  {
    optionText: i18n.t("customSolution.sides.left"),
    value: "ESQ",
  },
  {
    optionText: i18n.t("customSolution.sides.unique"),
    value: "UNI",
  },
];

export const jobs = [
  {
    optionText: i18n.t("customSolution.jobs.physiotherapist.optionText"),
    value: i18n.t("customSolution.jobs.physiotherapist.value"),
  },
  {
    optionText: i18n.t("customSolution.jobs.orthopedist.optionText"),
    value: i18n.t("customSolution.jobs.orthopedist.value"),
  },
  {
    optionText: i18n.t("customSolution.jobs.occupationalTherapist.optionText"),
    value: i18n.t("customSolution.jobs.occupationalTherapist.value"),
  },
  {
    optionText: i18n.t("customSolution.jobs.other.optionText"),
    value: i18n.t("customSolution.jobs.other.value"),
  },
];

export const genders = [
  {
    optionText: i18n.t("customSolution.gender.male.optionText"),
    value: i18n.t("customSolution.gender.male.value"),
  },
  {
    optionText: i18n.t("customSolution.gender.female.optionText"),
    value: i18n.t("customSolution.gender.female.value"),
  },
];

export const startPartnerData = {
  is_person: true,
  name: "",
  phone: "",
  email: "",
  professional_number: "",
  cep: "",
  document_number: "",
  company_name: "",
};
export const startPatientData = {
  name: "",
  gender: {
    optionText: "",
    value: "",
  },
  age: "",
  months: "",
  weight: "",
  height: "",
  responsible: "",
  phone: "",
  email: "",
  document: "",
  natural_person: "",
};
export const startExamData = {
  any_indication: false,
  prior_use: false,
  professional_name: "",
  phone: "",
  diagnosis: [],
  other_diagnosis: "",
  prior_orthosis: "",
  measures: undefined,
  professional_job: {
    optionText: "",
    value: "",
  },
  side: {
    optionText: "",
    value: "",
  },
};

export const startPlanitData = {
  doctor_name: "",
  doctor_contact: "",
  body_part: "",
  injury_type: "",
  target: "",
  surgery_date: "",
};

export const startOrderData = {
  partner: startPartnerData,
  patient: startPatientData,
  exam: startExamData,
  planit: startPlanitData,
  urgencyType: "normal" as const,
  delivery_address: null,
  color: "",
  urgencyReason: "",
  notes: "",
  printer: {
    optionText: "",
    value: "",
  },
  status: i18n.t("orders.status.processing.value"),
  quantity: 1,
  standardFile: {},
  document: "",
  service_number: "",
};

export const orderTypeOptions: IOption[] = [
  { value: "is_partner", optionText: "B2B" },
  { value: "is_patient", optionText: "B2C" },
];
