import styled from 'styled-components';
import global from '../../../../global';

export const ContentContainer = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 120px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VideoContainer = styled.div`
  margin-top: 50px;
  width: 80%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
    .small.width.unit}) {
    width: 100vw;
    padding: 10px;
    height: calc(60vw - 200);
  }
`;