import { Container, PageTitle } from "./styles";

import developmentImg from "../../assets/development.svg";

import { i18n } from "../../localization/i18n";

export function InDevelopment() {
  return (
    <Container>
      <PageTitle>{`${i18n.t("global.underConstruction")}`}</PageTitle>

      <img src={developmentImg} alt="Em desenvolvimento" />
    </Container>
  );
}
