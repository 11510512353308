import { ButtonHTMLAttributes } from "react";

import { Container } from "./styles";

interface OutlinedButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  loading?: boolean;
  buttonWidth?: number;
  buttonHeight?: number;
  startWithMinWidth?: boolean;
  fullWidth?: boolean;
  buttonColor?: string;
}

export function OutlinedButton({
  text,
  buttonHeight = undefined,
  buttonWidth = undefined,
  loading = false,
  startWithMinWidth = false,
  fullWidth = false,
  buttonColor = "var(--fixit)",
  ...rest
}: OutlinedButtonProps) {
  return (
    <Container
      buttonHeight={buttonHeight}
      buttonWidth={buttonWidth}
      startWithMinWidth={startWithMinWidth}
      fullWidth={fullWidth}
      buttonColor={buttonColor}
      {...rest}
    >
      {loading ? <div className="loading" /> : <>{text}</>}
    </Container>
  );
}
