import { createGlobalStyle } from "styled-components";
import global from "../global";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    html, body, #root {
        height: 100%;
        overflow-x: hidden;
        background: #fafafa;
    }

    *, button, input {
        border: 0;
        outline: 0;

        font-family: 'Roboto', Arial, Helvetica, sans-serif;
    }

    span, p {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    :root {
    --primary: #36393f;
    --secondary: #2f3136;
    --tertiary: rgb(32,34,37);
    --quaternary: #292b2f;
    --quinary: #393d42;
    --senary: #828386;
    --background: #fafafa;
    --watermark: #f7f7f7;

    --white: #fff;
    --light-gray: #d6d6d6;
    --gray: #8a8c90;
    --dark-gray: #5C5C5C;

    --notification: #f84a4b;
    --formError: #D13438;
    --mention-detail: #F5B649;
    --whatsapp-color: #00F06E;
    --active-green: #03BD70;
    --active-green-hover: #05ab67;
    --active-orange: #FF5126;

    // Order status
    --canceled: #f84a4b;
    --sent: #8a8c90;
    --development: #0088ff;
    --finished: #03BD70;
    --printed: #9835D1;
    --warning: #FFCA51;
    --error: #FF5126;
    --success: #03BD70;

    //licensee status
    --onboarding: #40E0D0;

    --fixit: #9835D1;
    --fixit-hover: #6e00ad;
    --fixit-bg: #9835D133;
    --fixit-hover-bg: #9835D105;
  }

  @media (min-width: ${global.breakpoints.mobile}) {
    #root {
      overflow-y: overlay;
    }

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: var(--fixit) #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 8px;
    }

    *::-webkit-scrollbar-track {
      position: absolute;
      right: -3rem;
      top: -50rem;
      background: transparent;
    }

    *::-webkit-scrollbar-thumb {
      background-color: var(--fixit);
      border-radius: 20px;
      border: 2px solid #ffffff;
    }
  }
`;
