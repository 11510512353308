import { Dispatch, SetStateAction } from "react";
import { showToast } from "../../../../components/CustomToast";
import { IStandardFileData } from "../../../../components/UploadStandardFilesModal/dtos";
import api from "../../../../services/api";

interface IPartConfirmation {
    ETag: string;
    PartNumber: number;
}

interface FileData {
    name: string;
    size: number;
    type: string;
    file_data: any;
}

interface IFileServiceProps {
    file: FileData;
    data: IStandardFileData;
    setStateCallback: Dispatch<SetStateAction<number>>;
}

const chunkSize = 1024 * 1024 * 5;

export default class FileService {
    uploadId?: string;
    randomFileId?: string;
    partsConfirmation: IPartConfirmation[];
    currentChunkIndex: number | null;
    file: FileData;
    data: IStandardFileData;
    standard_id: string
    setStateCallback: Dispatch<SetStateAction<number>>;

    constructor(
        { file, data, setStateCallback }: IFileServiceProps
    ) {
        this.uploadId = "";
        this.randomFileId = "";
        this.partsConfirmation = [];
        this.currentChunkIndex = null;
        this.file = file;
        this.data = data;
        this.standard_id = "";
        this.setStateCallback = setStateCallback;
    }

    public async submitChunckedForm(): Promise<void> {
        if (!this.file?.file_data) {
            return;
        }

        this.currentChunkIndex = 0;
        this.readChunk();
    };

    private readChunk() {
        if (this.currentChunkIndex !== null && this.file.file_data) {
            if (this.currentChunkIndex == 0 && !this.uploadId && !this.randomFileId) {
                this.readAndUploadCurrentChunk();
            } else if (this.currentChunkIndex > 0 && !!this.uploadId && !!this.randomFileId) {
                this.readAndUploadCurrentChunk();
            }
        }
    }

    private async readAndUploadCurrentChunk() {
        if (this.currentChunkIndex == null) {
            return;
        }

        const reader = new FileReader();

        const from = this.currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = this.file.file_data.slice(from, to);
        reader.onload = async (e) => await this.uploadChunk(e);
        reader.readAsDataURL(blob);
    }

    private async getStandardSolution() {

        const query = {
            namekey: this.data.solution_name,
            size: this.data.size
        }
        try {
            const response = await api.get(`/solutionStandards`, { params: query });
            this.standard_id = response.data.id;
        } catch (error) {
            showToast({
                type: "error",
                message: `Não encontramos uma Standard Solution com esse namekey: ${this.data.solution_name} e tamanho: ${this.data.size}.`,
            });
        }
    }

    private async uploadChunk(readerEvent: ProgressEvent<FileReader>) {
        if (this.currentChunkIndex == null || !readerEvent.target || !this.file?.file_data) {
            showToast({
                type: "error",
                message: `Nenhum documento foi adicionado`,
            });
            return;
        }

        await this.getStandardSolution()

        const filesize = this.file.size;
        const chunks = Math.ceil(filesize / chunkSize) - 1;
        const isLastChunk = this.currentChunkIndex === chunks;

        const chunk = readerEvent.target.result;

        const params = new URLSearchParams();
        params.set("name", this.file.name);
        params.set("size", `${this.file.size}`);
        params.set("type", this.file.type);

        params.set("printer_id", this.data.printer.id);
        params.set("standard_id", this.standard_id);
        if (this.data.side) params.set("side", this.data.side);
        if (this.data.part) params.set("part", this.data.part);
        params.set("print_time", this.data.print_time);
        params.set("material_weight", this.data.material_weight);
        params.set("material_cost", this.data.material_cost);
        params.set("solution_name", this.data.solution_name || "");
        params.set("currentChunkIndex", this.currentChunkIndex.toString());
        params.set("totalChunks", Math.ceil(this.file.size / chunkSize).toString());
        if (this.uploadId) {
            params.set("UploadId", this.uploadId);
        }
        if (this.randomFileId) {
            params.set("randomFileId", this.randomFileId);
        }
        if (isLastChunk) {
            params.set("Parts", JSON.stringify(this.partsConfirmation));
        }

        const headers = { "Content-Type": "application/octet-stream" };

        try {

            if (this.standard_id !== "") {
                const response = await api.post(`/standard-files?${params.toString()}`, chunk, { headers });

                if (this.currentChunkIndex === 0) {
                    this.uploadId = response.data.UploadId;
                    this.randomFileId = response.data.randomFileId;
                }

                if (isLastChunk) {

                    this.setStateCallback(prevState => prevState + this.file.size);

                    this.partsConfirmation = [];
                    this.currentChunkIndex = null;
                    this.uploadId = undefined;
                    this.randomFileId = undefined;
                } else {
                    if (this.currentChunkIndex !== null) {
                        this.partsConfirmation = [
                            ...this.partsConfirmation,
                            { ETag: response.data.ETag, PartNumber: response.data.PartNumber },
                        ];

                        this.currentChunkIndex = this.currentChunkIndex + 1;
                        this.readChunk();
                    }
                }
            }
        } catch (e) {
            showToast({
                type: "error",
                message: `Erro no upload do arquivo: ${this.file.name}`,
            });
            this.uploadId = undefined;
            this.randomFileId = undefined;
            this.currentChunkIndex = null;
            this.partsConfirmation = [];
        }
    }

}