import styled from "styled-components";
import global from "../../../../../../global";

export const CreatePaymentInfosModalContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.div`
  .label {
    font-size: 16px;
    font-weight: 600;
    color: var(--dark-gray);
    margin-top: 20px;
  }

  .Mui-checked {
    color: var(--fixit) !important;
  }

  .MuiTypography-root {
    color: var(--dark-gray);
  }
`;

export const InputLine = styled.div`
  margin-top: 16px;

  display: flex;
  align-items: flex-start;
  gap: 16px;

  &.center {
    align-items: center;
    gap: 10px;
  }

  img {
    width: 36px;
    height: 36px;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.center {
      align-items: flex-start;
    }
  }
`;

export const InputContainer = styled.div`
  flex: 1;

  &.three_grid {
    min-width: 280px;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    &.three_grid {
      min-width: auto;
    }
  }
`;

export const Footer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    width: 100%;
  }
`;
