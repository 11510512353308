import styled from "styled-components";

export const OrthesesPerDiagnosisModalContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.div`
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const ListContainer = styled.div`
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .helpers {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .helper {
    font-size: 16px;
    color: var(--dark-gray);
  }

  .item_container {
    width: 100%;
  }

  .MuiCircularProgress-root {
    color: var(--fixit);
  }
`;
