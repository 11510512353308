import { AssociatesNotFoundContainer, AssociatesAndMapContainer, AssociatesContent, BackgroundContainer, ButtonContainer, Container, FormContainer, InputContainer, MapContainer, SliderContainer } from "./style";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { useEffect, useState } from "react";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import AssociatesItem from "./components/AssociatesItem";
import GoogleMap from "./components/GoogleMap";
import { showToast } from "../../../../components/CustomToast";
import { i18n } from "../../../../localization/i18n";

import * as Yup from "yup";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../utils/validateFormFields";
import api from "../../../../services/api";
import { Slider } from "@mui/material";


interface IAssociatesProps {
  id: string;
  name: string;
  phone: string;
  instagram: string;
  profile_picture: string;
  professional_number: string;
  distance: string;
  duration: string;
  rating: number;
  latLong: {
    lat: number;
    lng: number;
    text: string;
  }
}

export default function FindNearestFixiter() {
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('')
  const [cep, setCep] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [nearbyAssociates, setNearbyAssociates] = useState<IAssociatesProps[]>([])
  const [centerCoordinates, setCenterCoordinates] = useState({ lat: 0, lng: 0 })
  const [distanceRange, setDistanceRange] = useState(10)
  const [distanceOptions, setDistanceOptions] = useState({
    options: [
      { value: 10, label: '10 km' },
      { value: 25, label: '25 km' },
      { value: 50, label: '50 km' },
      { value: 100, label: '100 km' },
      { value: 200, label: '200 km' },
    ],
    max: 100
  })
  async function handleSearchAssociates() {
    setLoading(true)
    const body = {
      name,
      phone_number: phoneNumber,
      email,
      cep,
      range: distanceRange
    }

    const yup_validation = Yup.object().shape({
      name: Yup.string().required(typesErrors.required),
      phone_number: Yup.string().required(typesErrors.required),
      email: Yup.string().required(typesErrors.required),
      cep: Yup.string().required(typesErrors.required)
    })

    const validation = await validateFormFields(body, yup_validation, {
      name: '',
      phone_number: '',
      email: '',
      cep: ''
    })

    if (validation.status === 400) {
      setLoading(false)
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});

    try {
      await api.post('patients-leads/find-nearby-fixiters', body).then((response) => {
        setNearbyAssociates(response.data.nearby)
        setCenterCoordinates(response.data.center)
        localStorage.setItem('@FixitApp:Lead', response.data.lead.id)
      })

      setLoading(false)

    } catch (error) {
      showToast({
        type: "error",
        message: i18n.t("Não foi possivel buscar os associados mais próximos de você. Tente novamente mais tarde."),
      });
      setLoading(false)
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 1000) {
      setDistanceOptions({
        options: [
          { value: 10, label: '10 km' },
          { value: 25, label: '25 km' },
          { value: 50, label: '50 km' },
          { value: 100, label: '100 km' },
        ],
        max: 100,
      })
    } else {
      setDistanceOptions({
        options: [
          { value: 10, label: '10 km' },
          { value: 25, label: '25 km' },
          { value: 50, label: '50 km' },
          { value: 100, label: '100 km' },
          { value: 200, label: '200 km' },
        ],
        max: 200,
      })
    }
  }, [window.innerWidth])

  return (
    <BackgroundContainer>
      <Container>
        <FormContainer onSubmit={(event) => {
          event.preventDefault()
          handleSearchAssociates()
        }}>
          <InputContainer>
            <OutlinedInput readOnly={loading} error={errors?.name} handleChange={(event) => setName(event)} inputName={"name"} label={"Nome"} value={name} />
            <OutlinedInput readOnly={loading} error={errors?.phone_number} mask="(99) 99999-9999" handleChange={(event) => setPhoneNumber(event)} inputName={"phoneNumber"} label={"Numero de telefone"} value={phoneNumber} />
            <OutlinedInput readOnly={loading} error={errors?.email} handleChange={(event) => setEmail(event)} inputName={"email"} label={"E-mail"} value={email} />
            <OutlinedInput readOnly={loading} error={errors?.cep} mask="99999-999" handleChange={(event) => setCep(event)} inputName={"cep"} label={"CEP"} value={cep} />
          </InputContainer>

          <SliderContainer>
            <Slider
              aria-label="Distância"
              valueLabelDisplay="off"
              step={null}
              min={10}
              value={distanceRange}
              marks={distanceOptions.options}
              max={distanceOptions.max}
              color="secondary"
              onChange={(event, value) => setDistanceRange(value as number)}
            />
          </SliderContainer>

          <ButtonContainer>
            <OutlinedButton type="submit" text="Buscar" buttonWidth={200} loading={loading} />
          </ButtonContainer>

          {nearbyAssociates && nearbyAssociates.length > 0 && (
            <AssociatesAndMapContainer>

              <MapContainer>
                <GoogleMap locations={nearbyAssociates.map(associates => associates.latLong)} referential={
                  {
                    center: centerCoordinates,
                    zoom: distanceRange === 10 ? 12 : distanceRange === 25 ? 11 : distanceRange === 50 ? 10 : distanceRange >= 100 ? 8 : distanceRange >= 200 ? 7 : 12
                  }
                } />
              </MapContainer>

              <AssociatesContent>
                {nearbyAssociates.map((associate) =>
                  <AssociatesItem
                    id={associate.id}
                    name={associate.name}
                    phone={associate.phone}
                    instagram={associate.instagram}
                    profile_picture={associate.profile_picture}
                    professional_number={associate.professional_number}
                    distance={associate.distance}
                    duration={associate.duration}
                    rating={associate.rating} />
                )}
              </AssociatesContent>

            </AssociatesAndMapContainer>
          )}
          {centerCoordinates.lat !== 0 && centerCoordinates.lng !== 0 && nearbyAssociates.length === 0 && (
            <AssociatesNotFoundContainer>
              <p>{`Não encontramos nenhum licenciado próximo a você dentro do raio escolhido. Sugerimos aumentar o raio de busca para otimizar sua pesquisa.`}</p>
            </AssociatesNotFoundContainer>
          )
          }
        </FormContainer>


      </Container>

    </BackgroundContainer>
  )
}
