export default class DecoderTransformer {
  count: number;
  algo: any;
  encryptionKey: CryptoKey;
  counter_size: number;
  setReceived: (arg0: number) => void;

  constructor(
    algo: any,
    counter_size: number,
    encryptionKey: CryptoKey,
    setReceived: (arg0: number) => void
  ) {
    this.count = 0;
    this.algo = algo;
    this.encryptionKey = encryptionKey;
    this.counter_size = counter_size;
    this.setReceived = setReceived;
  }

  async transform(
    chunk: Uint8Array,
    controller: { enqueue: (arg0: Uint8Array) => void }
  ) {
    if (chunk.length) {
      this.count++;

      // Caso o chunk seja menor, o tamanho do dado a cryptografado também tem que diminuir.
      const chunk_size = chunk.length - this.counter_size;

      const cryptData = chunk.slice(0, chunk_size);
      const counter = chunk.slice(chunk_size);

      const decryptedFile = await crypto.subtle.decrypt(
        {
          ...this.algo,
          counter: counter,
        },
        this.encryptionKey,
        cryptData
      );

      const decryptedUint8Array = new Uint8Array(decryptedFile);

      // console.log(new TextDecoder().decode(decryptedFile))
      controller.enqueue(decryptedUint8Array);
      this.setReceived(chunk_size);
    }
  }
}