import { useCallback } from "react";
import { RiDownloadLine, RiDeleteBinLine } from "react-icons/ri";
import { ALGO } from "../../../../utils/constants";
import { EncryptionFactory } from "../../../../utils/crypto/GenerateEncryptionKey";
import ByteSliceTransformer from "../../../../utils/stream/ByteSliceTransformer";
import DecoderTransformer from "../../../../utils/stream/DecoderTransformer";

import { Container, Header, Title } from "./styles";

interface DownloadFileCard {
  file_id: string;
  fileLink: string;
  originalName: string;
  fileSize: number;
  onDelete: () => void;
}

export function DownloadCard({
  file_id,
  fileLink,
  fileSize,
  originalName,
  onDelete,
}: DownloadFileCard) {

  const getAndDownloadDecryptedFile = useCallback(async () => {
    const token = localStorage.getItem("@FixitApp:token");
    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/files/custom/${file_id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok || !response.body) {
      throw response.statusText;
    }
    if (response.status === 200) {
      const cryptoFileReadableStream = response.body;
      await decryptAndStoreFile(cryptoFileReadableStream);
    }
  }, [fileLink])

  const decryptAndStoreFile = useCallback(async (cryptoFileReadableStream: any) => {

    const root = await navigator.storage.getDirectory();
    const dirHandle = await root.getDirectoryHandle("tmpFixitFolder", {
      create: true,
    });
    const fileHandle = await dirHandle.getFileHandle("gcode.fixit", {
      create: true,
    });
    const writable = await fileHandle.createWritable({
      keepExistingData: false,
    });
    const writer = writable.getWriter();
    const chunk_size = +(process.env.REACT_APP_CHUNK_SIZE || "1024");
    const counter_size = +(process.env.REACT_APP_COUNTER_SIZE || "16");

    if (!process.env.REACT_APP_FIXIT_FILE_KEY) {
      throw new Error("Cannot find file key");
    }

    await cryptoFileReadableStream
      .pipeThrough(
        new TransformStream(
          new ByteSliceTransformer(chunk_size + counter_size)
        )
      )
      .pipeThrough(
        new TransformStream(
          new DecoderTransformer(
            ALGO,
            counter_size,
            await new EncryptionFactory().getEncryptionKey(
              ALGO,
              process.env.REACT_APP_FIXIT_FILE_KEY
            ),
            (received: number) => {
              console.log("received: ", received);
            }
          )
        )
      )
      .pipeTo(
        new WritableStream({
          write(chunk) {
            writer.write(chunk);
          },
        })
      );

    await writer.close().then(() => downloadStoredFile())
  }, []);

  const downloadStoredFile = async () => {
    try {
      const root = await navigator.storage.getDirectory();
      const dirHandle = await root.getDirectoryHandle("tmpFixitFolder", {
        create: false,
      });
      const fileHandle = await dirHandle.getFileHandle("gcode.fixit", {
        create: false,
      });
      const file = await fileHandle.getFile();

      const blob = new Blob([file], { type: file.type });

      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = `${originalName}`
      document.body.appendChild(link);
      link.click();

      URL.revokeObjectURL(url);

    } catch (error) {
      console.error("Erro ao realizar o download:", error);
    }
  };



  return (
    <Container>
      <Header>
        <Title>{originalName}</Title>
      </Header>

      <div className="icons">
        <RiDownloadLine size={24} color="var(--fixit)" onClick={getAndDownloadDecryptedFile} />
        <div className="exclude">
          <RiDeleteBinLine onClick={onDelete} size={24} color="var(--fixit)" />
        </div>
      </div>
    </Container>
  );
}
