import styled from "styled-components";

export const Container = styled.div`
  width: fit-content;
  border-radius: 10px;
  padding: 6px 20px;
  background: #fff;
  box-shadow: -1px 2px 25px -14px #000000;

  display: flex;
  align-items: center;

  div.icons {
    margin-left: 20px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      cursor: pointer;
    }
  }
`;



export const Header = styled.div`

`

export const Title = styled.div`
  color: var(--dark-gray);
  font-weight: bold;
  font-size: medium;
`;

export const Subtitle = styled.div`
  font-size: x-small;
`