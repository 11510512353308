import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
  SaveText,
  PrinterActionButton,
  CustomDeviceLine,
  ConnectContainer,
  MenuContainer,
  LoaderLabelContainer,
} from "./styles";
import { usePrinters } from "../../hooks/PrintersContext";
import { i18n } from "../../localization/i18n";
import LineBreakTransformer from "../../utils/stream/LineBreakTransformer";
import { BsThreeDotsVertical } from "react-icons/bs";
import DropdownBasicMenu from "../DropdownBasicMenu";
import { BaudRate } from "./mocks";
import { OutlinedInput } from "../OutlinedInput";
import { showToast } from "../CustomToast";
import { Loader } from "../Loader";
import { useWebSocket } from "../../hooks/PrinterWebSocketContext";

interface PrinterConfigInfosProps {
  closeModal: () => void;
}

export function PrinterConfig({ closeModal }: PrinterConfigInfosProps) {
  const [configPort, setConfigPort] = useState<SerialPort>();
  const [portIsOnline, setPortIsOnline] = useState(false);
  const { printers, defaultPrinter, setDefaultPrinter } = usePrinters();
  const [printerReader, setPrinterReader] =
    useState<ReadableStreamDefaultReader<string>>();
  const [printerWriter, setPrinterWriter] =
    useState<WritableStreamDefaultWriter<string>>();
  const [wakeLock, setWakeLock] = useState<WakeLockSentinel>();
  const [printerMessage, setPrinterMessage] = useState<string>();
  const [printerTestMessage, setPrinterTestMessage] = useState<string>();
  const [commandPool, setCommandPool] = useState<string>();
  const [userBaudRate, setUserBaudRate] = useState(
    localStorage.getItem("@FixitApp:baudRate")
  );

  const [connectViaLan, setConnectViaLan] = useState(false)
  const [connecting, setConnecting] = useState(false)
  const [printerIP, setPrinterIP] = useState("")

  const { isReady, setIPAddress } = useWebSocket();

  useEffect(() => {
    setPrinterIP(localStorage.getItem("@FixitApp:printerIP") ?? "")
  }, [])

  const COMMANDLIST = {
    milimetters: "G21",
    extruder: "M109 S200 T0",
    bed: "M190 S60 T0",
    start: "M117 FIX IT STREAM",
    end: "M117 Thank you",
    debug: "M155 S0",
  };

  const openPort = async (port: SerialPort) => {
    if (port.readable?.locked) {
      console.log("Port locked - close port");
      await port.close();
    }

    if (!port.readable) {
      try {
        await port.open({
          baudRate: Number(
            localStorage.getItem("@FixitApp:baudRate") ?? 115200
          ),
        });
      } catch (err: any) {
        if (err.code === DOMException.NETWORK_ERR) {
          setConfigPort(undefined);
          window.alert(i18n.t("global.configs.networkerror"));
          return;
        } else {
          console.log("port already open");
        }
      }
    }

    if (!port) {
      window.alert(i18n.t("global.configs.noserialport"));
      return;
    }

    if (!port.readable) {
      window.alert(i18n.t("global.configs.portnotreadlable"));
      return;
    }

    if (!port.writable) {
      window.alert(i18n.t("global.configs.portnotreadlable"));
      return;
    }

    const lineReader = await loadReader(port.readable);

    if (!lineReader) {
      window.alert(i18n.t("global.configs.cantloadreader"));
      return;
    }

    setPrinterReader(lineReader);

    const textEncoder = new TextEncoderStream();
    const writableStreamClosed = textEncoder.readable.pipeTo(port.writable);

    setPrinterWriter(textEncoder.writable.getWriter());
  };

  useEffect(() => {
    if (configPort) {
      openPort(configPort);
    }
  }, [configPort]);

  const loadPrinterFromOS = async (filter = true) => {
    if (!defaultPrinter) {
      window.alert(i18n.t("global.configs.noprinter"));
      return;
    }

    const requestPortPidVidFilter: {
      usbVendorId: number;
      usbProductId: number;
    }[] = [];

    defaultPrinter.device_product_id.forEach((pid) => {
      defaultPrinter.device_vendor_id.forEach((vid) => {
        requestPortPidVidFilter.push({
          usbVendorId: +vid,
          usbProductId: +pid,
        });
      });
    });

    if ("serial" in navigator) {
      !defaultPrinter.port &&
        navigator.serial
          .requestPort(
            filter &&
              defaultPrinter.device_vendor_id &&
              defaultPrinter.device_product_id
              ? {
                filters: requestPortPidVidFilter,
              }
              : {}
          )
          .then((port) => {
            setConfigPort(port);
          });
    }
  };

  const printerWriting = async (printerInstruction: string): Promise<void> => {
    if (!printerWriter) {
      return;
    }
    return await printerWriter.write(`${printerInstruction}\n`);
  };

  async function printerReading() {
    if (!printerReader) {
      return;
    }
    try {
      const { value, done } = await printerReader.read();
      if (done) {
        // setPrinterReader(undefined)
        // setPrinterWriter(undefined)
        printerReader.releaseLock();
        return;
      }

      if (!value) {
        return;
      }

      setPrinterMessage((prevState) =>
        prevState !== value ? value : `${value} odd`
      );
    } catch (e) {
      console.log(e);
    }
  }

  const loadReader = async (
    readable: ReadableStream
  ): Promise<ReadableStreamDefaultReader | undefined> => {
    try {
      return readable
        .pipeThrough(new TextDecoderStream())
        .pipeThrough(new TransformStream(new LineBreakTransformer()))
        .getReader();
    } catch (e) {
      console.log(e);
    }
  };

  const testSerialPortComunication = async () => {
    if (printerReader && printerWriter) {
      setCommandPool(COMMANDLIST.start);
    }
  };

  useEffect(() => {
    if (printerReader && printerMessage && defaultPrinter) {
      const msg = printerMessage;
      setPrinterMessage(undefined);

      if (msg.length && !portIsOnline) {
        setPortIsOnline(true);
      }

      if (msg.indexOf("ok") !== -1) {
        if (commandPool === COMMANDLIST.start) {
          setCommandPool(undefined);
          const configPortInfo = configPort?.getInfo();
          const printerConfigSign: any = JSON.parse(
            localStorage.getItem("printerConfigSign") ?? "{}"
          );
          const vip = defaultPrinter.device_vendor_id.find(
            (vip) => vip === configPortInfo?.usbVendorId?.toString()
          );
          const pid = defaultPrinter.device_product_id.find(
            (vip) => vip === configPortInfo?.usbProductId?.toString()
          );
          printerConfigSign[defaultPrinter.value] = {
            date: new Date().toJSON(),
            usbProductId: !pid ? configPortInfo?.usbProductId : undefined,
            usbVendorId: !vip ? configPortInfo?.usbVendorId : undefined,
          };
          localStorage.setItem(
            "printerConfigSign",
            JSON.stringify(printerConfigSign)
          );
          setDefaultPrinter({
            ...defaultPrinter,
            port: configPort,
          });
        } else if (commandPool === COMMANDLIST.end) {
          setCommandPool(undefined);
          printerReader?.cancel("teste");
          printerWriter?.close();
          setConfigPort(undefined);
          closeModal();
          return;
        } else if (!!commandPool) {
          setCommandPool(undefined);
        }
      }
      printerReading();
    }
  }, [printerMessage, printerReader]);

  useEffect(() => {
    if (commandPool && printerWriter && printerReader) {
      printerWriting(commandPool);
    }
  }, [commandPool, printerWriter, printerReader]);

  useEffect(() => {
    if ("wakeLock" in navigator) {
      navigator.wakeLock
        .request("screen")
        .then((wl: WakeLockSentinel) => setWakeLock(wl));
    }

    if (defaultPrinter?.port?.writable && !printerWriter) {
      const textEncoder = new TextEncoderStream();
      const writableStreamClosed = textEncoder.readable.pipeTo(
        defaultPrinter.port.writable
      );
      setPrinterWriter(textEncoder.writable.getWriter());
    }

    if (defaultPrinter?.port?.readable && !printerReader) {
      loadReader(defaultPrinter.port.readable).then((lineReader) =>
        setPrinterReader(lineReader)
      );
    }

    if (defaultPrinter?.port?.writable && defaultPrinter?.port?.readable) {
      setPortIsOnline(true);
    }

    return () => {
      console.log("unmount");
    };
  }, []);

  useEffect(() => {
    if (printerReader && printerWriter) {
      if (!defaultPrinter?.port) {
        setTimeout(() => {
          if (!portIsOnline) {
            printerWriter.write(`${COMMANDLIST.milimetters}\n`);
          }
        }, 2000);
      }
      printerReading();
    }
  }, [printerReader, printerWriter]);

  return (
    <Container>
      <RiCloseFill
        size={24}
        color="var(--fixit)"
        onClick={async () => {
          if (!defaultPrinter?.port && !configPort) {
            closeModal();
            return;
          }
          !commandPool && setCommandPool(COMMANDLIST.end);
        }}
      />

      <Header>
        <span>{`${i18n.t("global.configs.printers.configure")}`}</span>
      </Header>

      {defaultPrinter?.lan_connection
        ?
        <InputLine>
          <OutlinedInput
            handleChange={(e) => { setPrinterIP(e) }}
            inputName={"PrinterIP"}
            label={"IP da Impressora"}
            value={printerIP}
            type={"text"}
            mask={"999.999.999.999"}
          />

        </InputLine>
        :
        <>
          <FormContainer>
            <InputLine>
              {!defaultPrinter?.port && (!configPort || !portIsOnline) && (
                <h5>{`${i18n.t("global.configs.printers.firstStep")}`}</h5>
              )}
              {!defaultPrinter?.port && configPort && portIsOnline && (
                <h5>{`${i18n.t("global.configs.printers.secondStep")}`}</h5>
              )}
              {defaultPrinter?.port && (
                <h5>{`${i18n.t("global.configs.printers.conclusion")}`}</h5>
              )}
            </InputLine>

            {defaultPrinter?.port && (
              <>
                <InputLine>
                  <PrinterActionButton
                    disabled={
                      !portIsOnline ||
                      !printerWriter ||
                      !printerReader ||
                      !!commandPool
                    }
                    onClick={async (e) => {
                      setCommandPool(COMMANDLIST.bed);
                    }}
                  >
                    {`${i18n.t("global.configs.printers.heatBed")}`}
                  </PrinterActionButton>
                </InputLine>
              </>
            )}
          </FormContainer>
        </>
      }


      <Footer>
        {!defaultPrinter?.port && (!configPort || !portIsOnline) && (
          <>
            <ConnectContainer>
              <SaveText
                onClick={async (e) => {
                  defaultPrinter?.lan_connection && setConnecting(true)
                  setIPAddress(printerIP)

                  if (defaultPrinter?.lan_connection) {
                    setConnecting(false)
                    defaultPrinter &&
                      setDefaultPrinter({
                        ...defaultPrinter,
                        ipConnected: isReady
                      })
                    localStorage.setItem("@FixitApp:printerIP", printerIP)
                    isReady && closeModal()
                  } else {
                    await loadPrinterFromOS();
                  }
                }}
                connecting={connecting}
                disabled={connecting}
              >
                {connecting ?
                  (<LoaderLabelContainer>
                    <Loader loaderSize={18} />
                    <span>Conectando</span>
                  </LoaderLabelContainer>)
                  :
                  `${i18n.t("global.configs.printers.connect")}`}
              </SaveText>
              {!defaultPrinter?.lan_connection && (
                <MenuContainer>
                  <DropdownBasicMenu
                    undoSnackBarAction={() => {
                      localStorage.setItem(
                        "@FixitApp:baudRate",
                        String(BaudRate[userBaudRate as keyof typeof BaudRate])
                      );
                      setUserBaudRate(
                        BaudRate[userBaudRate as keyof typeof BaudRate]
                      );
                    }}
                    snackBarLabel={`${i18n.t(
                      "global.configs.printers.baudRateChangeSucess"
                    )}`}
                    rows={[
                      {
                        label: `${i18n.t(
                          "global.configs.printers.changeBaudRateTo"
                        )} ${BaudRate[
                        (userBaudRate as keyof typeof BaudRate) ?? "115200"
                        ]
                          }`,
                        onClick: () => {
                          localStorage.setItem(
                            "@FixitApp:baudRate",
                            String(
                              BaudRate[
                              (userBaudRate as keyof typeof BaudRate) ??
                              "115200"
                              ]
                            )
                          );
                          setUserBaudRate(
                            BaudRate[
                            (userBaudRate as keyof typeof BaudRate) ?? "115200"
                            ]
                          );
                        },
                      }
                    ]
                    }
                    menuIcon={
                      <BsThreeDotsVertical size={25} color="var(--gray)" />
                    }
                  />
                </MenuContainer>
              )}
            </ConnectContainer>

            {!defaultPrinter?.lan_connection &&
              <CustomDeviceLine>
                {`${i18n.t("global.configs.printers.notfound")}`}{" "}
                <span onClick={() => loadPrinterFromOS(false)}>
                  {`${i18n.t("global.configs.printers.manualSearch")}`}
                </span>{" "}
              </CustomDeviceLine>}
          </>
        )}

        {!defaultPrinter?.port && configPort && portIsOnline && (
          <SaveText
            disabled={!printerWriter || !printerReader}
            onClick={async (e) => {
              await testSerialPortComunication();
            }}
          >
            {`${i18n.t("global.configs.printers.test")}`}
          </SaveText>
        )}
      </Footer>
    </Container>
  );
}
