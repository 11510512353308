import styled from 'styled-components'
import global from '../../../global'

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 71px);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  a {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-decoration: none;
    font-weight: bold;
    color: var(--fixit);
    @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
      font-size: 1.4rem;
    }
  }

  span {
    line-height: 0;
    font-size: 0;
    margin-right: 10px;
  }
`

export const ErrorCode = styled.h1`
  color: var(--fixit);
  font-size: 8rem;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    font-size: 6rem;
  }
`

export const ErrorMessage = styled.p`
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  color: var(--dark-gray);

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    font-size: 1.3rem;
  }
`

export const NotFoundImg = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 43%;
  height: auto;
  z-index: -1;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    width: 66%;
  }
`
