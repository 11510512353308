import styled, { keyframes } from "styled-components";

type InputContainerProps = {
  errorMargin: boolean;
};

const lateralPadding = 8;
const mobileWidth = 720;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 50px;

  .passRecovery {
    margin-bottom: 26px;
    color: var(--fixit);
  }

  @media (max-width: ${mobileWidth}px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 ${lateralPadding}vw;
  }
`;

const opacityTransition = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const Branding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  animation: ${opacityTransition} 2s;

  .ilustration {
    width: calc(50vw - 210px);

    @media (max-width: ${mobileWidth}px) {
      display: none;
    }
  }

  @media (max-width: ${mobileWidth}px) {
    margin: 60px 0;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 10px;
  padding: 30px;
  background: #fff;
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  animation: ${appearFromRight} 1s;

  img {
    width: 100px;
    height: auto;
  }

  h1 {
    font-size: 28px;
    font-weight: 500;
    color: var(--dark-gray);
    margin-top: 18px;
  }

  @media (max-width: ${mobileWidth}px) {
    width: 100%;
  }
`;

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 30px 30px 30px;

  @media (max-width: 480px) {
    width: 100%;
    padding: 0px;
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  width: 320px;
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: ${({ errorMargin }) => (errorMargin ? 16 : 0)}px;

  transition: all 0.3s;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const PassRecoveryButton = styled.div`
  font-size: 16px;
  margin-top: 12px;
  color: var(--fixit);
  cursor: pointer;
`;

export const Footer = styled.div`
  margin-top: 28px;

  button {
    width: 100%;
  }
`;

export const CreateAccountLine = styled.div`
  font-size: 16px;
  color: var(--dark-gray);
  margin-top: 26px;
  text-align: center;

  span {
    font-weight: 500;
    color: var(--fixit);
    cursor: pointer;
  }
`;
