import { useState } from "react";
import { useAuth } from "../../hooks/AuthContext";
import { i18n } from "../../localization/i18n";
import api from "../../services/api";
import { showToast } from "../CustomToast";
import { Modal } from "../Modal";
import { OutlinedButton } from "../OutlinedButton";
import { OutlinedInput } from "../OutlinedInput";
import { Container, InputDiv, ModalTitle } from "./styles";


interface ModalProps {
    open: boolean;
    closeModal: () => void;
    reload: () => void
}

interface DataProps {
    name: string;
    email: string;
    role: string;
}

export function UserModal ({open, closeModal, reload }: ModalProps) {
    const { user } = useAuth();
    
    const [data, setData] = useState<DataProps>({
        name: user.name,
        email: user.email,
        role: user.role,
    });

    async function updateUserData (id: string, data: DataProps) {
        try {
            await api.put(`users/${id}`, data)
            showToast({
                type: 'success',
                message: 'User updated successfully!',
            })
            closeModal();
            reload();
        } catch (error) {
            showToast({
                type: 'error',
                message: 'Wasnt possible to update the user data!',
            })
        }
    }

    return (
        <Modal
            open={open}
            onRequestClose={closeModal}
        >
            <Container>
                <ModalTitle>{`${i18n.t('userProfile.data_user.update')}`}</ModalTitle>
                <InputDiv>
                    <OutlinedInput
                        inputName={'email'}
                        label={`${i18n.t('userProfile.data_user.email')}`}
                        handleChange={(event) =>
                            setData((prevState) => ({
                                ...prevState,
                                email: event,
                            }))}
                        value={data.email || ''}
                    />
                </InputDiv>

                <InputDiv>
                    <OutlinedInput 
                        inputName='name'
                        label={`${i18n.t('userProfile.data_user.name')}`}
                        handleChange={(event) =>
                            setData((prevState) => ({
                                ...prevState,
                                name: event,
                            }))}
                        value={data.name || ''}
                    />
                </InputDiv>

                <OutlinedButton 
                    text={`${i18n.t('tips.updateButton')}`}
                    onClick={async () => {
                        await updateUserData(user.id, data);
                    }}
                />
            </Container>
        </Modal>
    )
}