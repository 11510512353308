import styled, { css, keyframes } from "styled-components";

type ContainerProps = {
  disabled: boolean;
};

type AccordionProps = {
  open: boolean;
  disabled?: boolean;
};

const closeDivider = keyframes`
  from {
    height: 0px;
  }

  to {
    height: 44px;
  }
`;

const openDivider = keyframes`
  from {
    width: 0%;
  }

  to {
    width: 90%;
  }
`;

export const Container = styled.div<ContainerProps>`
  width: 100%;
  border-radius: 8px;
  background: ${({ disabled }) => (disabled ? "#ccc" : "#fff")};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: all 0.4s ease;
`;

export const Header = styled.div<AccordionProps>`
  display: flex;
  flex-direction: row;
  align-items: ${({ open }) => (open ? "flex-start" : "center")};
  gap: 20px;
  padding: 10px 30px 10px 10px;

  svg { 
    align-self: center;
  }

`;

export const IconContainer = styled.div`
  width: 56px;
  height: 56px;

  img {
    margin-left: 6px;
    width: 56px;
    height: 56px;
  }
`;

export const CloseDivider = styled.div<ContainerProps>`
  width: 2px;
  height: 44px;
  background: ${({ disabled }) =>
    disabled ? "var(--white)" : "var(--printed)"};

  animation: ${closeDivider} 0.3s linear;
`;

export const OpenDivider = styled.div`
  width: 90%;
  height: 3px;
  margin-top: 5px;
  background: var(--printed);

  animation: ${openDivider} 0.5s linear;
`;

export const OpenDividerText = styled.a`
  font-size: 12px;
  font-weight: 500;
  color: var(--fixit);
  margin-top: 6px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const InfosContentContainer = styled.div<AccordionProps>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    transition: all 0.3s;

    ${({ open }) =>
      open &&
      css`
        transform: rotate(180deg);
      `}
  }
`;

export const OpenInfosContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 8px;
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const EmptyTitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: #000000;
  text-transform: uppercase;
`;

export const Title = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-transform: uppercase;
`;

export const EmptySubtitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-top: 8px;
`;

export const Subtitle = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-top: 4px;
`;

export const PendingInfos = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  color: #ff4133;
  margin-top: 8px;
`;

export const UpdatedAt = styled.div`
  margin-top: 2px;
`;

export const ContentContainer = styled.div<AccordionProps>`
  width: 100%;
  padding-bottom: 30px;
  transition: all 0.4s ease;

  ${({ open }) =>
    !open &&
    css`
      display: none;
    `}
`;

export const Link = styled.a`
  font-size: 10px;
  opacity: 80%;
  color: var(--error);
  cursor: pointer;
  text-decoration: underline;
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  gap: 5px;
`