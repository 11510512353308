import styled from "styled-components";
import global from "../../global";

interface IOptions {
  selected?: boolean;
  hasNotification?: boolean;
}

export const ProfileContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 20px;
`;

export const OptionsContainer = styled.div`
  position: sticky;
  width: 352px;
  margin-left: 64px;
  background: #ffff;
  border-radius: 10px;
  overflow: hidden;
  padding: 0px 0px;
  border: 1px solid var(--light-gray);
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ProfileOptions = styled.div<IOptions>`
  background: #ffff;
  width: 100%;
  font-size: 16px;
  border-left: 2px solid #ffff;
  padding: 1rem 1.5rem;
  color: var(--gray);

  background-color: ${(props) =>
    props.selected ? "var(--background)" : "#FFFF"};
  border-color: ${(props) =>
    props.selected
      ? "var(--fixit)"
      : props.hasNotification
      ? "var(--error)"
      : "#FFFF"};
  color: ${(props) => (props.selected ? "var(--dark-gray)" : "var(--gray)")};

  :hover {
    background-color: var(--background);
    color: var(--dark-gray);
  }
`;

export const ProfileDescription = styled.div`
  border-radius: 10px;
  display: flex;
  gap: 3rem;
  flex-direction: column;
  //remover o padding?
  /* padding: 2rem; */
  width: 100%;
  min-height: 80vh;
`;

export const Title = styled.h1`
  color: var(--gray);
  font-weight: 500;
`;

/* export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  gap: 15px;
`
 */
/* export const ButtonIcon = styled.div`
  height: 28px;
  width: 28px;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color .2s;

  &.active, &:hover {
    background-color: var(--light-gray);
  }

  display: flex;
  justify-content: space-around;
  align-items: center;

  margin-right: 18px;
  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    margin-right: 10px;
  }
`; */
