import styled, { css } from "styled-components";
import global from "../../../../../../global";

interface FilterContainerProps {
  open: boolean;
}

interface CheckboxProps {
  checked?: boolean;
}

export const Container = styled.div`
  position: relative;

  svg {
    cursor: pointer;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    padding-right: 10px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const FilterContainer = styled.div<FilterContainerProps>`
  position: absolute;
  right: 0px;
  top: 36px;

  width: 300px;
  border-radius: 10px;
  box-shadow: 4px 3px 8px var(--gray);
  background: var(--white);
  z-index: 10;

  padding: 10px;

  display: ${({ open }) => (open ? "block" : "none")};
`;

export const FilterSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
  font-size: 13px;
  font-weight: 500;
  color: var(--dark-gray);
  padding-right: 5px;

  svg {
    padding-bottom: 3px;
  }
`;

export const SectionName = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  color: var(--dark-gray);
  line-height: 9px;
  padding-bottom: 3px;
`;

export const SelectContainer = styled.div`
  margin-top: 10px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
`;

export const CheckboxContainer = styled.div<CheckboxProps>`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #989898;
    text-transform: uppercase;

    ${({ checked }) =>
      !checked &&
      css`
        text-decoration: line-through;
      `}
  }
`;

export const ApplyButton = styled.button`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--finished)"};
  cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
  width: 100px;
  border: 1px solid
    ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--finished)")};
  padding: 10px 10px;
  border-radius: 10px;
  background-color: var(--white);

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .react-datepicker-wrapper {
    max-width: 90px;

    input {
      max-width: 70px;
      font-size: 13px;
      padding-bottom: 3px;
      font-weight: 500;
      color: var(--dark-gray);
    }
  }
`;

export const PeriodInputsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  border: 2px solid var(--gray);
  padding: 3px 5px;
  margin-bottom: 15px;
  border-radius: 15px;

  .separator {
    padding: 0px 5px;
  }

  &.month {
    max-width: 170px;
  }

  &.period {
    max-width: 270px;
    padding: 3px 10px;
  }
`;

export const PeriodInput = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const PeriodText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 9px;
  color: #9e9e9e;
`;

export const DatePickerContainer = styled.div`
  display: flex;
  align-items: center;

  svg {
    color: var(--dark-gray);
  }

  input {
    width: 56px;
    padding-left: 7px;
    padding-bottom: 3px;
    font-size: 13px;
    font-weight: 500;
    color: var(--dark-gray);
    border-radius: 8px;
  }
`;

export const InputSeparator = styled.div`
  border: 1px solid var(--light-gray);
  height: 20px;
  width: 1px;
  margin-right: 6px;
  margin-left: 6px;
`;
