import { useEffect, useState } from "react";
import { useAuth } from "../../../../hooks/AuthContext";
import ReactPlayer from "react-player";

import {
  Attachments,
  Container,
  Description,
  Header,
  InitialKitButton,
  JumpThisStepButton,
  Label,
  LinkDescription,
  LinkGroup,
  PrincipalInfo,
  ProceedButton,
  Progress,
  ProgressBar,
  SendButton,
  StartButton,
  Step,
  StepLabel,
  VideoDescription,
} from "./styles";

import fixitguys from "../../../../assets/fixitguys.jpg";
import fixittecnology from "../../../../assets/fixittecnology.jpg";
import punhofix from "../../../../assets/punhofix.png";

import { RiArrowRightSLine, RiCheckFill } from "react-icons/ri";
import api from "../../../../services/api";
import { showToast } from "../../../../components/CustomToast";
import { useOnboard } from "../../../../hooks/OnboardContext";
import { Modal } from "../../../../components/Modal";
import { ChooseSignatureSolutions } from "./components/SelectSolutions";
import { i18n } from "../../../../localization/i18n";
import { customerSituations, orderTypes } from "../../../../utils/constants";
import { SendCertificateModal } from "./components/SendCertificateModal";
import { FaArrowRight } from "react-icons/fa";

interface IInitialKit {
  id: string;
}

export function Onboard() {
  const { setNavigationAction, user } = useAuth();
  const [startingTheOnboard, setStartingTheOnboard] = useState(true);
  const [fixItIntroduction, setFixItIntrodution] = useState(false);
  const [tecnologyBehind, setTecnologyBehind] = useState(false);
  const [punhoFixTraining, setPunhoFixTraining] = useState(false);
  const [lastOnboardStep, setLastOnboardStep] = useState(false);
  const [hasAttachments, setHasAttachments] = useState(false);
  const [initialKit, setInitialKit] = useState(false);
  const [initialKitSolution, setInitialKitSolution] = useState<IInitialKit>();
  const [mobileDisplayVideo, setMobileDisplayVideo] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [sendCertificateModal, setSendCertificateModal] = useState(false);
  const [signature, setSignature] = useState({
    solutions_quantity: 0,
    avaliable_solutions: 0,
  });

  const { licensee, setLicensee } = useOnboard();

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: "Fix It Onboard",
    });
  }, []);

  useEffect(() => {
    if (fixItIntroduction) {
      setHasAttachments(true);
    }
  }, [fixItIntroduction]);

  useEffect(() => {
    if (tecnologyBehind) {
      setHasAttachments(true);
    }
  }, [tecnologyBehind]);

  useEffect(() => {
    if (punhoFixTraining) {
      // setHasAttachments(true)
    }
  }, [punhoFixTraining]);

  function displayWindowSize() {
    const screenWidth = document.documentElement.clientWidth;

    if (screenWidth <= 700 && !mobileDisplayVideo) {
      setMobileDisplayVideo(true);
    } else if (screenWidth >= 700 && mobileDisplayVideo) {
      setMobileDisplayVideo(false);
    }
  }

  useEffect(() => {}, [initialKit]);

  useEffect(() => {
    window.addEventListener("resize", displayWindowSize);
  }, []);

  async function handleRequestInitialKit() {
    try {
      await api
        .put(`/licensees/${user.person.licensee.id}/initial-kit`)
        .then((response) => {
          setLicensee(response.data);
          showToast({
            type: "success",
            message: "Parabens, você acabou de solicitar seu kit inicial!",
          });
        });
    } catch (error) {
      return;
    }
  }

  async function setOnboardStatus() {
    try {
      await api
        .put(`licensees/user/complete?isCompleted=true`)
        .then((response) => {
          setLicensee(response.data);
        });
    } catch (error) {
      return;
    }
  }

  async function getSignature() {
    try {
      await api.get("/signatures/licensee").then((response) => {
        setSignature(response.data);
      });
    } catch (error) {
      showToast({
        type: "error",
        message: "Você não possui uma assinatura, entre em contato com o SAF",
      });
    }
  }

  async function handleGetSolutionByNameKey(initialKit: string) {
    try {
      await api.get(`/solutions/key/${initialKit}`).then((response) => {
        setInitialKitSolution(response.data);
      });
    } catch (error) {
      console.log("Não foi possível encontrar a solução");
    }
  }

  async function handleCreateInitialKit() {
    if (!licensee) {
      return;
    }

    if (licensee.ordered_initial_kit) {
      showToast({
        type: "error",
        message: "Kit já requisitado!",
      });
      return;
    }

    const body = {
      licensee_id: user.person.licensee.id,
      category: orderTypes.fixiter,
      color: "Azul Oceano",
      notes: "",
      printer: [],
      quantity: 1,
      user_id: user.id,
      solution_id: initialKitSolution?.id,
      solution_type: "standard",
      standardFile: {},
      status: "processing",
      total: 0,
    };

    try {
      await api.post("/orders", body).then((response) => {
        setInitialKit(true);
      });
      handleRequestInitialKit();
    } catch (error) {
      showToast({
        type: "error",
        message:
          "Erro no pedido do seu kit inicial, entre em contato com o SAF",
      });
    }
  }

  async function handleOnboardingStatus() {
    if (
      licensee?.completed_onboard &&
      licensee?.situation === customerSituations.onboarding
    ) {
      setStartingTheOnboard(false);
      setLastOnboardStep(true);
    }
  }

  useEffect(() => {
    handleOnboardingStatus();
    getSignature();
    handleGetSolutionByNameKey("initialkit");
  }, []);

  return (
    <>
      {/* <Modal open={openModal}>
        <ChooseSignatureSolutions
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      </Modal> */}

      <Modal open={sendCertificateModal} modalWidth={450}>
        <SendCertificateModal
          closeModal={() => setSendCertificateModal(false)}
        />
      </Modal>
      <Container>
        {startingTheOnboard && (
          <>
            <Header>
              <h2>{`${i18n.t("fixiterOnboarding.introduction")}`}</h2>
              <h4>{`${i18n.t("fixiterOnboarding.firstDescription")}`}</h4>
            </Header>

            <PrincipalInfo>
              <Description>
                <h5>{`${i18n.t("fixiterOnboarding.description")}`}</h5>
              </Description>

              <ProgressBar>
                <Step>1</Step>
                <Progress />
                <Step>2</Step>
                {/* <Progress />
              <Step>3</Step> */}
              </ProgressBar>
              <Label>
                <StepLabel>{`${i18n.t(
                  "fixiterOnboarding.stepOne"
                )}`}</StepLabel>
                <StepLabel>{`${i18n.t(
                  "fixiterOnboarding.stepTwo"
                )}`}</StepLabel>
                {/*               <StepLabel>{i18n.t("fixiterOnboarding.stepThree")}</StepLabel>
                 */}{" "}
              </Label>
            </PrincipalInfo>
            <StartButton
              onClick={() => {
                setStartingTheOnboard(false);
                setFixItIntrodution(true);
              }}
            >
              <span>{`${i18n.t("fixiterOnboarding.startTheOnboard")}`}</span>
              <RiArrowRightSLine size="2rem" />
            </StartButton>
          </>
        )}

        {fixItIntroduction && (
          <>
            <Header>
              {!hasAttachments && (
                <>
                  <JumpThisStepButton
                    onClick={() => {
                      setFixItIntrodution(false);
                      setTecnologyBehind(true);
                    }}
                  >
                    <div>
                      <span>Pular etapa</span>
                      <FaArrowRight size={12} />
                    </div>
                  </JumpThisStepButton>
                  <h2>{`${i18n.t("fixiterOnboarding.firstStepHeader")}`}</h2>
                </>
              )}
            </Header>

            {hasAttachments ? (
              <Attachments>
                <h3>Video 1 - {`${i18n.t("fixiterOnboarding.stepOne")}`}:</h3>
                <VideoDescription></VideoDescription>
                <h5>{`${i18n.t("fixiterOnboarding.attachments.header")}`}:</h5>
                <a
                  href="https://usefixit.com.br/portfolio"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  1. {`${i18n.t("fixiterOnboarding.attachments.portfolio")}`}.
                </a>
                <ProceedButton
                  onClick={() => {
                    setHasAttachments(false);
                  }}
                >
                  <span>{`${i18n.t("fixiterOnboarding.proceed")}`}</span>
                  <RiArrowRightSLine size="2rem" />
                </ProceedButton>
              </Attachments>
            ) : (
              <ReactPlayer
                width={mobileDisplayVideo ? "100%" : undefined}
                style={{ marginBottom: "20px" }}
                url="https://youtu.be/HXN-qPzMQlA"
                config={{
                  youtube: {
                    playerVars: {
                      hl: `${i18n.t("language.userLanguage")}`,
                      cc_lang_pref: `${i18n.t("language.userLanguage")}`,
                      cc_load_policy: 1,
                    },
                  },
                }}
                playing
                controls
                onEnded={() => {
                  setFixItIntrodution(false);
                  setTecnologyBehind(true);
                }}
                light={fixitguys}
              />
            )}

            <PrincipalInfo>
              {!hasAttachments && (
                <>
                  <ProgressBar>
                    <Step wichStep={fixItIntroduction}>1</Step>
                    <Progress />
                    <Step>2</Step>
                  </ProgressBar>
                  <Label>
                    <StepLabel>{`${i18n.t(
                      "fixiterOnboarding.stepOne"
                    )}`}</StepLabel>
                    <StepLabel>{`${i18n.t(
                      "fixiterOnboarding.stepTwo"
                    )}`}</StepLabel>
                  </Label>
                </>
              )}
            </PrincipalInfo>
          </>
        )}

        {licensee && tecnologyBehind && (
          <>
            <Header>
              {!hasAttachments && (
                <>
                  <JumpThisStepButton
                    onClick={() => {
                      setTecnologyBehind(false);
                      setLastOnboardStep(true);
                      setOnboardStatus();
                    }}
                  >
                    <div>
                      <span>Pular etapa</span>
                      <FaArrowRight size={12} />
                    </div>
                  </JumpThisStepButton>
                  <h2>{`${i18n.t("fixiterOnboarding.thirdStepHeader")}`}</h2>
                </>
              )}
            </Header>
            {hasAttachments ? (
              <Attachments>
                <h3>Video 2 - {`${i18n.t("fixiterOnboarding.stepTwo")}`}:</h3>
                <VideoDescription>
                  {`${i18n.t("fixiterOnboarding.stepTwoDescription")}`}
                </VideoDescription>
                <h5>{`${i18n.t("fixiterOnboarding.attachments.header")}`}:</h5>
                <LinkGroup>
                  <span>
                    1 - {`${i18n.t("fixiterOnboarding.attachments.tools")}`}
                  </span>
                  <LinkDescription>
                    {`${i18n.t(
                      "fixiterOnboarding.attachmentsToolDescription"
                    )}`}
                  </LinkDescription>
                  <div>
                    <a
                      href="https://www.google.com/shopping/product/11033328363846273508/offers?q=Soprador+T%C3%A9rmico+Gamma&rlz=1C1CHZN_enBR983BR983&biw=1536&bih=792&dpr=1.25&prds=eto:14455953610237612513_0&sa=X&ved=0ahUKEwimgdqUqOb0AhV9rZUCHb2MCe4Q3q4ECPsJ"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {`${i18n.t("fixiterOnboarding.attachments.heatBlower")}`}
                    </a>
                    <a
                      href="https://www.google.com/shopping/product/6612653592794439521/offers?q=tesoura&rlz=1C1CHZN_enBR983BR983&biw=1536&bih=792&dpr=1.25&prds=eto:14267720726957108604_0&sa=X&ved=0ahUKEwiuwOPfqOb0AhWWqpUCHcgXBQwQ3q4ECIAK"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {`${i18n.t("fixiterOnboarding.attachments.scissors")}`}
                    </a>
                    <a
                      href="https://www.google.com/shopping/product/6717353029405030953/offers?q=Malha+Tubular+10cm+x+15m+Polarfix&rlz=1C1CHZN_enBR983BR983&biw=1536&bih=792&dpr=1.25&prds=eto:3373682702866585693_0&sa=X&ved=0ahUKEwjj-pn5qOb0AhWbqpUCHYXMCzgQ3q4ECMAH"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {`${i18n.t("fixiterOnboarding.attachments.tubularMesh")}`}
                    </a>
                    <a
                      href="https://www.google.com/shopping/product/13729878154882194494/offers?q=fita+metrica&rlz=1C1CHZN_enBR983BR983&biw=1536&bih=792&dpr=1.25&prds=eto:263630952734890429_0&sa=X&ved=0ahUKEwipw8Ocqeb0AhXLppUCHW6PAQEQ3q4ECJ0K"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {`${i18n.t(
                        "fixiterOnboarding.attachments.measuringTape"
                      )}`}
                    </a>
                    <a
                      target="_blank"
                      href="https://www.casadoroadie.com.br/abracadeira-para-cabos-velcro-one-wrap-15cm-preto-kit-com-150"
                      rel="noreferrer"
                    >
                      {`${i18n.t("fixiterOnboarding.attachments.velcro")}`}
                    </a>
                  </div>
                </LinkGroup>
                <span>
                  2. {`${i18n.t("fixiterOnboarding.attachments.startKit")}`}.
                </span>
                <LinkDescription>
                  {`${i18n.t(
                    "fixiterOnboarding.attachmentsStartKitDescription"
                  )}`}
                </LinkDescription>
                {licensee.ordered_initial_kit ? (
                  <InitialKitButton initialKit={licensee?.ordered_initial_kit}>
                    <span>Solicitado!</span>
                    <RiCheckFill size="1.5rem" />
                  </InitialKitButton>
                ) : (
                  <InitialKitButton
                    initialKit={initialKit}
                    onClick={() => {
                      handleCreateInitialKit();
                    }}
                  >
                    <span>{`${i18n.t("fixiterOnboarding.initialKit")}`}</span>
                    <RiArrowRightSLine size="2rem" />
                  </InitialKitButton>
                )}
                <LinkDescription>
                  {`${i18n.t("fixiterOnboarding.initialKitDescription")}`}
                </LinkDescription>
                <a
                  target="_blank"
                  href="https://drive.google.com/file/d/1xwwTwnEcs3LfaYh6JJFbTU6m1Ot6lbeE/view?usp=sharing"
                  rel="noreferrer"
                >
                  3.{" "}
                  {`${i18n.t("fixiterOnboarding.attachments.goodPractices")}`}
                </a>
                <ProceedButton
                  onClick={() => {
                    setHasAttachments(false);
                  }}
                >
                  <span>{`${i18n.t("fixiterOnboarding.proceed")}`}</span>
                  <RiArrowRightSLine size="2rem" />
                </ProceedButton>
              </Attachments>
            ) : (
              <ReactPlayer
                width={mobileDisplayVideo ? "100%" : undefined}
                url="https://youtu.be/2hxXlfe9P_o"
                config={{
                  youtube: {
                    playerVars: {
                      hl: `${i18n.t("language.userLanguage")}`,
                      cc_lang_pref: `${i18n.t("language.userLanguage")}`,
                      cc_load_policy: 1,
                    },
                  },
                }}
                playing
                controls
                onEnded={() => {
                  setTecnologyBehind(false);
                  // setPunhoFixTraining(true);
                  setLastOnboardStep(true);
                  setOnboardStatus();
                }}
                light={fixittecnology}
              />
            )}

            <PrincipalInfo>
              {!hasAttachments && (
                <>
                  <ProgressBar>
                    <Step wichStep={tecnologyBehind}>1</Step>
                    <Progress />
                    <Step wichStep={tecnologyBehind}>2</Step>
                    {/*                     <Progress />
                    <Step>3</Step> */}
                  </ProgressBar>
                  <Label>
                    <StepLabel>{`${i18n.t(
                      "fixiterOnboarding.stepOne"
                    )}`}</StepLabel>
                    <StepLabel>{`${i18n.t(
                      "fixiterOnboarding.stepTwo"
                    )}`}</StepLabel>
                    {/*                     <StepLabel>{i18n.t("fixiterOnboarding.stepThree")}</StepLabel>
                     */}{" "}
                  </Label>
                </>
              )}
            </PrincipalInfo>
          </>
        )}

        {punhoFixTraining && (
          <>
            <Header>
              {!hasAttachments && (
                <h2>{`${i18n.t("fixiterOnboarding.thirdStepHeader")}`}</h2>
              )}
            </Header>
            {hasAttachments ? (
              <Attachments>
                <h3>Video 3 - {`${i18n.t("fixiterOnboarding.stepThree")}`}:</h3>
                <VideoDescription>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled i.
                </VideoDescription>
                <h5>{`${i18n.t("fixiterOnboarding.attachments.header")}`}:</h5>
                <a>
                  1. {`${i18n.t("fixiterOnboarding.attachments.portfolio")}`}.
                </a>
                <ProceedButton
                  onClick={() => {
                    setHasAttachments(false);
                  }}
                >
                  <span>{`${i18n.t("fixiterOnboarding.proceed")}`}</span>
                  <RiArrowRightSLine size="2rem" />
                </ProceedButton>
              </Attachments>
            ) : (
              <ReactPlayer
                width={mobileDisplayVideo ? "100%" : undefined}
                url="https://www.youtube.com/watch?v=6oPT_afuU4s"
                config={{
                  youtube: {
                    playerVars: {
                      hl: `${i18n.t("language.userLanguage")}`,
                      cc_lang_pref: `${i18n.t("language.userLanguage")}`,
                      cc_load_policy: 1,
                    },
                  },
                }}
                playing
                controls
                onEnded={() => {
                  setPunhoFixTraining(false);
                  setLastOnboardStep(true);
                }}
                light={punhofix}
              />
            )}
            <PrincipalInfo>
              {!hasAttachments && (
                <>
                  <ProgressBar>
                    <Step wichStep={punhoFixTraining}>1</Step>
                    <Progress />
                    <Step wichStep={punhoFixTraining}>2</Step>
                    <Progress />
                    <Step wichStep={punhoFixTraining}>3</Step>
                  </ProgressBar>
                  <Label>
                    <StepLabel>{`${i18n.t(
                      "fixiterOnboarding.stepOne"
                    )}`}</StepLabel>
                    <StepLabel>{`${i18n.t(
                      "fixiterOnboarding.stepTwo"
                    )}`}</StepLabel>
                    <StepLabel>
                      {`${i18n.t("fixiterOnboarding.stepThree")}`}
                    </StepLabel>
                  </Label>
                </>
              )}
            </PrincipalInfo>
          </>
        )}

        {lastOnboardStep && (
          <>
            <Header>
              <h2>{`${i18n.t("fixiterOnboarding.conclusionHeader")}`}</h2>
              <h4>{`${i18n.t(
                "fixiterOnboarding.firstConclusionDescription"
              )}`}</h4>
              <h4>{`${i18n.t("fixiterOnboarding.firstConclusionWelcome")}`}</h4>
            </Header>

            <PrincipalInfo>
              <Description>
                <h5>{`${i18n.t(
                  "fixiterOnboarding.conclusionDescription"
                )}`}</h5>
              </Description>
              <ProgressBar>
                <Step wichStep={lastOnboardStep}>1</Step>
                <Progress />
                <Step wichStep={lastOnboardStep}>2</Step>
                {/* <Progress />
              <Step wichStep={lastOnboardStep}>3</Step> */}
              </ProgressBar>
              <Label>
                <StepLabel>{`${i18n.t(
                  "fixiterOnboarding.stepOne"
                )}`}</StepLabel>
                <StepLabel>{`${i18n.t(
                  "fixiterOnboarding.stepTwo"
                )}`}</StepLabel>
                {/*               <StepLabel>{i18n.t("fixiterOnboarding.stepThree")}</StepLabel>
                 */}{" "}
              </Label>
            </PrincipalInfo>

            {/* {signature.solutions_quantity && !signature.avaliable_solutions ? (
              <StartButton onClick={() => setOpenModal(true)}>
                <span>
                  {signature.solutions_quantity &&
                  !signature.avaliable_solutions
                    ? i18n.t("fixiterOnboarding.selectSolutions")
                    : i18n.t("fixiterOnboarding.sendCertificate")}
                </span>
                <RiArrowRightSLine size="2rem" />
              </StartButton>
            ) :  */}

            <SendButton
              onClick={() => setSendCertificateModal(true)}
              // target="_blank"
              // href="https://api.whatsapp.com/send?phone=5511981669461&text=Ol%C3%A1,%20acabei%20de%20finalizar%20os%20treinamentos%20na%20plataforma%20da%20Fix%20it,%20quero%20enviar%20meu%20certificado%20para%20validar%20meu%20acesso."
            >
              <span>{`${i18n.t("fixiterOnboarding.sendCertificate")}`}</span>
              <RiArrowRightSLine size="2rem" />
            </SendButton>
          </>
        )}
      </Container>
    </>
  );
}
