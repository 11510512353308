import React from 'react'
import { Link } from 'react-router-dom'
import notFoundImg from '../../../assets/404.jpg'
import { RiArrowLeftLine } from 'react-icons/ri'

import { Container, ErrorCode, ErrorMessage, NotFoundImg } from './styles'

interface INotFoundProps {
  isCreator?: boolean
}

const NotFound = ({ isCreator }: INotFoundProps) => {
  return (
    <Container>
      <ErrorCode>404</ErrorCode>
      <ErrorMessage>
        Ops! Parece que você se perdeu
      </ErrorMessage>
      {isCreator ?
        <Link to="/creators/dashboard">
          <span><RiArrowLeftLine size={30} /></span> Voltar
        </Link>
      :
        <Link to="/dashboard">
          <span><RiArrowLeftLine size={30} /></span> Voltar
        </Link>
      }
      <NotFoundImg src={notFoundImg}/>
    </Container>
  )
}

export default NotFound
