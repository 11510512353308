import styled from "styled-components";

export const Container = styled.div`
  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  svg {
    width: 22px;
    height: 22px;
    color: var(--fixit);
    cursor: pointer;
  }

  span {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-gray);
  }
`;

export const Title = styled.h1`
  font-size: 18px;
  color: var(--fixit);
  font-weight: 500;
`

export const FormContainer = styled.div`
  width: 100%;

  .section_title {
    font-size: 18px;
    color: var(--primary);
    text-align: center;
    margin-top: 20px;
  }
`;

export const CheckBoxGroupContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

export const EndLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 16px;
  margin-top: 20px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const PrintersContainer = styled.div`
  margin-top: 20px;

  > span {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  .printer_empty_state {
    font-size: 16px;
    color: var(--dark-gray);
    margin-top: 4px;
  }
`;

export const PrinterItem = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 10px 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 10px;

  svg {
    cursor: pointer;
  }

  span {
    color: var(--primary);
  }
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;

export const Language = styled.select`
  width: fit-content;
  cursor: pointer;
  color: var(--gray);
  font-weight: 500;
  background-color: var(--background);
  border: 1px solid var(--gray);
  border-radius: 10px;
  padding: 5px;

  option {
    color: var(--dark-gray);
    font-size: 12px;
    line-height: 14px;
    width: fit-content;
  }

  &:hover {
    color: var(--fixit);
    border: 1px solid var(--fixit);
  }
`;
