import api from "../../../services/api";
import { ISolution } from "../dtos";


class Services {
    async getSolutions (signal: AbortSignal) : Promise<ISolution[]> {
        const { data } = await api.get("/solutions", { signal })
        return data;
    }
}

export const services = new Services();

