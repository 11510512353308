import ReactDynamicFont from "react-dynamic-font";
import { FiInfo } from "react-icons/fi";
import Popover from "../../../../../../components/Popover";
import {
  CardValue,
  IconContainer,
  Info,
  MiniCardBox,
  MiniHeader,
} from "./styles";

interface NumberCardProperties {
  icon: React.ReactNode;
  title: string;
  value: number | string;
  hasAditionalInfo?: boolean;
  aditionalInfo?: string;
}

export function NumberCardBox({
  icon,
  title,
  value,
  hasAditionalInfo = false,
  aditionalInfo,
}: NumberCardProperties) {
  return (
    <MiniCardBox>
      <Popover label={aditionalInfo} position="left">
        <Info display={hasAditionalInfo}>
          <FiInfo color="var(--fixit)" />
        </Info>
      </Popover>
      <IconContainer>{icon}</IconContainer>
      <CardValue>
        <ReactDynamicFont content={value.toString()} />
      </CardValue>
      <MiniHeader>{title}</MiniHeader>
    </MiniCardBox>
  );
}
