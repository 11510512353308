import React, { ReactNode } from 'react';

import { Flex, PageTile } from './styles';

interface IHeadProps {
  title: string;
  children?: ReactNode;
}

const Head: React.FC<IHeadProps> = ({ title, children }) => {
  return (
    <Flex>
      <PageTile>{title}</PageTile>
      {children}
    </Flex>
  );
};

export default Head;
