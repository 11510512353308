import styled from 'styled-components'

interface IPreviewProps {
  src: string
}

export const Container = styled.ul`
  margin-top: 20px;

  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dark-gray);

    & + li {
      margin-top: 15px;
    }
  }
`

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;

    p {
      font-size: 12px;
    }

    span {
      font-size: 12px;
      color: var(--light-gray);

      button {
        border: 0;
        background: transparent;
        color: #FA0A0A;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`

export const Preview = styled.div<IPreviewProps>`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`
