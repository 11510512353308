import styled from 'styled-components'


export const ButtonInput = styled.div`
    width: 100%;
    justify-content: center;
`


export const InputContainer = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  justify-items: center;
  flex-direction: column;
  margin-top: 10px;
  padding: 0;
  transition: all 0.3s;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const SubmitButton = styled.div`
    background-color: var(--fixit);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    height: 35px;
    font-size: 20px;
    color: var(--fixit);
    cursor: pointer;
    border: 2px solid var(--gray);
    border-radius: 20px;
    transition: 0.5s;
    color: white;

    &:hover{
    }
`;

export const JustifyInput = styled.textarea`
padding: 5px 5px;
height: 100%;
width: 100%;
border: 2px solid var(--gray);
border-radius: 12px;
font-size: 16px;
color: var(--dark-gray);
transition: 0.5s;
resize: none;
&:focus{
      border-color: var(--fixit);
    }
`
export const InputHeader = styled.header`
font-weight: 500;
text-align: center;
color: var(--fixit);
font-size: 22px;
padding: 10px 0 0 0;
`

export const CloseButton = styled.button`
    background: inherit;
    color: var(--fixit);
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    
`