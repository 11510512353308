import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/AuthContext";

import GlobalStyles from "./styles/GlobalStyles";
import { PrinterProvider } from "./hooks/PrintersContext";
import { OnboardProvider } from "./hooks/OnboardContext";
import { TipsProvider } from "./hooks/TipsContext";
import Router from "./routes";
import { WebSocketProvider } from "./hooks/PrinterWebSocketContext";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <WebSocketProvider>
          <TipsProvider>
            <PrinterProvider>
              <OnboardProvider>
                <Router />
              </OnboardProvider>
            </PrinterProvider>
          </TipsProvider>
        </WebSocketProvider>
      </AuthProvider>
      <GlobalStyles />
    </BrowserRouter>
  );
}

export default App;
