import styled, { css } from "styled-components";
import global from "../../global";

type FormProps = {
    disabled?: boolean;
};

export const Container = styled.div`
  z-index: 100;
  position: absolute;
  top: calc(50vh - 45px);
  left: 50vw;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 20px;
  background-color: var(--white);
  min-width: 25vw;
  align-items: center;
  justify-content: center;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    top: 10px;
    left: 10px;
    transform: translate(0%, 0%);
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
  }
  
`;

export const DownloadButton = styled.button`

  min-width: 100px;
  max-width: 200px;
  border-radius: 4px;
  border: none;
  background: var(--fixit);
  font-size: 20px;
  padding: 8px 8px;
  display: grid;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  margin: 0 auto;

  margin-top: 10px;
  margin-bottom: 5px;

  a{
    text-decoration: none;
    color: var(--white)
  }

  a:hover{
    text-decoration: underline;
  }
`;

export const Border = styled.div`
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
`;

export const CloseBtn = styled.button`
  border: none;
  outline: none;
  background: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  cursor: pointer;
  color: var(--dark-gray);
  transition: color 100ms ease-in-out;
  :hover {
    color: var(--fixit);
  }
`;

export const InfoBtn = styled.button`
  border: none;
  outline: none;
  background: transparent;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--finished);
  transition: color 100ms ease-in-out;
  :hover {
    color: var(--fixit);
  }
  display: flex;
`;

export const Title = styled.h1`
  color: var(--dark-gray);
  margin-bottom: 10px;
  top: 30px;
  left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

export const Subtitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;

  span {
    font-size: 15px;
    color: var(--dark-gray);
    font-weight: 300;
  }
`

export const Backdrop = styled.div`
  position: absolute; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100vh; /* Full height (cover the whole page) */
  
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  
  align-items: center;
  justify-content: center;
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  //cursor: pointer; /* Add a pointer on hover */
`;

export const ScrollableContainer = styled.div`
  max-height: 50vh;
  overflow-y: auto;
  padding: 5px;
  display: grid;
  flex-direction: column;
  row-gap: 5px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    max-height: calc(100vh - 50px);
  }
`

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;


export const SaveText = styled.span<FormProps>`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${({ disabled }) =>
    disabled ? "var(--light-gray)" : "var(--finished)"};
  cursor: ${({ disabled }) => disabled ? "default" : "pointer"};
  width: 100%;
  border: 1px solid ${({ disabled }) =>
                      disabled ? "var(--light-gray)" : "var(--finished)"};
  padding: 10px 10px;
  border-radius: 10px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        border-color: var(--finished);
        color: var(--white);
        background: var(--finished);
      }
    `}
`;