import { ISolution } from "../../dtos";
import { i18n } from "../../../../localization/i18n";
import { RiDownload2Line } from "react-icons/ri";
import Popover from "../../../../components/Popover";
import {
  Svg,
  Container,
  ContentInfos,
  Header,
  LeftContent,
  MobileContent,
  RightContent,
  SolutionInfos,
  IconButtonContainer,
} from "./styles";
import { ImageUploadPopup } from "../ImageUploadPopup";
import { CustomSolutionOptions } from "../CustomSolutionOptions";
import { StandardSolutionOptions } from "../StandardSolutionOptions";
import { DownloadSolutionOptions } from "../DownloadSolutionOptions";
import { EditSolutionOptions } from "../EditSolutionOptions";
import { Modal } from "../../../../components/Modal";
import { I18NTextLanguageObject } from "../../../../utils/getActiveLanguage";

interface ISolutionItemParams {
  editSolution: () => void;
  solution: ISolution;
  updateSolution: (solution: ISolution) => void;
}

export function SolutionItem({
  solution,
  editSolution,
  updateSolution,
}: ISolutionItemParams) {
  return (
    <>
      <Container>
        <Header>
          <LeftContent>
            <ImageUploadPopup
              updateImageLink={(link) => {
                solution.link_img = link;
              }}
              solution={solution}
            />
            <SolutionInfos>
              <span className="solution_name">{solution?.name}</span>
              <span className="solution_location">
                {solution.solution_category?.name[
                  i18n.language as keyof I18NTextLanguageObject
                ] ?? `${i18n?.t("solutions.uncategorized")}`}
              </span>
            </SolutionInfos>
          </LeftContent>

          <RightContent>
            <CustomSolutionOptions
              updateSolution={(enable, number_measures, link_guide) => {
                updateSolution({
                  ...solution,
                  is_custom: enable,
                  number_measures,
                  link_guide,
                });
              }}
              solution={solution}
            />
            <StandardSolutionOptions
              updateSolution={(enable, parts) => {
                updateSolution({
                  ...solution,
                  is_standard: enable,
                  parts,
                });
              }}
              solution={solution}
            />
            <DownloadSolutionOptions
              updateSolution={(link, enable) => {
                const tempSolution: ISolution = { ...solution };

                if (!!link) {
                  tempSolution.download_link = link;
                }
                if (link === "") {
                  tempSolution.download_link = undefined;
                }

                if (enable !== null) {
                  tempSolution.is_download = enable;
                }
                updateSolution(tempSolution);
              }}
              solution={solution}
            />
            <EditSolutionOptions
              onClick={editSolution}
              updateSolution={(solutionData: Partial<ISolution>) => {
                updateSolution({
                  ...solution,
                  ...solutionData,
                });
              }}
              solution={solution}
            />
          </RightContent>
        </Header>
        <MobileContent>
          <LeftContent>
            <ContentInfos>
              <span className="data_value">{solution.quantity}</span>
            </ContentInfos>
            <ContentInfos>
              <span className="data_value">{solution.sides}</span>
            </ContentInfos>
          </LeftContent>
        </MobileContent>
      </Container>
    </>
  );
}
