import styled from "styled-components";
import global from "../../../../global";

export const PaymentInfosContainer = styled.div`
  .title {
    font-size: 22px;
    font-weight: bold;
    color: var(--dark-gray);
  }
`;

export const Content = styled.div`
  max-width: 100%;
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    grid-template-columns: 1fr;
  }
`;
