import { ActionBadge, Container, SmallAvatar } from "./styles";
import { RiPencilLine } from "react-icons/ri";
import { Avatar, ClickAwayListener } from "@mui/material";
import { ISolution } from "../../dtos";
import { useEffect, useState } from "react";
import { BoxImageUpload } from "../BoxImageUpload";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import Popover from "../../../../components/Popover";
import { i18n } from "../../../../localization/i18n";

const chunkSize = 1024 * 1024 * 5;

interface IPartConfirmation {
    ETag: string;
    PartNumber: number;
}

interface IUploadProgress {
    currentChunkIndex: number | null;
    currentFileIndex: number;
    UploadId?: string;
    randomFileId?: string;
    partsConfirmation: Array<IPartConfirmation>;
}

interface IImageUploadPopupParams {
    solution: ISolution;
    updateImageLink: (link: string) => void;
}

export function ImageUploadPopup({ solution, updateImageLink } : IImageUploadPopupParams) {
    const [ editImageSolution, setEditImageSolution ] = useState<ISolution>();
    const [file, setFile] = useState<File>()
    const [progress, setProgress] = useState<number>(0)
    const [uploadFase, setUploadFase] = useState<string>()
    const [uploadProgress, setUploadProgress] = useState<IUploadProgress>({
        currentChunkIndex: null,
        currentFileIndex: 0,
        partsConfirmation: []
      });
    
    const [totalSize, setTotalSize] = useState(0);
    const [totalChunks, setTotalChunks] = useState(0);

    const handleClickAway = () => {
        setEditImageSolution(undefined);
        setUploadFase(undefined)
        setFile(undefined)
        setProgress(0)
        setUploadProgress({
          currentChunkIndex: null,
          currentFileIndex: 0,
          partsConfirmation: []
        })
        setTotalSize(0)
        setTotalChunks(0) 
    }

  function uploadChunk(readerEvent: ProgressEvent<FileReader>, chunkBlobSize: number) {
    if (
      uploadProgress.currentChunkIndex == null ||
      !readerEvent.target?.result ||
      !file
    ) {
      showToast({
        type: "error",
        message: `Nenhuma imagem foi adicionado`,
      });
      return;
    }

    const filesize = file.size;
    const chunks = Math.ceil(filesize / chunkSize) - 1;
    const isLastChunk = uploadProgress.currentChunkIndex === chunks;

    const chunk = readerEvent.target.result;

    const params = new URLSearchParams();
    params.set("name", file.name);
    params.set(
      "size",
      `${file.size}`
    );
    params.set("type", file.type);

    params.set(
      "currentChunkIndex",
      uploadProgress.currentChunkIndex.toString()
    );
    params.set(
      "totalChunks",
      Math.ceil(
        file.size / chunkSize
      ).toString()
    );
    if (uploadProgress.UploadId) {
      params.set("UploadId", uploadProgress.UploadId);
    }
    if (uploadProgress.randomFileId) {
      params.set("randomFileId", uploadProgress.randomFileId);
    }
    if (isLastChunk) {
      params.set("Parts", JSON.stringify(uploadProgress.partsConfirmation));
    }

    const headers = { "Content-Type": "application/octet-stream" };
    api
      .patch(`/solutions/${solution.id}/image?${params.toString()}`, chunk, {
        headers,
      })
      .then((response) => {
        setTotalChunks((prevState) => prevState + chunkBlobSize);
        
        const nextStage: IUploadProgress = uploadProgress;

        if (uploadProgress.currentChunkIndex === 0) {
          nextStage.UploadId = response.data.UploadId;
          nextStage.randomFileId = response.data.randomFileId;
        }

        if (isLastChunk) {
          nextStage.partsConfirmation = [];
          nextStage.UploadId = undefined;
          nextStage.randomFileId = undefined;
          nextStage.currentChunkIndex = null;
          nextStage.currentFileIndex = 0;
          showToast({
            type: "success",
            message: "Arquivo padronizado configurado com sucesso!",
          });
          updateImageLink(response.data.link)
        } else {
          nextStage.partsConfirmation = [
            ...nextStage.partsConfirmation,
            { ETag: response.data.ETag, PartNumber: response.data.PartNumber },
          ];
          nextStage.currentChunkIndex = (nextStage.currentChunkIndex || 0) + 1;
        }

        setUploadProgress({ ...nextStage });
      })
      .catch((e) => {
        showToast({
          type: "error",
          message: `Erro ao atualizar imagem da solução`,
        });
        setUploadProgress((prevState) => ({
          partsConfirmation: [],
          UploadId: undefined,
          randomFileId: undefined,
          currentChunkIndex: null,
          currentFileIndex: 0
        }));
      });
    }

    useEffect(() => {
      if (totalChunks > 0 && totalSize > 0) {
        setProgress(Math.ceil((totalChunks / totalSize) * 100));
      }
    }, [totalChunks])

    function readAndUploadCurrentChunk() {
        if (uploadProgress.currentChunkIndex == null || !file) {
            return;
        }

        const reader = new FileReader();

        const from = uploadProgress.currentChunkIndex * chunkSize;
        const to = from + chunkSize;
        const blob = file.slice(
            from,
            to
        );
        reader.onload = (e) => uploadChunk(e, blob.size);
        reader.readAsDataURL(blob);
    }

    useEffect(() => {
        if (uploadProgress.currentChunkIndex !== null) {
            readAndUploadCurrentChunk();
        }
    }, [uploadProgress.currentChunkIndex]);

    useEffect(() => {
        if (!!file) {
            setTotalSize(file.size);
        }
    }, [file])

    useEffect(() => {
        if (!!uploadFase && uploadFase === 'prepare') {
            setUploadProgress((prevState) => ({
                ...prevState,
                currentChunkIndex: 0,
                currentFileIndex: 0,
                UploadId: undefined,
                randomFileId: undefined,
                partsConfirmation: [],
                progress: 1
            }));
        }
    }, [uploadFase])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
                <Popover position="right" label={`${i18n.t("solutions.edit_image")}`}>
                  <ActionBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      onClick={() => setEditImageSolution(solution)}
                      badgeContent={
                          <SmallAvatar>
                              <RiPencilLine
                                      size={13}
                                      onClick={() => {}}
                                  />
                          </SmallAvatar>
                      }>
                          <Avatar alt={solution.name?.charAt(0)} src={`${process.env.REACT_APP_STORAGE_BASE_URL}${solution.link_img}`} />
                  </ActionBadge>
                </Popover>
                {
                    !!editImageSolution ?
                    (
                        <BoxImageUpload
                            progress={progress}
                            file={file}
                            setFile={(f) => setFile(f)}
                            uploadFase={uploadFase}
                            close={() => handleClickAway()}
                            submit={() => setUploadFase('prepare')}/>
                    ) : null
                }
            </Container>
        </ClickAwayListener>
    )
}