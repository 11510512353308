import React, { useState } from 'react';
import {
  Container,
  DropdownBtn,
  List,
  ListItem,
  Overlay
} from './styles';

interface IDropdownProps {
  selectHandler: () => void  
  selectItem(index: number): void
  selectionList: string[]
  selected: string
  width?: string
}

const Dropdown = ({ selectHandler, selectionList, selected, selectItem, width }: IDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => {
    setIsOpen(!isOpen)
    selectHandler()
  }

  return (
    <Container>
      <DropdownBtn width={width} onClick={toggleMenu}>{selected}</DropdownBtn>
      {isOpen ? 
        <>
          <List>
            {
              selectionList.map((alternative, index) => {
                return <ListItem onClick={() => {selectItem(index); toggleMenu()}}>{alternative}</ListItem>
              }) 
            }
          </List>
          <Overlay onClick={toggleMenu}/>
        </> : 
        null }
    </Container>
  );
};

export default Dropdown;
