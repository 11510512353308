import { useRoutes } from "react-router-dom";
import { lazy, Suspense } from "react";
import { i18n } from "../../localization/i18n";

import { Loading } from "../../components/Loading";

const Dashboard = lazy(() => import("../../modules/Admin/pages/Dashboard"));
const ListCreators = lazy(() => import("../../modules/Admin/pages/Creators"));
const CreateCreator = lazy(
  () => import("../../modules/Admin/pages/CreateCreator")
);
const UploadTerms = lazy(() => import("../../modules/Admin/pages/UploadTerms"));
const Configurations = lazy(
  () => import("../../modules/Admin/pages/Configurations")
);
const Fields = lazy(() => import("../../modules/Admin/pages/Fields"));
const Categories = lazy(() => import("../../modules/Admin/pages/Categories"));
const EditField = lazy(() => import("../../modules/Admin/pages/EditField"));
const EditCategory = lazy(
  () => import("../../modules/Admin/pages/EditCategory")
);
const CreateField = lazy(() => import("../../modules/Admin/pages/CreateField"));
const CreateCategory = lazy(
  () => import("../../modules/Admin/pages/CreateCategory")
);
const EditCreator = lazy(() => import("../../modules/Admin/pages/EditCreator"));
const Orders = lazy(() =>
  import("../../modules/Orders").then((module) => ({ default: module.Orders }))
);
const ShowOrder = lazy(() => import("../../modules/Orders/ShowOrder"));
const LicenseesList = lazy(() =>
  import("../../modules/Licensee").then((module) => ({
    default: module.LicenseesList,
  }))
);
const CreateLicensee = lazy(
  () => import("../../modules/Licensee/pages/CreateLicensee")
);
const EditLicensee = lazy(
  () => import("../../modules/Licensee/pages/EditLicensee")
);
const Docs = lazy(() => import("../../modules/Admin/pages/Docs"));
const Printers = lazy(() => import("../../modules/Admin/pages/Printers"));
const Users = lazy(() => import("../../modules/Admin/pages/Users"));
const Tips = lazy(() => import("../../modules/Admin/pages/Tips"));
const DiagnosisList = lazy(() => import("../../modules/Admin/pages/Diagnosis"));
const Prices = lazy(() => import("../../modules/Admin/pages/Prices"));
const Plans = lazy(() => import("../../modules/Admin/pages/Plans"));
const Bills = lazy(() => import("../../modules/Admin/pages/Bills"));
const Layout = lazy(() => import("../../components/Layout"));

function AdminRoutes() {
  const adminRoutes = useRoutes([
    {
      path: "/admin/dashboard",
      element: (
        <Layout>
          <Dashboard />
        </Layout>
      ),
    },
    {
      path: "/admin/orders",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            <Orders />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/admin/orders/show",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <ShowOrder />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/admin/prices",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Prices />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/admin/plans",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Plans />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/admin/bills",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Bills />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/licensees",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <LicenseesList />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/create/licensee",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <CreateLicensee />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/creators",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <ListCreators />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/licensee",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <LicenseesList />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/create/creator",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <CreateCreator />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/edit/licensee",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <EditLicensee />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/users/edit/creator",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <EditCreator />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Configurations />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/fields",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Fields />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/fields/:field_key",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Categories />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/fields/create",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <CreateField />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/fields/:field_key/edit",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <EditField />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/fields/:field_key/create",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <CreateCategory />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/fields/:field_key/:category_key/edit",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <EditCategory />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/docs/:document_type",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Docs />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/terms/:contract_version/upload",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <UploadTerms />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/printers",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Printers />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/users",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Users />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/tips",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <Tips />
          </Suspense>
        </Layout>
      ),
    },
    {
      path: "/configs/diagnosis",
      element: (
        <Layout>
          <Suspense
            fallback={
              <Loading
                loading={true}
                text={`${i18n.t("login.starting")}`}
                numberOfDots={3}
                dotSymbol="."
              />
            }
          >
            {" "}
            <DiagnosisList />
          </Suspense>
        </Layout>
      ),
    },
  ]);

  return adminRoutes;
}

export default AdminRoutes;
