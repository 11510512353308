

export class EncryptionFactory {
    async getEncryptionKey(algo: any, FILE_KEY: string) {

        const masterPassword = await crypto.subtle.importKey(
            'raw',
            new TextEncoder().encode(FILE_KEY),
            { name: 'PBKDF2' },
            false,
            ['deriveKey']
        )

        return await crypto.subtle.deriveKey(
            {
            name: 'PBKDF2',
            salt: new TextEncoder().encode(FILE_KEY.length + ""),
            hash: { name: 'SHA-1'},
            iterations: 1000,
            },
            masterPassword,
            algo,
            false,
            [ 'encrypt', 'decrypt' ]
        )
    }
}