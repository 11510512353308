import { useEffect, useState } from "react";
import { Modal } from "../Modal";

import {
  Title,
  ScrollableContainer,
  InputLine,
  Footer,
  SaveText,
  Subtitle,
  CancelBtn,
} from "./styles";
import api from "../../services/api";

import "react-circular-progressbar/dist/styles.css";
import {
  IFileChoice,
  IOrder,
  IPrinting,
} from "../../modules/Orders/dtos";
import { i18n } from "../../localization/i18n";
import { OutlinedCheckbox } from "../OutlinedCheckbox";
import { format } from "date-fns";
import { showToast } from "../CustomToast";
import { solutionTypes } from "../../utils/constants";

interface PrintManagementPopUpModalProps {
  order: IOrder;
  isOpen: boolean;
  onRequestClose: () => void;
  closeIfClickOut?: boolean;
  inheritOverflow?: boolean;
}

export function PrintManagementPopUpModal({
  order,
  isOpen,
  inheritOverflow,
  onRequestClose,
}: PrintManagementPopUpModalProps) {
  const [loading, setLoading] = useState(true);
  const [fileChoices, setFileChoices] = useState<IFileChoice[]>();
  const [selectedFileChoice, setSelectedFileChoice] = useState<IFileChoice>();
  const [selectedPrinting, setSelectedPrinting] = useState<IPrinting>();
  const [showProgress, setShowProgress] = useState(false);
  const [fileChoicesCount, setFileChoicesCount] = useState();

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' às 'HH:mm");
  }

  const getFileChooses = async () => {
    try {
      const {
        data: { data, total },
      } = await api.get(`/file-choice/order/${order.id}`);
      setFileChoices(data);
      setFileChoicesCount(total);
    } catch (e) {
      setFileChoices(undefined);
    }
  };

  function cancelChoice(choiceId?: string) {
    if (!choiceId) {
      return;
    }

    setLoading(true);
    api
      .delete(`/file-choice/${choiceId}`)
      .then(({ data }) => {
        getFileChooses();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function cancelPrinting(printingId?: string) {
    if (!printingId) {
      return;
    }
    setLoading(true);
    api
      .delete(`/printings/${printingId}`)
      .then(({ data }) => {
        setFileChoices((prevFileChoices) => {
          const newState = prevFileChoices?.map((f) => {
            if (f.id === data.file_stream.file_choice_id) {
              return {
                ...f,
                file_streams: f.file_streams.map((s) => {
                  if (s.id === data.file_stream_id) {
                    return {
                      ...s,
                      printings: s.printings.map((p) => {
                        if (p.id === data.id) {
                          const newPrinting: IPrinting = data;
                          return newPrinting;
                        }

                        return p;
                      }),
                    };
                  }

                  return s;
                }),
              };
            }
            return f;
          });

          setSelectedFileChoice((prev) =>
            newState?.find((f) => f.id === prev?.id)
          );

          return newState;
        });
        setSelectedPrinting(undefined);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const reopenFileChoice = async (fileChoiceId: string) => {
    try {
      await api.put(`/file-choice/${fileChoiceId}/reopen`).then(() => getFileChooses())
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  };

  const endFileChoice = async (fileChoiceId: string) => {
    try {
      await api.put(`/file-choice/${fileChoiceId}/complete`);
      await getFileChooses();
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  };

  const resetPrinting = async (printingId?: string) => {
    if (!printingId) {
      return;
    }

    try {
      await api.put(`/printings/${printingId}/progress`, {
        progress: 0,
      });

      setFileChoices((prevFileChoices) => {
        const newState = prevFileChoices?.map((f) => {
          return {
            ...f,
            file_streams: f.file_streams.map((s) => {
              return {
                ...s,
                printings: s.printings.map((p) => {
                  if (p.id === printingId) {
                    return {
                      ...p,
                      progress: 0,
                      is_active: true,
                    };
                  }

                  return p;
                }),
              };
            }),
          };
        });

        setSelectedFileChoice((prev) =>
          newState?.find((f) => f.id === prev?.id)
        );

        return newState;
      });
      setSelectedPrinting(undefined);
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  };

  const endPrinting = async (printingId?: string) => {
    if (!printingId) {
      return;
    }

    try {
      await api.put(`/printings/${printingId}/progress`, {
        progress: 100,
      });

      setFileChoices((prevFileChoices) => {
        const newState = prevFileChoices?.map((f) => {
          return {
            ...f,
            file_streams: f.file_streams.map((s) => {
              return {
                ...s,
                printings: s.printings.map((p) => {
                  if (p.id === printingId) {
                    return {
                      ...p,
                      progress: 100,
                      is_active: true,
                    };
                  }

                  return p;
                }),
              };
            }),
          };
        });

        setSelectedFileChoice((prev) =>
          newState?.find((f) => f.id === prev?.id)
        );

        return newState;
      });
      setSelectedPrinting(undefined);
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  };

  useEffect(() => {
    if (!!order && isOpen) {
      // getPrintHistory();
      getFileChooses();
    }
  }, [order, isOpen]);

  return (
    <Modal
      open={isOpen}
      inheritOverflow={inheritOverflow}
      onRequestClose={onRequestClose}
      title={<Title>{`${i18n.t("orders.printManagement")}`}</Title>}
      backAction={
        !!showProgress
          ? () => {
            setSelectedFileChoice(undefined);
            setSelectedPrinting(undefined);
            setShowProgress(false);
          }
          : undefined
      }
    >
      {
        order.solution_type === solutionTypes.standard ?
          <Subtitle>
            <span>{`${i18n.t("orders.printChoose.available_info", {
              available: order.quantity - (fileChoicesCount || 0),
              quantity: order.quantity,
            })}`}</span>
          </Subtitle>
          :
          <Subtitle>
            <span>{`${i18n.t("orders.printChoose.available_info", {
              available: order.file_choices.length - order.file_choices.filter(f => f.completed).length,
              quantity: order.quantity,
            })}`}</span>
          </Subtitle>
      }

      <ScrollableContainer>
        {!showProgress || !selectedFileChoice
          ? fileChoices?.map((existingFileChoice) => (
            <InputLine key={existingFileChoice.id}>
              <OutlinedCheckbox
                streched
                label={i18n.t(
                  existingFileChoice.completed
                    ? "orders.printChoose.printControl.completed"
                    : "orders.printChoose.printControl.printing",
                  {
                    print_option: `${(order.solution_type === solutionTypes.custom ? order.solution.name : existingFileChoice.solution_standard?.size)} ${existingFileChoice.side}`,
                  }
                )}
                checked={selectedFileChoice?.id === existingFileChoice.id}
                handleChange={() => {
                  setSelectedFileChoice(existingFileChoice);
                }}
              ></OutlinedCheckbox>
            </InputLine>
          ))
          : selectedFileChoice.file_streams?.map((stream) => {
            return stream.printings?.map((printing) => (
              <InputLine key={printing.id}>
                {printing.is_active && printing.progress > 95 ? (
                  <OutlinedCheckbox
                    inactiveBgColor="var(--dark-gray)"
                    activeBgColor="var(--dark-gray)"
                    streched={true}
                    label={i18n.t(
                      "orders.printChoose.printControl.completed",
                      {
                        print_option: `- ${stream.part}`,
                      }
                    )}
                    extralabel={formatDate(printing.created_at)}
                    checked={selectedPrinting?.id === printing.id}
                    handleChange={() => {
                      setSelectedPrinting(printing);
                    }}
                  ></OutlinedCheckbox>
                ) : (
                  <OutlinedCheckbox
                    inactiveBgColor="var(--warning)"
                    streched={true}
                    label={i18n.t(
                      "orders.printChoose.printControl.tryagain",
                      {
                        print_option: ` - ${stream.part}`,
                      }
                    )}
                    extralabel={formatDate(printing.created_at)}
                    checked={selectedPrinting?.id === printing.id}
                    handleChange={() => {
                      setSelectedPrinting(printing);
                    }}
                  ></OutlinedCheckbox>
                )}
              </InputLine>
            ));
          })}
      </ScrollableContainer>
      <Footer>
        {!showProgress ? (
          <>
            <CancelBtn
              width={110}
              disabled={!selectedFileChoice || order.solution_type === solutionTypes.custom}
              onClick={() => {
                order.solution_type !== solutionTypes.custom &&
                  cancelChoice(selectedFileChoice?.id);
              }}
            >
              Excluir
            </CancelBtn>
            {selectedFileChoice?.completed ? (
              <SaveText
                disabled={!selectedFileChoice}
                onClick={() =>
                  !!selectedFileChoice &&
                  reopenFileChoice(selectedFileChoice?.id)
                }
              >
                Reabrir
              </SaveText>
            ) : (
              <SaveText
                disabled={!selectedFileChoice || selectedFileChoice?.completed}
                onClick={() =>
                  !!selectedFileChoice && endFileChoice(selectedFileChoice?.id)
                }
              >
                Concluir
              </SaveText>
            )}
            <SaveText
              disabled={!selectedFileChoice}
              onClick={() => !!selectedFileChoice && setShowProgress(true)}
            >
              {`${i18n.t("orders.printChoose.progress")}`}
            </SaveText>
          </>
        ) : (
          <>
            <CancelBtn
              width={110}
              disabled={!selectedPrinting}
              onClick={() => {
                if (order.solution_type === solutionTypes.custom) {
                  resetPrinting(selectedPrinting?.id);
                  return;
                } else {
                  cancelPrinting(selectedPrinting?.id);
                }
              }}
            >
              {order.solution_type === solutionTypes.custom ? "Reiniciar" : "Cancelar"}
            </CancelBtn>
            <SaveText
              disabled={!selectedPrinting || selectedPrinting?.progress > 95}
              onClick={() => {
                (!selectedPrinting || selectedPrinting?.progress <= 95) && endPrinting(selectedPrinting?.id);
              }}
            >
              Finalizar
            </SaveText>
          </>
        )}
      </Footer>
    </Modal>
  );
}
