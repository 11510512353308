import { Container, ProgressBackground, Progress } from "./styles";

interface ProgressBarProps {
  text: string;
  percentage: number;
}

export function ProgressBar({ text, percentage }: ProgressBarProps) {
  return (
    <Container>
      <ProgressBackground>
        <Progress percentage={percentage} />
      </ProgressBackground>
      {text}
    </Container>
  );
}