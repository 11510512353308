import { useEffect } from "react";

import { i18n } from "../../../../localization/i18n";

import { useAuth } from "../../../../hooks/AuthContext";

import { ConfigItem } from "../../../../components/ConfigItem";

import {
  RiPagesLine,
  RiPriceTag2Line,
  RiPrinterCloudLine,
  RiUser3Line,
  RiLightbulbLine,
  RiFirstAidKitLine,
} from "react-icons/ri";

import { Container, EvaluationWrapper } from "./styles";

export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
}

export default function Configurations() {
  const { setNavigationAction } = useAuth();

  const mock = [
    {
      title: "Diagnósticos",
      label: `Clique para configurar`,
      help: "Configure os dianósticos disponíveis para os pedidos.",
      icon: <RiFirstAidKitLine />,
      ativo: true,
      action: "/saf/configs/diagnosis",
    },
  ];

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("global.configs.header"),
    });
  }, []);

  return (
    <>
      <Container>
        <EvaluationWrapper>
          {mock.map((section) => {
            return (
              <ConfigItem
                action={section.action}
                icon={section.icon}
                section={section.title}
                label={section.label}
                help={section.help}
              />
            );
          })}
        </EvaluationWrapper>
      </Container>
    </>
  );
}
