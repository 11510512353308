import {
  RiLineChartLine,
  RiMenuLine,
  RiToolsLine,
  RiUserStarFill,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { i18n } from "../../../localization/i18n";
import { LinkItem, LinkTitle, RouteButton } from "../styles";

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const SafMenuList = ({ toggleMenu }: MenuListProps) => (
  <>
    <LinkItem onClick={toggleMenu}>
      <Link to="/saf/dashboard">
        <RouteButton>
          <RiLineChartLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.reports")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/saf/orders">
        <RouteButton>
          <RiMenuLine size={26} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.requests")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/saf/licensees">
        <RouteButton>
          <RiUserStarFill size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.licensees")}`}</LinkTitle>
      </Link>
    </LinkItem>

    <LinkItem onClick={toggleMenu}>
      <Link to="/saf/configs/diagnosis">
        <RouteButton>
          <RiToolsLine size={28} color="var(--white)" />
        </RouteButton>
        <LinkTitle>{`${i18n.t("sidebar.config")}`}</LinkTitle>
      </Link>
    </LinkItem>
  </>
);
