import { RiDownloadLine } from "react-icons/ri";
import { i18n } from "../../../../localization/i18n";
import { DataBar, Field, FlexCenter, Section } from "../../styles";

interface IDownloadFile {
    popupData: any;
    popupLink: string;
    costFormatted: string;
    downloadTimer: number;
}


const DownloadFile = ({
    popupData,
    popupLink,
    costFormatted,
    downloadTimer
  }: IDownloadFile) => {
      return (
       <>
       <FlexCenter>
          <RiDownloadLine size={64} />
       </FlexCenter>
       <FlexCenter>
            <h1>
                {`${i18n.t("orders.downloadStarting")}`}{" "}
                <br /> {`${i18n.t("orders.printingSuccess")}`}
            </h1>
            <Section>
                <h2>{`${i18n.t("orders.printingData")}`}</h2>
                <DataBar>
                <Field>
                    <strong>{`${i18n.t("orders.printingTime")}:`} </strong>
                    {popupData.printTime}
                </Field>
                <Field>
                    <strong>{`${i18n.t("orders.materialCost")}:`} </strong>
                    {costFormatted}
                </Field>
                <Field>
                    <strong>{`${i18n.t("orders.materialWeight")}:`} </strong>
                    {popupData.weight}
                </Field>
                </DataBar>
            </Section>
            {
                downloadTimer > 0 ? (
                    <span className="timer">
                        Download em {downloadTimer} segundos
                    </span>
                ) :
                (
                    <a
                        href={`${process.env.REACT_APP_STORAGE_BASE_URL}${popupLink}`}
                    >
                        {`${i18n.t("orders.clickToDownload")}`}
                    </a>
                )
            }
        </FlexCenter>
       </>   
      )
}



export default DownloadFile;