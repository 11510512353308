import { useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import { showToast } from "../../../../components/CustomToast";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { SearchableSelect } from "../../../../components/SearchableSelect";
import { SolidButton } from "../../../../components/SolidButton";
import { i18n } from "../../../../localization/i18n";
import api, { isAxiosCancel } from "../../../../services/api";
import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  Footer,
} from "../../../Admin/pages/Prices/components/CreatePrice/styles";
import { IOption, IPlans, Licensee } from "../../dtos";
import { ISearchableSolution, IUserTerms, SignaturePeriodMocks } from "./mocks";
import * as Yup from "yup";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../utils/validateFormFields";
import { ISignature } from "../../../../hooks/OnboardContext";
import { paymentRecurrencyMocks } from "../../../../components/LicenseeModal/mocks";
import { format } from "date-fns";
import { Checkbox, FormControlLabel } from "@mui/material";

interface SignatureInfoModalImp {
  licensee: Licensee;
  licenseeSignature?: ISignature;
  activePlans: IPlans[];
  onRequestClose: () => void;
  handleAssignPlanToPerson: (
    licensee_id: string,
    plan_id?: string | number,
    user_terms_id?: string,
    payment_value?: string,
    payment_recurrency?: string | number,
    end_date?: Date | null
  ) => void;
  handleChangeLicenseePlan: (
    licensee_id: string,
    plan_id?: string | number,
    user_terms_id?: string,
    payment_value?: string,
    payment_recurrency?: string | number,
    end_date?: Date | null
  ) => void;
}

interface IFormData {
  licensee_id?: string;
  plan?: IOption;
  user_terms?: IUserTerms;
  payment_value?: string;
  payment_recurrency?: IOption;
  end_date?: Date | null;
}

export function SignatureInfoModal({
  licensee,
  licenseeSignature,
  activePlans,
  onRequestClose,
  handleAssignPlanToPerson = () => { },
  handleChangeLicenseePlan = () => { },
}: SignatureInfoModalImp) {
  const [formData, setFormData] = useState<IFormData>();
  const [userTerms, setUserTerms] = useState<ISearchableSolution[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [userTermsSearchValue, setUserTermsSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);
  const [withoutEndDate, setWithoutEndDate] = useState(false);

  const formatedPlansToSelect = activePlans.map((plan) => {
    return {
      value: plan.id,
      optionText: plan.plan_name,
    };
  });

  function formatDate(date: string | undefined) {
    if (date === undefined) {
      return "";
    }
    date.replace("Z", "");
    return format(new Date(date), "yyyy-MM-dd");
  }

  async function AssignOrChangeUserSignature() {
    setLoading(true);
    const body = {
      licensee_id: licensee.id,
      plan_id: formData?.plan?.value,
      user_terms: formData?.user_terms?.id,
      payment_value: formData?.payment_value,
      payment_recurrency: formData?.payment_recurrency?.value,
      end_date: withoutEndDate ? null : formData?.end_date,
    };

    const bodySchema = Yup.object().shape({
      licensee_id: Yup.string().required(typesErrors.required),
      plan_id: Yup.string().required(typesErrors.required),
      user_terms: Yup.string().required(typesErrors.required),
      payment_value: Yup.number()
        // .positive("O numero precisa ser positivo")
        .required(typesErrors.required),
      payment_recurrency: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(body, bodySchema, {
      licensee_id: "",
      plan_id: "",
      user_terms: "",
      payment_value: "",
      payment_recurrency: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      setLoading(false);
      return;
    }

    setErrors({});

    !licenseeSignature
      ? handleAssignPlanToPerson(
        body.licensee_id,
        body.plan_id,
        body.user_terms,
        body.payment_value,
        body.payment_recurrency,
        body.end_date
      )
      : handleChangeLicenseePlan(
        licenseeSignature.id,
        body.plan_id,
        body.user_terms,
        body.payment_value,
        body.payment_recurrency,
        body.end_date
      );
  }

  useEffect(() => {
    setLoading(false);
    setWithoutEndDate(licenseeSignature?.end_date === null)

    if (licenseeSignature) {
      setFormData({
        plan: {
          optionText: licenseeSignature.plan.plan_name,
          value: licenseeSignature.plan.id,
        },
        user_terms: {
          id: licenseeSignature.user_terms?.id,
          name: licenseeSignature.user_terms?.name,
          type: licenseeSignature.user_terms?.type,
        },
        payment_value: licenseeSignature.payment_value,
        payment_recurrency: {
          optionText:
            paymentRecurrencyMocks[
            licenseeSignature.payment_recurrency as keyof typeof paymentRecurrencyMocks
            ],
          value: licenseeSignature.payment_recurrency,
        },
        end_date: licenseeSignature.end_date,
      });
      setUserTermsSearchValue(licenseeSignature.user_terms?.name);
    }
  }, [licensee.signature, licensee.id, licenseeSignature]);

  async function searchUserTerms(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      // request de profissionais
      const { data } = await api.get(
        `/user_terms/search?term=${userTermsSearchValue}&type=user_terms`,
        { signal }
      );
      setUserTerms(
        data.map((p: any) => ({
          id: p.id,
          label: p.name,
          model: p,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os contratos.",
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (userTermsSearchValue != "") {
      searchUserTerms(signal);
    }

    return () => abortController.abort();
  }, [userTermsSearchValue]);

  return (
    <Container>
      <RiCloseFill size={24} color="var(--fixit)" onClick={onRequestClose} />

      <Header>
        <span>{`Selecionar plano`}</span>
      </Header>

      <FormContainer>
        <InputLine>
          <InputContainer>
            <OutlinedSelect
              error={errors.plan_id}
              label={`Plano`}
              options={[...formatedPlansToSelect]}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  plan: selected,
                }));
              }}
              selectedOption={formData?.plan?.optionText || ""}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <SearchableSelect
              error={errors.user_terms}
              label={`Documento do contrato`}
              options={userTerms}
              defaultValue={userTermsSearchValue}
              loading={selectLoading}
              onSelect={(selected) => {
                if (typeof selected === "string") {
                  setFormData((prevState) => ({
                    ...prevState,
                    user_terms: undefined,
                  }));
                  return;
                }

                setFormData((prevState) => ({
                  ...prevState,
                  user_terms: userTerms?.find((s) => s.model.id === selected.id)
                    ?.model,
                }));
              }}
              onChange={(value) => {
                setUserTermsSearchValue(value);
              }}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <InputContainer>
            <OutlinedSelect
              error={errors.payment_recurrency}
              label={`Recorrência de pagamento`}
              options={SignaturePeriodMocks}
              handleSelect={(selected) => {
                setFormData((prevState) => ({
                  ...prevState,
                  payment_recurrency: selected,
                }));
              }}
              selectedOption={formData?.payment_recurrency?.optionText || ""}
            />
          </InputContainer>
        </InputLine>
        <InputLine>
          <InputContainer>
            <OutlinedInput
              error={errors.payment_value}
              label={`Valor da parcela/pagamento`}
              inputName="file_price"
              type={"number"}
              handleChange={(event) => {
                setFormData((prevState) => ({
                  ...prevState,
                  payment_value: event,
                }));
              }}
              value={formData?.payment_value ?? 0}
            />
          </InputContainer>
        </InputLine>

        <InputLine>
          <OutlinedInput
            type="date"
            readOnly={withoutEndDate}
            handleChange={(event) => {
              setFormData((prevState) => ({
                ...prevState,
                end_date: event,
              }));
            }}
            inputName={"end_date"}
            label={"Vencimento"}
            value={formatDate(String(formData?.end_date ?? new Date()))}
            error={errors.end_date}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={withoutEndDate}
                onChange={() => setWithoutEndDate(!withoutEndDate)}
                size="small"
                sx={{
                  color: `var(--gray)`,
                  "&.Mui-checked": {
                    color: `var(--fixit)`,
                  },
                }} />

            }
            label="Vencimento indefinido"
            sx={{
              color: `var(--gray)`,
              textAlign: "center",
            }} />
        </InputLine>
      </FormContainer>
      <Footer>
        <SolidButton
          fullWidth
          text={`${i18n.t("financial.prices.save")}`}
          onClick={AssignOrChangeUserSignature}
          loading={loading}
          disabled={loading}
        />
      </Footer>
    </Container>
  );
}
