import styled from "styled-components";

export const Container = styled.div`
  position: relative;

  > svg {
    width: 30px;
    height: 30px;
  }
`;

export const FilterContent = styled.div`
  position: absolute;
  top: 34px;
  right: 34px;

  width: 350px;
  padding: 30px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  z-index: 10;

  @media (max-width: 420px) {
    right: -20px;
    width: 96vw;
  }
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 36px;

  button {
    width: 100%;
  }
`;
