import styled from "styled-components";

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.form`
  margin-top: 26px;
`;

export const InputLine = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 27px;
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const Footer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 16px;

  button {
    width: 100%;
  }
`;
