import React from 'react'

import {
  Input
} from './styles'

interface IToggleableInputProps {
  isDisabled: boolean,
  value: string,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

const ToggleableInput = ({ isDisabled, value, handleChange }: IToggleableInputProps) => {
  return (
    <Input
      value={value}
      disabled={isDisabled}
      onChange={(e) => handleChange(e)}
    />
  )
}

export default ToggleableInput
