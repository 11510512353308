import { useEffect, useState } from "react";
import { format } from "date-fns";
import { Modal } from "../Modal";

import {
  Title,
  ScrollableContainer,
  InputLine,
  Footer,
  SaveText,
  Subtitle,
} from "./styles";
import api from "../../services/api";

import "react-circular-progressbar/dist/styles.css";
import { IFileChoice, IFileStream } from "../../modules/Orders/dtos";
import { i18n } from "../../localization/i18n";
import { OutlinedCheckbox } from "../OutlinedCheckbox";

interface PrintHistoryPopUpModalProps {
  orderId: string;
  quantity: number;
  isOpen: boolean;
  onRequestClose: () => void;
  closeIfClickOut?: boolean;
  inheritOverflow?: boolean;
}

interface Printing {
  id: string;
  progress: number;
  created_at: string;
}

export function PrintHistoryPopUpModal({
  orderId,
  quantity,
  isOpen,
  inheritOverflow,
  onRequestClose,
}: PrintHistoryPopUpModalProps) {
  const [loading, setLoading] = useState(true);
  const [printings, setPrintings] = useState<Printing[]>([]);
  const [fileChoices, setFileChoices] = useState<IFileChoice[]>();
  const [selectedFileChoice, setSelectedFileChoice] = useState<IFileChoice>();
  const [selectedFileStream, setSelectedFileStream] = useState<IFileStream>();
  const [showProgress, setShowProgress] = useState(false);
  const [fileChoicesCount, setFileChoicesCount] = useState();

  function formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' às 'HH:mm");
  }

  function getPrintHistory() {
    setLoading(true);
    api
      .get(`/printings/order/${orderId}`)
      .then((response) => {
        setPrintings(response.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const getFileChooses = async () => {
    try {
      const {
        data: { data, total },
      } = await api.get(`/file-choice/order/${orderId}`);
      setFileChoices(data);
      setFileChoicesCount(total);
    } catch (e) {
      setFileChoices(undefined);
    }
  };

  function getProgressColor(progress: number) {
    if (progress === 100) {
      return "3, 189, 112";
    }

    if (progress > 30) {
      return "255, 202, 81";
    }

    return "255, 72, 0";
  }

  function cancelPrinting(printingId: string) {
    setLoading(true);
    api
      .delete(`/printings/${printingId}`)
      .then((response) => {
        getPrintHistory();
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!!orderId && isOpen) {
      // getPrintHistory();
      getFileChooses();
    }
  }, [orderId, isOpen]);

  return (
    <Modal
      open={isOpen}
      inheritOverflow={inheritOverflow}
      onRequestClose={onRequestClose}
    >
      <Title>{`${i18n.t("orders.printHistory")}`}</Title>
      <Subtitle>
        <span>{`${i18n.t("orders.printChoose.available_info", {
          available: quantity - (fileChoicesCount || 0),
          quantity: quantity,
        })}`}</span>
      </Subtitle>
      <ScrollableContainer>
        {!showProgress || !selectedFileChoice
          ? fileChoices?.map((existingFileChoice) => (
              <InputLine>
                <OutlinedCheckbox
                  streched
                  label={i18n.t(
                    existingFileChoice.completed
                      ? "orders.printChoose.printControl.completed"
                      : "orders.printChoose.printControl.printing",
                    {
                      print_option: `${existingFileChoice.solution_standard?.size} ${existingFileChoice.side}`,
                    }
                  )}
                  checked={selectedFileChoice?.id === existingFileChoice.id}
                  handleChange={() => {
                    setSelectedFileChoice(existingFileChoice);
                  }}
                ></OutlinedCheckbox>
              </InputLine>
            ))
          : selectedFileChoice.file_streams?.map((stream) => {
              const activePrinting = stream.printings?.find((s) => s.is_active);
              return (
                <InputLine key={stream.id}>
                  {activePrinting && activePrinting.progress > 95 && (
                    <OutlinedCheckbox
                      activeBgColor="var(--dark-gray)"
                      streched={true}
                      label={i18n.t(
                        "orders.printChoose.printControl.completed",
                        {
                          print_option: `- ${stream.part}`,
                        }
                      )}
                      extralabel={stream.name}
                      checked={true}
                      readOnly={true}
                      handleChange={() => {}}
                    ></OutlinedCheckbox>
                  )}
                  {activePrinting && activePrinting.progress <= 95 && (
                    <OutlinedCheckbox
                      inactiveBgColor="var(--warning)"
                      streched={true}
                      label={i18n.t(
                        "orders.printChoose.printControl.tryagain",
                        {
                          print_option: ` - ${stream.part}`,
                        }
                      )}
                      extralabel={stream.name}
                      checked={selectedFileStream?.id === stream.id}
                      handleChange={() => {
                        setSelectedFileStream(stream);
                      }}
                    ></OutlinedCheckbox>
                  )}
                  {!activePrinting && (
                    <OutlinedCheckbox
                      streched={true}
                      label={i18n.t(
                        "orders.printChoose.printControl.selectStream",
                        {
                          print_option: ` - ${stream.part}`,
                        }
                      )}
                      extralabel={stream.name}
                      checked={selectedFileStream?.id === stream.id}
                      handleChange={() => {
                        setSelectedFileStream(stream);
                      }}
                    ></OutlinedCheckbox>
                  )}
                </InputLine>
              );
            })}
      </ScrollableContainer>
      <Footer>
        {!showProgress ? (
          <SaveText
            disabled={!selectedFileChoice}
            onClick={() => !!selectedFileChoice && setShowProgress(true)}
          >
            {`${i18n.t("orders.printChoose.progress")}`}
          </SaveText>
        ) : (
          <SaveText
            onClick={() => {
              setSelectedFileChoice(undefined);
              setSelectedFileStream(undefined);
              setShowProgress(false);
            }}
          >
            {`${i18n.t("orders.printChoose.header")}`}
          </SaveText>
        )}
      </Footer>
    </Modal>
  );
}

export default PrintHistoryPopUpModal;
