import styled from "styled-components";
import global from "../../../../global";

interface ISignature {
  situation?: string;
  is_activated?: boolean;
}

interface IconColor {
  hasFile?: boolean;
  correctLink?: boolean;
} 

export const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
  transition: all 0.3s;
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Divider = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: var(--fixit);
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.manager {
    min-width: 120px;
  }

  &.phone {
    min-width: 100px;
  }

  &.document {
    min-width: 80px;
  }

  .data_label {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .data_value {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const ImageContainer = styled.div<IconColor>`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: ${(IconColor) => ((IconColor.hasFile) && (!IconColor.correctLink))  ? "var(--fixit)" : "var(--active-orange)"};

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--white);
  }

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
`;

export const SolutionInfos = styled.div<ISignature>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .solution_name {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .solution_location {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const StatusDesktopContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }

  @media (max-width: ${global.breakpoints.mobile}) {
    display: none;
  }
`;

export const MoreOptionsContainer = styled.div`
  svg {
    cursor: pointer;
  }
`;



export const FileCardFooter = styled.div`
  width: 100%;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;

export const ConfigButton = styled.button`
  width: 140px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  color: var(--white);
  font-size: 16px;
  background: var(--fixit);
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background: #6e00ad;
  }
`;

export const SidesColumnContainer = styled.div`
  width: 100%;
  margin: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

export const Sides = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  color: var(--fixit);
  margin-left: 5px;
`;

export const LinkSide = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;

  svg {
    &hover {
      cursor: pointer;
    }
  }
`;

export const FileText = styled.div`
  color: var(--dark-gray);
  font-weight: 400;
  margin-left: 5px;
  width: 100%;
`;

export const Link = styled.a`

`;

export const AddIcon = styled.button`
  display: flex;
  margin-left: 185px;
  background-color: var(--white);

  svg {
    color: var(--fixit);

    :hover {
      cursor: pointer;
    }
  }
`;

export const File = styled.div`
  width: 85%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5px;

  a {
    font-size: 16px;
    text-decoration: none;
  }

  svg {
    cursor: pointer;
  }

  .text {
    color: var(--dark-gray);
    font-weight: 400;
  }

  @media (max-width:1100px) {
    display: flex;
  }
`;

export const Size = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export const Parts = styled.div`
  display: flex;
  flex-direction: row;

  font-size: 14px;
  font-style: italic;
  margin-right: 5px;
`;

export const InfosContainer = styled.div`
  width: 160px;
  height: 28px;

  display: flex;
  flex-direction: row;
  
  align-items: center;
  justify-content: flex-start;
`;

export const SidesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  flex-direction: row;
  
  width: 100%;
  gap: 3px;
  align-items: end;
  border-radius: 10px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.3);
`;

export const ButtonIconText = styled.div`
  height: 28px;
  border-radius: 4px;

  cursor: pointer;

  transition: background-color 0.2s;

  &.active,
  &:hover {
    background-color: var(--light-gray);
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
