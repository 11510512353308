import styled from 'styled-components';

export const WhatsappForm = styled.form`
    display: flex;
    padding: 10px 10px;
`

export const WhatsappButton = styled.button`
  width: 180px;
  height: 60px;
  
  color: var(--white);
  background-color: var(--whatsapp-color);
  
  display: flex;
  align-items: center;
  justify-content: start;
  padding-left: 10px;
  font-weight: bold;

  border: 5px solid var(--whatsapp-color);
  border-radius: 30px;

  font-size: 18px;

  img{
    margin-right: 5px;
  }

  :hover{
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.1s;
  }
  
`;