import { ClickAwayListener } from "@mui/material";
import { IOrder } from "../../dtos";
import { useEffect, useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { Container, IconButtonContainer} from "./styles";
import Popover from "../../../../components/Popover";
import { i18n } from "../../../../localization/i18n";
import { BoxCustomOrderUploadForm } from "../BoxCustomOrderUploadForm";
import { orderStatus, solutionTypes } from "../../../../utils/constants";
import { RiToolsFill } from "react-icons/ri";
import { usePrinters } from "../../../../hooks/PrintersContext";

const chunkSize = 1024 * 1024 * 5;

interface IPartConfirmation {
  ETag: string;
  PartNumber: number;
}

interface IUploadProgress {
  currentChunkIndex: number | null;
  UploadId?: string;
  randomFileId?: string;
  partsConfirmation: Array<IPartConfirmation>;
}

interface ICustomOrderUploadPopupParams {
  order: IOrder;
  onUpdate: (order: IOrder) => void;
}

interface IUploadFase {
  fase: string;
}

export interface IOrderUploadData {
  printingTime: string;
  materialWeight: string;
  materialCost: string;
  slicer: string;
}

export function CustomOrderUploadForm({
  order,
  onUpdate,
}: ICustomOrderUploadPopupParams) {
  const [orderCustomUploadData, setOrderCustomUploadData] =
    useState<IOrderUploadData>();
  
  const {defaultPrinter} = usePrinters()

  const [loading, setLoading] = useState(false);
  const [editOrder, setEditOrder] = useState<IOrder>();
  const [uploadFase, setUploadFase] = useState<IUploadFase>();
  const [file, setFile] = useState<File>();
  const [progress, setProgress] = useState<number>(0);
  const [uploadProgress, setUploadProgress] = useState<IUploadProgress>({
    currentChunkIndex: null,
    partsConfirmation: [],
  });

  const [totalSize, setTotalSize] = useState(0);
  const [totalChunks, setTotalChunks] = useState(0);
  const [slicer, setSlicer] = useState<string>();

  const handleClickAway = () => {
    setEditOrder(undefined);
  };

  const handleDeleteFile = (id: string) => {
    api.delete(`/files/${id}`).then(() => {
      updateOrderStatus(orderStatus.development);
      onUpdate({ ...order, files: order.files.filter((f) => f.id !== id) });
      // updateSolution("", null)
    });
  };

  const updateOrderStatus = async (status: string) => {
    try {
      await api.patch(`/orders/${order.id}`, { status: status });
      onUpdate({
        ...order,
        status: status,
      });
    } catch (e) {
      showToast({
        type: "error",
        message: "Não foi possível atualizar o status do pedido",
      });
    }
  };

  function uploadChunk(
    readerEvent?: ProgressEvent<FileReader>,
    chunkBlobSize: number = 0
  ) {
    if (
      uploadProgress.currentChunkIndex == null ||
      !file ||
      !orderCustomUploadData
    ) {
      showToast({
        type: "error",
        message: `Nenhum arquivo foi adicionado`,
      });
      return;
    }

    setLoading(true);

    const chunk = readerEvent?.target?.result;
    const params = new URLSearchParams();
    const filesize = file?.size ?? 0;
    const chunks = file ? Math.ceil(filesize / chunkSize) - 1 : 0;
    const isLastChunk = uploadProgress.currentChunkIndex === chunks;

    params.set("name", file.name);
    params.set("printer_id", order.printer?.id ?? defaultPrinter?.value);
    params.set("size", `${file.size}`);
    params.set("type", order.planit_id ? "planit" : "custom");
    params.set("print_time", orderCustomUploadData.printingTime);
    params.set("material_weight", orderCustomUploadData.materialWeight);
    params.set("material_cost", orderCustomUploadData.materialCost);
    if (slicer) {
      params.set("slicer", slicer);
    }
    params.set(
      "currentChunkIndex",
      uploadProgress.currentChunkIndex.toString()
    );
    params.set("totalChunks", Math.ceil(file.size / chunkSize).toString());
    if (uploadProgress.UploadId) {
      params.set("UploadId", uploadProgress.UploadId);
    }
    if (uploadProgress.randomFileId) {
      params.set("randomFileId", uploadProgress.randomFileId);
    }
    if (isLastChunk) {
      params.set("Parts", JSON.stringify(uploadProgress.partsConfirmation));
    }

    const headers = { "Content-Type": "application/octet-stream" };
    api
      .post(`/files/${order.id}?${params.toString()}`, chunk, {
        headers,
      })
      .then((response) => {
        setTotalChunks((prevState) => prevState + chunkBlobSize);

        const nextStage: IUploadProgress = uploadProgress;

        if (uploadProgress.currentChunkIndex === 0) {
          nextStage.UploadId = response.data.UploadId;
          nextStage.randomFileId = response.data.randomFileId;
        }

        if (isLastChunk) {
          nextStage.partsConfirmation = [];
          nextStage.UploadId = undefined;
          nextStage.randomFileId = undefined;
          nextStage.currentChunkIndex = null;
          showToast({
            type: "success",
            message: "Arquivo padronizado configurado com sucesso!",
          });
          onUpdate({
            ...order,
            status: orderStatus.completed,
            files: [...order.files, response.data.file],
          });
          setOrderCustomUploadData(undefined);
          setFile(undefined);
          setSlicer(undefined);
          setUploadFase(undefined);
        } else {
          nextStage.partsConfirmation = [
            ...nextStage.partsConfirmation,
            { ETag: response.data.ETag, PartNumber: response.data.PartNumber },
          ];
          nextStage.currentChunkIndex = (nextStage.currentChunkIndex || 0) + 1;
        }

        setUploadProgress({ ...nextStage });
        setLoading(false);
      })
      .catch((e) => {
        showToast({
          type: "error",
          message: `Erro ao atualizar arquivo da solução`,
        });
        setUploadProgress((prevState) => ({
          partsConfirmation: [],
          UploadId: undefined,
          randomFileId: undefined,
          currentChunkIndex: null,
        }));
        setLoading(false);
      });
  }

  function readAndUploadCurrentChunk() {
    if (uploadProgress.currentChunkIndex == null) {
      return;
    }

    if (!file) {
      uploadChunk(undefined, 0);
      return;
    }

    const reader = new FileReader();

    const from = uploadProgress.currentChunkIndex * chunkSize;
    const to = from + chunkSize;
    const blob = file.slice(from, to);
    reader.onload = (e) => uploadChunk(e, blob.size);
    reader.readAsDataURL(blob);
  }

  useEffect(() => {
    if (totalChunks > 0 && totalSize > 0) {
      setProgress(Math.ceil((totalChunks / totalSize) * 100));
    }
  }, [totalChunks]);

  useEffect(() => {
    if (!!file) {
      setTotalSize(file.size);
    }
  }, [file]);

  useEffect(() => {
    if (uploadProgress.currentChunkIndex !== null) {
      readAndUploadCurrentChunk();
    }
  }, [uploadProgress.currentChunkIndex]);

  useEffect(() => {
    if (!!uploadFase && uploadFase.fase === "prepare") {
      setUploadProgress((prevState) => ({
        ...prevState,
        currentChunkIndex: 0,
        UploadId: undefined,
        randomFileId: undefined,
        partsConfirmation: [],
      }));
    }
  }, [uploadFase]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Container>
        <Popover position="left" label={`${i18n.t("solutions.is_custom")}`}>
          <IconButtonContainer
            active={
              order.status !== orderStatus.cancelled &&
              order.solution_type !== solutionTypes.standard
            }
            onClick={() => {
              if (
                order.status !== orderStatus.cancelled &&
                order.solution_type !== solutionTypes.standard
              ) {
                setEditOrder(order);
              }
            }}
          >
            <RiToolsFill size={18} />
          </IconButtonContainer>
        </Popover>
        {!!editOrder ? (
          <BoxCustomOrderUploadForm
            loading={loading}
            startDevelopment={() => {
              updateOrderStatus(orderStatus.development);
            }}
            callReview={async () => {
              await updateOrderStatus(orderStatus.review);
            }}
            commitReview={async () => {
              await updateOrderStatus(
                order.files?.length
                  ? orderStatus.completed
                  : orderStatus.development
              );
            }}
            deleteFile={handleDeleteFile}
            progress={progress}
            setProgress={setProgress}
            file={file}
            setFile={(f) => setFile(f)}
            orderCustomUploadData={orderCustomUploadData}
            setOrderCustomUploadData={setOrderCustomUploadData}
            slicer={slicer}
            setSlicer={setSlicer}
            order={order}
            uploadFase={uploadFase?.fase}
            close={() => handleClickAway()}
            submit={() =>
              setUploadFase({
                fase: "prepare",
              })
            }
          />
        ) : null}
      </Container>
    </ClickAwayListener>
  );
}
