import { ClickAwayListener } from "@mui/material";
import { ISolution } from "../../dtos";
import { useEffect, useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { Container, IconButtonContainer, Svg } from "./styles";
import PenImg from "../../../../assets/icons/pen_custom_plano.svg";
import Popover from "../../../../components/Popover";
import { i18n } from "../../../../localization/i18n";
import { BoxCustomSolution } from "../BoxCustomSolution";

interface IImageUploadPopupParams {
    solution: ISolution;
    updateSolution: (enable: boolean, number_measures: number, link_guide: string) => void;
}

interface IUploadFase {
    enable: boolean;
    fase: string;
    number_measures: number;
    link_guide: string;
}

export function CustomSolutionOptions({ solution, updateSolution } : IImageUploadPopupParams) {
    const [ editSolution, setEditSolution ] = useState<ISolution>();
    const [uploadFase, setUploadFase] = useState<IUploadFase>()
    
    const handleClickAway = () => {
      setEditSolution(undefined);
    }

    useEffect(() => {
        if (!!uploadFase && uploadFase.fase === 'prepare') {
            api
                .patch(`/solutions/${solution.id}/custom?enable=${uploadFase.enable}`, {
                    link_guide: uploadFase.link_guide,
                    number_measures: uploadFase.number_measures
                })
                .then((response) => {
                    showToast({
                        type: "success",
                        message: "Status da funcionalidade atualizada com sucesso!",
                      });
                    setUploadFase(undefined)
                    updateSolution(uploadFase.enable,
                        uploadFase.number_measures,
                        uploadFase.link_guide)
                })
        }
    }, [uploadFase])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
                <Popover position="left" label={`${i18n.t("solutions.is_custom")}`}>
                    <IconButtonContainer onClick={() => setEditSolution(solution)}>
                        <Svg alt="Custom" active={solution.is_custom} src={PenImg} ></Svg>
                    </IconButtonContainer>
                </Popover>
                {
                    !!editSolution ?
                    (   <BoxCustomSolution
                            solution={solution}
                            uploadFase={uploadFase?.fase}
                            close={() => handleClickAway()}
                            submit={(enable, number_measures, link_guide) => setUploadFase({
                                fase: 'prepare',
                                enable,
                                number_measures,
                                link_guide
                              })}/>
                    ) : null
                }
            </Container>
        </ClickAwayListener>
    )
}