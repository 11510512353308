import styled, { css } from "styled-components";
import global from "../../../../../global";

interface SectionContainerProps {}

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectContainer = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
  }
`;
