import { FaLock } from "react-icons/fa";
import { BiBookOpen } from "react-icons/bi";
import {
  RiDownloadCloud2Line,
  RiLineChartLine,
  RiMenuLine,
} from "react-icons/ri";
import { Link } from "react-router-dom";
import { useOnboard } from "../../../hooks/OnboardContext";
import { i18n } from "../../../localization/i18n";
import { customerSituations } from "../../../utils/constants";
import { RouteButton } from "../styles";

interface MenuListProps {
  extendedMode: boolean;
}

export const FixiterMenuList = ({ extendedMode }: MenuListProps) => {
  const { licensee, planHasExpired } = useOnboard();

  return (
    <>
      <Link
        to={
          licensee?.completed_onboard &&
          licensee?.situation !== customerSituations.onboarding &&
          !planHasExpired
            ? "/fixiter/dashboard"
            : "/fixiter/dashboard"
        }
      >
        {" "}
        {/* Go to Dashboard */} {/* 6 because of 12px margin */}
        <RouteButton
          extendedMode={extendedMode}
          disabled={
            !licensee?.completed_onboard ||
            licensee?.situation === customerSituations.onboarding ||
            planHasExpired
          }
        >
          <FaLock
            className="is_over"
            size={16}
            display={licensee?.completed_onboard ? "none" : ""}
          />
          <RiLineChartLine size={28} />
          {extendedMode && <span>{`${i18n.t("sidebar.reports")}`}</span>}
        </RouteButton>
      </Link>

      <Link
        to={
          licensee?.completed_onboard &&
          licensee?.situation !== customerSituations.onboarding &&
          !planHasExpired
            ? "/fixiter/solutions"
            : "/fixiter/dashboard"
        }
      >
        {" "}
        {/* Go to Solutions */}
        <RouteButton
          extendedMode={extendedMode}
          disabled={
            !licensee?.completed_onboard ||
            licensee?.situation === customerSituations.onboarding ||
            planHasExpired
          }
        >
          <FaLock
            className="is_over"
            size={16}
            display={licensee?.completed_onboard ? "none" : ""}
          />
          <RiDownloadCloud2Line size={28} />
          {extendedMode && <span>{`${i18n.t("sidebar.solutions")}`}</span>}
        </RouteButton>
      </Link>

      <Link
        to={
          licensee?.completed_onboard &&
          licensee?.situation !== customerSituations.onboarding &&
          !planHasExpired
            ? "/fixiter/orders"
            : "/fixiter/dashboard"
        }
      >
        {" "}
        {/* Go to Orders */}
        <RouteButton
          extendedMode={extendedMode}
          disabled={
            !licensee?.completed_onboard ||
            licensee?.situation === customerSituations.onboarding ||
            planHasExpired
          }
        >
          <FaLock
            className="is_over"
            size={16}
            display={licensee?.completed_onboard ? "none" : ""}
          />
          <RiMenuLine size={26} />
          {extendedMode && <span>{`${i18n.t("sidebar.requests")}`}</span>}
        </RouteButton>
      </Link>

      <Link
        to={
          licensee?.completed_onboard &&
          licensee?.situation !== customerSituations.onboarding &&
          !planHasExpired
            ? "/fixiter/courses"
            : "/fixiter/dashboard"
        }
      >
        {" "}
        {/* Go to Dashboard */} {/* 6 because of 12px margin */}
        <RouteButton
          extendedMode={extendedMode}
          disabled={
            !licensee?.completed_onboard ||
            licensee?.situation === customerSituations.onboarding ||
            planHasExpired
          }
        >
          <FaLock
            className="is_over"
            size={16}
            display={!licensee?.completed_onboard ? "" : "none"}
          />
          <BiBookOpen size={28} />
          {extendedMode && <span>{`${i18n.t("sidebar.courses")}`}</span>}
        </RouteButton>
      </Link>
    </>
  );
};
