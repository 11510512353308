import React from 'react'

import { i18n } from '../../localization/i18n'
import { Container, FileInfo } from './styles'

interface IFileListProps {
  files: Array<any>
  handleDelete?: (index: number) => void
}

const FileList = ({ files, handleDelete }: IFileListProps) => {
  return (
    <Container>
      {files.map((uploadedFile, index) => (
        <li key={index} id={"file" + index}>
          <FileInfo>
            <div>
              <p>{uploadedFile.name}</p>
              <span>
                64kb {handleDelete && <button onClick={(_) => {handleDelete(index)}}>{`${i18n.t('fileList.delete')}`}</button>}
              </span>
            </div>
          </FileInfo>
        </li>
      ))}
    </Container>
  )
}


export default FileList
