import React from "react";

import { Container, LinkList, Overlay, Language, Separator } from "./styles";
import Logo from "../../assets/logo-white.svg";
import { useAuth } from "../../hooks/AuthContext";
import { roles } from "../../utils/constants";
import { LicenseeMenuList } from "./MenuList/LicenseeMenuList";
import { CreatorMenuList } from "./MenuList/CreatorMenuList";
import { SafMenuList } from "./MenuList/SafMenuList";
import { AdminMenuList } from "./MenuList/AdminMenuList";
import { FixiterMenuList } from "./MenuList/FixiterMenuList";
import { EmployeeMenuList } from "./MenuList/EmployeeMenuList";
import { getActiveLanguage } from "../../utils/getActiveLanguage";

interface INavMenuProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
  isMenuOpen: boolean;
}

const MobileSideBar = ({ toggleMenu, isMenuOpen }: INavMenuProps) => {
  const { user } = useAuth();

  function handleLanguageChange(e: React.ChangeEvent<HTMLSelectElement>) {
    window.localStorage.setItem("i18nextLng", e.target.value);
    window.location.reload();
  }

  return (
    <Container isMenuOpen={isMenuOpen}>
      <img className="logo" src={Logo} width="50%" alt="Fix it logo" />
      <Separator />
      <LinkList>
        {user.role === roles.licensee && (
          <LicenseeMenuList toggleMenu={toggleMenu} />
        )}
        {user.role === roles.creator && (
          <CreatorMenuList toggleMenu={toggleMenu} />
        )}
        {user.role === roles.admin && <AdminMenuList toggleMenu={toggleMenu} />}
        {user.role === roles.saf && <SafMenuList toggleMenu={toggleMenu} />}
        {user.role === roles.fixiter && (
          <FixiterMenuList toggleMenu={toggleMenu} />
        )}
        {user.role === roles.employee && (
          <EmployeeMenuList toggleMenu={toggleMenu} />
        )}
      </LinkList>

      <Language
        onChange={(e) => handleLanguageChange(e)}
        value={getActiveLanguage()}
      >
        <option value="en">EN 🇬🇧</option>
        <option value="pt-BR">PT 🇧🇷</option>
        <option value="es">ES 🇪🇸</option>
        <option value="fr">FR 🇫🇷</option>
      </Language>
      <Overlay onClick={toggleMenu} isMenuOpen={isMenuOpen} />
    </Container>
  );
};

export default MobileSideBar;
