import { useEffect, useState } from "react";

import {
  Container,
  EvaluationWrapper,
} from "./styles";
import api from "../../../../services/api";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { AddressItem } from "./components/AddressItem";
import { Modal } from "../../../../components/Modal";
import { useOnboard } from "../../../../hooks/OnboardContext";
import { IAddress } from "./dtos";
import { AddressModal } from "../../../../components/AddressModal";
import { showToast } from "../../../../components/CustomToast";
import NoInfo from "../../../../components/NoInfo";
import AbsoluteButton from "../../../../components/AbsoluteButton";

export default function Address() {
  const { licensee } = useOnboard();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [addresses, setAddresses] = useState<IAddress[]>();
  const [editAddress, setEditAddress] = useState<IAddress>();

  async function getLicenseeAddresses(id: string) {
    try {
      const response = await api.get(`/address/all/${id}`);
      setAddresses(response.data)
    }
    catch (error) {
      console.log(error);
    }
  }

  async function deleteAddress(address_id: string) {

    try {
      await api.delete(`/address/${address_id}`);
      showToast({
        type: 'success',
        message: 'Endereço deletado!'
      })
    }

    catch (error) {
      console.log(error);
    }

    finally {
      if (licensee) {
        getLicenseeAddresses(licensee.id);
      }
    }
  }

  async function getAddressById(id: string) {
    try {
      const response = await api.get(`/address/${id}`);
      setEditAddress(response.data);
    } catch (error) {

    }
  }

  useEffect(() => {
    if (licensee) {
      getLicenseeAddresses(licensee.id);
    }
  }, [licensee]);

  return (
    <Container>
      {
        (licensee && isCreateModalOpen) && (
          <Modal open={isCreateModalOpen} modalWidth={500}>
            {<AddressModal
              getLicenseeAddresses={getLicenseeAddresses}
              licensee={licensee}
              closeModal={async () => {
                await getLicenseeAddresses(licensee?.id);
                setIsCreateModalOpen(false)
              }}
            />}
          </Modal>
        )
      }
      <EvaluationWrapper>
        {
          licensee && addresses?.map((address) => {
            return (
              <AddressItem
                getAddressById={getAddressById}
                address_id={address.id}
                deleteAddress={deleteAddress}
                getLicenseeAddresses={getLicenseeAddresses}
                licensee={licensee}
                address={address}
                editAddress={editAddress}
              />
            )
          })
        }
        {addresses?.length === 0 ?
          <NoInfo noInfo={`${i18n.t(`noInfo.address`)}`} />
          :
          <></>
        }
        <AbsoluteButton
          absolute
          onClick={() => {
            setIsCreateModalOpen(true);
          }}
        />
      </EvaluationWrapper>
    </Container >
  )
}
