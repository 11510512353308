import styled from "styled-components";

export const Container = styled.div`
  > svg {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
`;

export const Header = styled.div`
  width: 95%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  svg {
    width: 22px;
    height: 22px;
    color: var(--fixit);
    cursor: pointer;
  }

  span {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-gray);
  }
`;

export const FormContainer = styled.div`
  width: 100%;

  .section_title {
    font-size: 18px;
    color: var(--primary);
    text-align: center;
    margin-top: 20px;
  }
`;

export const CheckBoxGroupContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: space-evenly;
`;

export const Text = styled.h2`
  font-size: 16px;
  font-weight: 400;
  color: var(--dark-gray);
`;

export const InputNear = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: end;
  gap: 5px;
  margin-top: 20px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`;


export const InputLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-top: 20px;

  @media (max-width: 540px) {
    flex-direction: column;
  }
`;

export const InputContainer = styled.div`
  width: 100%;
`;

export const PrintersContainer = styled.div`
  margin-top: 20px;

  > span {
    font-size: 20px;
    font-weight: bold;
    color: var(--dark-gray);
  }

  .printer_empty_state {
    font-size: 16px;
    color: var(--dark-gray);
    margin-top: 4px;
  }
`;

export const PrinterItem = styled.div`
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
  border-radius: 10px;
  padding: 10px 16px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-top: 10px;

  svg {
    cursor: pointer;
  }

  span {
    color: var(--primary);
  }
`;

export const PrinterItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 10px;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
