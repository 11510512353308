import {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";
import api from "../services/api";
import { useAuth } from "./AuthContext";
import { roles, solutionTypes } from "../utils/constants";
import { TermsOfUseModal } from "../components/TermsOfUseModal";
import { IPlan } from "../modules/Admin/pages/Plans/dtos";

export interface ITermosUso {
  id: string;
  name: string;
  version: string;
  document_link: string;
  type: string;
}

interface ILicensee {
  id: string;
  name: string;
  completed_onboard: boolean;
  signature: ISignature[];
  country: string;
  state: string;
  city: string;
  contract: string;
  situation: string;
  manager_name: string;
  phone: string;
  document_number: string;
  person_type: string;
  instagram: string;
  created_at: string;
  is_blocked: boolean;
  is_fixpoint: boolean;
  is_test: boolean;
  ordered_initial_kit: boolean;
  companyName: string;
  email: string;
  user_term_id: string;
  provider_id: string
}

interface ITermsAcceptance {
  ip_address: string;
  signed_at: Date;
  document: string;
}

export interface ISignature {
  id: string;
  plan: IPlan;
  is_activated?: boolean;
  end_date: Date;
  created_at: Date;
  user_terms: ITermosUso;
  terms_acceptance: ITermsAcceptance;
  payment_value: string;
  payment_recurrency: string;
}

interface IOnboardContext {
  licensee?: ILicensee;
  setLicensee: (licensee: any) => void;
  loadLicensee: () => void;
  solutionsWithAccess: string[];
  setSolutionsWithAccess: (solutionsNameKey: any) => void;
  checkBlocked: (pathname: string) => {
    message?: string;
    hasAccess: boolean;
  };
  planHasExpired: boolean;
}

const OnboardContext = createContext<IOnboardContext>({} as IOnboardContext);

const OnboardProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [licensee, setLicensee] = useState<ILicensee>();
  const [openModal, setOpenModal] = useState(false);
  const [solutionsWithAccess, setSolutionsWithAccess] = useState<string[]>([]);
  const [planHasExpired, setPlanHasExpired] = useState(false);
  const { user, signOut } = useAuth();
  const checkBlocked = (pathname: string) => {
    let pathnameArr = pathname.split("/");
    const solution_type = pathnameArr.pop();
    const solution_key = pathnameArr.pop();

    if (!licensee) {
      return {
        hasAccess: true,
      };
    }

    if (!licensee?.signature) {
      return {
        message: "",
        hasAccess: false,
      };
    }

    if (
      solution_type &&
      solution_type === solutionTypes.custom &&
      !licensee.signature[0]?.plan.custom_orders
    ) {
      return {
        message: "",
        hasAccess: false,
      };
    }

    if (
      solution_type &&
      solution_type === solutionTypes.standard &&
      !licensee.signature[0]?.plan.standard_orders
    ) {
      return {
        message: "",
        hasAccess: false,
      };
    }

    if (solution_key && !solutionsWithAccess.includes(solution_key)) {
      return {
        message: "",
        hasAccess: false,
      };
    }

    return {
      hasAccess: true,
    };
  };

  const loadLicensee = () => {
    if (
      user &&
      (user?.role === roles.fixiter ||
        user?.role === roles.licensee ||
        user?.role === roles.employee)
    ) {
      api.get(`/licensees/user/onboard`).then((response) => {
        setLicensee(response.data);
        // Consultar as name_key das soluções que o licenciado pode utilizar
      });
    } else {
      setLicensee(undefined);
    }
  };

  useEffect(() => {
    if (licensee) {
      api.get(`/signatures/solutions`).then((response) => {
        setSolutionsWithAccess(response.data.map((s: any) => s.name_key));
      });
    }
  }, [licensee]);

  const getEndDate = () => {
    if (licensee?.signature[0]?.end_date &&
      new Date() >=
      (new Date(licensee?.signature[0]?.end_date) ?? new Date())
    ) {
      setPlanHasExpired(true);
      // REALIZAR SOFT DELETE DA ASSINATURA
    } else setPlanHasExpired(false);
  };

  useEffect(() => {
    if (user) {
      loadLicensee();
    }
  }, [user]);

  useEffect(() => {
    if (user && licensee?.signature) {
      if (user.role === roles.fixiter && !licensee?.completed_onboard) {
        return;
      }
      getEndDate();
    }
  }, [user, licensee?.signature, licensee?.completed_onboard]);

  return (
    <OnboardContext.Provider
      value={{
        licensee,
        setLicensee: (licensee: any) => {
          setLicensee(licensee);
        },
        loadLicensee,
        solutionsWithAccess,
        setSolutionsWithAccess: (solutionsNameKey: any) => {
          setSolutionsWithAccess([...solutionsWithAccess, solutionsNameKey]);
        },
        checkBlocked,
        planHasExpired,
      }}
    >
      {/* {openModal && (
        <Modal open={openModal}>
          <ChooseSignatureSolutions closeModal={() => toggleModal()} />
        </Modal>
      )} */}
      {!!user &&
        !!licensee &&
        !!licensee.signature[0] &&
        !licensee.signature[0]?.terms_acceptance && (
          <TermsOfUseModal
            user={user}
            userTerm={licensee.signature[0].user_terms}
            signOut={signOut}
            loadLicensee={loadLicensee}
          />
        )}

      {children}
    </OnboardContext.Provider>
  );
};

function useOnboard() {
  const context = useContext(OnboardContext);

  if (!context) {
    throw new Error("useOnboard must be used within an AuthProvider");
  }

  return context;
}

export { useOnboard, OnboardProvider };
