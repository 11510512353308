import { Bill } from "../../../../../../hooks/AuthContext";
import { BillsList } from "./components/BillsList";
import { BillsContainer, Header, ListContent } from "./styles";

interface IBillsCard {
  header: string;
  icon: React.ReactNode;
  billsArray: Partial<Bill>[];
}

export function BillsCard({ icon, header, billsArray }: IBillsCard) {
  return (
    <>
      <BillsContainer>
        <Header>
          {icon}
          {header}
        </Header>
        <ListContent>
          {billsArray.map((bill) => (
            <BillsList
              referring_period={bill.referring_period}
              status={bill.payment_situation}
              bill_value={Number(bill.bill_value).toFixed(2)}
            />
          ))}
        </ListContent>
      </BillsContainer>
    </>
  );
}
