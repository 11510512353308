import styled from 'styled-components';
import global from '../../../../global';

interface InputProps {
  readonly inputWidth: string;
}

export const Container = styled.div`
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-bottom: 22px;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin-bottom: 10px;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 22px;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    margin-bottom: 10px;
  }
`;

export const InputLabel = styled.label`
  display: flex;
  flex-direction: row;
  color: var(--dark-gray);
  font-size: 18px;
  margin-right: 8px;
`;

export const InputContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-bottom: 12px;
  margin-right: 10px;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    margin-bottom: 14px;
    padding: 0;
    width: 100%;
  }
`;

export const Input = styled.input<InputProps>`
  height: 40px;
  width: ${props => (props.inputWidth)};

  padding-left: 12px;

  border: 1px solid var(--light-gray);
  border-radius: 6px;

  font-size: 20px;
  color: var(--dark-gray);
  
  ::placeholder {
    color: var(--light-gray);
    @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
      padding-left: 0px;
    }
  }

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    width: 100%;
  }
`;

export const FlexButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex;
`

export const Submit = styled.button`
  font-size: 16px;
  background-color: var(--fixit);
  color: var(--white);

  :disabled {
    background-color: var(--gray);
  }

  border-radius: 6px;
  width: 150px;
  height: 45px;

  cursor: pointer;
`

export const Cancel = styled.button`
  width: 150px;
  height: 45px;
  background: transparent;

  font-size: 16px;
  color: var(--dark-gray);

  margin-right: 8px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  
  cursor: pointer;
`

export const EvaluationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    flex-wrap: wrap;
  }
`

export const Section = styled.section`
  h2 {
    color: var(--dark-gray);
    padding-bottom: 12px;
  }

  padding: 20px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  min-width: 300px;
  width: calc(50% - 30px);
`

export const Field = styled.div`
  color: var(--dark-gray);
  padding-top: 6px;
`

export const FilesWrapper = styled.div`
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 16px;
`

export const LabelLink = styled.a`
  font-size: 16px;
  padding-bottom: 20px;
  margin-bottom: 10px;

  color: var(--fixit);

  cursor: pointer;
`;