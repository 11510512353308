import styled from "styled-components";

export const Container = styled.div`
  position: relative;
`;

export const NewDiagnosisButton = styled.button`
  position: fixed;
  top: calc(100vh - 80px);
  right: 40px;

  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: var(--fixit);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    box-shadow: 0px 8px 16px 0px ${"var(--fixit)"};
  }

  svg {
    width: 26px;
    height: 26px;
    color: var(--white);
  }
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-right: 20px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  svg {
    transition: all 0.2s;
    cursor: pointer;
    color: var(--dark-gray);

    &:hover {
      color: var(--fixit);
    }
  }
`;

export const ActiveFilters = styled.div`
  margin-right: 40px;
  display: flex;
  align-items: center;
  gap: 20px;

  .filter {
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 20px;
    padding: 4px 10px;
    border: 1px solid var(--gray);

    span {
      font-weight: 20px;
      color: var(--gray);
    }

    svg {
      width: 20px;
      height: 20px;
      color: var(--gray);
      cursor: pointer;
    }
  }
`;

export const ListContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
