import { RiDownloadCloud2Line, RiLineChartLine, RiMenuLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { LinkItem, LinkTitle, RouteButton } from '../styles';

interface MenuListProps {
  toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
}

export const CreatorMenuList = ({toggleMenu} : MenuListProps) => (
    <>
      <LinkItem>
        <Link to="/creators/dashboard" onClick={toggleMenu}> {/* Go to Home */}
          <RouteButton> 
            <RiLineChartLine size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>Relatórios</LinkTitle>
        </Link>
      </LinkItem>

      <LinkItem>
        <Link to="/creators/solutions" onClick={toggleMenu}> {/* Go to Solutions */}
          <RouteButton> 
            <RiDownloadCloud2Line size={28} color="var(--white)" />
          </RouteButton>
          <LinkTitle>Soluções</LinkTitle>
        </Link>  
      </LinkItem>

      <LinkItem>
        <Link to="/creators/orders" onClick={toggleMenu}> {/* Go to Orders */}
          <RouteButton>
            <RiMenuLine size={26} color="var(--white)" />
          </RouteButton> 
          <LinkTitle>Pedidos</LinkTitle>
        </Link>  
      </LinkItem>
    </>
)