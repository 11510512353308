import styled from "styled-components";


export const Container = styled.div`
    display: flex;    
    justify-content: center;
    align-items: center;
    padding: 1rem 0;
    gap: 0.5rem;
    img {
        width: 40px;
    }
    font-size: 0.7rem;
    color: var(--dark-gray);
    a {

        text-decoration: none;

        &:visited {
            color: var(--dark-gray);
        }
        
        &:hover {
            text-decoration: underline;
            color: var(--development);
            cursor: pointer;
        }

    }
`;