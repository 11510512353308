import styled from "styled-components";

type StatusProps = {
    active?: boolean;
};
  

export const Container = styled.div`
  position: relative;
`;

export const IconButtonContainer = styled.div<StatusProps>`
  position: relative;
  display: flex;

  svg {
    color: ${(props) => (!props.active ? "var(--light-gray)" : "var(--fixit)")};
    cursor: pointer;
  };
`;
