import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.div`
  width: 100%;
  justify-content: center;

  .Mui-selected {
    color: var(--fixit) !important;
  }

  .MuiTabs-indicator {
    background-color: var(--fixit);
  }
`;

export const TabsComponentContainer = styled.div`
  margin-top: 16px;
`;
