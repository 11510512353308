/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import { OutlinedCheckbox } from "../../../../components/OutlinedCheckbox";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { usePrinters } from "../../../../hooks/PrintersContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";
import { solutionTypes } from "../../../../utils/constants";
import { IPrintChoiceFormData, IStandard, IFileChoice } from "../../dtos";
import {
  AlertMessage,
  Footer,
  FormContainer,
  Header,
  InputContainer,
  InputLine,
  SaveText,
  Subtitle,
} from "./styles";

interface IPrintChoose {
  order: any;
  printer?: {
    id: string;
    name: string;
    xyz: string[];
  };
  onProceed: (fileChoice: IFileChoice) => void;
  onSelect: (formData: IPrintChoiceFormData) => void;
  onAdd: (formData: IPrintChoiceFormData) => Promise<IFileChoice>;
}

const PrintChoose = ({
  order,
  printer,
  onProceed,
  onSelect,
  onAdd,
}: IPrintChoose) => {
  const { defaultPrinter, setPrinting } = usePrinters();
  const [standards, setStandards] = useState<IStandard[]>();
  const [formData, setFormData] = useState<IPrintChoiceFormData>({
    order_id: order.id,
    printer_id: printer && printer.id,
  });

  const [hasError, setHasError] = useState(false);
  const [isStandardFileSelected, setIsStandardFileSelected] = useState(false);

  const [fileChoicesCount, setFileChoicesCount] = useState();
  const [existingFileChoices, setExistingFileChoices] =
    useState<IFileChoice[]>();
  const [selectedFileChoice, setSelectedFileChoice] = useState<IFileChoice>();

  useEffect(() => {
    if (order?.id) {
      api
        .get(`/file-choice/order/${order.id}?completed=false`)
        .then(({ data: { data, total } }) => {
          setExistingFileChoices(data);
          setFileChoicesCount(total);
        })
        .catch((e) => {
          setExistingFileChoices(undefined);
        });
    }
  }, [order.id, existingFileChoices?.length]);

  useEffect(() => {
    if (order.solution?.standards?.length) {
      setStandards(order.solution.standards);
    }
  }, [order.solution]);

  useEffect(() => {
    if (defaultPrinter) {
      setFormData((prevState) => ({
        ...prevState,
        printer_id: defaultPrinter.value,
      }));
    }
  }, [defaultPrinter]);

  useEffect(() => {
    if (isStandardFileSelected) {
      setFormData((prevState) => ({
        ...prevState,
        standard: order.standard_file.solution_standard,
        side: order.standard_file.side,
      }));
      return;
    }
    setFormData({
      order_id: order.id,
      printer_id: printer && printer.id,
    });
  }, [isStandardFileSelected]);

  useEffect(() => {
    if (standards && order.solution_standard) {
      setFormData((prevState) => ({
        ...prevState,
        standard: order.solution_standard,
        side: order?.exam?.side,
      }));
    }
  }, [standards, order.solution_standard]);

  const onEnd = async (fileChoiceId: string) => {
    try {
      await api.put(`/file-choice/${fileChoiceId}/complete`);
      setSelectedFileChoice(undefined);
      setExistingFileChoices((prevState) =>
        prevState?.filter((p) => p.id !== fileChoiceId)
      );
    } catch (error: any) {
      error.response?.data?.message &&
        showToast({
          type: "error",
          message: i18n.t(`error.${error.response?.data.message}`),
        });
    }
  };

  return (
    <>
      <Header>
        <span>{`${i18n.t("orders.printChoose.header")}`}</span>
      </Header>
      {order.solution_type === solutionTypes.standard ?
        <Subtitle>
          <span>{`${i18n.t("orders.printChoose.available_info", {
            available: order.quantity - (fileChoicesCount || 0),
            quantity: order.quantity,
          })}`}</span>
        </Subtitle>
        :
        <Subtitle>
          <span>{`${i18n.t("orders.printChoose.available_info", {
            available: order.quantity - (order.file_choices.length || 0),
            quantity: order.quantity,
          })}`}</span>
        </Subtitle>
      }

      <FormContainer>
        {existingFileChoices?.map((existingFileChoice) =>
          existingFileChoice.file_streams.length &&
            existingFileChoice.file_streams.find((stream) => {
              return !stream.printings.find(
                (p) => p.is_active && p.progress > 95
              );
            }) ? (
            <InputLine>
              <OutlinedCheckbox
                streched
                label={i18n.t(
                  printer
                    ? "orders.printChoose.proceed"
                    : "orders.printChoose.waiting",
                  {
                    file_choice: `${existingFileChoice.solution_standard?.size ??
                      existingFileChoice.file_streams[0].name.split(".")[0]
                      } ${existingFileChoice.side}`,
                  }
                )}
                checked={selectedFileChoice?.id === existingFileChoice.id}
                handleChange={() => {
                  setSelectedFileChoice(existingFileChoice);
                }}
              ></OutlinedCheckbox>
            </InputLine>
          ) : (
            <InputLine>
              <OutlinedCheckbox
                activeBgColor="var(--dark-gray)"
                streched
                label={i18n.t(
                  existingFileChoice?.completed
                    ? "orders.printChoose.completed"
                    : existingFileChoice.file_streams.length
                      ? "orders.printChoose.end"
                      : "orders.printChoose.demand",
                  {
                    file_choice: `${existingFileChoice.solution_standard?.size ??
                      existingFileChoice.file_streams[0].name.split(".")[0]
                      } ${existingFileChoice.side}`,
                  }
                )}
                checked={selectedFileChoice?.id === existingFileChoice.id}
                handleChange={() => {
                  setSelectedFileChoice({
                    ...existingFileChoice,
                    completed: !!existingFileChoice.file_streams.length,
                  });
                }}
              ></OutlinedCheckbox>
            </InputLine>
          )
        )}
        {((existingFileChoices && existingFileChoices.length === 0) ||
          !printer) &&
          order.quantity > (fileChoicesCount || 0) && (
            <>
              <InputLine>
                <InputContainer>
                  <OutlinedSelect
                    label={`${i18n.t("orders.printChoose.standard")}`}
                    disabled={isStandardFileSelected || !standards?.length}
                    options={
                      standards?.map((s) => ({
                        optionText: s.size,
                        value: s.id,
                      })) || []
                    }
                    handleSelect={(selected) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        standard: {
                          id: selected.value.toString(),
                          size: selected.optionText,
                        },
                      }));
                    }}
                    selectedOption={formData?.standard?.size || ""}
                  />
                </InputContainer>
              </InputLine>

              <InputLine>
                <InputContainer>
                  <OutlinedSelect
                    label={`${i18n.t("orders.printChoose.sides")}`}
                    options={
                      order.solution?.sides?.map((side: any) => ({
                        optionText: side,
                        value: side,
                      })) || []
                    }
                    handleSelect={(selected) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        side: selected.optionText.toString(),
                      }));
                    }}
                    selectedOption={formData?.side || ""}
                  />
                </InputContainer>
              </InputLine>
            </>
          )}
      </FormContainer>
      <Footer>
        {!existingFileChoices ||
          (!formData.side && !selectedFileChoice) ||
          hasError ? (
          <AlertMessage>
            {hasError
              ? `${i18n.t("orders.printChoose.error")}`
              : existingFileChoices?.length && !selectedFileChoice
                ? `${i18n.t("orders.printChoose.waitingchoice")}`
                : `${i18n.t("orders.printChoose.advice")}`}
          </AlertMessage>
        ) : printer ? (
          selectedFileChoice?.completed ? (
            <SaveText onClick={() => onEnd(selectedFileChoice.id)}>
              {`${i18n.t("orders.printChoose.endAndSelect")}`}
            </SaveText>
          ) : existingFileChoices?.length ? (
            <SaveText
              disabled={!selectedFileChoice}
              onClick={() =>
                selectedFileChoice && onProceed(selectedFileChoice)
              }
            >
              {`${i18n.t("orders.printChoose.prepare")}`}
            </SaveText>
          ) : (
            <SaveText
              disabled={!!selectedFileChoice}
              onClick={() => onSelect(formData)}
            >
              {`${i18n.t("orders.printChoose.save")}`}
            </SaveText>
          )
        ) : selectedFileChoice ? (
          <SaveText
            disabled={!selectedFileChoice.file_streams?.length}
            onClick={() =>
              !!selectedFileChoice.file_streams?.length &&
              onProceed(selectedFileChoice)
            }
          >
            {!selectedFileChoice.file_streams?.length
              ? `${i18n.t("orders.printChoose.notStarted")}`
              : `${i18n.t("orders.printChoose.progress")}`}
          </SaveText>
        ) : (
          <SaveText
            disabled={order.quantity <= (fileChoicesCount || 0)}
            onClick={() =>
              order.quantity > (fileChoicesCount || 0) &&
              onAdd(formData).then((fileChoice) => {
                fileChoice &&
                  setExistingFileChoices((prevState) => [
                    ...(prevState || []),
                    fileChoice,
                  ]);
              })
            }
          >
            {`${i18n.t("orders.printChoose.add")}`}
          </SaveText>
        )}
      </Footer>
    </>
  );
};

export default PrintChoose;
