import styled, { css } from "styled-components";

interface SectionContainerProps {
}

export const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;
