import { ClickAwayListener } from "@mui/material";
import { ISolution } from "../../dtos";
import { useEffect, useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { Container, IconButtonContainer } from "./styles";
import Popover from "../../../../components/Popover";
import { i18n } from "../../../../localization/i18n";
import { BoxCustomSolution } from "../BoxCustomSolution";
import { RiEdit2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

interface IEditSolutionPopupParams {
    solution: ISolution;
    onClick: () => void;
    updateSolution: (solutionData: Partial<ISolution>) => void;
}

interface IUploadFase {
    enable: boolean;
    fase: string;
}

export function EditSolutionOptions({ solution, onClick, updateSolution } : IEditSolutionPopupParams) {
    const [ editSolution, setEditSolution ] = useState<ISolution>();
    const [uploadFase, setUploadFase] = useState<IUploadFase>()
    const navigate = useNavigate();
    
    const handleClickAway = () => {
      setEditSolution(undefined);
    }

    function goToEditSolution(id: string) {
        navigate(
            "/creators/solutions/edit", {
            state: {
            solution_id: id,
            },
        });
    }

    useEffect(() => {
        if (!!uploadFase && uploadFase.fase === 'prepare') {
            api
                .patch(`/solutions/${solution.id}/activate?is_custom=${uploadFase.enable}`, {})
                .then((response) => {
                    showToast({
                        type: "success",
                        message: "Status da funcionalidade atualizada com sucesso!",
                    });
                    setUploadFase(undefined)
                    updateSolution({})
                })
        }
    }, [uploadFase])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
                <Popover position="left" label={`${i18n.t("solutions.is_custom")}`}>
                    <IconButtonContainer onClick={() => onClick()}>
                        <RiEdit2Line
                            size={18}
                            onClick={() => {}}
                        />
                    </IconButtonContainer>
                </Popover>
            </Container>
        </ClickAwayListener>
    )
}