import styled from "styled-components";
import global from "../../global";

interface ILegend {
  active?: boolean;
}

export const Container = styled.div`
  padding-bottom: 50px;

  @media (max-width: ${global.breakpoints.mobile}) {
    padding: 0 10px;
  }

  .Header {
    display: flex;
    padding: 0.5rem 0 0;
  }
`;

export const FilterContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const SquareEffect = styled.div`
  content: "";
  position: absolute;
  background: var(--fixit);
  border: 2px solid var(--fixit);
  right: 160px;
  top: 100px;
  z-index: 0;
`;

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    color: var(--gray);
    width: 30px;
    height: 30px;

    :hover {
      color: var(--fixit);
    }
  }
`;

export const OperationalColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--fixit);

  border-radius: 50px;
`;

export const EffectedColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--finished);

  border-radius: 50px;
`;

export const OnboardingColor = styled.div`
  width: 10px;
  height: 10px;
  background: var(--onboarding);

  border-radius: 50px;
`;

export const TrainingColor = styled.div`
  width: 10px;
  height: 10px;
  background: yellow;

  border-radius: 50px;
`;

export const ImplantationColor = styled.div`
  width: 10px;
  height: 10px;
  background: orange;

  border-radius: 50px;
`;

export const ChurnColor = styled.div`
  width: 10px;
  height: 10px;
  background: black;

  border-radius: 50px;
`;

export const BlockColor = styled.div`
  width: 10px;
  height: 10px;
  background: red;

  border-radius: 50px;
`;

export const ContentContainer = styled.div`
  margin: 2px;
  width: 70%;
  background: white;
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  text-align: center;
  gap: 10px;
  color: var(--fixit);

  span {
    font-size: 12px;
    color: var(--dark-gray);
  }
`;

export const Legend = styled.div<ILegend>`
  width: 200px;
  background: white;
  flex-wrap: wrap;

  position: absolute;
  top: 10px;
  right: 120px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  z-index: 10;
  cursor: pointer;

  border-radius: 20px;

  visibility: ${(ILegend) => (ILegend.active ? "visible" : "hidden")};
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
`;

export const FilterTags = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  margin-right: 20px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 11px 8px 14px;
  border-radius: 25px;
  background: #eae8e8;
  background: var(--white);
  border: 1px solid var(--fixit);

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: var(--fixit);
  }

  svg {
    margin-left: 2px;
    cursor: pointer;
    color: var(--fixit);
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  gap: 26px;

  @media (max-width: ${global.breakpoints.mobile}) {
    margin-top: 28px;
  }
`;

export const LicenseeInfosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  .licensee_info {
    display: flex;
    align-items: center;
    gap: 2px;

    p {
      font-weight: 500;
      color: var(--primary);
    }

    span {
      color: var(--dark-gray);
    }
  }
`;

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5rem;

  border-radius: 14px;
  padding: 0;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    flex-direction: column;
    padding: 16px 20px 16px 22px;

    .StandardTotalInfo {
      justify-content: flex-end;
    }
  }
`;

export const TotalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 50px;

  .label {
    font-size: 10px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .value {
    font-size: 16px;
    line-height: 10px;
    color: var(--active-green);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const DateContainer = styled.div`
  padding: 2rem 0 0 0;
`;
