import styled, { css } from "styled-components";

interface IContainerProps {
  isDragActive: boolean;
  isDragReject: boolean;
  fullWidth: boolean;
}

interface IUploadMessageProps {
  type?: string;
}

const dragActive = css`
  border-color: var(--fixit);
`;

const dragReject = css`
  // border-color: var(--red);
  border-color: #fa0a0a;
`;

export const Container = styled.div<IContainerProps>`
  border: 1px dashed var(--light-gray);
  border-radius: 4px;
  cursor: pointer;
  padding: 40px;

  ${(props) => props.isDragActive && dragActive}
  ${(props) => props.isDragReject && dragReject}

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

interface IObjectKeys {
  [key: string]: string;
}

interface ImessageColors extends IObjectKeys {
  default: string;
  error: string;
  success: string;
}

const messageColors = {
  default: "var(--light-gray)",
  error: "#FA0A0A",
  success: "var(--fixit)",
} as ImessageColors;

export const UploadMessage = styled.div<IUploadMessageProps>`
  display: flex;
  color: ${(props) => messageColors[props.type || "default"]};
  justify-content: center;
  align-items: center;
`;
