import api from "../../../services/api";
import { countries } from "../../../utils/countries";
import { IPerson } from "../../PersonModal/dtos";
import { ILicensee } from "../dtos";
import { LicenseeSituation } from "../mock";

class Services {
    licenseeSituation (userSituation: string) : string {
        const result = LicenseeSituation.filter((situation) => {
            if (situation.value === userSituation) {
                return (
                    situation.optionText
                )
            }
        })
        return result[0].optionText;
    }

    licenseeCountry(userCountry: string) : string {
        const result = countries.filter((country) => {
            if (country.code === userCountry) {
                return (
                    country.label
                )
            }
        })
        return result[0].label;
    }

    async getPersonById (personId: string): Promise<IPerson | undefined> {
        try {
            const response = await api.get(`/person/${personId}`);
            return (response.data);
          } catch (error) {
            console.log(error);
          }
    }

    getLicenseePrinters (licensee: ILicensee): string[] {
        if(licensee) {
            const printer = licensee.printers.map((printer) => {
                return printer.name
           })
           return printer;
        }
       return [];
    }
}

export const services = new Services();

