import styled from "styled-components";

export const ConfirmOrderModalContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.div`
  margin-top: 20px;

  .order_resume {
    display: flex;
    flex-direction: column;
    gap: 12px;

    strong {
      font-size: 16px;
    }

    .item {
      color: var(--dark-gray);
    }

    .measures {
      display: flex;
      gap: 4px;

      color: var(--dark-gray);
    }
  }

  .address {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 12px 0 0;
    color: var(--dark-gray);

    .newAddress {
      color: var(--fixit);
      cursor: pointer;
      display: flex;
      justify-content: flex-end;
      gap: 2.5px;
      strong {
        font-size: 14px;
        font-weight: 500;
      }
      span {
        font-size: 14px;
        color: var(--gray);
      }
    }
  }

  .addAddress {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 2rem;
    color: var(--dark-gray);
  }

  .urgency {
    margin-top: 16px;

    display: flex;
    flex-direction: column;
  }

  .total {
    margin-top: 10px;
    color: var(--fixit);
    font-weight: bold;

    display: flex;
    justify-content: flex-end;

    strong {
      color: var(--dark-gray);
      font-size: 16px;
    }

    span {
      margin-left: 4px;
    }
  }
`;

export const UrgencyInfo = styled.div`
  margin-top: 12px;
  color: var(--dark-gray);
  font-size: 16px;

  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 8px;

  svg {
    min-width: 20px;
    min-height: 20px;
    color: var(--fixit);
  }
`;

export const JustifyContainer = styled.div`
  margin-top: 20px;
`;

export const ConfirmationContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-top: 20px;

  border: 2px solid var(--error);
  border-radius: 10px;
  padding: 14px;

  color: var(--dark-gray);
`;

export const CheckboxContainer = styled.div`
  flex: 1;
`;

export const Footer = styled.div`
  margin-top: 36px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;
