import Dropzone, { Accept } from 'react-dropzone'
import { i18n } from '../../localization/i18n'
import { Container, UploadMessage } from './styles'

interface IDropZoneProps {
  onUpload: (file: any) => void;
  accept?: Accept;
  fullWidth?: boolean;
  multiple?: boolean;
}

const DropZone = ({ accept, multiple = true, onUpload, fullWidth }: IDropZoneProps) => {
  const renderDragMessage = (isDragActive: boolean, isDragReject: boolean) => {
    if (!isDragActive) {
      return <UploadMessage>{`${i18n.t('dropZone.dragActive')}`}</UploadMessage>
    }

    if (isDragReject) {
      return <UploadMessage type="error">{`${i18n.t('dropZone.error')}`}</UploadMessage>
    }

    return <UploadMessage type="success">{`${i18n.t('dropZone.success')}`}</UploadMessage>
  }

  return (
    <Dropzone multiple={multiple} accept={accept} onDropAccepted={onUpload}>
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <Container
          {...getRootProps()}
          className="dropZone"
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          fullWidth={fullWidth}
        >
          {renderDragMessage(isDragActive, isDragReject)}
          <input {...getInputProps()} />
        </Container>
      )}
    </Dropzone>
  );
};

export default DropZone;
