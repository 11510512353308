export default class LineBreakTransformer {
  container: string;
  decoder: TextDecoder;
  constructor() {
    this.container = "";
    this.decoder = new TextDecoder();
  }

  *run(text: string) {
    const lines = text.split(/\r?\n/);

    while (lines.length > 1) {
      const data = lines.shift();

      yield data;
    }

    this.container = lines[0];
  }

  transform(chunk: any, controller: { enqueue: (arg0: string) => void }) {
    this.container += chunk;

    for (const data of this.run(this.container)) {
      data && controller.enqueue(data);
    }
  }
  flush(controller: { enqueue: (arg0: string) => void }) {
    controller.enqueue(this.container);
  }
}
