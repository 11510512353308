import styled, { css } from "styled-components";

type ButtonProps = {
  disabled?: boolean;
};

type ProgressProps = {
  progress: number;
};

export const Container = styled.div`
    position: absolute;
    left: 30px;
    top: 40px;
    width: max-content;

    border: 1px solid var(--light-gray);
    border-radius: 10px;

    padding: 20px 10px 10px 10px;

    background-color: var(--white);
    box-shadow: 4px 3px 8px var(--light-gray);

    list-style-type: none;
    z-index: 5;
`;

export const Title = styled.h1`
    color: var(--gray);
    font-size: 16px;
    padding-left: 5px;
`;

export const Subtitle = styled.div`
    padding-left: 5px;
    color: var(--light-gray);
    font-size: 12px;
    margin-top: 2px;
    margin-bottom: 15px;
`;

export const DropzoneContainer = styled.div`
    margin-top: 8px;
`;

export const File = styled.div`
    display: flex;
    width: 300px;
    height: 30px;
    position: relative;
    border-radius: 6px;
    background: var(--watermark);
`;

export const FileLabel = styled.div<ButtonProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 8px 10px;
  position: absolute;
  inset:0;
  align-items: center;
  border-radius: 6px;
  span {
      font-size: 14px;
      color: var(--gray);
  }
  svg {
      cursor: pointer;
      color: ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--gray)")};
  }
`

export const ProgressBar = styled.div<ProgressProps>`
    width: ${({ progress }) => `${progress < 100 ? progress : 100}%`};

    border-radius: 6px;
    background-color: var(--fixit-bg);
    position: absolute;
    inset:0;
    padding-top: 10px;
    text-align: center;
`

export const ButtonContainer = styled.div`
    width: 100%;
    height: 50px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
`;

export const LinkButton = styled.button<ButtonProps>`
  font-size: 12px;
  border-radius: 6px;
  padding: 2px 5px 0px 5px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  color: ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--gray)")};
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}

  svg {
    color: ${({ disabled }) => (disabled ? "var(--light-gray)" : "var(--gray)")};
  }

  &:link,
  &:visited {
    text-transform: uppercase;
    text-decoration: none;
    padding: 15px 40px;
    display: inline-block;
    border-radius: 100px;
    transition: all 0.2s;
    position: absolute;
  }

  &:active {
    transform: translateY(-1px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }
`;


export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`

export const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
`