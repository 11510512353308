import styled from "styled-components";

interface IHomeCardProps{
  disabled?: boolean;
}

export const Container = styled.div<IHomeCardProps>`
  width: 500px;
  height: 192px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  padding: 20px;
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  justify-content: flex-start;

  transition: all 0.3s;

  &:hover {
    transform: ${(IHomeCardProps => IHomeCardProps.disabled ? "" : "translateY(-10px)")};
    cursor: ${(IHomeCardProps => IHomeCardProps.disabled ? "not-allowed" : "pointer")};
  }

  @media (max-width: 600px) {
    width: calc(100% - 20px);
    height: 132px;
  }

  opacity: ${(IHomeCardProps => IHomeCardProps.disabled ? "0.2" : "1")};
`;

export const IconContainer = styled.div`
  position: absolute;
  margin-left: -10px;
  width: 150px;
  min-width: 100px;
  min-height: 100px;

  @media (max-width: 600px) {
    margin-left: -20px;
    width: 115px;
  }

  img, embed {
    position: relative;
    width: 100%;
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  margin-top: 0px;
  margin-right: 24px;
  margin-bottom: 0px;
  margin-left: 150px;
  background-color: var(--fixit);

  @media (max-width: 600px) {
    margin-left: 100px;
  }

`;

export const CardInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  height: 100%;

  @media (max-width: 600px) {
    justify-content: space-between;
  }
`;

export const CardTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #000000;

  transition: all 0.3s;

  &:hover {
    color: var(--fixit);
  }

  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

export const CardDescription = styled.p`
  width: 280px;
  font-size: 16px;
  line-height: 20px;
  color: #000000;

  @media (max-width: 600px) {
    width: fit-content;
    font-size: 12px;
    line-height: 14px;
  }
`;

export const NextText = styled.span`
  display: flex;
  align-items: center;

  font-size: 14px;
  line-height: 14px;
  color: #989898;

  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
