import React, { useState } from "react";

import { showToast } from "../CustomToast";

import { SolidButton } from "../SolidButton";
import { OutlinedInput } from "../OutlinedInput";
import { Modal } from "../Modal";

import * as Yup from "yup";
import api from "../../services/api";

import validateFormFields from "../../utils/validateFormFields";
import { typesErrors } from "../../utils/validateFormFields/yupErrors";

import { RiCloseLine } from "react-icons/ri";
import fixItLogo from "../../assets/logo.svg";

import {
  Form,
  FormContent,
  Branding,
  CloseBtn,
  InputContainer,
  Container,
} from "./styles";
import { i18n } from "../../localization/i18n";

interface IChangePasswordPopupProps {
  user_id: string;
  open: boolean;
  setOpen: (arg: boolean) => void;
}

export default function ChangePasswordPopup({
  user_id,
  setOpen,
  open,
}: IChangePasswordPopupProps) {
  const [actualPsw, setActualPsw] = useState("");
  const [newPsw, setNewPsw] = useState("");
  const [pswConfirmation, setPswConfirmation] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  function clearInputsAndCloseModal() {
    setActualPsw("");
    setNewPsw("");
    setPswConfirmation("");
    setOpen(!open);
  }

  function verifyPasswords() {
    if (newPsw !== pswConfirmation) {
      setLoading(false);
      showToast({
        type: "error",
        message: "As senhas não coincidem.",
      });

      return false;
    }

    return true;
  }

  async function changePassword(id: string): Promise<void> {
    try {
      await api
        .patch(`/users/${id}/change-password`, {
          newPassword: newPsw,
          password: actualPsw,
        })
        .then(() => clearInputsAndCloseModal());
      showToast({
        type: "success",
        message: `${i18n.t("userProfile.password.success")}`,
      });
      setOpen(false);
    } catch (error) {
      showToast({
        type: "error",
        message: `${i18n.t("userProfile.password.error")}`,
      });
    } finally {
      setLoading(false);
    }
  }

  async function validateForm(event: React.FormEvent) {
    event.preventDefault();

    setLoading(true);

    const body = {
      actualPsw,
      newPsw,
      pswConfirmation,
    };

    const changePasswordSchema = Yup.object().shape({
      actualPsw: Yup.string().required(typesErrors.required),
      newPsw: Yup.string()
        .required(typesErrors.required)
        .required("Senha é obrigatória")
        .matches(
          /^(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).+$/,
          typesErrors.passwordCharacters
        )
        .min(8, typesErrors.passwordLength),
      pswConfirmation: Yup.string().required(typesErrors.required),
    });

    const validation = await validateFormFields(body, changePasswordSchema, {
      actualPsw: "",
      newPsw: "",
      pswConfirmation: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: "Verifique os campos com erro.",
      });
      setLoading(false);

      return;
    }

    setErrors({});
    verifyPasswords() && changePassword(user_id);
  }

  return (
    <Modal open={open} modalWidth={400}>
      <Container>
        <CloseBtn onClick={() => clearInputsAndCloseModal()}>
          <RiCloseLine />
        </CloseBtn>

        <Branding>
          <img src={fixItLogo} alt="FixIt Logo" />
        </Branding>

        <Form onSubmit={validateForm}>
          <h2>{`${i18n.t("global.password.change_password")}`}</h2>

          <FormContent>
            <InputContainer>
              <OutlinedInput
                type="password"
                inputName="atualPsw"
                label={`${i18n.t("global.password.password")}`}
                handleChange={(event) => {
                  setActualPsw(event);
                }}
                value={actualPsw}
                error={errors.actualPsw}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                type="password"
                inputName="newPsw"
                label={`${i18n.t("global.password.new_password")}`}
                handleChange={(event) => {
                  setNewPsw(event);
                }}
                value={newPsw}
                error={errors.newPsw}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                type="password"
                inputName="confirmNewPsw"
                label={`${i18n.t("global.password.password_confirmation")}`}
                handleChange={(event) => {
                  setPswConfirmation(event);
                }}
                value={pswConfirmation}
                error={errors.pswConfirmation}
              />
            </InputContainer>

            <SolidButton
              type="submit"
              text={`${i18n.t("global.password.save_changes")}`}
              loading={loading}
              buttonHeight={40}
            />
          </FormContent>
        </Form>
      </Container>
    </Modal>
  );
}
