import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { RiCloseLine, RiDeleteBin7Line, RiSaveLine } from "react-icons/ri";
import DropZone from "../../../../components/DropZone";
import { i18n } from "../../../../localization/i18n";
import { ISolution } from "../../dtos";
import { Container, DropzoneContainer, File, FileLabel, Header, LinkButton, ProgressBar, Subtitle, SwitchFormGroup, SwitchFormControlLabel, Title, TitleContainer, FixitSwitch } from "./styles";

interface IBoxDownloadFileUploadParams {
    solution: ISolution;
    uploadFase?: string;
    progress: number;
    file?: File;
    setFile: (file?: File) => void;
    deleteFile: () => void;
    submit: (enable: boolean) => void;
    close: () => void;
}

export function BoxDownloadFileUpload ({ solution, file, setFile, deleteFile, uploadFase, progress, submit, close } : IBoxDownloadFileUploadParams) {
    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    const [enable, setEnable] = useState(solution.is_download);

    useEffect(() => {
        setEnable(solution.is_download)
    }, [solution.is_download])

    return (
        <>
            <Container>
                <Header>
                    <TitleContainer>
                        <Title>{`${i18n.t('solutions.download_file_upload_title')}`}</Title>
                        <Subtitle>{`${i18n.t('solutions.download_file_upload_subtitle')}`}</Subtitle>
                    </TitleContainer>
                    {
                        progress === 100 ? (
                            <LinkButton onClick={() => {
                                /* validatePatientForm(tmpPatientData).then(valid => {
                                    valid && updatePatientData()
                                }); */
                                    close();
                                }} >
                                <RiCloseLine size={16} />
                                {`${i18n.t('orders.actions.close')}`}
                            </LinkButton>

                        ) : (
                            <LinkButton disabled={!!uploadFase || (!file && enable === solution.is_download )} onClick={() => {
                                /* validatePatientForm(tmpPatientData).then(valid => {
                                    valid && updatePatientData()
                                }); */
                                    submit(enable);
                                }} >
                                <RiSaveLine size={16} />
                                {`${i18n.t('orders.actions.save')}`}
                            </LinkButton>
                        )
                    }
                    
                </Header>
                <SwitchFormGroup>
                    <SwitchFormControlLabel labelPlacement="start" control={
                        <FixitSwitch checked={enable} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setEnable(event.target.checked)
                        }} />
                        } label={`${i18n.t('solutions.download_file_enable')}`} />
                </SwitchFormGroup>
                
                {
                    <DropzoneContainer>
                        {
                            !file && !solution.download_link ? (
                                <DropZone
                                    multiple={false}
                                    onUpload={(fileArr) => {
                                        // setReadFile(file);
                                        setFile(fileArr[0]);
                                    }}
                                />
                            ) : 
                            ( 
                                file ?
                                    <File>
                                        <ProgressBar className={"progress " + (progress === 100 ? 'done' : '')} progress={progress}></ProgressBar>
                                        <FileLabel disabled={!!uploadFase}>
                                            <span>{file.name}</span>
                                            {
                                                !progress ? (
                                                    <RiDeleteBin7Line
                                                        onClick={async () => {
                                                            setFile(undefined);
                                                        }}
                                                        size={18}
                                                    />
                                                ) : (
                                                    <span>{progress}%</span>
                                                )
                                            }
                                            
                                        </FileLabel>
                                    </File>
                                    :
                                    <File>
                                        <FileLabel>
                                            <span>{solution.download_link?.split("/").pop()}</span>
                                            {
                                                <RiDeleteBin7Line
                                                    onClick={async () => {
                                                        deleteFile();
                                                    }}
                                                    size={18}
                                                />
                                            }
                                            
                                        </FileLabel>
                                    </File>
                            )
                        }
                    </DropzoneContainer>
                }
                
            </Container>
        </>
    )
}