import { useEffect, useState } from "react";
import { i18n } from "../../../../localization/i18n";
import { IPatientData } from "../../../../modules/Orders/CreateOrder/dtos";
import { LoadMore } from "../../../../modules/Orders/styles";
import api from "../../../../services/api";
import { showToast } from "../../../CustomToast";
import NoInfo from "../../../NoInfo";
import { PatientItem } from "./components/PatientItem";
import { Container, Footer } from "./styles";





export default function PatientList(){
    
    const [licenseePatients, setLicenseePatients] = useState<IPatientData[]>([])
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1)

    function loadMore() {
        setPage(page + 1);
      }

    async function GetLicenseePatients(){
        try{
            await api.get(`/patients?page=${page}`).then((response) => {
                setLicenseePatients([...licenseePatients, ...response.data.patients]);
                setTotalPage(response.data.total/10)
            })
        } catch(err){
            showToast({
                type: 'error',
                message: 'Não foi possivel carregar seus pacientes'
            })
        }
    }
    
    useEffect(() => {
        GetLicenseePatients()
    }, [page])
    
    
    return(
        <Container>
            {licenseePatients.map((patient) => {
                return(
                    <PatientItem patient={patient}                        
                    />
                )
            })}
            {
                licenseePatients.length === 0 &&
                <NoInfo noInfo={`${i18n.t(`noInfo.patient`)}`}/>
            }
            <Footer>
                {page < totalPage ? (
                    <LoadMore onClick={loadMore}>Carregar mais...</LoadMore>
                ) : (
                    ""
                )}
            </Footer>
        </Container>
    )
}