import React, {useState} from "react"
import { RiAddLine, RiDeleteBinLine, RiEditLine, RiCheckLine } from "react-icons/ri"

import Head from "../../components/Head";
import Tooltip from "../../components/Tooltip"
import ToggleableInput from "../../components/ToggleableInput";

import {
  Container,
  Section,
  Field,
  Label,
  SubTitle,
  AddBtn,
  DeleteBtn,
  PasswordReset,
  Select,
  SelectOption,
  PrinterSelect,
  PrinterCount,
  InfoWrapper,
  PrintersWrapper,
  EditBtn
} from "./styles";

interface IProfileData {
  unit: string
  email: string
  situation: string
  managerName: string
  phoneNumber: string
  instagram: string
}

const Profile = () => {
  const [isEditingProfile, setIsEditingProfile] = useState(false);

  const [profile, setProfile] = useState({
    unit: "(SP) Diadema",
    email: "diadema@usefixit.com.br",
    situation: "em implatação",
    managerName: "Guilherme Correia",
    phoneNumber: "(11)91234-5678",
    instagram: "usuarioinstagram",
  } as IProfileData)

  const handleChangeInput = (change: {}) => {
    const newProfile = {...profile, ...change}
    setProfile(newProfile)
  }

  // MOCKUP
  const [printers, setPrinters] = useState([
    { count: 1, model: "Sethi3D S4X" },
  ])

  const handleAddPrinter = () => {
    const newPrinters = [...printers]
    newPrinters.push({ count: 1, model: "Sethi3D S4X" })
    setPrinters(newPrinters)
  }

  const handleChangePrinterCount = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newPrinters = [...printers]
    if (+e.target.value >= 0) newPrinters[index].count = +e.target.value
    setPrinters(newPrinters)
  }

  const handleSelectPrinter = (e: React.ChangeEvent<HTMLSelectElement>, index: number) => {
    let newPrinters = [...printers]
    newPrinters[index].model = e.target.value
    setPrinters(newPrinters)
  }

  const handleDeletePrinter = (index: number) => {
    let newPrinters = [...printers]
    newPrinters.splice(index, 1)
    setPrinters(newPrinters)
  }

  const handleSaveChanges = () => {
    setIsEditingProfile(false)
  }

  return (
    <>
      <Head title="Minha unidade"/>
      <Container>
        <InfoWrapper>
          <Section>
            <Field>
              <Label>
                Unidade
                <ToggleableInput
                  isDisabled={true}
                  value={profile.unit}
                  handleChange={(e) => handleChangeInput({unit: e.target.value})}
                />
              </Label>
            </Field>
            <Field>
              <Label>
                Email
                <ToggleableInput
                  isDisabled={true}
                  value={profile.email}
                  handleChange={(e) => handleChangeInput({email: e.target.value})}
                />
              </Label>
            </Field>
            <Field>
              <Label>
                Situação
                <ToggleableInput
                  isDisabled={true}
                  value={profile.situation}
                  handleChange={(e) => handleChangeInput({situation: e.target.value})}
                />
              </Label>
            </Field>
          </Section>
          <Section>
            <Field>
              <Label>
                Nome do gestor
                <ToggleableInput
                  isDisabled={!isEditingProfile}
                  value={profile.managerName}
                  handleChange={(e) => handleChangeInput({managerName: e.target.value})}
                />
              </Label>
            </Field>
            <Field>
              <Label>
                Telefone
                <ToggleableInput
                  isDisabled={!isEditingProfile}
                  value={profile.phoneNumber}
                  handleChange={(e) => handleChangeInput({phoneNumber: e.target.value})}
                />
              </Label>
            </Field>
            <Field>
              <Label>
                Instagram
                <ToggleableInput
                  isDisabled={!isEditingProfile}
                  value={profile.instagram}
                  handleChange={(e) => handleChangeInput({instagram: e.target.value})}
                />
              </Label>
            </Field>
          </Section>
          {isEditingProfile ?
            <EditBtn onClick={handleSaveChanges}><RiCheckLine /> Salvar mudanças</EditBtn>
          :
            <EditBtn onClick={() => setIsEditingProfile(true)}><RiEditLine /> Editar perfil</EditBtn>
          }
          <br/>
          <PasswordReset>Alterar senha</PasswordReset>
        </InfoWrapper>
        <PrintersWrapper>
          <SubTitle>
            <h2>Impressoras</h2>
          </SubTitle>
          <Section>
            {printers.map((printer, index) => {
              return (
                <PrinterSelect>
                  <PrinterCount
                    type="number"
                    value={printer.count}
                    onChange={(e) => handleChangePrinterCount(e, index)}
                    min="1"
                  />
                  <Select
                    key={index}
                    value={printer.model}
                    onChange={(e) => handleSelectPrinter(e, index)}
                  >
                    <SelectOption>Sethi3D S4X</SelectOption>
                    <SelectOption>Sethi3D S3</SelectOption>
                  </Select>
                  <DeleteBtn><RiDeleteBinLine size={20} onClick={() => handleDeletePrinter(index)}/></DeleteBtn>
                </PrinterSelect>
              )
            })}
            <Tooltip
              content={"Adicionar impressora"}
              position="right"
              width="24px"
              height="24px"
            >
              <AddBtn onClick={handleAddPrinter}>
                <RiAddLine size={20} />
              </AddBtn>
            </Tooltip>
          </Section>
        </PrintersWrapper>
      </Container>
    </>
  )
}

export default Profile;
