import styled from "styled-components";

export const EditOrderUrgencyModalContainer = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;

  svg {
    width: 26px;
    height: 26px;
    color: var(--fixit);

    cursor: pointer;
  }

  .title {
    font-size: 20px;
    color: var(--fixit);
    font-weight: 500;
  }
`;

export const Content = styled.div`
  margin-top: 16px;

  .Mui-checked {
    color: var(--fixit) !important;
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const InputContainer = styled.div`
  margin-top: 20px;

  div {
    height: 140px;
  }

  .total {
    height: fit-content;
    margin-top: 10px;
    color: var(--fixit);
    font-weight: bold;

    display: flex;
    justify-content: flex-end;

    strong {
      color: var(--dark-gray);
      font-size: 16px;
    }

    span {
      margin-left: 4px;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 30px;

  button {
    width: 100%;
  }
`;
