import { lazy, useEffect, useState } from "react";

import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import api from "../../../../services/api";

import {
  Container,
  SubContainer,
  ResultContainer,
  ChartArea,
  ResultArea,
  ResultLabel,
  ChartTitle,
} from "./styles";

const Chart = lazy(() => import("react-apexcharts"));
export interface IReport {
  day: number;
  month: number;
  yearByMonth: number[];
  lastSevenDays: number[];
}

export default function Dashboard() {
  const [report, setReport] = useState<IReport>({} as IReport);
  const { setNavigationAction } = useAuth();

  useEffect(() => {
    api
      .get(`/reports/creators`)
      .then((response) => {
        setReport(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  let lastSevenDaysLabel = [] as string[];

  for (
    let removeDaysQuantity = 1;
    removeDaysQuantity <= 7;
    removeDaysQuantity++
  ) {
    const date = new Date(
      new Date().setDate(new Date().getDate() - removeDaysQuantity)
    ); // Subtracting the date
    const dateFormatted = date.toLocaleDateString("pt-br", {
      day: "2-digit",
      month: "2-digit",
    });
    lastSevenDaysLabel.push(dateFormatted);
  }

  const optionsLastSevenDays = {
    colors: ["#9835D1"],
    labels: lastSevenDaysLabel,
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: "var(--gray)",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined,
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false,
        },
      },
    },
    yaxis: {
      show: true,
    },
  };

  const seriesLastSevenDays = [{ name: "Pedidos", data: report.lastSevenDays }];

  const optionsYearByMonth = {
    colors: ["#9835D1"],
    labels: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    chart: {
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      foreColor: "var(--gray)",
    },
    grid: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: undefined,
      },
      background: {
        enabled: true,
        borderColor: undefined,
        dropShadow: {
          enabled: false,
        },
      },
    },
    yaxis: {
      show: true,
    },
  };

  const seriesYearByMonth = [{ name: "Pedidos", data: report.yearByMonth }];

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: i18n.t("dashboard.header"),
    });
  }, []);

  return (
    <>
      <Container>
        <SubContainer>
          <ResultContainer>
            <ChartArea maxWidth={156}>
              <ResultArea>
                <ChartTitle>Pedidos hoje</ChartTitle>
                <ResultLabel>{report.day}</ResultLabel>
              </ResultArea>
            </ChartArea>

            <ChartArea maxWidth={156}>
              <ResultArea>
                <ChartTitle>Pedidos no mês</ChartTitle>
                <ResultLabel>{report.month}</ResultLabel>
              </ResultArea>
            </ChartArea>
          </ResultContainer>
          <ChartArea maxWidth={360}>
            <ChartTitle>Pedidos nos últimos 7 dias</ChartTitle>
            <Chart
              options={optionsLastSevenDays}
              series={seriesLastSevenDays}
              type="area"
              height={160}
            />
          </ChartArea>

          <ChartArea maxWidth={540}>
            <ChartTitle>Pedidos em {new Date().getFullYear()}</ChartTitle>
            <Chart
              options={optionsYearByMonth}
              series={seriesYearByMonth}
              type="bar"
              height={160}
            />
          </ChartArea>
        </SubContainer>
      </Container>
    </>
  );
}
