import { useEffect, useState } from "react";

import {
  Container,
  ContentContainer,
  VideoContainer,
} from "./styles";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";
import { CourseItem } from "./CourseItem";
import ReactPlayer from "react-player/youtube";
import fixitguys from "../../../../assets/fixitguys.jpg";
import fixittecnology from "../../../../assets/fixittecnology.jpg";
import { videos } from "../../../../utils/constants";


export default function Courses() {
  const { setNavigationAction } = useAuth();

  const [ punhoFixVideo, setPunhoFixVideo ] = useState(false);
  const [ fixitVideo, setFixitVideo ] = useState(false);

  const togglePunhoFixVideo = () => {
    setPunhoFixVideo(!punhoFixVideo);
    setFixitVideo(false);
  }

  const toggleFixitVideo = () => {
    setFixitVideo(!fixitVideo);
    setPunhoFixVideo(false);
  }

  const player = [
    {
      key: videos.punhofix,
      component: (
        <ReactPlayer
          width={"100%"}
          style={{ }}
          controls={true}
          url="https://youtu.be/2hxXlfe9P_o"
          playing={true}
          light={fixitguys}
      />
      ),
    },
    {
      key: videos.fixit,
      component: (
        <ReactPlayer
        width={"100%"}
        style={{ }}
        controls={true}
        url="https://youtu.be/HXN-qPzMQlA"
        playing={true}
        light={fixittecnology}
        />
      ),
    },
  ]

  useEffect(() => {
    setNavigationAction({
      hasButton: false,
      description: "Cursos",
    });
  }, [])

  return (
    <Container>
      <ContentContainer>
        <CourseItem
          key={videos.fixit}
          name={i18n.t("courses.names.fixit")}
          description={i18n.t("courses.descriptions.fixit")}
          onClick={() => toggleFixitVideo()}
          selected={fixitVideo}
        />
        <CourseItem
          key={videos.punhofix}
          name={i18n.t("courses.names.punhofix")}
          description={i18n.t("courses.descriptions.punhofix")}
          onClick={() => togglePunhoFixVideo()}
          selected={punhoFixVideo}
        />
      </ContentContainer>
      <VideoContainer>
        { 
          punhoFixVideo && 
            player.map((video) => {
              if(video.key === videos.punhofix) {
                return video.component;
              }
            })
        }
         { 
          fixitVideo && 
            player.map((video) => {
              if(video.key === videos.fixit) {
                return video.component;
              }
            })
        }
      </VideoContainer>
    </Container>
  );
}

