import styled from "styled-components";

interface ButtonProps {
  disabled?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 500px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const NotAvailableOverlay = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  cursor: not-allowed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.7);
`;

export const Header = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  padding: 20px 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
  }
`;

export const GifContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    object-fit: contain;
    width: 100%;
    border-radius: 20px;
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 30px 20px 15px 20px;

  display: flex;
  align-items: end;
  justify-content: space-between;

  background: rgb(255, 255, 255);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5158438375350141) 50%,
    rgba(255, 255, 255, 0.85) 100%
  );
  border-radius: 20px;

  .solution_infos {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .solution_name {
    font-weight: bold;
    font-size: 15px;
    line-height: 18px;
    color: #000000;
  }

  .solution_types {
    display: flex;
    gap: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000;
  }
`;

export const SelectContainer = styled.div`
  position: relative;
  width: 150px;
`;

export const DownloadButton = styled.a`
  position: relative;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  color: var(--white);
  background: var(--fixit);

  display: flex;
  align-items: center;

  cursor: pointer;

  transition: width 0.8s;

  span {
    visibility: hidden;
    transition: visibility 0s;
  }

  .download_icon {
    position: absolute;
    right: 6px;
    top: 5px;
    width: 25px;
    height: 25px;
  }

  height: 38px;
  width: 38px;
  justify-content: center;
  &:hover {
    padding: 0 5px 0 15px;
    width: 150px;
    span {
      visibility: visible;
      transition-delay: 0.4s;
    }
    justify-content: start;
  }
`;

export const CustomButton = styled.button<ButtonProps>`
  position: relative;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;

  color: var(--white);
  background-color: ${(props) =>
    props.disabled ? "var(--light-gray)" : "var(--fixit)"};

  display: flex;
  align-items: center;

  cursor: pointer;

  transition: width 0.8s;

  span {
    visibility: hidden;
    transition: visibility 0s;
  }
  img {
    position: absolute;
    top: 7px;
    right: 6px;
    width: 25px;
    filter: invert(100%) sepia(89%) saturate(0%) hue-rotate(21deg)
      brightness(104%) contrast(101%);
  }
  height: 38px;
  width: 38px;
  justify-content: center;
  &:hover {
    padding: 0 5px 0 15px;
    width: 150px;
    span {
      visibility: visible;
      transition-delay: 0.4s;
    }
    justify-content: start;
  }
`;

export const StandardButton = styled.button<ButtonProps>`
  position: relative;
  border: 1px solid
    ${(props) => (props.disabled ? "var(--light-gray)" : "var(--fixit)")};
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => (props.disabled ? "var(--white)" : "var(--fixit)")};
  background-color: ${(props) =>
    props.disabled ? "var(--light-gray)" : "var(--white)"};

  display: flex;
  align-items: center;

  cursor: pointer;

  transition: width 0.8s;

  span {
    visibility: hidden;
    transition: visibility 0s;
  }
  img {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 28px;
    filter: ${(props) =>
      props.disabled
        ? "invert(100%) sepia(89%) saturate(0%) hue-rotate(21deg) brightness(104%) contrast(101%)"
        : "invert(25%) sepia(47%) saturate(4977%) hue-rotate(269deg) brightness(87%) contrast(87%)"};
  }
  height: 38px;
  width: 38px;
  justify-content: center;
  &:hover {
    padding: 0 5px 0 15px;
    width: 150px;
    span {
      visibility: visible;
      transition-delay: 0.4s;
    }
    justify-content: start;
  }

  .arrow_icon {
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    transition: 0.2s;
  }

  .download_icon {
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    transition: 0.2s;
  }
`;

export const PriceInfo = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--fixit);
  padding: 3px 8px;
  border-radius: 10px;
  font-weight: 600;
  background-color: #ffffff6b;
  color: var(--fixit);

  b {
    font-size: 0.9em;
    color: var(--dark-gray);
  }
`;

export const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 5px;
`;
