import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import { RiArrowDownSLine } from "react-icons/ri";

import {
  Container,
  DropdownContainer,
  OptionsContainer,
  Option,
  Error,
  Label,
} from "./styles";

interface IOption {
  optionText: string;
  value: string | number;
  id?: string;
}

interface Props {
  options: IOption[];
  label: string;
  handleSelect: (selectedOption: IOption) => any;
  placeholder?: string;
  disabled?: boolean;
  selectedOption: string | number;
  inputWidth?: number;
  error?: string;
  marginOnError?: boolean;
  readOnly?: boolean;
}

export function OutlinedSelect({
  handleSelect = () => {},
  options,
  label,
  disabled,
  readOnly = false,
  placeholder,
  selectedOption,
  inputWidth,
  error,
  marginOnError = false,
}: Props) {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (showOptions) {
          setShowOptions(false);
        }
      }}
    >
      <Container>
        <DropdownContainer
          open={showOptions}
          inputWidth={inputWidth}
          error={error}
          marginOnError={marginOnError}
          onClick={() => {
            if (!disabled && !readOnly) {
              setShowOptions(!showOptions);
            }
          }}
        >
          <Label
            error={error}
            open={showOptions}
            selectedOption={!!selectedOption}
            readOnly={readOnly}
          >
            {label}
          </Label>

          <input
            disabled={disabled}
            type="text"
            value={selectedOption}
            onChange={() => {}}
            readOnly={readOnly}
            placeholder={placeholder}
          />

          <RiArrowDownSLine color="var(--dark-gray)" className="arrow_icon" />
        </DropdownContainer>

        {error && <Error>{error}</Error>}

        {showOptions && (
          <OptionsContainer open={showOptions} inputWidth={inputWidth}>
            <div className="dropdown_options">
              {options?.map((option) => {
                return (
                  <Option
                    key={option.value}
                    onClick={() => {
                      handleSelect(option);
                      setShowOptions(false);
                    }}
                  >
                    <div className="option_text">{option.optionText}</div>
                  </Option>
                );
              })}
            </div>
          </OptionsContainer>
        )}
      </Container>
    </ClickAwayListener>
  );
}
