import { useState } from "react";

import { i18n } from "../../../../localization/i18n";

import { OutlinedButton } from "../../../../components/OutlinedButton";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { SolidButton } from "../../../../components/SolidButton";

import { Plans } from "../..";

import { showToast } from "../../../../components/CustomToast";

import {
  Container,
  StepTitle,
  Footer,
  InputContainer,
  InputLine,
} from "./styles";

interface StepTwoProps {
  plans: Plans[];
  selectedLanguage: string;
  asigneePlan: (planId: string) => void;
  updateLicenseeList: () => void;
  closeModal: () => void;
}

interface IOption {
  value: string | number;
  optionText: string;
}

export function StepFour({
  plans,
  selectedLanguage,
  asigneePlan,
  updateLicenseeList,
  closeModal
}: StepTwoProps) {
  const [selectedPlan, setSelectedPlan] = useState<IOption | null>(null);

  function formatPlans() {
    const formattedPlans = plans.map((plan) => {
      return {
        value: plan.id,
        optionText: `${plan.plan_name}, ${plan.currency}`,
      };
    });

    return formattedPlans;
  }

  return (
    <Container>
      <StepTitle>{`${i18n.t("userRegistration.plans")}`}</StepTitle>

      <InputLine>
        <InputContainer>
          <OutlinedSelect
            label="Planos"
            options={formatPlans()}
            selectedOption={selectedPlan?.optionText ?? ""}
            handleSelect={(event) => {
              setSelectedPlan(event);
            }}
          />
        </InputContainer>
      </InputLine>

      <Footer>
        {/* <OutlinedButton
          buttonWidth={220}
          buttonHeight={42}
          text={i18n.t("userRegistration.prevStep")}
          onClick={prevStep}
        /> */}

        <SolidButton
          text={i18n.t("userRegistration.finishLater")}
          buttonWidth={220}
          buttonHeight={42}
          buttonColor="var(--fixit)"
          onClick={() => {
            updateLicenseeList()
            closeModal()
          }}
        />

        <SolidButton
          disabled={!selectedPlan}
          text={i18n.t("userRegistration.nextStep")}
          buttonWidth={220}
          buttonHeight={42}
          buttonColor="var(--active-green)"
          buttonHoverColor="var(--active-green-hover)"
          onClick={() => {
            if (!selectedPlan?.value) {
              showToast({
                type: "error",
                message: i18n.t("userRegistration.errorChoosePlan"),
              });
              return;
            }

            asigneePlan(selectedPlan?.value.toString());
          }}
        />
      </Footer>
    </Container>
  );
}
