import { format } from "date-fns";
import { roles } from "../../../utils/constants";
import { IOrder } from "../dtos";

interface IFactoryDataInput {
  orders: IOrder[];
  role: string;
}

interface IXlsColumns {
  incremental_id: number;
  solution_name: string;
  solution_type?: string;
  status: string;
  order_date: string;
  licensee_name?: string;
  patient?: string;
  partner?: string;
  provider?: string;
  total?: number;
  quantity: number;
  weight?: string;
  color?: string;
  observation?: string;
}

export class OrdersToXlsFormatFactory implements IFactoryDataInput {
  constructor({ orders, role }: IFactoryDataInput) {
    this.orders = orders;
    this.role = role;
  }

  orders: IOrder[];
  role: string;

  private formatDate(date: string) {
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' às 'HH:mm");
  }

  private fixiterFormatedOrders(order: IOrder): IXlsColumns {
    const formatedData: IXlsColumns = {
      incremental_id: order.incremental_id,
      solution_name: order.solution.name,
      status: order.status,
      color: order.color,
      weight: order.files[0]?.material_weight,
      observation: order.notes,
      order_date: this.formatDate(order.created_at),
      quantity: order.quantity,
      provider: order.provider?.name,
    };

    return formatedData;
  }

  private employeeFormatedOrders(order: IOrder) {
    const formatedData: IXlsColumns = {
      incremental_id: order.incremental_id,
      solution_name: order.solution.name,
      solution_type: order.solution_type,
      status: order.status,
      color: order.color,
      weight: order.files[0]?.material_weight,
      observation: order.notes,
      order_date: this.formatDate(order.created_at),
      patient: order.patient?.name ?? "",
      partner: order.partner?.name ?? "",
      quantity: order.quantity,
    };

    return formatedData;
  }

  private licenseeAndInternalUsersFormatedOrders(order: IOrder) {
    const formatedData: IXlsColumns = {
      incremental_id: order.incremental_id,
      solution_name: order.solution.name,
      solution_type: order.solution_type,
      status: order.status,
      color: order.color,
      weight: order.files[0]?.material_weight,
      observation: order.notes,
      order_date: this.formatDate(order.created_at),
      patient: order.patient?.name ?? "",
      partner: order.partner?.name ?? "",
      quantity: order.quantity,
      total: order.total,
    };

    return formatedData;
  }

  public formatJsonToXls(): IXlsColumns[] {
    return this.orders.map((order) => {
      return this.role === roles.fixiter
        ? this.fixiterFormatedOrders(order)
        : this.role === roles.employee
          ? this.employeeFormatedOrders(order)
          : this.licenseeAndInternalUsersFormatedOrders(order);
    });
  }
}
