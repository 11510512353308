import styled from "styled-components";
import global from "../../global";

interface ITotalProps {
  isOpen?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Header = styled.div`
  width: 100%;
  margin-top: 10px;
  gap: 10px;

  display: flex;
  align-items: end;
  justify-content: space-between;

  .title {
    color: var(--dark-gray);
    font-size: 30px;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    flex-direction: column-reverse;
    margin-top: 0px;
    gap: 10px;
  }
`;

export const FixiterActionsText = styled.span`
  text-align: center;
  font-weight: 500;
  color: var(--fixit);
  font-size: 20px;
  margin-bottom: 10px;
`;

export const FixiterCardName = styled.span`
  font-size: 16px;
  color: var(--dark-gray);
  margin-bottom: 5px;

  label {
    color: black;
    margin-left: 5px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const CardsContainer = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  gap: 50px;
`;

export const ListContainer = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 26px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    gap: 10px;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
`;

export const LoadMore = styled.div`
  color: var(--gray);
  padding: 5px 18px;
  cursor: pointer;
  border: 1px solid var(--gray);
  border-radius: 15px;
  font-size: 14px;
`;

export const Form = styled.form`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    margin-left: 0px;
    justify-content: end;
    gap: 10px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-right: 0px;
  }
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 11px 8px 14px;
  border-radius: 25px;
  background: #eae8e8;
  background: var(--white);
  border: 1px solid var(--gray);

  span {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: var(--gray);
  }

  svg {
    margin-left: 2px;
    cursor: pointer;
    color: var(--gray);
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    padding: 5px 9px 5px 10px;

    span {
      font-size: 12px;
      line-height: 12px;
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
`;

export const FilterContainer = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
  }
`;

export const OrderInfosContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;

  .price_info {
    display: flex;
    align-items: center;
    gap: 2px;

    p {
      font-weight: 500;
    }
  }
`;

export const PopupContentWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  color: var(--dark-gray);

  .timer {
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 0px;
    margin-bottom: 10px;
    line-height: 0;
    border: 2px solid var(--printed);
    border-radius: 25px;
    color: var(--printed);
    padding: 20px 15px;
  }

  svg {
    color: var(--fixit);
  }

  h1 {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.4rem;
    margin-right: auto;
  }

  ul {
    font-size: 1.1rem;
    padding: 8px 8px 8px 0;
    list-style-type: none;
    width: 100%;

    span {
      font-weight: 500;
      margin-right: 12px;
    }

    li {
      margin-bottom: 4px;
      display: flex;
      width: 100%;
    }
  }

  a {
    font-size: 1.2rem;
    color: var(--fixit);
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 32px;
    line-height: 0;
  }
`;

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 12px;
`;

export const FilamentConfigText = styled.h1`
  font-size: 8px;
  font-weight: 500;

  color: var(--fixit);
`;

export const Section = styled.section`
  h2 {
    color: var(--dark-gray);
    padding-bottom: 12px;
  }

  padding: 20px;
  min-width: 300px;
  width: calc(50% - 30px);

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    margin-right: 0;
  }

  .filament_config {
    font-size: 12px;
    color: var(--dark-gray);
    margin-top: 20px;
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--dark-gray);
  padding-top: 6px;
  font-size: 12px;
`;

export const DataBar = styled.div`
  display: flex;
  gap: 5px;
  justify-content: space-around;
`;

export const TotalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  min-width: 50px;

  .label {
    font-size: 10px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .value {
    font-size: 14px;
    line-height: 10px;
    color: var(--active-green);
    margin-top: 6px;
    font-weight: bolder;
  }

  /* .value_discounted {
    font-size: 14px;
    line-height: 10px;
    color: var(--error);
    margin-top: 6px;
    font-weight: bolder;
  } */
`;

export const TotalContainer = styled.div`
  display: flex;
  gap: 12px;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  .StandardTotalInfo {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    width: 100%;
    flex-direction: column;
    padding: 16px 20px 16px 22px;

    .StandardTotalInfo {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
`;

export const TotalDescription = styled.div<ITotalProps>`
  ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
  flex-direction: row;
  gap: 12px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    justify-content: flex-end;
  }
`;

export const ArrowIcon = styled.div<ITotalProps>`
  display: flex;
  cursor: pointer;
  align-items: flex-end;

  .RightArrow {
    ${(props) => (props.isOpen ? `display: none;` : `display: flex;`)}
    @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
      display: none;
    }
  }

  .LeftArrow {
    ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
    @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
      display: none;
    }
  }

  .UpArrow {
    ${(props) => (props.isOpen ? `display: flex;` : `display: none;`)}
    @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
      display: none;
    }
  }

  .DownArrow {
    ${(props) => (props.isOpen ? `display: none;` : `display: flex;`)}
    @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
      display: none;
    }
  }
`;

export const TotalMobileView = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    gap: 1rem;
  }
`;

export const SwitchBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
  height: 20px;
  width: 20px;
  border: 1px solid var(--light-gray);
`;
