import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Footer = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    justify-content: center;

    button {
        width: 100%;
    }
`

export const Description = styled.div`
    margin-top: 10px;
    text-align: justify;
    margin-bottom: 25px;
    font-size: 14px;
    color: black;
`;


export const Title = styled.h1`
    color: var(--fixit);
    font-size: 20px;
    margin-bottom: 15px;
`;