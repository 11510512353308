import { Container, PrincipalText, SecondaryText } from "./styles";
import WhatsappBtn from "./WhatsappButton";
import { i18n } from "../../../../localization/i18n";

const PlanExpired = () => {
  return (
    <Container>
      <PrincipalText>
        {`${i18n.t("disabled_user.disabledFixiter")}`}
      </PrincipalText>
      <SecondaryText>
        {`${i18n.t("disabled_user.disabledFixiterSecondary")}`}
      </SecondaryText>
      <WhatsappBtn />
    </Container>
  );
};

export default PlanExpired;
