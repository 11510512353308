import { useState } from "react";
import { RiEdit2Line, RiDeleteBin7Line } from "react-icons/ri";
import { Modal } from "../../../../../../components/Modal";
import { PersonModal } from "../../../../../../components/PersonModal";
import { ProfileTypes } from "../../../../../../components/PersonModal/mocks";
import Popover from "../../../../../../components/Popover";
import { i18n } from "../../../../../../localization/i18n";
import { ILicensee, IPerson } from "../../dtos";
import { ActionItem, ActionsContainer, Container, Header, IconButtonContainer, ImageContainer, LeftContent, PrincipalInfos, RightContent } from "./styles";

interface IPersonItem {
    person: IPerson;
    licensee: ILicensee;
    editPerson?: IPerson;
    getLicenseePersons: (id: string) => Promise<void>;
    getPersonById: (id: string) => Promise<void>;
    getUserById: (id: string) => Promise<void>;
    deletePerson: (address_id: string) => Promise<void>;
    address_id?: string;
}

export function PersonItem({ editPerson, getPersonById, getUserById, licensee, person, getLicenseePersons, deletePerson }: IPersonItem) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditOpen, setIsEditOpen] = useState(false);

    return (
        <Container>
            <Header>
                <LeftContent>
                    <ImageContainer>
                        {ProfileTypes.map((profile) => {
                            if (profile.role === person?.user?.role) {
                                return (
                                    profile.component
                                )
                            }
                        })}
                    </ImageContainer>
                    <PrincipalInfos>
                        <span className="solution">
                            {ProfileTypes.map((profile) => {
                                if (profile.role === person?.user?.role) {
                                    return (
                                        profile.label
                                    );
                                }
                            })}
                        </span>
                        <span className="date">{person.name} {person.bp_register ?? ""}</span>
                        <span className="date">{person.expertise}</span>
                    </PrincipalInfos>
                </LeftContent>
                <RightContent>
                    <ActionsContainer>
                        <ActionItem>
                            <IconButtonContainer>
                                <Popover position="left" label={`${i18n.t("person.edit_personal_data")}`}>
                                    <RiEdit2Line size={18} onClick={() => {
                                        person &&
                                            getPersonById(person.id);
                                        getUserById(person.user.id);
                                        setIsEditOpen(true);
                                        setIsModalOpen(true);
                                    }} />
                                </Popover>
                                <Popover position="left" label={`${i18n.t("person.delete_user")}`}>
                                    <RiDeleteBin7Line size={18} onClick={() => {
                                        deletePerson(person.id);
                                        getLicenseePersons(licensee.id);
                                    }} />
                                </Popover>
                            </IconButtonContainer>
                            {
                                isModalOpen &&
                                <Modal open={isModalOpen}>
                                    <PersonModal
                                        editPerson={editPerson}
                                        person_id={person.id}
                                        getLicenseePersons={getLicenseePersons}
                                        isEditOpen={isEditOpen}
                                        licensee={licensee}
                                        closeModal={async () => {
                                            setIsModalOpen(false);
                                            setIsEditOpen(false);
                                        }} />
                                </Modal>
                            }
                        </ActionItem>
                    </ActionsContainer>
                </RightContent>
            </Header>
        </Container>
    )
}