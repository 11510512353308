import styled from "styled-components";

export const Container = styled.div`
  width: 170px;
  padding: 18px;
  border-radius: 20px;
  background: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  transition: 0.2s;

  &:hover {
    transform: translateY(-20px);
  }
`;

export const CardImageContainer = styled.div`
  width: 100px;
  height: 100px;
  text-align: center;
`;

export const CardTitle = styled.span`
  width: 100%;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  color: #000;
  margin-top: 10px;
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #000;
  margin: 14px 0px;
`;

export const SelectText = styled.span`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: var(--fixit);
`;
