import styled, { css } from "styled-components";

type ButtonProps = {
  buttonWidth?: number;
  buttonHeight?: number;
  startWithMinWidth?: boolean;
  fullWidth?: boolean;
  buttonColor?: string;
  buttonHoverColor?: string;
  fontSize?: number;
};

export const Container = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 20px;
  border-radius: 8px;
  background-color: ${({ buttonColor }) => buttonColor};
  box-shadow: 0px 8px 16px 0px #9835d13d;

  color: var(--white);
  font-size: 16px;

  cursor: pointer;
  transition: background-color 0.2s;

  ${({ startWithMinWidth }) =>
    startWithMinWidth &&
    css`
      min-width: 200px;
    `};

  ${({ buttonWidth }) =>
    buttonWidth &&
    css`
      width: ${buttonWidth}px;
    `};

  ${({ buttonHeight }) =>
    buttonHeight &&
    css`
      height: ${buttonHeight}px;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

    ${({ fontSize }) =>
    fontSize &&
    css`
      font-size: ${fontSize}px;
    `};

  &:hover {
    background: ${({ buttonHoverColor }) => buttonHoverColor};
  }

  &:disabled {
    background: var(--light-gray);
    cursor: auto;
  }

  .loading {
    border: 3px solid var(--white);
    border-top: 3px solid var(--gray);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 0.4s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
