import React, { ReactNode, useState } from "react";
import DesktopSideBar from "../DesktopSideBar";
import MobileSideBar from "../MobileSideBar";

import NavBtn from "../NavBtn";
import Topbar from "../Topbar";

import { PageContainer } from "./styles";

const LayoutIframe: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <MobileSideBar toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <NavBtn toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      <DesktopSideBar />
      <PageContainer isMenuOpen={isMenuOpen}>
        <Topbar />
        <div className="content-area">{children}</div>
      </PageContainer>
    </>
  );
};

export default LayoutIframe;
