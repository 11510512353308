import { ClickAwayListener } from "@mui/material";
import { ISolution } from "../../dtos";
import { useEffect, useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import api from "../../../../services/api";
import { Container, IconButtonContainer, Svg } from "./styles";
import AwardImg from "../../../../assets/icons/award_standards_plano.svg";
import Popover from "../../../../components/Popover";
import { i18n } from "../../../../localization/i18n";
import { BoxStandardSolution } from "../BoxStandardSolution";

interface IImageUploadPopupParams {
    solution: ISolution;
    updateSolution: (enable: boolean, parts: string[]) => void;
}

interface IUploadFase {
    enable: boolean;
    fase: string;
    parts: string[];
}


/* Update part info in back*/
export function StandardSolutionOptions({ solution, updateSolution } : IImageUploadPopupParams) {
    const [ editSolution, setEditSolution ] = useState<ISolution>();
    const [uploadFase, setUploadFase] = useState<IUploadFase>()
    
    const handleClickAway = () => {
      setEditSolution(undefined);
    }

    useEffect(() => {
        if (!!uploadFase && uploadFase.fase === 'prepare') {
            api
                .patch(`/solutions/${solution.id}/standard?enable=${uploadFase.enable}`, {
                    parts: uploadFase.parts
                })
                .then((response) => {
                    showToast({
                        type: "success",
                        message: "Status da funcionalidade atualizada com sucesso!",
                      });
                    setUploadFase(undefined)
                    updateSolution(uploadFase.enable, uploadFase.parts)
                })
        }
    }, [uploadFase])

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Container>
                <Popover position="left" label={`${i18n.t("solutions.is_standard")}`}>
                    <IconButtonContainer onClick={() => setEditSolution(solution)}>
                        <Svg alt="Standard" active={solution.is_standard} src={AwardImg} ></Svg>
                    </IconButtonContainer>
                </Popover>
                {
                    !!editSolution ?
                    (
                        <BoxStandardSolution
                            solution={solution}
                            uploadFase={uploadFase?.fase}
                            close={() => handleClickAway()}
                            submit={(enable, parts) => setUploadFase({
                                fase: 'prepare',
                                enable,
                                parts
                              })}/>
                    ) : null
                }
            </Container>
        </ClickAwayListener>
    )
}