import { Container, TextItem } from "./styles";

interface INoInfo{
    noInfo: string
}


export default function NoInfo({noInfo} : INoInfo){

    return(
        <Container>
            <TextItem>
                {noInfo}
            </TextItem>
        </Container>
    )
}