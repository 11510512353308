import styled, { css } from "styled-components";

type ContainerProps = {
  error: string;
  filled: string;
  readOnly?: boolean;
};

export const Container = styled.div<ContainerProps>`
  position: relative;

  width: 100%;
  margin-bottom: ${({ error }) => (error ? 10 : 0)}px;

  fieldset {
    border-width: 2px;
    border-color: #989898;
  }

  label {
    font-weight: 500;
    color: ${({ filled, readOnly }) => (filled && !readOnly ? "var(--fixit)" : "#989898")};
  }

  .MuiOutlinedInput-root {
    border-radius: 10px;
    padding: 0 35px 0 18px !important;
    height: 46px;
  }

  .MuiAutocomplete-input {
    padding: 0px;
    color: var(--dark-gray);
  }

  .MuiOutlinedInput-notchedOutline {
    padding-left: 18px;

    legend span {
      padding-left: 6px;
      padding-right: 6px;
    }

  }

  .MuiInputLabel-root {
    left: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #989898;
  }

  .Mui-focused {
    label {
      color:  ${({ readOnly }) => (!readOnly ? "var(--fixit)" : "#989898")};
      left: 12px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
    }

    fieldset {
      border-color: ${({ readOnly }) => (!readOnly ? "var(--fixit)" : "#989898")} !important;
    }
  }

  ${({ error }) =>
    error &&
    css`
      fieldset {
        border-color: var(--formError);
      }

      label {
        color: var(--formError);
      }

      .Mui-focused {
        label {
          color: var(--formError);
        }

        fieldset {
          border-color: var(--formError) !important;
        }
      }
    `};
`;

export const Error = styled.div`
  position: absolute;
  bottom: -17px;
  left: 11px;

  font-size: 12px;
  color: var(--formError);
`;
