import { Container } from "./styles";
import { RiCheckDoubleFill, RiCloseFill, RiDeleteBin7Line, RiEditLine, RiEyeLine, RiSaveLine } from "react-icons/ri";
import { AccordionActionsContainer, AccordionContainer, AccordionContentContainer, CircleButton, InputContainer, InputLine, LinkButton, SectionFormTitle, DropzoneContainer, File } from "../../styles";
import { AccordionOrder } from "../../../../../components/AccordionOrder";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import { CustomSolutionForm, IPlanitData } from "../../dtos";
import PlanitImg from "../../../../../assets/order_steps/planit.svg";
import { FaSearchPlus } from "react-icons/fa";
import { i18n } from "../../../../../localization/i18n";
import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import api from "../../../../../services/api";
import { showToast } from "../../../../../components/CustomToast";
import FormData from "form-data";

interface PlanitOrderProps {
  orderType: string;
  planitAccordion: boolean;
  planitEdited: Date;
  planitComplete: boolean;
  planitEditable: boolean;
  planitSearchable: boolean;
  tmpPlanitData: IPlanitData;
  planitFormErrors: any;
  setTmpPlanitData: (value: React.SetStateAction<IPlanitData>) => void
  validatePlanitForm: (data: IPlanitData) => Promise<boolean>;
  setPlanitEdited: (value: React.SetStateAction<Date>) => void;
  setPlanitAccordion: (value: React.SetStateAction<boolean>) => void;
  setPlanitEditable: (value: React.SetStateAction<boolean>) => void;
  setPlanitSearchable: (value: React.SetStateAction<boolean>) => void;
  setPlanitComplete: (value: React.SetStateAction<boolean>) => void;
  orderData: CustomSolutionForm;
  setOrderData: (value: React.SetStateAction<CustomSolutionForm>) => void;
  handleDiscard?: () => void;
}

export default function PlanitOrderSection({
  orderType,
  planitAccordion,
  planitEdited, 
  planitComplete,
  planitEditable,
  planitSearchable,
  planitFormErrors,
  setPlanitAccordion,
  setPlanitSearchable,
  setPlanitEditable,
  setPlanitEdited,
  setPlanitComplete,
  validatePlanitForm,
  tmpPlanitData,
  setTmpPlanitData,
  orderData,
  setOrderData,
  handleDiscard
}: PlanitOrderProps) {
  const [loading, setLoading] = useState(false);

  const dateValueFormat = (date: string) => {
    if (date && date.length) {
      return date.split("/").reverse().join("-");
    }
  };

  function getPlanitSubtitle () {
    return orderData.planit.doctor_name && (orderData.planit.body_part) ?
            `${orderData.planit.doctor_name || ""}, ${orderData.planit.body_part}`
          : 
            ""
  }

  async function updatePlanitData() {    
    setLoading(true);
    const abortController = new AbortController();
    let signal = abortController.signal;

    const body : any = {...tmpPlanitData,
      surgery_date: dateValueFormat(tmpPlanitData.surgery_date)
    }
   
    try {
      let response : AxiosResponse
      if (tmpPlanitData.id) {
        response = await api.patch(`/planits/${tmpPlanitData.id}`, body, { signal })
        setTmpPlanitData((prevState) => ({
          ...prevState,
          files_links: response.data.files_links,
        }))
      } else {
        response = await api.post('/planits', body, { signal })
        setTmpPlanitData((prevState) => ({
          ...prevState,
          id: response.data.id,
        }))
      }
      setOrderData((prevState) => ({
        ...prevState,
        planit: response.data,
      }));

      setPlanitEdited(new Date());
      setPlanitEditable(false);
      setPlanitSearchable(false);
      setPlanitAccordion(false);
      setPlanitComplete(true);
    } catch (error) {
      showToast({
        type: "error",
        message:  "Não foi possível salvar os dados do planejamento",
      });
    } finally {
      setLoading(false);
    }

    return () => abortController.abort();
  }

  return (
    <Container>
      <AccordionContainer>
        <AccordionOrder
          open={planitAccordion}
          emptyTitle={i18n.t('orders.planit_section.empty_title')}
          emptySubtitle={i18n.t('orders.planit_section.empty_subtitle')}
          title={`${i18n.t('orders.planit_section.title_template')}${orderData?.planit.injury_type}`}
          subTitle={getPlanitSubtitle()}
          icon={PlanitImg}
          lastUpdate={planitEdited}
          handleDiscard={() => {
            handleDiscard && handleDiscard();
          }}
          pendingInfos={!planitComplete}
          handleChange={() => {}}
          toogleComponent={
            <AccordionActionsContainer>
              {
                !planitAccordion ? 
                  (
                    orderData?.planit?.id ?
                      (
                        <CircleButton onClick={() => { 
                          setPlanitAccordion(true)}
                        } >
                          <RiEyeLine size={25} color="#989898"/>
                        </CircleButton>
                      ) :
                      (
                        <LinkButton onClick={() => { 
                            setPlanitAccordion(true)
                            setPlanitSearchable(true)
                          }
                        } >
                          {`${i18n.t('orders.actions.new')}`}
                          <FaSearchPlus size={20} color="#989898"/>
                        </LinkButton>
                      )
                  )
                :
                (
                  <>
                      {
                        !planitEditable && !planitSearchable ? 
                        <>
                          <LinkButton  onClick={() => setPlanitEditable(true)} >
                            {`${i18n.t('orders.actions.edit')}`}
                            <RiEditLine size={20} color="#989898" />
                          </LinkButton>
                          <CircleButton>
                            <RiCheckDoubleFill size={25} color="#989898" onClick={() => {
                              setPlanitAccordion(false)
                              setPlanitEditable(false)
                              setPlanitSearchable(false)
                            }} />
                          </CircleButton>
                        </>
                        :
                        <>
                          <LinkButton onClick={() => {
                            validatePlanitForm(tmpPlanitData).then(valid => {
                              valid && updatePlanitData()
                            });
                          }} >
                            {`${i18n.t('orders.actions.save')}`}
                            <RiSaveLine size={20} color="#989898" />
                          </LinkButton>
                          <CircleButton>
                            <RiCloseFill size={25} color="#989898" onClick={() => {
                              setTmpPlanitData({...orderData.planit});
                              setPlanitAccordion(false)
                              setPlanitEditable(false)
                              setPlanitSearchable(false)
                            }} />
                          </CircleButton>
                        </>
                      }
                  </>
                )
              }
            </AccordionActionsContainer>
            
          }
        >
          <AccordionContentContainer>
            <SectionFormTitle>
              {`${i18n.t('orders.planit_section.doctor_subsection_title')}`}
            </SectionFormTitle>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="doctor_name"
                  value={tmpPlanitData.doctor_name}
                  label={`${i18n.t('customSolution.fullName')}`}
                  autoComplete="off"
                  readOnly={!planitEditable && !planitSearchable}
                  handleChange={(event) => {
                    setTmpPlanitData((prevState) => ({
                      ...prevState,
                      doctor_name: event,
                    }));
                  }}
                  error={planitFormErrors.doctor_name}
                />
              </InputContainer>

              <InputContainer containerWidth="280px">
                <OutlinedInput
                  inputName="partner_contact"
                  value={tmpPlanitData.doctor_contact}
                  label={`${i18n.t('customSolution.contact')}`}
                  mask={`${i18n.t('customSolution.contactMask')}`}
                  readOnly={!planitEditable && !planitSearchable}
                  handleChange={(event) => {
                    setTmpPlanitData((prevState) => ({
                      ...prevState,
                      doctor_contact: event,
                    }));
                  }}
                  error={planitFormErrors.doctor_contact}
                />
              </InputContainer>
            </InputLine>

            <SectionFormTitle>{`${i18n.t('orders.planit_section.evaluation_subsection_title')}`}</SectionFormTitle>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="body_part"
                  value={tmpPlanitData.body_part}
                  label={`${i18n.t('surgicalPlanning.partOfTheBody')}`}
                  autoComplete="off"
                  readOnly={!planitEditable && !planitSearchable}
                  handleChange={(event) => {
                    setTmpPlanitData((prevState) => ({
                      ...prevState,
                      body_part: event,
                    }));
                  }}
                  error={planitFormErrors.body_part}
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="injury_type"
                  value={tmpPlanitData.injury_type}
                  label={`${i18n.t('surgicalPlanning.lesionType')}`}
                  autoComplete="off"
                  readOnly={!planitEditable && !planitSearchable}
                  handleChange={(event) => {
                    setTmpPlanitData((prevState) => ({
                      ...prevState,
                      injury_type: event,
                    }));
                  }}
                  error={planitFormErrors.injury_type}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="target"
                  value={tmpPlanitData.target}
                  label={`${i18n.t('surgicalPlanning.whatShouldBeSegmented')}`}
                  autoComplete="off"
                  readOnly={!planitEditable && !planitSearchable}
                  handleChange={(event) => {
                    setTmpPlanitData((prevState) => ({
                      ...prevState,
                      target: event,
                    }));
                  }}
                  error={planitFormErrors.target}
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="surgery_date"
                  value={tmpPlanitData.surgery_date}
                  label={`${i18n.t('surgicalPlanning.surgeryDate')}`}
                  autoComplete="off"
                  readOnly={!planitEditable && !planitSearchable}
                  handleChange={(event) => {
                    setTmpPlanitData((prevState) => ({
                      ...prevState,
                      surgery_date: event,
                    }));
                  }}
                  placeholder="00/00/0000"
                  mask="99/99/9999"
                  error={planitFormErrors.surgery_date}
                />
              </InputContainer>
            </InputLine>
          </AccordionContentContainer>
        </AccordionOrder>
      </AccordionContainer>
    </Container>
  );
}
