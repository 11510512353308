import { i18n } from "../../../../../../../localization/i18n";
import { CustomSolutionForm, Solution } from "../../../../dtos";

interface StandardOrderInfosProps {
  solution: Solution;
  order: CustomSolutionForm;
}

export function StandardOrderInfos({ solution, order }: StandardOrderInfosProps) {
  return (
    <div className="order_resume">
      {order?.partner?.name && (
        <span className="item">
        <strong>{`${i18n.t("orders.confirmation.partner")}`}: </strong>
        {order?.partner?.name}
      </span>
      )}

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.solution")}`}: </strong>
        {solution.name} - {`${i18n.t("orders.confirmation.standard")}`}
      </span>
    </div>
  );
}
