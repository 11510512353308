import styled from "styled-components";
import global from "../../../../global";

export const Container = styled.div`
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Form = styled.form`
  width: 50%;
  display: flex;
  flex-direction: column;
  
  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    width: 100%;
  }
`

export const Label = styled.label`
  font-size: 18px;
  margin-top: 26px;
  margin-bottom: 12px;
  font-weight: bold;
  color: var(--dark-gray);
`

export const FilesWrapper = styled.div`
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  padding: 20px;
`

export const Comment = styled.textarea`
  height: 80px;
  border: 1px solid var(--light-gray);
  border-radius: 6px;
  resize: none;
  padding: 12px;
  color: var(--dark-gray);
`

export const Flex = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export const Submit = styled.button`
  margin-top: 12px;
  background-color: var(--fixit);
  border-radius: 6px;
  width: 150px;
  height: 45px;
  cursor: pointer;
`
