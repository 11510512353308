import React, { lazy, Suspense } from "react";
import { Loader } from "../../../../../../components/Loader";
import { Container, Header } from "./styles";

const ReactApexChart = lazy(() => import("react-apexcharts"));
interface IDashboardCard {
  header: string;
  icon: React.ReactNode;
  list: {
    data: number[];
    label: string[];
  };
  redirectOnClick?: boolean;
}
export function BarChartCard({
  icon,
  header,
  list,
  redirectOnClick = false,
}: IDashboardCard) {
  const barChartState = {
    series: [
      {
        data: list.data,
      },
    ],
    options: {
      plotOptions: {
        bar: {
          barHeight: "100%",
          distributed: true,
          horizontal: true,
          dataLabels: {
            position: "center",
          },
        },
      },
      colors: ["#9835D1", "#9835D1", "#9835D1", "#9835D1", "#9835D1"],
      dataLabels: {
        enabled: true,
        style: {
          colors: ["#fff"],
        },
        formatter: function (
          val: string,
          opt: {
            w: { globals: { labels: { [x: string]: string } } };
            dataPointIndex: string | number;
          }
        ) {
          return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
        },
        offsetX: 0,
        dropShadow: {
          enabled: false,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        categories: list.label,
      },
      yaxis: {
        labels: {
          show: false,
        },
      },
      title: {
        text: "",
        floating: true,
      },
      subtitle: {
        text: "",
      },
      chart: {
        toolbar: {
          show: false,
        },
        events: {
          click: function (chart: any, w: any, e: any) {
            var dataPointIndex = e.dataPointIndex;
            var label = e.globals.labels[dataPointIndex];

            redirectOnClick && console.log("Clicked label:", label);
          },
        },
      },
      tooltip: {
        theme: "dark",
        x: {
          show: false,
        },
        y: {
          title: {
            formatter: function () {
              return "";
            },
          },
        },
      },
    },
  };

  return (
    <Container>
      <Header>
        {icon}
        {header}
      </Header>
      <Suspense fallback={<Loader />}>
        <ReactApexChart
          options={barChartState.options}
          series={barChartState.series}
          type="bar"
          height={165}
          width={370}
        />
      </Suspense>
    </Container>
  );
}
