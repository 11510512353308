import styled from "styled-components";

export const Container = styled.form`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const StepTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  color: var(--dark-gray);
  /* margin-bottom: 10px; */
`;

export const StepSubtitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: var(--dark-gray);
  margin-bottom: 10px;
`;

export const InputLine = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
`;

export const InputContainer = styled.div`
  width: 400px;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Footer = styled.div`
  margin-top: 18px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;

  @media (max-width: 1000px) {
    width: 100%;

    button {
      flex: 1;
    }
  }
`;
