import styled from "styled-components";

interface ContainerProps {
  checked?: boolean;
  backgroundCheckedColor: string;
  backgroundNullableColor: string;
}

export const Container = styled.div<ContainerProps>`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid var(--gray);
  background: ${({ checked, backgroundCheckedColor, backgroundNullableColor }) =>
    checked === undefined ? `${backgroundNullableColor}` : checked ? `${backgroundCheckedColor}` : "var(--white)"};

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`;
