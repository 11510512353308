import { Avatar, Badge, styled as mui_styled } from "@mui/material";
import styled from "styled-components";
import global from "../../../../global";

type StatusProps = {
  active?: boolean;
};

export const Container = styled.div`
  position: relative;
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);
  transition: all 0.3s ease-in-out;

  &:hover {
    background: var(--fixit-hover-bg);
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const Divider = styled.div`
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: var(--fixit);
`;

export const MobileContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;

  @media (min-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
`;

export const CenterContent = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive
      .small.width.unit}) {
    display: none;
  }
`;

export const ContentInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.manager {
    min-width: 120px;
  }

  &.phone {
    min-width: 100px;
  }

  &.document {
    min-width: 130px;
  }

  .data_label {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .data_value {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const ImageContainer = styled.div`
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  background: var(--fixit);

  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: var(--white);
  }
`;

export const Img = styled.img`
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`

export const Svg = styled.img<StatusProps>`
  object-fit: contain;
  width: 15px;
  height: 15px;
  
  cursor: pointer;

  filter: ${(props) => (!props.active ? 
    "invert(90%) sepia(69%) saturate(0%) hue-rotate(114deg) brightness(85%) contrast(100%)" :
    "invert(24%) sepia(86%) saturate(1904%) hue-rotate(263deg) brightness(98%) contrast(95%)"
  )};
`
export const IconButtonContainer = styled.div<StatusProps>`
  position: relative;
  display: flex;

  svg {
    color: ${(props) => (!props.active ? "var(--light-gray)" : "var(--fixit)")};
    cursor: pointer;
  };
`;


export const StyledBadge = mui_styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px white`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const SolutionInfos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .solution_name {
    font-size: 12px;
    line-height: 15px;
    color: var(--gray);
    font-weight: 500;
  }

  .solution_location {
    font-size: 14px;
    line-height: 10px;
    color: var(--gray);
    margin-top: 6px;
    font-weight: bolder;
  }
`;

export const MoreOptionsContainer = styled.div`
  svg {
    cursor: pointer;
  }
`;
