import { useRoutes } from "react-router-dom";
import Layout from "../../components/Layout";
import DashboardCreator from "../../modules/creators/pages/DashboardCreator";
import StandardSolutionsList from "../../modules/creators/pages/StandardSolutionsList";
import CreateSolution from "../../modules/creators/pages/CreateSolution";
import EditSolution from "../../modules/creators/pages/EditSolution";
import { Orders } from "../../modules/Orders";
import ShowOrder from "../../modules/Orders/ShowOrder";
import MakeSolution from "../../modules/creators/pages/MakeSolution";
import NotFound from "./NotFound";
import SolutionsCreator from "../../modules/creators/pages/SolutionsCreator";
import Solutions from "../../modules/Solutions";
import MultiStandardSolutionsUpload from "../../modules/creators/pages/MultiStandardSolutionsUpload";


function CreatorsRoutes() {
  const creatorRoutes = useRoutes([
    {
      path: '/creators/dashboard',
      element: (
        <Layout>
          <DashboardCreator />
        </Layout>
      )
    },
    {
      path: '/creators/solutions',
      element: (
        <Layout>
          <Solutions />
        </Layout>
      )
    },
    {
      path: '/creators/solutions_old',
      element: (
        <Layout>
          <SolutionsCreator />
        </Layout>
      )
    },
    {
      path: '/creators/solutions/create',
      element: (
        <Layout>
          <CreateSolution />
        </Layout>
      )
    },
    {
      path: '/creators/solutions/edit',
      element: (
        <Layout>
          <EditSolution />
        </Layout>
      )
    },
    {
      path: '/creators/orders',
      element: (
        <Layout>
          <Orders />
        </Layout>
      )
    },
    {
      path: '/creators/orders/show',
      element: (
        <Layout>
          <ShowOrder />
        </Layout>
      )
    },
    {
      path: '/creators/orders/make',
      element: (
        <Layout>
          <MakeSolution />
        </Layout>
      )
    },
    {
      path: '/creators/standard-solutions',
      element: (
        <Layout>
          <StandardSolutionsList />
        </Layout>
      )
    },
    {
      path: '/creators/multi-standard-solutions-upload',
      element: (
        <Layout>
          <MultiStandardSolutionsUpload />
        </Layout>
      )
    },
    {
      path: '/creators/*',
      element: (
        <NotFound />
      )
    },
  ])

  return creatorRoutes;
}

export default CreatorsRoutes;
