import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

export const InputDiv = styled.div`
    width: 100%;
`;

export const ModalTitle = styled.h1`
    color: var(--fixit);
    font-size: 18px;

    font-weight: 500;
`;