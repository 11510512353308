import { ReactNode } from "react";
import { Container, PopoverModal } from "./styles";

interface PopoverProps {
  children: ReactNode;
  label: string | ReactNode;
  position?: "left" | "right";
  minWidth?: string;
  styles?: React.CSSProperties;
  containerClasses?: string;
  popoverClasses?: string;
}

export default function Popover({
  label,
  children,
  minWidth,
  position,
  styles,
  containerClasses,
  popoverClasses,
}: PopoverProps) {
  return (
    <Container className={containerClasses}>
      <PopoverModal
        style={{ minWidth, ...styles }}
        position={position}
        className={popoverClasses}
      >
        {label}
      </PopoverModal>
      {children}
    </Container>
  );
}
