import styled, { css } from "styled-components";

export const Container = styled.div``;

export const ImageContainer = styled.div`
  width: 350px;
  height: 200px;

  display: flex;
  align-content: center;
  margin: 0 auto;
  border-radius: 20px;
  padding: 2px;
  margin-bottom: 20px;

  @media (max-width: 450px) {
    width: 100%;
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 100%;

  border-radius: 8px;
  margin: 0 auto;
`;

export const CarouselContainer = styled.div`
  width: 100%;

  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 30px !important;
    height: 60px !important;
  }

  .image-gallery-thumbnails .image-gallery-thumbnails-container {
    display: none;
  }
`;

export const Description = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
  color: var(--secondary);
`;

export const TitleText = styled.span`
  color: var(--fixit);
  font-size: 28px;
  font-weight: 600;
  text-align: center;
`;

export const Header = styled.div`
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 10px;
  display: flex;
`;

export const Separator = styled.div`
  width: 80%;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 2px;
  background-color: var(--fixit);

  margin-left: auto;
  margin-right: auto;
`;

export const Footer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 200px;
    height: 40px;
  }
`;
