import { useState } from "react";

import { OutlinedInput } from "../../../../components/OutlinedInput";
import { OutlinedSelect } from "../../../../components/OutlinedSelect";
import { OutlinedButton } from "../../../../components/OutlinedButton";
import { SolidButton } from "../../../../components/SolidButton";
import { showToast } from "../../../../components/CustomToast";

import { i18n } from "../../../../localization/i18n";
import * as Yup from "yup";
import validateFormFields from "../../../../utils/validateFormFields";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import { roles } from "../../../../utils/constants";

import { IAuthFormData } from "../../dtos";

import {
  Container,
  InputLine,
  InputContainer,
  Footer,
  StepTitle,
} from "./styles";

interface StepOneProps {
  formData: IAuthFormData | undefined;
  nextStep: () => void;
  prevStep: () => void;
  createUser: () => void;
  setFormData: any;
  loading: boolean;
}

export function StepOne({
  formData,
  setFormData,
  nextStep,
  prevStep,
  loading,
  createUser,
}: StepOneProps) {
  const [errors, setErrors] = useState({} as any);

  async function validateEmailAndName(
    event?: React.FormEvent<HTMLFormElement>
  ) {
    event?.preventDefault();

    const body = {
      email: formData?.email,
      role: formData?.role.value,
      password: formData?.password,
      password_confirmation: formData?.password_confirmation,
    };

    const emailAndNameSchema = Yup.object().shape({
      email: Yup.string()
        .email(typesErrors.validEmail)
        .required(typesErrors.required),
      role: Yup.string().required(typesErrors.required),
      password: Yup.string().required(typesErrors.required),
      password_confirmation: Yup.string()
        .required(typesErrors.required)
        .oneOf([Yup.ref("password"), null], typesErrors.matchPassword),
    });

    const validation = await validateFormFields(body, emailAndNameSchema, {
      email: "",
      role: "",
      password: "",
      password_confirmation: "",
    });

    if (validation.status === 400) {
      setErrors(validation.errors);
      showToast({
        type: "error",
        message: i18n.t("form_errors.alert"),
      });

      return;
    }

    setErrors({});

    createUser();
  }

  function formatRoles() {
    const formattedRoles = Object.entries(roles)
      .filter((role) => role[1] === roles.fixiter || role[1] === roles.licensee)
      .map((role) => {
        return {
          optionText: role[0],
          value: role[1],
        };
      });

    return formattedRoles;
  }

  function clearError(prop: string) {
    setErrors((prevState: any) => ({
      ...prevState,
      [prop]: "",
    }));
  }

  return (
    <Container
      onSubmit={(event) => {
        validateEmailAndName(event);
      }}
    >
      <StepTitle>{`${i18n.t("userRegistration.fillNameAndEmail")}`}</StepTitle>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="email"
            label={`${i18n.t("userRegistration.email")}`}
            handleChange={(event) => {
              setFormData((prevState: IAuthFormData) => ({
                ...prevState,
                email: event,
              }));
              clearError("email");
            }}
            value={formData?.email ?? ""}
            error={errors?.email}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedSelect
            label="Atribuição"
            options={formatRoles()}
            handleSelect={(event) => {
              setFormData((prevState: IAuthFormData) => ({
                ...prevState,
                role: {
                  value: event.value,
                  optionText: event.optionText,
                },
              }));
              clearError("role");
            }}
            selectedOption={formData?.role?.optionText ?? ""}
            error={errors?.role}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="password"
            type="password"
            label={`${i18n.t("userRegistration.password")}`}
            handleChange={(event) => {
              setFormData((prevState: IAuthFormData) => ({
                ...prevState,
                password: event,
              }));
              clearError("password");
            }}
            value={formData?.password ?? ""}
            error={errors?.password}
          />
        </InputContainer>
      </InputLine>

      <InputLine>
        <InputContainer>
          <OutlinedInput
            inputName="password_confirmation"
            type="password"
            label={`${i18n.t("userRegistration.password_confirmation")}`}
            handleChange={(event) => {
              setFormData((prevState: IAuthFormData) => ({
                ...prevState,
                password_confirmation: event,
              }));
              clearError("password_confirmation");
            }}
            value={formData?.password_confirmation ?? ""}
            error={errors?.password_confirmation}
          />
        </InputContainer>
      </InputLine>
      <Footer>
        <OutlinedButton
          buttonWidth={220}
          buttonHeight={42}
          text={`${i18n.t("userRegistration.prevStep")}`}
          onClick={() => {
            setFormData((prevState: IAuthFormData) => ({
              ...prevState,
              password: "",
              password_confirmation: "",
            }));
          }}
        />

        <SolidButton
          text={`${i18n.t("userRegistration.nextStep")}`}
          buttonWidth={220}
          buttonHeight={42}
          type="submit"
          loading={loading}
        />
      </Footer>
    </Container>
  );
}
