import { CardContainer, ImageContainer } from "./styles";
import ImagemCoringa from "../../../../../../assets/imagem_coringa.png";

interface MiniSolutionCardProps {
  link_img?: string;
}
export function MiniSolutionCard({ link_img }: MiniSolutionCardProps) {
  return (
    <CardContainer>
      <ImageContainer>
        <img
          src={
            link_img
              ? `${process.env.REACT_APP_STORAGE_BASE_URL}${link_img}`
              : ImagemCoringa
          }
          alt="gif"
        />
      </ImageContainer>
    </CardContainer>
  );
}
