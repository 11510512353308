import { useState } from "react";
import useInterval from '../../hooks/UseInterval'

import ReactDOM from "react-dom";

import logoImg from "../../assets/symbol-purple_animation.svg";

import { Container, Text, Dots, LoadingText } from "./styles";


interface LoadingProps {
  loading: boolean;
  text: string;
  numberOfDots: number;
  dotSymbol: string;
}

export function Loading({ loading, text, numberOfDots, dotSymbol }: LoadingProps) {
  const [dotQuantity, setDotQuantity] = useState<number>(1);

  useInterval(
    () => {
      // Your custom logic here
      const dots = dotQuantity;
      if (dotQuantity >= numberOfDots) {
        setDotQuantity(1);
        return
      }
      setDotQuantity(dots+1)
    },
    // Delay in milliseconds or null to stop it
    numberOfDots ? 1000 : null,
  )

  return ReactDOM.createPortal(
    <Container is_loading={loading}>
      <img src={logoImg} alt="Logo" />
      <LoadingText>
        <Text>{text}</Text>
        <Dots>{` ${dotSymbol.repeat(dotQuantity)}`}</Dots>
      </LoadingText>
    </Container>,
    document.body
  );
}
