import { HTMLAttributes, ReactNode, useState } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { Container, Error } from "./styles";

interface Options {
  label: string;
  id?: number | string;
  [property: string]: any;
}

interface SearchableSelectProps {
  options: Options[];
  label: string;
  loading?: boolean;
  error?: string;
  defaultValue?: string;
  readOnly?: boolean;
  onSelect: (selected: Options | string) => any;
  onChange: (value: string) => any;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: Options
  ) => ReactNode;
}

export function SearchableSelect({
  options,
  label,
  error = "",
  loading = false,
  defaultValue = "",
  readOnly = false,
  onSelect,
  onChange,
  renderOption,
}: SearchableSelectProps) {
  const [filled, setFilled] = useState("");

  return (
    <Container error={error} filled={filled} readOnly={readOnly}>
      <Autocomplete
        freeSolo
        options={options}
        loading={loading}
        inputValue={defaultValue}
        renderInput={(params) => <TextField {...params} label={label} />}
        readOnly={readOnly}
        renderOption={renderOption}
        onChange={(_, selected) => {
          if (selected) {
            onSelect(selected);

            if (typeof selected !== "string") setFilled(selected.label);
          }
        }}
        onInputChange={(_, value) => {
          setFilled(value);
          onChange(value);
          if (value === "") {
            setFilled("");
          }
        }}
      />

      <Error>{error}</Error>
    </Container>
  );
}
