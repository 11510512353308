import { showToast } from "../../../../components/CustomToast";

import { i18n } from "../../../../localization/i18n";

import api from "../../../../services/api";

import { Modal } from "../../../../components/Modal";

import { RiCloseLine } from "react-icons/ri";

import {
    InputContainer,
    ButtonInput,
    SubmitButton,
    CloseButton,
    JustifyInput,
    InputHeader,
} from "./styles"
import { useState } from "react";
import { orderStatus } from "../../../../utils/constants";


interface ICanceledOrder {
    justify?: string;
    orderIdentification: string;
    trigger: boolean;
    onClose: () => any;
}


export function CancelOrderModal({
    orderIdentification, 
    trigger,
    onClose = ()=>{} 
} : ICanceledOrder) {

    const [justification, setJustification] = useState("")

    async function cancelOrder(id: string) {
      try {
        api.patch(`/orders/${id}`, {
          "status": orderStatus.cancelled
        })
        showToast({ type: "success", message: i18n.t('optionsBtn.successMessage')});
        setTimeout(()=>{
          window.location.href="/orders"
        }, 5000);
      } catch(error) {
        showToast({ type: "error", message: `${error}`});
      }
    }

    const cancelTheOrder = () => {
        cancelOrder(orderIdentification);
        onClose();
    }

    return(
        <Modal open={trigger}>
                <InputHeader>Informe o motivo do cancelamento:</InputHeader>
                <CloseButton onClick={onClose}>
                    <RiCloseLine size={24}/>
                </CloseButton>
                <InputContainer>
                    <JustifyInput 
                    placeholder="O pedido foi cancelado pois..." 
                    onChange={(event)=>{
                        setJustification(event.target.value)
                    }}/>
                </InputContainer>
                <ButtonInput>
                    <SubmitButton onClick={cancelTheOrder}>Cancelar pedido</SubmitButton>
                </ButtonInput>
        </Modal>
    )
  }