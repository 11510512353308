import { useEffect, useState } from "react";

import { i18n } from "../../localization/i18n";

import { OutlinedInput } from "../OutlinedInput";
import { OutlinedSelect } from "../OutlinedSelect";
import { showToast } from "../CustomToast";
import { OutlinedButton } from "../OutlinedButton";
import { SolidButton } from "../SolidButton";
import { OutlinedCheckbox } from "../OutlinedCheckbox";

import { countries } from "../../utils/countries";
import { brazilianStates, personTypes, roles } from "../../utils/constants";

import api from "../../services/api";

import { contractOptions, paymentRecurrencyMocks } from "./mocks";

import { RiDeleteBin5Line, RiPencilLine } from "react-icons/ri";

import {
  Container,
  Header,
  FormContainer,
  InputLine,
  InputContainer,
  PrinterItem,
  PrintersContainer,
  Footer,
  CheckBoxGroupContainer,
  Title,
  Language,
} from "./styles";
import { Modal } from "../Modal";
import { useAuth } from "../../hooks/AuthContext";
import { ISignature } from "../../hooks/OnboardContext";
import { format } from "date-fns";

interface Licensee {
  id: string;
  manager_name: string;
  name: string;
  state: string;
  city: string;
  phone: string;
  document_number: string;
  person_type: string;
  instagram: string;
  created_at: string;
  is_blocked: boolean;
  is_fixpoint: boolean;
  is_test: boolean;
}

interface LicenseeInfosProps {
  licensee?: Licensee;
  open: boolean;
  closeModal: () => void;
  getLicensees: () => void;
  readOnly?: boolean;
  userVisualization?: boolean;
}

interface IOption {
  optionText: string;
  value: string | number;
}

interface IFormData {
  name: string;
  email: string;
  manager_name: string;
  state: string;
  city: string;
  phone: string;
  company_phone: string;
  companyName: string;
  country: {
    optionText: string;
    value: string | number;
  };
  instagram: string;
  person_type: {
    value: string | number;
    optionText: string;
  };
  document_number: string;
  is_public: boolean;
  contract: {
    value: string | number;
    optionText: string;
  };
  is_fixpoint: boolean;
  is_test: boolean;
  language: string;
}

export function LicenseeModal({
  licensee,
  closeModal,
  open,
  getLicensees,
  readOnly = true,
  userVisualization = false,
}: LicenseeInfosProps) {
  const formattedCountries = countries.map((item) => ({
    optionText: `${item.label} ${item.code} +${item.phone}`,
    value: item.code,
  }));

  const formattedPersonTypes = Object.entries(personTypes).map((person) => ({
    optionText: i18n.t(`global.personTypes.${person[0]}`),
    value: person[1],
  }));

  const { user } = useAuth();
  const [printers, setPrinters] = useState<IOption[]>([]);
  const [selectedPrinters, setSelectedPrinters] = useState<IOption[]>([]);
  const [modeReadOnly, setModeReadOnly] = useState(readOnly);
  const [isFixpoint, setIsFixpoint] = useState(licensee?.is_fixpoint);
  const [licenseeSignature, setLicenseeSignature] = useState<ISignature>();
  const [data, setData] = useState<IFormData>({
    name: "",
    email: "",
    person_type: formattedPersonTypes.find(
      (t) => t.value === personTypes.FISICA
    ) ?? {
      optionText: "Física",
      value: "Natural",
    },
    document_number: "",
    contract: {
      optionText: "",
      value: "",
    },
    is_public: true,
    companyName: "",
    manager_name: "",
    country: {
      optionText: "",
      value: "",
    },
    state: "",
    city: "",
    phone: "",
    company_phone: "",
    instagram: "",
    is_fixpoint: isFixpoint ?? false,
    is_test: false,
    language: "",
  });

  async function handleUpdateLicensee() {
    try {
      const body = {
        ...data,
        name: data.name,
        printers: selectedPrinters.map((item) => item.value),
        country: data.country.value,
        contract: data.contract.value,
        person_type: data.person_type.value,
        is_fixpoint: isFixpoint,
        language: data.language,
      };

      await api.patch(`/licensees/${licensee?.id}`, body);

      closeModal();
      getLicensees();
      clearDataState();

      showToast({
        type: "success",
        message: "Licenciado atualizado com sucesso.",
      });
    } catch (error) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro  ao tentar atualizar as informações do licenciado.",
      });
    }
  }

  async function getPrinters() {
    try {
      const response = await api.get("/printers");

      const formattedPrinters = response.data.map((printer: any) => {
        return {
          value: printer.id,
          optionText: printer.name,
        };
      });

      setPrinters(formattedPrinters);
    } catch (err) {
      showToast({
        type: "error",
        message: "Ocorreu um erro  ao tentar carregar as impressoras.",
      });
    }
  }

  async function getLicenseeData() {
    try {
      const response = await api.get(`/licensees/${licensee?.id}`);
      setData({
        ...response.data,
        contract: contractOptions.find(
          (c) => c.value === response.data.contract
        ),
        person_type: formattedPersonTypes.find(
          (p) => p.value === response.data.person_type
        ),
        country: formattedCountries.find((country) =>
          country.optionText.includes(response.data.country)
        ),
      });

      const formattedPrinters = response.data.printers.map((printer: any) => {
        return {
          value: printer.id,
          optionText: printer.name,
        };
      });

      setSelectedPrinters(formattedPrinters);
    } catch (err) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro  ao tentar carregar as informações do licenciado.",
      });
    }
  }

  function handleDeletePrinter(index: number) {
    let printers = [...selectedPrinters];

    printers.splice(index, 1);

    setSelectedPrinters(printers);
  }

  async function getLicenseeSignature(id: string) {
    try {
      await api.get(`signatures/get-signature/${id}`).then((response) => {
        setLicenseeSignature(response.data);
      });
    } catch (error) {
      console.log(error);
    }
  }

  function clearDataState() {
    setData({
      name: "",
      email: "",
      person_type: formattedPersonTypes.find(
        (t) => t.value === personTypes.FISICA
      ) ?? {
        optionText: "Física",
        value: "FISICA",
      },
      document_number: "",
      contract: {
        optionText: "",
        value: "",
      },
      is_public: false,
      manager_name: "",
      country: {
        optionText: "",
        value: "",
      },
      state: "",
      companyName: "",
      city: "",
      phone: "",
      company_phone: "",
      instagram: "",
      is_fixpoint: false,
      is_test: false,
      language: "",
    });

    setSelectedPrinters([]);
    setModeReadOnly(readOnly);
  }

  function formatDate(date: string | undefined) {
    if (date === undefined) {
      return "";
    }
    date.replace("Z", "");
    return format(new Date(date), "dd/MM/yyyy' às 'HH:mm");
  }

  useEffect(() => {
    if (licensee?.id && open) {
      getLicenseeData();
      getLicenseeSignature(licensee.id);
    }
  }, [licensee?.id, open]);

  useEffect(() => {
    getPrinters();
  }, []);

  return (
    <Modal
      open={open}
      modalWidth={600}
      onRequestClose={() => {
        closeModal();
        clearDataState();
      }}
    >
      <Container>
        <Header>
          {userVisualization ? (
            <Title>{`${i18n.t("userProfile.data_licensee.update")}`}</Title>
          ) : (
            <>
              <span>
                {!modeReadOnly ? `Editar ${licensee?.name}` : licensee?.name}
              </span>
              <RiPencilLine
                onClick={() => setModeReadOnly((prevState) => !prevState)}
              />
            </>
          )}
        </Header>

        <FormContainer>
          <InputLine>
            <CheckBoxGroupContainer>
              <InputContainer>
                <OutlinedCheckbox
                  readOnly={modeReadOnly}
                  label={`${i18n.t("global.licensees.field.is_public")}`}
                  checked={data.is_public}
                  handleChange={() => {
                    if (modeReadOnly) {
                      return;
                    }
                    setData((prevState) => ({
                      ...prevState,
                      is_public: !data.is_public,
                    }));
                  }}
                ></OutlinedCheckbox>
              </InputContainer>
              {userVisualization && (
                <Language
                  onChange={(event) => {
                    setData((prevState) => ({
                      ...prevState,
                      language: event.target.value,
                    }));
                  }}
                  value={data.language ?? "en"}
                >
                  <option value="pt-BR">PT 🇧🇷</option>
                  <option value="es">ES 🇪🇸</option>
                  <option value="en">EN 🇬🇧</option>
                  <option value="fr">FR 🇫🇷</option>
                </Language>
              )}
            </CheckBoxGroupContainer>

            {user.role === roles.admin && (
              <InputContainer>
                <OutlinedCheckbox
                  readOnly={modeReadOnly}
                  label={`${i18n.t("global.licensees.field.is_test")}`}
                  checked={data.is_test}
                  handleChange={() => {
                    if (modeReadOnly) {
                      return;
                    }
                    setData((prevState) => ({
                      ...prevState,
                      is_test: !data.is_test,
                    }));
                  }}
                ></OutlinedCheckbox>
              </InputContainer>
            )}

            <CheckBoxGroupContainer>
              <OutlinedCheckbox
                readOnly={modeReadOnly}
                label={`${i18n.t("global.licensees.field.natural_person")}`}
                checked={data?.person_type?.value === "FISICA"}
                handleChange={() => {
                  if (modeReadOnly) {
                    return;
                  }
                  const personType = formattedPersonTypes?.find(
                    (t) => t?.value === "FISICA"
                  );
                  personType &&
                    setData((prevState) => ({
                      ...prevState,
                      person_type: personType,
                    }));
                }}
              ></OutlinedCheckbox>

              <OutlinedCheckbox
                readOnly={modeReadOnly}
                label={`${i18n.t("global.licensees.field.legal_entity")}`}
                checked={data?.person_type?.value === personTypes.JURIDICA}
                handleChange={() => {
                  if (modeReadOnly) {
                    return;
                  }
                  const personType = formattedPersonTypes?.find(
                    (t) => t?.value === personTypes.JURIDICA
                  );
                  personType &&
                    setData((prevState) => ({
                      ...prevState,
                      person_type: personType,
                    }));
                }}
              ></OutlinedCheckbox>
            </CheckBoxGroupContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${data.person_type.value === personTypes.FISICA ? i18n.t("userProfile.data_licensee.enterprise_name") : i18n.t("userProfile.data_licensee.fantasy_name")}`}
                inputName="name"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    name: event,
                  }))
                }
                value={data.name}
              />
            </InputContainer>

            <InputContainer>
              {data.person_type?.value === personTypes.FISICA ? (
                <OutlinedInput
                  readOnly={modeReadOnly}
                  label={`${i18n.t("userProfile.data_licensee.document")}`}
                  mask={`${i18n.t("global.licensees.field.cpfMask")}`}
                  inputName="document"
                  handleChange={(event) =>
                    setData((prevState) => ({
                      ...prevState,
                      document_number: event,
                    }))
                  }
                  value={data.document_number}
                />
              ) : (
                <OutlinedInput
                  readOnly={modeReadOnly}
                  label="CNPJ"
                  inputName={`${i18n.t("userProfile.data_licensee.document")}`}
                  handleChange={(event) =>
                    setData((prevState) => ({
                      ...prevState,
                      document_number: event,
                    }))
                  }
                  value={data.document_number}
                />
              )}
            </InputContainer>
          </InputLine>

          {data.person_type?.value === personTypes.JURIDICA && (
            <InputLine>
              <InputContainer>
                <OutlinedInput
                  readOnly={modeReadOnly}
                  label={`${i18n.t(
                    "userProfile.data_licensee.enterprise_name"
                  )}`}
                  inputName="companyName"
                  handleChange={(event) =>
                    setData((prevState) => ({
                      ...prevState,
                      companyName: event,
                    }))
                  }
                  value={data.companyName}
                />
              </InputContainer>
            </InputLine>
          )}

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${i18n.t("userProfile.data_licensee.manager_name")}`}
                inputName="manager"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    manager_name: event,
                  }))
                }
                value={data.manager_name}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${i18n.t("userProfile.data_licensee.phone")}`}
                inputName="phone"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    phone: event,
                  }))
                }
                value={data.phone}
                mask="(99) 99999-9999"
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${i18n.t("userProfile.data_licensee.company_phone")}`}
                inputName="company_phone"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    company_phone: event,
                  }))
                }
                value={data.company_phone}
                mask="(99) 99999-9999"
              />
            </InputContainer>

            <InputContainer>
              <OutlinedSelect
                disabled={modeReadOnly}
                label={`${i18n.t("userProfile.data_licensee.contract")}`}
                options={contractOptions}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    contract: selected,
                  }));
                }}
                selectedOption={data.contract?.optionText}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${i18n.t("userProfile.data_licensee.instagram")}`}
                inputName="instagram"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    instagram: event,
                  }))
                }
                value={data.instagram}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                readOnly={modeReadOnly}
                options={brazilianStates}
                label={`${i18n.t("address.state")}`}
                handleSelect={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    state: event.value.toString(),
                  }))
                }
                selectedOption={data.state ?? ""}
              />
            </InputContainer>

            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${i18n.t("userProfile.personal_data.city")}`}
                inputName="city"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    city: event,
                  }))
                }
                value={data.city}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={modeReadOnly}
                label={`${i18n.t("userProfile.data_user.email")}`}
                inputName="email"
                handleChange={(event) =>
                  setData((prevState) => ({
                    ...prevState,
                    email: event,
                  }))
                }
                value={data.email}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label={`Plano`}
                inputName="plan"
                handleChange={(event) => { }}
                value={licenseeSignature?.plan.plan_name ?? ""}
              />
            </InputContainer>
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label={`Termo de Uso`}
                inputName="document"
                handleChange={(event) => { }}
                value={licenseeSignature?.user_terms?.name ?? ""}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label={`Data de assinatura`}
                inputName="agreement_date"
                handleChange={(event) => { }}
                value={
                  licenseeSignature?.terms_acceptance?.signed_at
                    ? formatDate(
                      String(licenseeSignature?.terms_acceptance?.signed_at)
                    )
                    : ""
                }
              />
            </InputContainer>
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label={`Vencimento`}
                inputName="end_date"
                handleChange={(event) => { }}
                value={
                  licenseeSignature?.end_date
                    ? formatDate(String(licenseeSignature?.end_date))
                    : "Sem vencimento"
                }
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label={`Parcelas`}
                inputName="portions"
                handleChange={(event) => { }}
                value={licenseeSignature?.payment_value ?? ""}
              />
            </InputContainer>
            <InputContainer>
              <OutlinedInput
                readOnly={true}
                label={`Recorrencia`}
                inputName="recurrency"
                handleChange={(event) => { }}
                value={
                  paymentRecurrencyMocks[
                  licenseeSignature?.payment_recurrency as keyof typeof paymentRecurrencyMocks
                  ] ?? ""
                }
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedSelect
                disabled={modeReadOnly}
                label={`${i18n.t("userProfile.personal_data.country")}`}
                options={formattedCountries}
                handleSelect={(selected) => {
                  setData((prevState) => ({
                    ...prevState,
                    country: selected,
                  }));
                }}
                selectedOption={data.country?.optionText}
              />
            </InputContainer>
          </InputLine>

          <InputLine>
            <InputContainer>
              <OutlinedCheckbox
                disabled={modeReadOnly}
                label="FixPoint"
                checked={isFixpoint || false}
                handleChange={() => {
                  setIsFixpoint(!isFixpoint);
                }}
              />
            </InputContainer>
          </InputLine>

          {userVisualization ? null : (
            <InputLine>
              <InputContainer>
                <OutlinedSelect
                  disabled={modeReadOnly}
                  label={`${i18n.t("userProfile.data_licensee.printers")}`}
                  options={printers}
                  handleSelect={(selected) => {
                    const printerAlreadySelected = selectedPrinters.find(
                      (item) => item.value === selected.value
                    );

                    if (printerAlreadySelected) return;

                    setSelectedPrinters((prevState) => [
                      ...prevState,
                      selected,
                    ]);
                  }}
                  selectedOption=""
                />
              </InputContainer>
            </InputLine>
          )}

          {
            <PrintersContainer>
              <span>{`${i18n.t("userProfile.selectedPrinters")}`}</span>

              {selectedPrinters.map((printer, index) => (
                <PrinterItem>
                  <span>{printer.optionText}</span>

                  {userVisualization ? null : (
                    <RiDeleteBin5Line
                      size={22}
                      color="var(--fixit)"
                      onClick={() => {
                        if (!modeReadOnly) {
                          handleDeletePrinter(index);
                        }
                      }}
                    />
                  )}
                </PrinterItem>
              ))}

              {!selectedPrinters.length && (
                <div className="printer_empty_state">
                  {`${i18n.t("userProfile.noPrinterSelected")}`}
                </div>
              )}
            </PrintersContainer>
          }
        </FormContainer>

        {(!modeReadOnly || userVisualization) && (
          <Footer>
            <OutlinedButton
              buttonWidth={180}
              text={`${i18n.t("tips.cancel")}`}
              onClick={() => {
                closeModal();
                clearDataState();
              }}
            />

            <SolidButton
              buttonWidth={180}
              text={`${i18n.t("tips.updateButton")}`}
              onClick={() => handleUpdateLicensee()}
            />
          </Footer>
        )}
      </Container>
    </Modal>
  );
}
