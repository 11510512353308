import { useEffect, useState } from "react";
import ReactDOM from "react-dom";

import { RiCloseLine } from "react-icons/ri";

import Logo from "../../assets/logo.svg";
import { useAuth } from "../../hooks/AuthContext";
import { i18n } from "../../localization/i18n";
import api from "../../services/api";
import { roles } from "../../utils/constants";
import { showToast } from "../CustomToast";
import { IPrice } from "../NewProductBox";
import { PriceInfo } from "../NewProductBox/styles";

import {
  Container,
  Header,
  ModalContent,
  Footer,
  SolutionDescription,
} from "./styles";

interface ISolution {
  id: string;
  name: string;
  solution_category: any;
  application_video_link?: string;
  background_image_link?: string;
  link_guide?: string;
  description?: string;
}

interface NewProductBoxProps {
  open: boolean;
  closeModal: () => any;
  solution: ISolution;
  licenseeCountry: string;
  sample?: any;
}

export function SolutionModal({
  open,
  closeModal,
  solution,
  licenseeCountry,
  sample,
}: NewProductBoxProps) {
  const [prices, setPrices] = useState<IPrice[]>([]);
  const { user } = useAuth();

  async function getPrices() {
    const queryList = [];
    queryList.push(`is_active=${true}`);
    queryList.push(`country=${licenseeCountry}`);
    queryList.push(`solution_id=${solution.id}`);

    try {
      const response = await api.get(`/prices?${queryList.join("&")}`);
      setPrices(response.data);
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao tentar carregar os preços",
      });
    }
  }

  useEffect(() => {
    getPrices();
  }, [solution]);

  return ReactDOM.createPortal(
    <Container open={open}>
      <div className="modal_wrapper">
        <ModalContent>
          <Header>
            {solution.background_image_link ? (
              <div className="image_container">
                <div className="effect"></div>
                <img
                  src={solution.background_image_link}
                  alt="imagem solução"
                />
              </div>
            ) : (
              <div className="logo_container">
                <img src={Logo} alt="Logo fixit" />
              </div>
            )}
            <div className="close_modal">
              <RiCloseLine
                onClick={closeModal}
                size={24}
                color={
                  solution.background_image_link
                    ? "var(--white)"
                    : "var(--fixit)"
                }
              />
            </div>
          </Header>

          {user.role === roles.employee ? null : (
            <SolutionDescription>
              <div className="solution_name">{solution.name}</div>
              {solution.description && (
                <>
                  <div className="description">Descrição</div>
                  <div className="solution_description">
                    {solution.description}
                  </div>
                </>
              )}
            </SolutionDescription>
          )}

          <Footer>
            {solution.link_guide && (
              <div className="footer_item">
                <a href={solution.link_guide} target="_blank" rel="noreferrer">
                  {`${i18n.t("customSolution.measuresGuide")}`}
                </a>
              </div>
            )}
            {sample?.link && (
              <div className="footer_item">
                <a href={sample.link} target="_blank" rel="noreferrer">
                  {`${i18n.t("solutions.sampleSolution")}`}
                </a>
              </div>
            )}
            {solution.application_video_link && (
              <div className="footer_item">
                <a
                  href={solution.application_video_link}
                  target="_blank"
                  rel="noreferrer"
                >
                  {`${i18n.t("solutions.aplicationVideo")}`}
                </a>
              </div>
            )}
          </Footer>
        </ModalContent>
      </div>
    </Container>,
    document.body
  );
}
