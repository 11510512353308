import styled from "styled-components";

interface TrophyColor {
  color: string;
}

export const StyledTrophy = styled.div<TrophyColor>`
  position: relative;
  font-size: 30px;
  color: ${(props) => props.color};
`;

export const TrophyNumber = styled.span<TrophyColor>`
  position: absolute;
  font-size: 15px;
  font-weight: 600;
  top: 25%;
  left: 48%;
  transform: translate(-50%, -50%);
  color: ${(props) => props.color};
`;
