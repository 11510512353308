import { useState } from "react";
import { showToast } from "../../../../components/CustomToast";
import { OutlinedInput } from "../../../../components/OutlinedInput";
import { SolidButton } from "../../../../components/SolidButton";
import api from "../../../../services/api";
import { Container, Footer, Header } from "./styles";
import * as Yup from "yup";
import { typesErrors } from "../../../../utils/validateFormFields/yupErrors";
import validateFormFields from "../../../../utils/validateFormFields";
import { i18n } from "../../../../localization/i18n";

interface Props {
  licensee_id: string;
  onHandleCloseRequest: () => void;
}

interface IData {
  description: string;
  ammount: number;
  licensee_id: string;
  type: string;
  transaction_date: Date;
}

export function AddFixcoinModal({ licensee_id, onHandleCloseRequest }: Props) {
  const [errors, setErrors] = useState({} as any);

  const [data, setData] = useState<IData>({
    description: "",
    ammount: 0,
    licensee_id,
    type: "income",
    transaction_date: new Date(),
  });

  async function createIncomeTransaction() {
    try {
      const yup_validation = Yup.object().shape({
        description: Yup.string().required(typesErrors.required),
        ammount: Yup.number().moreThan(0).required(typesErrors.required),
      });

      const validation = await validateFormFields(data, yup_validation, {
        description: "",
        ammount: "",
      });

      if (validation.status === 400) {
        setErrors(validation.errors);
        showToast({
          type: "error",
          message: i18n.t("form_errors.alert"),
        });

        return;
      }

      setErrors({});
      await api.post("/transactions", data).then((response) => {
        showToast({
          type: "success",
          message: "Fixcoin adicionado a carteira do licenciado",
        });
        onHandleCloseRequest();
      });
    } catch (error) {
      showToast({
        type: "success",
        message: "Não foi possivel adicionar Fixcoin",
      });
      console.log(error);
    }
  }

  return (
    <Container>
      <Header>Cadastro de Fixcoin</Header>
      <OutlinedInput
        error={errors?.description}
        handleChange={(event) =>
          setData((prevState) => ({
            ...prevState,
            description: event,
          }))
        }
        inputName={""}
        label={"Descrição"}
        value={data.description}
        type="text"
      />
      <OutlinedInput
        error={errors?.ammount}
        handleChange={(event) =>
          setData((prevState) => ({
            ...prevState,
            ammount: event,
          }))
        }
        inputName={""}
        label={"Qtd. Fixcoin"}
        value={data.ammount}
        type="number"
      />
      <Footer>
        <SolidButton
          text={"Enviar Fixcoins"}
          onClick={createIncomeTransaction}
          fullWidth
        />
      </Footer>
    </Container>
  );
}
