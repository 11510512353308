import { useNavigate } from "react-router-dom";
import Popover from "../Popover";

import { RiSettings3Line } from "react-icons/ri";

import {
  ActionItem,
  ActionsContainer,
  Container,
  Header,
  IconButtonContainer,
  ImageContainer,
  LeftContent,
  PrincipalInfos,
  RightContent,
} from "./styles";

interface IConfigItem {
  section: string;
  label: string;
  help: string;
  icon: JSX.Element;
  action: string;
}

export function ConfigItem({
  section,
  label,
  help,
  icon,
  action,
}: IConfigItem) {
  const navigate = useNavigate();

  return (
    <Container>
      <Header>
        <LeftContent>
          <ImageContainer>
            <Popover
              position="right"
              label={help}
              styles={{
                minWidth: "200px",
                maxWidth: "200px",
                whiteSpace: "normal",
              }}
            >
              {icon}
            </Popover>
          </ImageContainer>
          <PrincipalInfos>
            <span className="solution">{section}</span>
            <span className="date">{label}</span>
          </PrincipalInfos>
        </LeftContent>
        <RightContent>
          <ActionsContainer>
            <ActionItem>
              <Popover position="left" label="Configurar">
                <IconButtonContainer>
                  <RiSettings3Line
                    size={18}
                    onClick={() => {
                      navigate({
                        pathname: action,
                      });
                    }}
                  />
                </IconButtonContainer>
              </Popover>
            </ActionItem>
          </ActionsContainer>
        </RightContent>
      </Header>
    </Container>
  );
}
