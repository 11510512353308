import { HTMLAttributes, ReactNode, useState } from "react";

import {
  Autocomplete,
  TextField,
  Chip,
  FilterOptionsState,
} from "@mui/material";

import { Container, Error } from "./styles";

interface Options {
  label: string;
  id?: number | string;
  description?: string;
  [property: string]: any;
}

interface SearchableSelectProps {
  options: Options[];
  label: string;
  loading?: boolean;
  error?: string;
  defaultValue?: string;
  value?: Options[];
  readOnly?: boolean;
  filterOptions?:
    | ((options: Options[], state: FilterOptionsState<Options>) => Options[])
    | undefined;
  renderOption?: (
    props: HTMLAttributes<HTMLLIElement>,
    option: Options
  ) => ReactNode;
  onSelect: (selected: (Options | string)[]) => any;
  onChange: (value: string) => any;
  onBlur?: () => void;
}

export function MultipleSearchableSelect({
  options,
  label,
  error = "",
  loading = false,
  defaultValue = "",
  readOnly = false,
  renderOption,
  filterOptions,
  value,
  onSelect,
  onChange,
  onBlur,
}: SearchableSelectProps) {
  const [filled, setFilled] = useState(false);

  return (
    <Container error={error} filled={filled} readOnly={readOnly}>
      <Autocomplete
        freeSolo
        multiple
        options={options}
        loading={loading}
        inputValue={defaultValue}
        value={value}
        readOnly={readOnly}
        renderOption={renderOption}
        filterOptions={filterOptions}
        onBlur={() => onBlur && onBlur()}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              size="small"
              label={option.label}
            />
          ))
        }
        renderInput={(params) => <TextField {...params} label={label} />}
        onChange={(_, selected) => {
          if (selected) {
            onSelect(selected);
            setFilled(selected.length > 0);
          }
        }}
        onInputChange={(_, value) => {
          setFilled(!!value);
          onChange(value);
          if (value === "") {
            setFilled(false);
          }
        }}
      />

      <Error>{error}</Error>
    </Container>
  );
}
