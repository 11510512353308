import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1000px) {
    padding: 0 16px 20px 16px;
  }
`;

export const LogoContainer = styled.div`
  width: 100%;
  margin-top: 30px;

  display: flex;
  justify-content: center;

  img {
    width: 200px;
  }
`;

export const FormContainer = styled.div`
  min-width: 970px;
  max-width: 1150px;
  padding: 30px 80px;
  margin-top: 30px;
  border-radius: 10px;
  background: var(--white);
  box-shadow: 0px 0px 15px 2px rgba(0, 0, 0, 0.25);

  @media (max-width: 1000px) {
    min-width: 100%;
    width: 100%;
    padding: 20px 0px;
  }
`;

export const StepperContainer = styled.div`
  padding: 0 30px;

  @media (max-width: 1000px) {
    padding: 0 16px;
  }
`;

export const ChangeLanguageContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
`;

export const ChangePriceContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
`;

export const LanguageSelect = styled.select`
  font-size: 16px;
  padding: 4px 10px;
  background: var(--white);
  color: var(--dark-gray);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  direction: rtl;

  display: flex;
  align-items: center;
  justify-content: center;

  option {
    color: var(--dark-gray);
    font-size: 16px;
  }
`;

export const PriceSelect = styled.select`
  font-size: 16px;
  padding: 4px 10px;
  background: var(--white);
  color: var(--dark-gray);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 6px;
  direction: rtl;

  display: none;
  align-items: center;
  justify-content: center;

  option {
    color: var(--dark-gray);
    font-size: 16px;
  }
`;
