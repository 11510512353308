import styled from 'styled-components';
import global from '../../global';
// OLD IFRAME LAYOUT

interface IContentAreaProps {
  readonly isMenuOpen: boolean;
}

export const PageContainer = styled.div<IContentAreaProps>`
  width: 100%;
  max-height: 9999px; // this is used to make the animation smoother
  transform: scale(1, 1) translateX(0); // This is needed to keep the ContentArea above de NavMenu
  background-color: var(--background);
  transition: transform 0.25s ease-in-out, border-radius 0.25s ease-in-out, margin 0.25s ease-in-out, max-height 0.25s ease-in-out;

  .content-area {
    position: relative;
    height: 70vh;
  }

  ${props => props.isMenuOpen
    ? `transform: translateX(75%) scale(0.90, 0.90);
    max-height: 100%;
    overflow: hidden;
    border-radius: 12px;
    position: relative;
    :before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
    }`
    : ``}

  @media(max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    min-height: 100%;
    padding-left: 0;

    .content-area {
      padding-bottom: 8vw;
    }
  }
`
