import { i18n } from "../../localization/i18n";

export const typesErrors = {
  required: i18n.t("form_errors.required"),
  minRequired: i18n.t("form_errors.minRequired"),
  matchPassword: i18n.t("form_errors.matchPassword"),
  validEmail: i18n.t("form_errors.validEmail"),
  validCountry: i18n.t("form_errors.validCountry"),
  invalidValue: i18n.t("form_errors.invalidValue"),
  passwordLength: i18n.t("form_errors.passwordLength"),
  passwordCharacters: i18n.t("form_errors.passwordCharacters"),
};
