import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../../hooks/AuthContext";

import { ActionsPopup } from "../../../../../Orders/components/ActionsPopup";
import { showToast } from "../../../../../../components/CustomToast";
import Popover from "../../../../../../components/Popover";

import { getActiveLanguage } from "../../../../../../utils/getActiveLanguage";
import { roles } from "../../../../../../utils/constants";

import api from "../../../../../../services/api";

import { Diagnosis, NonVerifiedDiagnosisOrder } from "../../dtos";

import {
  RiMore2Fill,
  RiPencilLine,
  RiDeleteBin5Line,
  RiCheckboxCircleLine,
  RiCloseLine,
} from "react-icons/ri";

import { Container, Info, LeftContent, RightContent } from "./styles";

interface DiagnosisItemProps {
  diagnosis: Diagnosis;
  getDiagnosis: () => void;
  onDiagnosisEdit: (diagnosis: Diagnosis) => void;
}

export function DiagnosisItem({
  diagnosis,
  getDiagnosis,
  onDiagnosisEdit,
}: DiagnosisItemProps) {
  const { user } = useAuth();

  const navigate = useNavigate();

  const [actionsPopover, setActionsPopover] = useState(false);
  const [nonVefiriedDiagnosisOrder, setNonVerifiedDiagnosisOrder] = useState<
    NonVerifiedDiagnosisOrder | undefined
  >(undefined);

  const actions = [
    {
      text: "Editar",
      icon: <RiPencilLine />,
      active: false,
      action: () => onDiagnosisEdit(diagnosis),
    },
    {
      text: "Deletar",
      icon: <RiDeleteBin5Line />,
      warning: true,
      active: false,
      action: () => handleDeleteTip(),
    },
  ];

  async function handleDeleteTip() {
    try {
      await api.delete(`/diagnosis/${diagnosis.id}`);

      showToast({
        type: "success",
        message: "Diagnóstico deletado com sucesso.",
      });

      getDiagnosis();
    } catch (error) {
      showToast({
        type: "error",
        message: "Ocorreu um erro ao deletar o diagnóstico.",
      });
    }
  }

  async function handleGetNonVerifiedDiagnosisOrder() {
    try {
      const response = await api.get(`/orders/diagnosis/${diagnosis.id}`);

      if (response?.data?.id) {
        setNonVerifiedDiagnosisOrder({
          id: response?.data?.id,
          incremental_id: response?.data?.incremental_id,
        });
      }
    } catch (error) {
      showToast({
        type: "error",
        message:
          "Ocorreu um erro ao carregar os pedidos com diagnósticos não verificados.",
      });
    }
  }

  function handleNavigateToOrder() {
    const url =
      user.role === roles.saf ? "/saf/orders/show" : "/admin/orders/show";

    navigate(url, {
      state: {
        order_id: nonVefiriedDiagnosisOrder?.id,
      },
    });
  }

  function parseDiagnosisName() {
    try {
      return diagnosis.name[getActiveLanguage()];
    } catch (error) {
      return;
    }
  }

  useEffect(() => {
    if (diagnosis && !diagnosis.verified) {
      handleGetNonVerifiedDiagnosisOrder();
    }
  }, [diagnosis]);

  return (
    <Container>
      <LeftContent>
        <div className="image">
          <span>{parseDiagnosisName()?.charAt(0)}</span>
        </div>
        <div className="title">{parseDiagnosisName()}</div>

        <Info>
          <span className="info">CID</span>
          <span className="value">{diagnosis?.cid}</span>
        </Info>

        {nonVefiriedDiagnosisOrder && (
          <Info onClick={handleNavigateToOrder}>
            <span className="info">Pedido</span>
            <span className="value">
              #{nonVefiriedDiagnosisOrder?.incremental_id}
            </span>
          </Info>
        )}
      </LeftContent>

      <RightContent>
        <Popover
          position="left"
          label={diagnosis.verified ? "Verificado" : "Não verificado"}
        >
          {diagnosis.verified ? (
            <RiCheckboxCircleLine color="var(--finished)" />
          ) : (
            <RiCloseLine color="var(--formError)" />
          )}
        </Popover>

        <div className="actions">
          <RiMore2Fill onClick={() => setActionsPopover(true)} />

          {actionsPopover && (
            <ActionsPopup
              options={actions}
              onClickAway={() => setActionsPopover(false)}
            />
          )}
        </div>
      </RightContent>
    </Container>
  );
}
