import { Container } from "./styles";
import {
  RiCheckDoubleFill,
  RiCloseFill,
  RiEditLine,
  RiEyeLine,
  RiSaveLine,
} from "react-icons/ri";
import {
  AccordionActionsContainer,
  AccordionContainer,
  AccordionContentContainer,
  CheckboxContainer,
  ChecksContainer,
  CircleButton,
  Divider,
  InputContainer,
  InputLine,
  LinkButton,
} from "../../styles";
import { AccordionOrder } from "../../../../../components/AccordionOrder";
import { SearchableSelect } from "../../../../../components/SearchableSelect";
import api, { isAxiosCancel } from "../../../../../services/api";
import { OutlinedInput } from "../../../../../components/OutlinedInput";
import { CustomSolutionForm, IPartnerData, ISearchData } from "../../dtos";
import PartnerImg from "../../../../../assets/order_steps/partner.svg";
import { FaSearchPlus } from "react-icons/fa";
import { useEffect, useState } from "react";
import { showToast } from "../../../../../components/CustomToast";
import { Checkbox } from "../../../../../components/Checkbox";
import { i18n } from "../../../../../localization/i18n";
import { AxiosResponse } from "axios";

interface PartnerOrderProps {
  orderType: string;
  partnerAccordion: boolean;
  partnerEdited: Date;
  partnerComplete: boolean;
  partnerEditable: boolean;
  partnerSearchable: boolean;
  tmpPartnerData: IPartnerData;
  partnerFormErrors: any;
  setTmpPartnerData: (value: React.SetStateAction<IPartnerData>) => void;
  validatePartnerForm: (data: IPartnerData) => Promise<boolean>;
  setPartnerEdited: (value: React.SetStateAction<Date>) => void;
  setPartnerAccordion: (value: React.SetStateAction<boolean>) => void;
  setPartnerEditable: (value: React.SetStateAction<boolean>) => void;
  setPartnerSearchable: (value: React.SetStateAction<boolean>) => void;
  setPartnerComplete: (value: React.SetStateAction<boolean>) => void;
  orderData: CustomSolutionForm;
  setOrderData: (value: React.SetStateAction<CustomSolutionForm>) => void;
  handleDiscard?: () => void;
}

export default function PartnerOrderSection({
  orderType,
  partnerAccordion,
  partnerEdited,
  partnerComplete,
  partnerEditable,
  partnerSearchable,
  partnerFormErrors,
  setPartnerAccordion,
  setPartnerSearchable,
  setPartnerEditable,
  setPartnerEdited,
  setPartnerComplete,
  validatePartnerForm,
  tmpPartnerData,
  setTmpPartnerData,
  orderData,
  setOrderData,
  handleDiscard,
}: PartnerOrderProps) {
  const [partners, setPartners] = useState<ISearchData[]>([]);
  const [selectLoading, setSelectedLoading] = useState(false);
  const [partnerSearchValue, setPartnerSearchValue] = useState("");
  const [existingPartner, setExistingPartner] = useState(false);
  const [loading, setLoading] = useState(false);
  var language = i18n.language;

  function ClearInputs() {
    if (handleDiscard) {
      handleDiscard();
    }
    setExistingPartner(false);
    setPartnerEditable(true);
  }

  useEffect(() => {
    const actualOrder: any = JSON.parse(
      localStorage.getItem("currentOrder") || "null"
    );
    if (actualOrder?.partner) {
      setExistingPartner(true);
    }
  }, []);

  async function updatePartnerData() {
    setLoading(true);
    const abortController = new AbortController();
    let signal = abortController.signal;

    try {
      let response: AxiosResponse;
      if (tmpPartnerData.id) {
        response = await api.patch(
          `/partners/${tmpPartnerData.id}`,
          tmpPartnerData,
          { signal }
        );
      } else {
        response = await api.post("/partners", tmpPartnerData, { signal });
        setTmpPartnerData((prevState) => ({
          ...prevState,
          id: response.data.id,
        }));
      }
      setOrderData((prevState) => ({
        ...prevState,
        partner: response.data,
      }));

      setPartnerEdited(new Date());
      setPartnerAccordion(false);
      setPartnerEditable(false);
      setPartnerSearchable(false);
      setPartnerComplete(true);
    } catch (error) {
      showToast({
        type: "error",
        message: "Não foi possível salvar os dados do profissional",
      });
    } finally {
      setLoading(false);
    }

    return () => abortController.abort();
  }

  async function searchPartners(signal: AbortSignal) {
    setSelectedLoading(true);
    try {
      // request de profissionais
      const { data } = await api.get(
        `/partners/search?is_person=${tmpPartnerData.is_person}&term=${partnerSearchValue}`,
        { signal }
      );
      setPartners(
        data.map((p: any) => ({
          id: p.id,
          label: p.name ?? p.company_name,
        }))
      );
    } catch (err) {
      if (isAxiosCancel(err)) {
        return "axios request cancelled";
      }
      showToast({
        type: "error",
        message: i18n.t("orders.partner_section.search.error"),
      });
    } finally {
      setSelectedLoading(false);
    }
  }

  function getPartnerSubtitle() {
    if (orderData.partner.phone && orderData.partner.email) {
      return `${orderData.partner.document_number}`;
    }

    return "";
  }

  useEffect(() => {
    const abortController = new AbortController();
    let signal = abortController.signal;

    if (partnerSearchValue != "") {
      searchPartners(signal);
    }

    return () => abortController.abort();
  }, [partnerSearchValue]);

  return (
    <Container>
      <AccordionContainer>
        <AccordionOrder
          disabled={orderType !== "is_partner"}
          open={partnerAccordion}
          emptyTitle={i18n.t("orders.partner_section.empty_title")}
          emptySubtitle={i18n.t("orders.partner_section.empty_subtitle")}
          title={orderData?.partner.name}
          subTitle={getPartnerSubtitle()}
          icon={PartnerImg}
          lastUpdate={partnerEdited}
          pendingInfos={!partnerComplete}
          handleDiscard={handleDiscard}
          handleChange={() => {}}
          toogleComponent={
            <AccordionActionsContainer>
              {!partnerAccordion ? (
                orderData?.partner?.id ? (
                  <CircleButton
                    disabled={orderType !== "is_partner"}
                    onClick={() => {
                      setPartnerAccordion(true);
                    }}
                  >
                    <RiEyeLine size={20} color="#989898" />
                  </CircleButton>
                ) : (
                  <LinkButton
                    disabled={orderType !== "is_partner"}
                    onClick={() => {
                      setPartnerAccordion(true);
                      setPartnerSearchable(true);
                    }}
                  >
                    {`${i18n.t("orders.actions.new")}`}
                    <FaSearchPlus size={20} color="#989898" />
                  </LinkButton>
                )
              ) : (
                <>
                  {!partnerEditable && !partnerSearchable ? (
                    <>
                      <LinkButton
                        disabled={orderType !== "is_partner"}
                        onClick={() =>
                          existingPartner
                            ? ClearInputs()
                            : setPartnerEditable(true)
                        }
                      >
                        {existingPartner ? (
                          <>
                            {`${i18n.t("orders.actions.discart")}`}
                            <RiCloseFill size={25} color="#989898" />
                          </>
                        ) : (
                          <>
                            {`${i18n.t("orders.actions.edit")}`}
                            <RiEditLine size={20} color="#989898" />
                          </>
                        )}
                      </LinkButton>
                      <CircleButton disabled={orderType !== "is_partner"}>
                        <RiCheckDoubleFill
                          size={25}
                          color="#989898"
                          onClick={() => {
                            setPartnerAccordion(false);
                            setPartnerEditable(false);
                            setPartnerSearchable(false);
                          }}
                        />
                      </CircleButton>
                    </>
                  ) : (
                    <>
                      <LinkButton
                        disabled={orderType !== "is_partner"}
                        onClick={() => {
                          validatePartnerForm(tmpPartnerData).then((valid) => {
                            valid && updatePartnerData();
                          });
                        }}
                      >
                        {`${i18n.t("orders.actions.save")}`}
                        <RiSaveLine size={20} color="#989898" />
                      </LinkButton>
                      <CircleButton disabled={orderType !== "is_partner"}>
                        <RiCloseFill
                          size={25}
                          color="#989898"
                          onClick={() => {
                            setTmpPartnerData({ ...orderData.partner });
                            setPartnerAccordion(false);
                            setPartnerEditable(false);
                            setPartnerSearchable(false);
                          }}
                        />
                      </CircleButton>
                    </>
                  )}
                </>
              )}
            </AccordionActionsContainer>
          }
        >
          <AccordionContentContainer>
            <ChecksContainer>
              <CheckboxContainer>
                <Checkbox
                  checked={tmpPartnerData.is_person}
                  readOnly={!partnerEditable && !partnerSearchable}
                  handleChange={() => {
                    setTmpPartnerData(() => ({
                      is_person: true,
                      name: "",
                      phone: "",
                      email: "",
                      professional_number: "",
                      cep: "",
                      document_number: "",
                      company_name: "",
                    }));
                  }}
                />

                <span>{`${i18n.t(
                  "orders.partner_section.entities.natural_person"
                )}`}</span>
              </CheckboxContainer>

              <CheckboxContainer>
                <Checkbox
                  readOnly={!partnerEditable && !partnerSearchable}
                  checked={!tmpPartnerData.is_person}
                  handleChange={() => {
                    setTmpPartnerData(() => ({
                      is_person: false,
                      name: "",
                      phone: "",
                      email: "",
                      professional_number: "",
                      cep: "",
                      document_number: "",
                      company_name: "",
                    }));
                  }}
                />

                <span>{`${i18n.t(
                  "orders.partner_section.entities.legal_entity"
                )}`}</span>
              </CheckboxContainer>
            </ChecksContainer>

            <InputLine>
              <InputContainer>
                <SearchableSelect
                  label={`${i18n.t("customSolution.fullName")}`}
                  options={partners}
                  defaultValue={tmpPartnerData.name}
                  loading={selectLoading}
                  readOnly={!partnerEditable && !partnerSearchable}
                  onSelect={(selected) => {
                    if (typeof selected === "string") {
                      setTmpPartnerData((prevState) => {
                        return {
                          is_person: prevState.is_person,
                          phone: "",
                          email: "",
                          professional_number: "",
                          cep: "",
                          document_number: "",
                          company_name: "",
                          name: selected,
                        };
                      });
                      return;
                    }

                    const abortController = new AbortController();
                    let signal = abortController.signal;

                    api
                      .get(`/partners/${selected.id}`, { signal })
                      .then((response) => {
                        setPartnerEditable(false);
                        setExistingPartner(true);
                        setPartnerSearchable(false);
                        setTmpPartnerData(response.data);
                        setOrderData((prevState) => ({
                          ...prevState,
                          partner: response.data,
                        }));
                        validatePartnerForm(response.data).then((valid) => {
                          if (valid) {
                            setPartnerEdited(new Date());
                            setPartnerComplete(true);
                          }
                        });
                      });

                    return () => abortController.abort();
                  }}
                  onChange={(value) => {
                    setPartnerSearchValue(value);
                    setTmpPartnerData((prevState) => ({
                      ...prevState,
                      name: value,
                    }));
                  }}
                  error={partnerFormErrors?.name}
                />
              </InputContainer>
            </InputLine>

            {tmpPartnerData.is_person ? (
              <InputLine>
                <InputContainer>
                  <OutlinedInput
                    inputName="cpf"
                    value={tmpPartnerData.document_number}
                    label={`${i18n.t("customSolution.cpf")}`}
                    readOnly={!partnerEditable && !partnerSearchable}
                    mask={language === "pt-BR" ? "999.999.999-99" : ""}
                    handleChange={(event) => {
                      setTmpPartnerData((prevState) => ({
                        ...prevState,
                        document_number: event,
                      }));
                    }}
                    error={partnerFormErrors?.document_number}
                  />
                </InputContainer>
              </InputLine>
            ) : (
              <>
                <InputLine>
                  <InputContainer>
                    <OutlinedInput
                      inputName="cnpj"
                      value={tmpPartnerData.document_number}
                      label={`${i18n.t("customSolution.cnpj")}`}
                      readOnly={!partnerEditable && !partnerSearchable}
                      mask={language === "pt-BR" ? "99.999.999/9999-99" : ""}
                      handleChange={(event) => {
                        setTmpPartnerData((prevState) => ({
                          ...prevState,
                          document_number: event,
                        }));
                      }}
                      error={partnerFormErrors?.document_number}
                    />
                  </InputContainer>
                </InputLine>

                <InputLine>
                  <InputContainer>
                    <OutlinedInput
                      inputName="company_name"
                      value={tmpPartnerData.company_name}
                      label={`${i18n.t("customSolution.company_name")}`}
                      readOnly={!partnerEditable && !partnerSearchable}
                      handleChange={(event) => {
                        setTmpPartnerData((prevState) => ({
                          ...prevState,
                          company_name: event,
                        }));
                      }}
                      error={partnerFormErrors?.company_name}
                    />
                  </InputContainer>
                </InputLine>
              </>
            )}

            <InputLine>
              <InputContainer containerWidth="280px">
                <OutlinedInput
                  inputName="celular"
                  value={tmpPartnerData.phone}
                  label={`${i18n.t("customSolution.phone")}`}
                  mask={
                    language === "pt-BR"
                      ? `${i18n.t("customSolution.contactMask")}`
                      : "(999)999-9999"
                  }
                  readOnly={!partnerEditable && !partnerSearchable}
                  handleChange={(event) => {
                    setTmpPartnerData((prevState) => ({
                      ...prevState,
                      phone: event,
                    }));
                  }}
                  error={partnerFormErrors?.phone}
                />
              </InputContainer>

              <InputContainer>
                <OutlinedInput
                  inputName="email"
                  value={tmpPartnerData.email}
                  label={`${i18n.t("customSolution.email")}`}
                  readOnly={!partnerEditable && !partnerSearchable}
                  handleChange={(event) => {
                    setTmpPartnerData((prevState) => ({
                      ...prevState,
                      email: event,
                    }));
                  }}
                  error={partnerFormErrors?.email}
                />
              </InputContainer>
            </InputLine>

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="registro"
                  value={tmpPartnerData.professional_number}
                  label={`${i18n.t("customSolution.professional_number")}`}
                  readOnly={!partnerEditable && !partnerSearchable}
                  handleChange={(event) => {
                    setTmpPartnerData((prevState) => ({
                      ...prevState,
                      professional_number: event,
                    }));
                  }}
                  error={partnerFormErrors.professional_number}
                />
              </InputContainer>
            </InputLine>

            <Divider />

            <InputLine>
              <InputContainer>
                <OutlinedInput
                  inputName="cep"
                  value={tmpPartnerData.cep}
                  label={`${i18n.t("customSolution.cep")}`}
                  mask={
                    language === "pt-BR"
                      ? `${i18n.t("customSolution.cepMask")}`
                      : ""
                  }
                  readOnly={!partnerEditable && !partnerSearchable}
                  handleChange={(event) => {
                    setTmpPartnerData((prevState) => ({
                      ...prevState,
                      cep: event,
                    }));
                  }}
                  error={partnerFormErrors.cep}
                />
              </InputContainer>
            </InputLine>
          </AccordionContentContainer>
        </AccordionOrder>
      </AccordionContainer>
    </Container>
  );
}
