import styled from "styled-components";
import global from "../../global";

type ButtonProps = {
  disabled?: boolean;
  providerSelected?: boolean;
};
interface ValueProps{
  paymentPending?: 'pending' | 'paid' | 'not_paid';
}

export const Container = styled.div`
  width: 100%;
  border-radius: 14px;
  padding: 16px 26px 16px 28px;
  background: var(--white);
  box-shadow: 1px 5px 10px rgb(0 0 0 / 25%);

  transition: all 0.3s;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    padding: 16px 20px 16px 22px;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const RightContent = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    gap: 15px;
  }
`;

export const ValueContainer = styled.div<ValueProps>`
  width: 45px;
  height: 100%;
  color: ${(props) => (props.paymentPending === 'not_paid' ? "var(--error)" : props.paymentPending === 'paid' ? "var(--active-green)" : "var(--warning)")};
  font-size: 1.5rem;
  text-align: end;
  overflow: hidden;

  div{
    font-size: 0.7rem;
    width: 100%;
  }
`;

export const PrincipalInfos = styled.div<ValueProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  min-width: 180px;
  max-width: 180px;

  .identity {
    font-size: 12px;
    line-height: 12px;
    color: var(--gray);
    font-weight: bolder;
  }

  .date {
    font-size: 10px;
    line-height: 11px;
    color: var(--gray);
    font-weight: 500;
    margin-top: 5px;
  }

  .solution {
    font-size: 14px;
    line-height: 14px;
    color: ${(props) => (props.paymentPending === 'not_paid' ? "var(--error)" : props.paymentPending === 'paid' ? "var(--active-green)" : "var(--warning)")};
    font-weight: bolder;
  }

  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    min-width: 190px;
    max-width: 190px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;

  svg {
    color: var(--fixit);
    cursor: pointer;
  }
`;

export const ActionItem = styled.div`
  @media (max-width: ${global.responsive.small.width.value}${global.responsive.small.width.unit}) {
    &.print {
      display: none;
    }

    &.download {
      display: none;
    }
  }
`;

export const IconButtonContainer = styled.div<ButtonProps>`
  position: relative;
  display: flex;

  svg {
    color: ${(props) => (props.disabled ? "var(--light-gray)" : "var(--gray)")};
    cursor: ${(props) => (props.disabled ? "default" : "pointer")};
  }
`;
