import {Container, PrincipalText, SecondaryText} from "./styles";
import WhatsappBtn from "./WhatsappButton";
import { i18n } from "../../../../localization/i18n";

const Payment = () => {
  return (
    <Container>
        <PrincipalText>
          {`${i18n.t("disabled_user.principal")}`}
        </PrincipalText>
        <SecondaryText>
          {`${i18n.t("disabled_user.secundary")}`}
        </SecondaryText>
      <WhatsappBtn />
    </Container>
  )
}

export default Payment;
