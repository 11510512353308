import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RiDeleteBin7Line } from "react-icons/ri";
import DropZone from "../../../../components/DropZone";

import { Checkbox } from "../../../../components/Checkbox";

import { sides } from "../../../../utils/constants";

import {
  Container,
  SectionLabel,
  Section,
  Flex,
  SubContainer,
  Input,
  InputLabel,
  InputContainer,
  CheckboxFlex,
  // Checkbox,
  Submit,
  Cancel,
  Delete,
  FlexButtons,
  UploadFilesContainer,
  DropzoneContainer,
  File,
  Image,
  ImageContainer,
  DeleteButton,
  SelectLine,
  SelectContainer,
  FileContainer,
  SideSelectContainer,
} from "./styles";

import api from "../../../../services/api";
import { useLocation } from "react-router-dom";
import { showToast } from "../../../../components/CustomToast";
import { AxiosError } from "axios";
import Dropdown from "../../../Admin/components/Dropdown";
import { useAuth } from "../../../../hooks/AuthContext";
import { i18n } from "../../../../localization/i18n";

interface ILocationState {
  state: {
    from: {
      pathname: string;
    };
    solution_id: string;
    solution_name: string;
    number_measures: number;
  }
}

export interface IOption {
  value: string;
  optionText: string;
}

export interface ISolution {
  id: string;
  name: string;
  parts: string[];
  sides: IOption[];
  form_type: string;
  suggested_price: string;
  category: string;
  link_img?: string;
  link_guide: string;
  number_measures: number;
  is_custom: boolean;
  is_standard: boolean;
  is_download: boolean;
  sample: ISample;
  standards: IStandard[];
  download_link?: string;
}

export interface IStandard {
  id?: string;
  order: number;
  size: string;
}

export interface ISample {
  id?: string;
  solution_id?: string;
  printer_id?: string;
  link?: string;
}

export interface ISolutionCategory extends IOption {
  id: string;
  name?: string;
}

export interface ISolutionField extends IOption {
  id: string;
  name?: string;
}

interface IFile {
  name: string;
  size: number;
  type: string;
  file_data: File;
}

export default function EditSolution() {
  const { setNavigationAction } = useAuth();
  const location = useLocation() as ILocationState;
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm({ mode: "all" });
  const { solution_id } = location.state || {};
  if (!solution_id) {
    navigate("/creators/solutions");
  }

  const [solution, setSolution] = useState<ISolution>({} as ISolution);
  const [fields, setFields] = useState<Array<ISolutionField>>([]);
  const [field, setField] = useState<ISolutionField>();

  const [categories, setCategories] = useState<Array<ISolutionCategory>>([]);
  const [category, setCategory] = useState<ISolutionCategory>();
  const [file, setFile] = useState({} as IFile);
  const [downloadFile, setDownloadFile] = useState({} as IFile);
  const [numberParts, setNumberParts] = useState<number>();
  const [numberSides, setNumberSides] = useState<number>();

  const formattedSides = Object.entries(sides).map((side) => ({
    optionText: i18n.t(`global.sides.${side[1]}`),
    value: side[1],
  }));

  useEffect(() => {
    api
      .get(`/categories`)
      .then((response) => {
        setFields(
          response.data.map((f: any) => ({
            ...f,
            value: f.id,
            optionText: f.name[i18n.language],
          }))
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (!!field) {
      api
        .get(`/categories/field/${field.id}`)
        .then((response) => {
          setCategories(
            response.data.map((c: any) => ({
              ...c,
              value: c.id,
              optionText: c.name[i18n.language],
            }))
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [field]);

  useEffect(() => {
    api.get(`/solutions/${solution_id}`).then((response) => {
      if (response.data.solution_category) {
        api
          .get(`/categories/${response.data.solution_category.id}`)
          .then((res) => {
            const category_data = res.data;
            if (!!category_data.solution_field) {
              setField({
                ...category_data.solution_field,
                name: category_data.solution_field.name[i18n.language as any]
              });
              setCategory({
                ...category_data,
                name: category_data.name[i18n.language as any]
              });
            } else {
              setField({
                ...category_data,
                name: category_data.name[i18n.language as any]
              });
            }
          });
      }

      const solutionSides: IOption[] = [];

      response.data.sides.forEach((side: string) => {
        const selectedSide = formattedSides.find((item) => item.value === side);
        if (selectedSide) solutionSides.push(selectedSide);
      });

      setSolution({
        ...response.data,
        sides: solutionSides,
      });
      setNumberParts(response.data.parts?.length || 1);
      setNumberSides(response.data.sides?.length || 1);
    });
  }, [solution_id]);

  useEffect(() => {
    if (numberParts && numberParts > solution.parts.length) {
      setSolution((prevState) => ({
        ...prevState,
        parts: [
          ...prevState.parts,
          ...Array.from(
            { length: numberParts - solution.parts.length },
            () => ""
          ),
        ],
      }));
      return;
    }

    if (numberParts && numberParts < solution.parts.length) {
      setSolution((prevState) => ({
        ...prevState,
        parts: prevState.parts.slice(0, numberParts),
      }));
      return;
    }
  }, [numberParts]);

  useEffect(() => {
    if (numberSides && numberSides > solution.sides.length) {
      setSolution((prevState) => ({
        ...prevState,
        sides: [...prevState.sides, { optionText: "", value: "" }],
      }));
      return;
    }

    if (numberSides && numberSides < solution.sides.length) {
      setSolution((prevState) => ({
        ...prevState,
        sides: prevState.sides.slice(0, numberSides),
      }));
      return;
    }
  }, [numberSides]);

  const [mouseOverImage, setMouseOverImage] = useState<boolean>(false);
  const [mouseOverFile, setMouseOverFile] = useState<boolean>(false);

  const submitForm = (values: any): void => {
    const solutionCategory =
      field && !category && !categories.length ? field : category;

    if (!solutionCategory) {
      showToast({ type: "error", message: "Informe a categoria da solução" });
      return;
    }

    var formData = new FormData();
    formData.append("name", values.name);
    formData.append("form_type", values.form_type);
    formData.append("suggested_price", values.suggested_price);
    formData.append("solution_category", JSON.stringify(solutionCategory));
    formData.append("link_guide", values.link_guide);
    if (solution.link_img) {
      formData.append("link_img", solution.link_img);
    }
    if (solution.download_link) {
      formData.append("download_link", solution.download_link);
    }
    formData.append("number_measures", values.number_measures);
    formData.append("is_custom", `${solution.is_custom}`);
    formData.append("is_standard", `${solution.is_standard}`);
    formData.append("is_download", `${solution.is_download}`);
    if (file) {
      formData.append("file", file.file_data);
    }
    if (downloadFile) {
      formData.append("downloadFile", downloadFile.file_data);
    }
    solution.parts.forEach((part) => {
      formData.append("parts", part);
    });
    solution.sides.forEach((side) => {
      formData.append("sides", side.value);
    });

    api
      .put(`/solutions/${solution_id}`, formData)
      .then(() => {
        showToast({
          type: "success",
          message: "Solução atualizada com sucesso!",
        });
        navigate("/creators/solutions");
      })
      .catch((error: AxiosError) => {
        showToast({
          type: "error",
          message: `Erro ao atualizar a solução. Detalhes: ${error.message}`,
        });
      });
  };

  const deleteSolution = () => {
    if (window.confirm("Realmente deseja excluir a solução?")) {
      api
        .delete(`/solutions/${solution_id}`)
        .then(() => {
          showToast({
            type: "success",
            message: "Solução deletada com sucesso!",
          });
          navigate("/creators/solutions");
        })
        .catch((error: AxiosError) => {
          showToast({
            type: "error",
            message: `Erro ao deletar a solução. Detalhes: ${error.message}`,
          });
        });
    }
  };

  const backPage = () => {
    navigate("/creators/solutions");
  };

  useEffect(() => {
    setNavigationAction({
      action: "back",
      hasButton: true,
      description: `Editar ${solution.name}`,
    });
  }, [solution]);

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(submitForm)}>
          <Flex>
            <Section>
              <SectionLabel>Infomações gerais</SectionLabel>
              <SubContainer>
                <CheckboxFlex>
                  <Checkbox
                    handleChange={() => {
                      setSolution((prevState) => ({
                        ...prevState,
                        is_custom: !prevState.is_custom,
                        is_download: !prevState.is_custom
                          ? false
                          : prevState.is_download,
                      }));
                    }}
                    checked={solution.is_custom}
                  />
                  <InputLabel>Personalizada</InputLabel>
                </CheckboxFlex>

                <CheckboxFlex>
                  <Checkbox
                    handleChange={() => {
                      setSolution((prevState) => ({
                        ...prevState,
                        is_standard: !prevState.is_standard,
                        is_download: !prevState.is_standard
                          ? false
                          : prevState.is_download,
                      }));
                    }}
                    checked={solution.is_standard}
                  />
                  <InputLabel>Padronizada</InputLabel>
                </CheckboxFlex>

                <CheckboxFlex>
                  <Checkbox
                    handleChange={() => {
                      setSolution((prevState) => ({
                        ...prevState,
                        is_download: !prevState.is_download,
                        is_custom: !prevState.is_download
                          ? false
                          : prevState.is_custom,
                        is_standard: !prevState.is_download
                          ? false
                          : prevState.is_standard,
                      }));
                    }}
                    checked={solution.is_download}
                  />
                  <InputLabel>É download</InputLabel>
                </CheckboxFlex>
              </SubContainer>

              <SelectLine>
                <SelectContainer>
                  <InputLabel>Categoria</InputLabel>
                  <Dropdown
                    options={fields}
                    handleSelect={(selected) => {
                      setField((prevState) => ({
                        ...prevState,
                        value: selected.value.toString(),
                        optionText: selected.optionText.toString(),
                        id: selected.value.toString(),
                        name: selected.optionText.toString(),
                      }));
                      setCategory((prevState) => undefined);
                    }}
                    selectedOption={field?.name && field.name}
                    placeholder="Selecione uma área ..."
                  />
                </SelectContainer>

                {(!!field || !!category) && (
                  <SelectContainer>
                    <InputLabel>Sub-categoria</InputLabel>
                    <Dropdown
                      options={categories}
                      handleSelect={(selected) => {
                        setCategory((prevState) => ({
                          ...prevState,
                          value: selected.value.toString(),
                          optionText: selected.optionText.toString(),
                          id: selected.value.toString(),
                          name: selected.optionText.toString(),
                        }));
                      }}
                      selectedOption={category && category.name ? category.name : ''}
                      placeholder="Selecione uma categoria..."
                    />
                  </SelectContainer>
                )}
              </SelectLine>

              <SubContainer>
                <InputContainer>
                  <InputLabel>Nome</InputLabel>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    defaultValue={solution.name}
                    inputWidth="380px"
                    ref={register({
                      required: {
                        value: true,
                        message: "Escreva o nome da solução!",
                      },
                    })}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>R$ Venda</InputLabel>
                  <Input
                    type="number"
                    id="suggested_price"
                    name="suggested_price"
                    step="0.01"
                    min="0.01"
                    defaultValue={solution.suggested_price}
                    inputWidth="100px"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputContainer>
              </SubContainer>

              <SubContainer>
                <InputContainer>
                  <InputLabel>Medidas</InputLabel>
                  <Input
                    type="number"
                    id="number_measures"
                    name="number_measures"
                    defaultValue={solution.number_measures}
                    inputWidth="70px"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputContainer>

                <InputContainer>
                  <InputLabel>Formulario</InputLabel>
                  <Input
                    type="text"
                    id="form_type"
                    name="form_type"
                    defaultValue={solution.form_type}
                    inputWidth="100px"
                    ref={register({
                      required: false,
                    })}
                  />
                </InputContainer>
              </SubContainer>
              <SubContainer>
                <InputContainer>
                  <InputLabel>Link do guia</InputLabel>
                  <Input
                    type="text"
                    id="link_guide"
                    name="link_guide"
                    defaultValue={solution.link_guide}
                    inputWidth="480px"
                    ref={register()}
                  />
                </InputContainer>
              </SubContainer>
            </Section>

            <Section>
              <SubContainer>
                <InputContainer>
                  <InputLabel>N. Lados</InputLabel>
                  <Input
                    type="number"
                    id="number_sides"
                    name="number_sides"
                    defaultValue={numberSides}
                    min={1}
                    // onChange={(e) => {
                    //   setNumberSides(+(e.target.value || "1"));
                    // }}
                    onChange={(event) => {
                      if (Number(event.target.value) > 3) return;
                      setNumberSides(Number(event.target.value));
                    }}
                    value={numberSides}
                    inputWidth="70px"
                    ref={register({
                      required: true,
                    })}
                  />
                </InputContainer>

                {solution?.sides?.map((side, i) => (
                  <SideSelectContainer>
                    <InputLabel>Lado {i + 1}</InputLabel>
                    <Dropdown
                      options={formattedSides}
                      handleSelect={(selected) => {
                        const sideAlreadySelected = solution.sides.find(
                          (item) => item.value === selected.value
                        );

                        if (sideAlreadySelected) return;

                        let tmpSides = [...solution.sides];
                        tmpSides[i] = selected;

                        setSolution((prevState) => ({
                          ...prevState,
                          sides: [...tmpSides],
                        }));
                      }}
                      selectedOption={solution?.sides[i]?.optionText}
                    />
                  </SideSelectContainer>
                ))}
              </SubContainer>
            </Section>

            {solution.is_standard && (
              <Section>
                <SubContainer>
                  <InputContainer>
                    <InputLabel>N. Partes</InputLabel>
                    <Input
                      type="number"
                      id="number_parts"
                      name="number_parts"
                      defaultValue={numberParts}
                      min={1}
                      onChange={(e) => {
                        setNumberParts(+(e.target.value || "1"));
                      }}
                      inputWidth="70px"
                      ref={register({
                        required: true,
                      })}
                    />
                  </InputContainer>

                  {solution.parts?.map((_, i) => (
                    <InputContainer key={i}>
                      <InputLabel>Parte {i + 1}</InputLabel>
                      <Input
                        type="text"
                        id="parts"
                        name="parts"
                        defaultValue={solution.parts[i]}
                        onChange={(e) => {
                          setSolution((prevState) => ({
                            ...prevState,
                            parts: prevState.parts.map((part, index) =>
                              index === i ? e.target.value : part
                            ),
                          }));
                        }}
                        inputWidth="100px"
                        ref={register()}
                      />
                    </InputContainer>
                  ))}
                </SubContainer>
              </Section>
            )}
            <Section>
              {!!solution.download_link && (
                <SubContainer>
                  <FileContainer
                    onMouseOver={() => {
                      setMouseOverFile(true);
                    }}
                    onMouseLeave={() => {
                      setMouseOverFile(false);
                    }}
                  >
                    <span>{solution.download_link}</span>
                    {mouseOverFile && (
                      <DeleteButton
                        type="button"
                        onClick={() => {
                          setSolution((prevState) => ({
                            ...prevState,
                            download_link: undefined,
                          }));
                        }}
                      >
                        Excluir
                      </DeleteButton>
                    )}
                  </FileContainer>
                </SubContainer>
              )}

              {!!solution.link_img && (
                <SubContainer>
                  <ImageContainer
                    onMouseOver={() => {
                      setMouseOverImage(true);
                    }}
                    onMouseLeave={() => {
                      setMouseOverImage(false);
                    }}
                  >
                    <Image
                      src={`${process.env.REACT_APP_STORAGE_BASE_URL}${solution.link_img}`}
                    />
                    {mouseOverImage && (
                      <DeleteButton
                        type="button"
                        onClick={() => {
                          setSolution((prevState) => ({
                            ...prevState,
                            link_img: undefined,
                          }));
                        }}
                      >
                        Excluir
                      </DeleteButton>
                    )}
                  </ImageContainer>
                </SubContainer>
              )}
            </Section>

            <Section>
              {!solution.download_link && solution.is_download && (
                <SubContainer>
                  <UploadFilesContainer>
                    <label>Arquivo para download</label>
                    <DropzoneContainer>
                      <DropZone
                        onUpload={(file) => {
                          const formattedFile = {
                            name: file[0].name,
                            size: file[0].size,
                            type: file[0].type,
                            file_data: file[0],
                          };
                          setDownloadFile(formattedFile);
                        }}
                      />
                    </DropzoneContainer>

                    {downloadFile.name && (
                      <File>
                        <span>{downloadFile.name}</span>
                        <RiDeleteBin7Line
                          onClick={() => setDownloadFile({} as IFile)}
                          size={20}
                          color="var(--fixit)"
                        />
                      </File>
                    )}
                  </UploadFilesContainer>
                </SubContainer>
              )}

              {!solution.link_img && (
                <SubContainer>
                  <UploadFilesContainer>
                    <label>Imagem de demonstração</label>
                    <DropzoneContainer>
                      <DropZone
                        accept={{'image/*': [".gif", ".jpeg",".jpg",".png"]}}
                        onUpload={(file) => {
                          const formattedFile = {
                            name: file[0].name,
                            size: file[0].size,
                            type: file[0].type,
                            file_data: file[0],
                          };
                          setFile(formattedFile);
                        }}
                      />
                    </DropzoneContainer>

                    {file.name && (
                      <File>
                        <span>{file.name}</span>
                        <RiDeleteBin7Line
                          onClick={() => setFile({} as IFile)}
                          size={20}
                          color="var(--fixit)"
                        />
                      </File>
                    )}
                  </UploadFilesContainer>
                </SubContainer>
              )}
            </Section>

            <FlexButtons>
              <div>
                <Cancel onClick={backPage} type="button">
                  Voltar
                </Cancel>
                <Submit type="submit">Atualizar</Submit>
              </div>
              <Delete onClick={deleteSolution} type="button">
                <RiDeleteBin7Line size={24} />
              </Delete>
            </FlexButtons>
          </Flex>
        </form>
      </Container>
    </>
  );
}
