import { i18n } from "../../../../../../../localization/i18n";

import { CustomSolutionForm, Solution } from "../../../../dtos";

interface CustomOrderInfosProps {
  solution: Solution;
  order: CustomSolutionForm;
}

export function CustomOrderInfos({ order, solution }: CustomOrderInfosProps) {
  return (
    <div className="order_resume">
      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.patient")}`}: </strong>
        {order.patient.name} - {order.patient.age}{" "}
        {`${i18n.t("orders.confirmation.years")}`}
      </span>

      {order?.partner?.name && (
        <span className="item">
          <strong>{`${i18n.t("orders.confirmation.partner")}`}: </strong>
          {order.partner.name}
        </span>
      )}

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.profissional")}`}: </strong>
        {order.exam.professional_name} -{" "}
        {order.exam.professional_job.optionText}
      </span>

      <span className="item">
        <strong>{`${i18n.t("orders.confirmation.solution")}`}: </strong>
        {solution.name} - {`${i18n.t("orders.confirmation.custom")} - ${i18n.t("global.sides." + order.exam.side.value)}`}
      </span>

      <span className="measures">
        <strong>{`${i18n.t("orders.confirmation.measures")}`}: </strong>
        {order.exam.measures?.map((item) => (
          <span>{item} cm |</span>
        ))}
      </span>
    </div>
  );
}
