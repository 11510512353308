import styled, { css } from "styled-components";

type ContainerProps = {
  buttonWidth?: number;
  buttonHeight?: number;
  startWithMinWidth?: boolean;
  fullWidth?: boolean;
  buttonColor?: string;
};

export const Container = styled.button<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px 20px;
  border-radius: 8px;
  border: 1px solid ${({ buttonColor }) => buttonColor};
  background: var(--white);

  color: ${({ buttonColor }) => buttonColor};
  font-size: 14px;

  cursor: pointer;
  transition: all 0.3s;

  ${({ startWithMinWidth }) =>
    startWithMinWidth &&
    css`
      min-width: 200px;
    `};

  ${({ buttonWidth }) =>
    buttonWidth &&
    css`
      width: ${buttonWidth}px;
    `};

  ${({ buttonHeight }) =>
    buttonHeight &&
    css`
      height: ${buttonHeight}px;
    `};

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `};

  &:hover {
    background: ${({ buttonColor }) => buttonColor};
    color: var(--white);
  }

  &:disabled {
    border: 1px solid var(--gray);
    color: var(--gray);
    cursor: not-allowed;

    &:hover {
      background: transparent;
    }
  }

  .loading {
    border: 3px solid var(--white);
    border-top: 3px solid var(--gray);
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 0.4s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
