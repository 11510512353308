import { i18n } from "../../../../../../localization/i18n";

export const licenseeStatus = [
  { optionText: "Todos", value: "all" },
  { optionText: "Ativos", value: "active" },
  { optionText: "Bloqueados", value: "blocked" },
];

export const nullOption = {
  optionText: i18n.t("global.novalue"),
  value: "",
};
