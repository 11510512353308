import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
`;

export const Separator = styled.div`
background: var(--light-gray);
height: 1px;
width: 100%;
margin: 0 0 1rem;
`
export const AssociateContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;
margin: 0 0 1rem;
`

export const FixitLogo = styled.img`
width: 100px;
height: 100px;
border-radius: 50%;
margin-right: 20px;
background-color: var(--dark-gray);
object-fit: cover;
`

export const DataContainer = styled.div`
h3{
    color: var(--dark-gray);
}

p{
    color: var(--gray);
}
`