import { MouseEvent, ReactNode, TouchEvent } from "react";

import { ClickAwayListener } from "@mui/material";

import { MdCheckCircleOutline } from "react-icons/md";

import { Container, Option } from "./styles";

interface ActionsPopupProps {
  options: Array<{
    text: string;
    icon: ReactNode;
    active?: boolean;
    warning?: boolean;
    action: () => void;
  }>;
  onClickAway?: (event: any) => void;
}

export function ActionsPopup({ options, onClickAway }: ActionsPopupProps) {
  return (
    <ClickAwayListener
      onClickAway={(event) => {
        onClickAway && onClickAway(event);
      }}
    >
      <Container>
        {options.map((option, index) => {
          return (
            <Option
              key={index}
              warning={option?.warning ?? false}
              onClick={(event) => {
                event.stopPropagation();
                option.action();
              }}
            >
              {option.icon}

              <span>{option.text}</span>

              {option?.active && (
                <div className="active_icon">
                  <MdCheckCircleOutline />
                </div>
              )}
            </Option>
          );
        })}
      </Container>
    </ClickAwayListener>
  );
}
