import { useAuth } from "../../../../../../../../hooks/AuthContext";
import { BillStatusIndicator, Container, Content } from "./styles";

interface IBillsListInfo {
  referring_period: string | undefined;
  status: string | undefined;
  bill_value: string | undefined;
}
export function BillsList({
  referring_period,
  status,
  bill_value,
}: IBillsListInfo) {
  const { currency } = useAuth();

  return (
    <>
      <Container>
        <Content>
          <span>{referring_period}</span>
          <span>{`${currency?.symbol} ${bill_value}`}</span>
          <BillStatusIndicator paid={status} />
        </Content>
      </Container>
    </>
  );
}
